import { makeStyles } from '@mui/styles';
import Chip from '@mui/material/Chip'; 

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: '4px',
    },
  },
}));

export default function Chips(props) {
  const classes = useStyles();

  // console.log(props.data2)
  // console.log(props.workmodetypes)

  return (
    <div className={classes.root} 
    // style={{ width: "100%", flexWrap: "nowrap" }}
    >

      {localStorage.getItem("jobtypeFlages") !== null ? JSON.parse(localStorage.getItem("jobtypeFlages")).map(data =>
        <Chip
          style={{ backgroundColor: "rgb(177, 205, 234)" }}
          label={props.jobtypes.map(jobtype => {
            if (jobtype.id === data) {
              return (jobtype.name !== undefined ? jobtype.name : jobtype.text)
            }
          })}
          onDelete={() => { props.jobtypeFunction(data, "calledfromchips");}}
        />
      ) : ""}

      {localStorage.getItem("visatypeFlages") !== null ? JSON.parse(localStorage.getItem("visatypeFlages")).map(data =>
        <Chip
          style={{ backgroundColor: "rgb(177, 205, 234)" }}
          label={props.visaOptions !== undefined ? props.visaOptions.map(visa => {
            if (visa.text === data) {
              return (visa.text !== undefined ? visa.text : visa.text)
            }else if(visa.id === data){
              return (visa.id !== undefined ? visa.text : visa.text)

            }
          }) : data}
          onDelete={() => { props.handleVisatype(data, "calledfromchips") }}
        />
      ) : ""}

      {props.data3.map(data =>
        <Chip
          style={{ backgroundColor: "rgb(177, 205, 234)" }}
          label={"OPT Issued:" + data}
          onDelete={() => { props.handleOptIssuedYear(data) }}
        />
      )}
      
      {props.data4.map(data =>
        <Chip
          style={{ backgroundColor: "rgb(177, 205, 234)" }}
          label={data}
          onDelete={() => { props.handleGradYear(data) }}
        />
      )}

      {localStorage.getItem("traveltypeFlages") !== null ? JSON.parse(localStorage.getItem("traveltypeFlages")).map(data =>
        <Chip
          style={{ backgroundColor: "rgb(177, 205, 234)" }}
          label={props.traveltypes.map(exp => {
            if (exp.id === JSON.parse(data)) {
              return (exp.name !== undefined ? exp.name : exp.text)
            }
          })}
          onDelete={() => { props.handleTraveltype(data, "calledfromchips") }}
        />
      ) : ""}


      {localStorage.getItem("jobsSortByDate") === 'jobsSortByDate' ?
        <Chip
          style={{ backgroundColor: "rgb(177, 205, 234)" }}
          label={props.lastupdated.map(updated => {
            if (updated.id === props.data6) {
              return (updated.name !== undefined ? updated.name : updated.text)
            }
          })}
          onDelete={() => { props.selectedOptionValue("remove","calledfromchips"); localStorage.removeItem("jobsSortByDate")  }}
        /> : ''}

      {localStorage.getItem("jobsSortBy") !==null ?
        <Chip
          style={{ backgroundColor: "rgb(177, 205, 234)" }}
          label={localStorage.getItem("jobsSortBy")}
          onDelete={() => { props.sortby("remove","calledfromchips") }}
        /> : ''}


      {localStorage.getItem("workModeFlages") !== null ? JSON.parse(localStorage.getItem("workModeFlages")).map(data =>
        <Chip
          style={{ backgroundColor: "rgb(177, 205, 234)" }}
          label={props.workmodetypes.map(work => {
            if (work.id === data) {
              return (work.name !== undefined ? work.name : work.name)
            }
          })}
          onDelete={() => { props.handleworkmode(data, "calledfromchips") }}
        />
      ) : ""}



    </div>
  );
}
