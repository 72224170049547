import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import ReactQuill from 'react-quill';
import axios from 'axios';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Key from "../clientVariables.json";
import Select from "react-select";
import { AiOutlineInfoCircle } from "react-icons/ai";
import 'react-quill/dist/quill.snow.css';
import { CircularProgress } from '@mui/material';
import { useHistory } from 'react-router-dom';

let openAISkills = require('../Components/OpenAIComponent')


const JobEditForm = () => {
    const navigate = useHistory();
    const formikRef = useRef();
    const location = useLocation()
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Mobile devices
    const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md")); // Tablets

    const [currentJobDetails, setCurrentJobDetails] = useState();
    const [jobTypeList, setJobTypeList] = useState([]);
    const [skillsLoading, setSkillsLoading] = useState(false);
    const [jobtypeIds, setJobtypeIds] = useState([]);
    const [screenerid, setScreenerid] = useState("");
    const [isRemote, setIsRemote] = useState();
    const [Hybrid, setHybrid] = useState(false)
    const [isMapJob, setIsMapJob] = useState(true);
    const [visatypeIds, setVisatypeIds] = useState([]);
    const [visaTypeList, setVisaTypeList] = useState([]);
    const [degrees, setDegrees] = useState([]);
    const [citiesList, setCitiesList] = useState([]);
    const [statesList, setStatesList] = useState([]);
    const [zipcodes, setZipCodes] = useState([]);

    const experience = [
        { key: 0, value: "0-3 Years", text: "0-3 Years" },
        { key: 1, value: "4-6 Years", text: "4-6 Years" },
        { key: 2, value: "7-10 Years", text: "7-10 Years" },
        { key: 3, value: "above 10 Years", text: "above 10 Years" },
    ];

    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

    const rowStyle = {
        '--bs-gutter-x': '0.5 rem',
        'paddingTop': '14px'

        // set the --bs-gutter-x value to 1rem
    };

    const handleJobtypeChange = (e) => {
        setJobtypeIds(Array.isArray(e) ? e.map((x) => x.value) : []);
    };

    const handleVisatypeChange = (e) => {
        setVisatypeIds(Array.isArray(e) ? e.map((x) => x.value) : []);
        //  setSkilltitles(Array.isArray(e) ? e.map((x) => x.label) : []);
    };

    const handleChangeHybrid = (e) => {
        let checked = e.target.checked;
        setHybrid(checked);
        setIsRemote(false);
    };

    const selectScreener = (id) => {
        setScreenerid(id);
        formikRef.current.setFieldValue("experience", id);
    };

    const onTextEditorchanged = (value) => {
        if (formikRef.current) {
            formikRef.current.setFieldValue("description", value);
        }
    };


    const onTextEditorBlured = async (e, editor) => {

        var desc = formikRef.current?.values?.description?.replace(/<(?:.|\n)*?>/gm, '')

        if (desc) {
            setSkillsLoading(true);
            var msg = "This is job description, it has job_role, skills and other details can you extract me {job_role (excluding any additional details or qualifications), skills (in shortforms as list)} only, no other information and make it as json  " + desc;


            openAISkills.openAI(msg, (error, results) => {


                if (error) {
                    setSkillsLoading(false);

                } else {
                    var data = results

                    var skillss = data && data.skills ? data.skills : [];

                    if (Array.isArray(skillss)) {
                        var skills = skillss.length > 0 ? skillss
                            .map((s) => s)
                            .join(", ")
                            : "";
                    } else {
                        const skillString = skillss.split(",");
                        var skills = skillString.length > 0 ? skillString
                            .map((s) => s)
                            .join(", ")
                            : "";
                    }
                    formikRef.current.setFieldValue("skills", skills);
                    setSkillsLoading(false);
                }
            });

        } else {
            formikRef.current.setFieldValue("skills", "");
        }

    };

    const getJobTypes = () => {
        axios
            .get(`${Key.domain}/commonAPIs/get_all_jobtypes`, {
                method: "GET", // *GET, POST, PUT, DELETE, etc.
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
                },
            })
            .then((resdata) => {
                setJobTypeList(
                    resdata.data.data.map((job) => ({
                        value: job.id,
                        label: job.title,
                    }))
                );
            });

        if (
            currentJobDetails
        ) {
            var jobtypeids = currentJobDetails.jobtype.split(",").map((i) => Number(i));
            setJobtypeIds(jobtypeids);
        }
    };
    const getVisaTypes = () => {
        axios
            .get(`${Key.domain}/commonAPIs/get_all_visatypes`, {
                method: "GET", // *GET, POST, PUT, DELETE, etc.
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
                },
            })
            .then((resdata) => {
                setVisaTypeList(
                    resdata.data.data.map((visa) => ({
                        value: visa.id,
                        label: visa.type_code,
                        code: visa.type_name,
                    }))
                );
            });

        if (
            currentJobDetails
        ) {
            let workpermitid = currentJobDetails.workpermit.split(",").map((i) => Number(i));
            setVisatypeIds(workpermitid);
        }
    };
    const handleChangeRemote = (e) => {
        let checked = e.target.checked;
        setIsMapJob(!checked);
        setIsRemote(checked);
        setHybrid(false);
    };

    const handleChangeJobMap = (e) => {
        let checked = e.target.checked;
        setIsMapJob(checked);
        setIsRemote(!checked);
    };

    const getDegrees = () => {
        axios
            .get(`${Key.domain}/commonAPIs/get_all_educations_list`, {
                method: "GET", // *GET, POST, PUT, DELETE, etc.
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
                },
            })
            .then((resdata) => {
                setDegrees(resdata.data.data);
            });
    }
    const getStates = (countryid, values) => {
        axios
            .post(`${Key.domain}/commonAPIs/get_states_by_countryid`, {
                country_id: countryid,
            })
            .then((response) => {
                setStatesList(response.data.data);
                if (formikRef.current) {
                    formikRef.current.setFieldValue("country", countryid || 233);
                }
            });
    };

    const getCities = (stateid, statename) => {
        axios
            .post(`${Key.domain}/commonAPIs/get_cities_by_stateid`, {
                state_id: stateid,
            })
            .then((response) => {
                setCitiesList(response.data.data);
                if (formikRef.current) {
                    formikRef.current.setFieldValue("state", stateid);
                }
            });
    };

    const getZipCodesBasedOnCity = (cityId) => {
        axios
            .post(`${Key.domain}/commonAPIs/get_zipcodes_By_cityId`, {
                city_id: cityId,
            })
            .then((response) => {
                setZipCodes(response.data.data.map((data) => data.zipcode));
            });
    };
    const getJobDetails = (jobId) => {
        axios.post(`${Key.domain}/Jobs/get_job_details`,
            {
                "id": jobId
            }).then(res => {
                if (res && res.data && res.data.data?.length > 0) {
                    setCurrentJobDetails(res?.data?.data[0]?.jobDetails)
                    let result = res?.data?.data[0]?.jobDetails;
                    if (formikRef?.current) {
                        formikRef?.current.setFieldValue('jobTitle', result?.title);
                        formikRef?.current.setFieldValue('jobType', result?.jobtype);
                        formikRef?.current.setFieldValue('experience', result?.experience);
                        formikRef?.current.setFieldValue('visaType', result?.visatypeName);
                        formikRef?.current.setFieldValue('skills', result?.prefferd_skillnames ?? result?.ai_skills ?? "");
                        formikRef?.current.setFieldValue('requiredTravel', result?.requiredtravel);
                        formikRef?.current.setFieldValue('description', result?.description);
                        formikRef?.current.setFieldValue('keywords', result?.metakeywords);
                        formikRef?.current.setFieldValue('NoOfJobs', result?.noofjobs);
                        formikRef?.current.setFieldValue('duration', result?.duration);
                        formikRef?.current.setFieldValue('qualifications', result?.qualifications);
                    }
                    getJobTypes()
                    getVisaTypes()
                    getDegrees();


                    setIsRemote(
                        result?.is_remote === 1 ? true : false
                    );
                    setIsMapJob(
                        result?.is_remote === 0 ? true : false
                    );
                    getStates(233);
                    getCities(parseInt(result?.state))
                    getZipCodesBasedOnCity(parseInt(result?.zipcode))

                    return result;

                }
            }).then(res => {
                setJobtypeIds(res.jobtype)
                setVisatypeIds(res.workpermit)
                formikRef.current?.setFieldValue("city", res?.city);
                formikRef.current?.setFieldValue("zipcode", res?.zipcode);
            })
    }

    const updateJobDetails = (fields) => {
        let stateObj = statesList.filter(x => x.id === parseInt(fields.state)).length > 0 ? statesList.filter(x => x.id === parseInt(fields.state))[0] : {};
        let cityObj = citiesList.filter(x => x.id === parseInt(fields.city)).length > 0 ? citiesList.filter(x => x.id === parseInt(fields.city))[0] : {};

        let workpermitNames = visaTypeList.filter(x => visatypeIds.includes(x.value)).length > 0 ?
            visaTypeList.filter(x => visatypeIds.includes(x.value)).map(x => x.code) : "";

        let jobTypeNames = jobTypeList.filter(x => jobtypeIds.includes(x.value)).length > 0 ?
            jobTypeList.filter(x => jobtypeIds.includes(x.value)).map(x => x.label) : ""

        axios
            .post(`${Key.domain}/manual/update_job`, {
                id: location.state.jobId,
                title: fields.jobTitle,
                alias: fields.jobTitle,
                jobtype: Array.isArray(jobtypeIds) ? jobtypeIds.join(',') : jobtypeIds,
                description: fields.description,
                Qualifications: fields.qualifications,
                screener_id: screenerid,
                ai_skills: fields.skills,
                skills: fields.skills,
                experience: fields.experience,
                country: fields.country,
                state: isRemote === 1 ? "" : fields.state,
                city: isRemote === 1 ? "" : fields.city,
                metadescription: fields.metaDescription,
                metakeywords: fields.keywords,
                workpermit: Array.isArray(visatypeIds) ? visatypeIds.join(',') : visatypeIds,
                requiredtravel: fields.requiredTravel,
                experience: fields.experience,
                isRemote: isRemote === true ? 1 : Hybrid ? 2 : 0,
                zipcode: fields.zipcode,
                map_enable: isMapJob === true ? 1 : 0,
            }).then(() => {
                navigate.goBack();

            })
        axios.post(`${Key.domain}/manual/update_job_in_solr`, {
            currentJobDetails: location.state.solrJobDetails,
            data: {
                id: location.state.jobId,
                title: fields.jobTitle,
                alias: fields.jobTitle,
                jobtype: jobtypeIds,
                description: fields.description,
                qualifications: fields.qualifications,
                screener_id: screenerid,
                ai_skills: fields.skills,
                prefferdskillsName: fields.skills,
                skills: fields.skills,
                experience: fields.experience,
                country: fields.country,
                state: isRemote === 1 ? "" : String(fields.state),
                city: isRemote === 1 ? "" : String(fields.city),
                stateName: stateObj.name,
                cityName: cityObj.cityName,
                workpermitName: workpermitNames,
                jobtypeName: jobTypeNames,
                metadescription: fields.metaDescription,
                metakeywords: fields.keywords,
                workpermit: visatypeIds,
                requiredtravel: fields.requiredTravel,
                experience: fields.experience,
                isRemote: isRemote === true ? 1 : Hybrid ? 2 : 0,
                zipcode: fields.zipcode,
                map_enable: isMapJob === true ? 1 : 0,
            }

        })





    }
    useEffect(() => {
        getJobDetails(location.state.jobId)
    }, [])



    return <Formik
        innerRef={ formikRef }
        initialValues={
            {
                jobTitle: currentJobDetails?.title,
                NoOfJobs: "",
                contactName: "",
                contactEmail: "",
                contactNumber: "",
                jobType: "",
                experience: currentJobDetails?.experience,
                visaType: currentJobDetails?.visatypeName,
                duration: "",
                description: "",
                qualifications: "",
                metaDescription: "",
                keywords: "",
                country: 233,
                state: "",
                city: "",
                skills: currentJobDetails?.prefferd_skillnames ?? currentJobDetails?.ai_skills ?? "",
                requiredTravel: currentJobDetails?.requiredtravel,
                salaryType: "",
                companyName: "",
                stateName: "",
                cityName: "",
                companyId: "",
                zipcode: "",
                qualifications: currentJobDetails?.qualifications
            }
        }
        validationSchema={ Yup.object().shape({
            jobTitle: Yup.string().required("Job Title is Required"),
            qualifications: Yup.string().required("Qualification Required"),
            state: isRemote
                ? Yup.string()
                : Yup.string().required("Select From Dropdown"),
            city: isRemote
                ? Yup.string()
                : Yup.string().required("Select From Dropdown"),
            // skills: Yup.string().required("Select from dropdown"),
            zipcode: Yup.string(),
        }) }
        onSubmit={ (fields) => {
            updateJobDetails(fields)
        } }
    >
        { ({ errors, status, touched, values, setFieldValue }) => (
            <Form>
                <div style={ { height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' } }>
                    <div className="container card" style={ { border: "1px solid lightgray", padding: '1rem' } }>
                        <div className="row" style={ { rowStyle } }>
                            <div className="col-md-12 row " style={ rowStyle }>
                                <div className={ `col-md-${isMobile || isTablet ? '12' : '7'} row` }>
                                    <div className={ `form-group1 ${isMobile || isTablet ? 'col-12' : 'col-6'}` }>
                                        <label htmlFor="jobTilte">
                                            Job Title<span style={ { color: "red" } }>*</span>
                                        </label>
                                        <Field
                                            name="jobTitle"
                                            type="text"
                                            placeholder="Job Tilte"
                                            value={ values.jobTitle }
                                            maxLength={ 50 }
                                            className={
                                                "form-control" +
                                                (errors.jobTitle && touched.jobTitle
                                                    ? " is-invalid"
                                                    : "")
                                            }

                                        />
                                        <ErrorMessage
                                            name="jobTitle"
                                            component="div"
                                            className="invalid-feedback"
                                        />

                                    </div>
                                    <div className={ `form-group1 ${isMobile || isTablet ? 'col-12' : 'col-6'}` }>
                                        <label htmlFor="jobtype">
                                            Job Type <span style={ { color: "red" } }>*</span>
                                        </label>
                                        <Select
                                            isMulti
                                            name="jobType"
                                            styles={ selectStyles }

                                            placeholder="Select a Job Type"
                                            className={ "custom-jobType-select" + (errors.jobType && touched.jobType ? "-invalid" : "valid") }

                                            options={ jobTypeList }
                                            onChange={ handleJobtypeChange }
                                            value={ jobTypeList.filter((obj) =>
                                                jobtypeIds.includes(obj.value)
                                            ) }
                                            style={ { border: "1px solid #000" } }
                                        ></Select>
                                        { touched.jobType && errors.jobType &&
                                            <div class="invalid-feedback" style={ { display: "flex" } }>{ errors.jobType }</div> }
                                    </div>
                                    <div className="col-md-12 mt-4">
                                        <div className={ `form-group1 ${isMobile || isTablet ? 'col-12' : 'col-12'}` }>
                                            <label htmlFor="description">
                                                Description
                                                {/* <span style={{ color: "red" }}>*</span> */ }
                                            </label>
                                            &nbsp;&nbsp;&nbsp;
                                            <span
                                                style={ {
                                                    fontSize: "15px",
                                                    color: "#406882",
                                                    cursor: "pointer",
                                                } }
                                            // onClick={ handleShow }
                                            >
                                                <AiOutlineInfoCircle color="#406882" />
                                                &nbsp; Review list of Job Postings not permitted on
                                                Jobsnprofiles
                                            </span>
                                            <div style={ { overflowY: 'scroll', height: '400px', scrollbarWidth: 'none', msOverflowStyle: 'none', } }>
                                                <ReactQuill theme="snow"
                                                    // onChange={(e) => onTextEditorchanged(e)}
                                                    style={ { border: '0px solid black' } }
                                                    name="description"
                                                    value={ values.description }
                                                    onChange={ (e) => onTextEditorchanged(e) }
                                                    // onBlur={ onTextEditorBlured }

                                                />
                                            </div>
                                        </div>
                                    </div>




                                </div>
                                <div className={ `col-md-${isMobile || isTablet ? '12' : '5'} ` }>
                                    <div className="col-md-12 row">
                                        <div className="col-md-6">
                                            <div className="form-group1">
                                                <label htmlFor="visaType">
                                                    VisaType /Work Permit
                                                    <span style={ { color: "red" } }>*</span>
                                                </label>
                                                <Select
                                                    isMulti
                                                    closeMenuOnSelect={ false }
                                                    name="visaType"
                                                    placeholder="Select VisaType/ Work permit"
                                                    options={ visaTypeList }
                                                    className={ "custom-visaType-select" + (errors.visaType && touched.visaType ? "-invalid" : "valid") }
                                                    onChange={ handleVisatypeChange }
                                                    value={ visaTypeList.filter((obj) =>
                                                        visatypeIds.includes(obj.value)
                                                    ) }
                                                ></Select>
                                                { touched.visaType && errors.visaType &&
                                                    <div class="invalid-feedback" style={ { display: "flex" } }>{ errors.visaType }</div> }
                                            </div>
                                        </div>
                                        <div className="col-md-6" style={ { paddingRight: "16px" } }>
                                            <div className="form-group1">
                                                <label htmlFor="videoURL">Experience</label>
                                                <Field
                                                    name="experience"
                                                    as="select"
                                                    className="form-select form-control"
                                                    onChange={ (e) => {

                                                        selectScreener(e.target.value);

                                                        formikRef.current.setFieldValue(
                                                            "experience",
                                                            e.target.value

                                                        );
                                                    } }
                                                    defaultValue={ values.experience }
                                                >
                                                    <option selected>Select Experience</option>

                                                    { experience.map((exp) => (
                                                        <option value={ exp.value }>{ exp.text }</option>

                                                    )) }
                                                </Field>
                                            </div>
                                        </div>
                                        <div>
                                        </div>



                                    </div>

                                    <div className="col-12 mt-4" style={ { paddingRight: "26px" } }>
                                        <label htmlFor="skills">Skills  <span style={ { color: "red" } }>* </span>
                                            { skillsLoading ? <><span>Generating skills please wait..  </span> <CircularProgress
                                                size={ 16 }
                                            /> </> : "" }  </label>

                                        <Field
                                            style={ { height: "70px" } }
                                            name="skills"
                                            value={ values.skills }
                                            type="text"
                                            as="textarea"
                                            placeholder="Skills"
                                            className="form-control"
                                        />



                                    </div>
                                    <div className="form-group1  col-12" style={ { paddingRight: "13px" } }>
                                        <label htmlFor="keywords">
                                            Keywords
                                        </label>
                                        <Field
                                            style={ { height: "40px" } }
                                            name="keywords"
                                            type="text"
                                            as="textarea"
                                            placeholder="Keywords"
                                            className="form-control"
                                        />

                                    </div>
                                    <div className="col-md-12 row">
                                        <div className="col-md-4">
                                            <label htmlFor="qualifications">
                                                Qualification<span style={ { color: "red" } }>*</span>
                                            </label>
                                            <Field name="qualifications" as="select"
                                                className={
                                                    "form-control" +
                                                    (errors.qualifications && touched.qualifications
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                                onChange={ (e) => formikRef.current.setFieldValue(
                                                    "qualifications",
                                                    e.target.value

                                                ) }
                                                value={ values.qualifications }
                                            >
                                                <option selected>Select Degree</option>
                                                { degrees.map(degree =>
                                                    <option value={ degree.title }>{ degree.title }</option>
                                                ) }



                                            </Field>

                                            <ErrorMessage
                                                name="qualifications"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>


                                        <div class="col-md-8 row">
                                            <div class="form-check col-5">
                                                <input
                                                    class="form-check-input "
                                                    type="checkbox"
                                                    id="remoteCheck"
                                                    onChange={ (e) => handleChangeRemote(e) }
                                                    checked={ isRemote }
                                                />
                                                <label class="form-check-label" for="remoteCheck">
                                                    Remote
                                                </label>
                                            </div>

                                            <div class="form-check col-5">
                                                <input
                                                    class="form-check-input "
                                                    type="checkbox"
                                                    id="remoteCheck"
                                                    onChange={ (e) => handleChangeHybrid(e) }
                                                    checked={ Hybrid }
                                                />
                                                <label class="form-check-label" for="remoteCheck">
                                                    Hybrid
                                                </label>
                                            </div>

                                            <div className="col-md-7">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    id="remoteCheck"
                                                    onChange={ (e) => handleChangeJobMap(e) }
                                                    checked={ isMapJob }
                                                />
                                                &nbsp;
                                                <label class="form-check-label" for="remoteCheck">
                                                    Post your job by map
                                                </label>
                                            </div>
                                        </div>

                                        { isRemote ? (
                                            ""
                                        ) : (
                                            <div className="row col-12" style={ { paddingRight: '0px', paddingRight: '0px', right: 0 } }>

                                                <div className="col-md-4" style={ { paddingRight: "0px" } }>
                                                    <div className="form-group1">
                                                        <label htmlFor="state">
                                                            State<span style={ { color: "red" } }>*</span>
                                                        </label>
                                                        <Field
                                                            name="state"
                                                            as="select"
                                                            className={
                                                                "form-select form-control" +
                                                                (errors.state && touched.state
                                                                    ? " is-invalid"
                                                                    : "")
                                                            }
                                                            onChange={ (e) => getCities(e.target.value, e.target.id) }
                                                            defaultValue={ values.state }
                                                        >
                                                            <option selected>Select state</option>
                                                            { statesList.map((state) => (
                                                                <option
                                                                    value={ state.id }
                                                                    id={ state.name }
                                                                    key={ state.id }
                                                                >
                                                                    { state.name }
                                                                </option>
                                                            )) }
                                                        </Field>

                                                        <ErrorMessage
                                                            name="state"
                                                            component="div"
                                                            className="invalid-feedback"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-4" style={ { paddingRight: "0px" } }>
                                                    <div className="form-group1">
                                                        <label htmlFor="city">
                                                            City<span style={ { color: "red" } }>*</span>
                                                        </label>
                                                        <Field
                                                            name="city"
                                                            as="select"
                                                            className={
                                                                "form-select form-control" +
                                                                (errors.city && touched.city
                                                                    ? " is-invalid"
                                                                    : "")
                                                            }
                                                            onChange={ (e) => {
                                                                formikRef.current.setFieldValue(
                                                                    "city",
                                                                    e.target.value
                                                                );
                                                                getZipCodesBasedOnCity(e.target.value)
                                                            } }
                                                        >
                                                            <option selected>Select city</option>
                                                            { citiesList.map((city) => (
                                                                <option
                                                                    value={ city.id }
                                                                    id={ city.cityName }
                                                                    key={ city.id }
                                                                >
                                                                    { city.cityName }
                                                                </option>
                                                            )) }
                                                        </Field>

                                                        <ErrorMessage
                                                            name="city"
                                                            component="div"
                                                            className="invalid-feedback"
                                                        />
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-4" style={ { paddingRight: "0px" } }>
                                                    <div className="form-group1">
                                                        <label htmlFor="zipcode">
                                                            Zipcode

                                                        </label>
                                                        <Field
                                                            name="zipcode"
                                                            as="select"
                                                            className={
                                                                "form-select form-control" +
                                                                (errors.zipcode && touched.zipcode
                                                                    ? " is-invalid"
                                                                    : "")
                                                            }
                                                            onChange={ (e) => {
                                                                // setZipCode(e.target.value);
                                                                formikRef.current.setFieldValue(
                                                                    "zipcode",
                                                                    e.target.value
                                                                );
                                                            } }
                                                            onBlur={ (e) => {
                                                                // getZipcodeCoordinates(e.target.value, e);
                                                            } }
                                                        >
                                                            <option selected>Select Zipcode</option>
                                                            { zipcodes.map((zipcode) => (
                                                                    <option
                                                                        value={ zipcode }
                                                                        id={ zipcode }
                                                                        key={ zipcode }
                                                                    >
                                                                        { zipcode }
                                                                    </option>
                                                            )) }
                                                        </Field>

                                                        <ErrorMessage
                                                            name="zipcode"
                                                            component="div"
                                                            className="invalid-feedback"
                                                        />
                                                    </div>
                                                </div> */}
                                                    <div className="col-md-4" style={{ paddingRight: "0px" }}>
                                                        <div className="form-group1">
                                                            <label htmlFor="zipcode">
                                                                Zipcode<span style={{ color: "red" }}>*</span>
                                                            </label>
                                                            {zipcodes?.length === 0 ? (
                                                                // Render an input field if zipcodes array is empty
                                                                <Field
                                                                    name="zipcode"
                                                                    type="text"
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.zipcode && touched.zipcode ? " is-invalid" : "")
                                                                    }
                                                                    placeholder="Enter Zipcode"
                                                                    onBlur={(e) => {
                                                                        getZipCodesBasedOnCity(e.target.value, e);
                                                                    }}
                                                                />
                                                            ) : (
                                                                // Render a select field if zipcodes array is not empty
                                                                <Field
                                                                    name="zipcode"
                                                                    as="select"
                                                                    className={
                                                                        "form-select form-control" +
                                                                        (errors.zipcode && touched.zipcode ? " is-invalid" : "")
                                                                    }
                                                                    onChange={(e) => {
                                                                        formikRef.current.setFieldValue("zipcode", e.target.value);
                                                                        // setCityName(e.target[e.target.selectedIndex].id);
                                                                    }}
                                                                    onBlur={(e) => {
                                                                        getZipCodesBasedOnCity(e.target.value, e);
                                                                    }}
                                                                >
                                                                    <option selected>
                                                                        Select Zipcode
                                                                    </option>
                                                                    {zipcodes.map((zipcode) => (
                                                                        <option value={zipcode} id={zipcode} key={zipcode}>
                                                                            {zipcode}
                                                                        </option>
                                                                    ))}
                                                                </Field>
                                                            )}
                                                            <ErrorMessage name="zipcode" component="div" className="invalid-feedback" />
                                                        </div>
                                                    </div>


                                            </div>
                                        ) }
                                    </div>
                                    <div className="col-12 row mt-2" >
                                        <div className="row col-md-12 align-items-center" >
                                            <div class="form-check col-md-6 " style={ { paddingLeft: "8%" } }>
                                            </div>
                                            <div className="row col-md-6 align-items-center">
                                                <button
                                                    type="button"
                                                    class="btn btn-outline-secondary col-5"
                                                    onClick={ () => navigate.goBack() }
                                                    style={ { height: '40px', width: 'fit-content' } }
                                                >
                                                    Cancel
                                                </button>

                                                &nbsp;

                                                <button type="submit"

                                                    className="btn btn-primary col-5"
                                                    id="update_profile"
                                                    style={ { height: '40px', width: 'fit-content' } }
                                                >
                                                    Update
                                                </button>

                                                &nbsp;
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </Form>

        ) }
    </Formik>


}

export default JobEditForm