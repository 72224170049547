import React, { Component } from "react";
import { FiChevronRight } from 'react-icons/fi';

class PageLoader extends Component {
    state = {};
    render() {
    //  console.log("toast called")
        return (
            <div
                style={{
                    height: "100%",
                    borderLeft: "5px solid #406882",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: 5,
                    progress: 0.2,
                    backgroundColor: "#fff",
                    textAlign: "center"
                }}                            >
                <FiChevronRight color={"#406882"} height="25px" width="25px" />{"  "}
                <span style={{ marginLeft: 5, color: "#000", fontSize: "17px" }}>{this.props.message}</span>
            </div>
        );
    }
}



export default PageLoader