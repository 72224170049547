import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    Grid,
    IconButton,
    Modal,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Tooltip,
    Typography,
    tableCellClasses,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Key from "../clientVariables.json";
import {
    BusinessCenterRounded,
    ChecklistRounded,
    CloseRounded,

    KeyboardDoubleArrowUpRounded,
    SwitchAccessShortcutAddRounded,
} from "@mui/icons-material";
import moment from "moment";
import { MdFlight, MdOutlineWorkHistory } from "react-icons/md";
import { FaUserGraduate } from "react-icons/fa";
import { FaListCheck } from "react-icons/fa6";
import parse from "html-react-parser";
import { styled, alpha } from "@mui/material/styles";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import '../Components/Employer/myJobs/styles.css';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputBase from '@mui/material/InputBase';
import AddIcon from '@mui/icons-material/Add';
import { useResponsiveStyles } from "../Styles/responsiveStyles";
import LoadingSkeletonJobView from "../Components/Employer/myJobs/JobViewLoadingSkeletons";
import ToastMessage from '../Components/ToastMessage'
// var openAISearch = require("../../OpenAISearch");

const JobViewManual = ({ jobDetails, job_id, jobDetailsFromJobView, packageExpired }) => {
    const [showToolbar, setShowToolbar] = useState(false);
    const skillMatchRef = useRef(null);
    const gridRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [skills, setSkills] = useState(jobDetails?.ai_skills ? jobDetails?.ai_skills[0].split(',') : []);
    const [showScrollButton, setShowScrollButton] = useState(false);
    const [openRoles, setOpenRoles] = useState(false);
    const [roles, setRoles] = useState("");
    const [relatedRoles, setRelatedRoles] = useState([])
    const [selectedRelatedTitles, setSelectedRelatedTitles] = useState([])
    const [requestAi, setRequestAi] = useState(false)
    const currentUser = useSelector((state) => state.UserReducer.user);
    const [newRelatedRole, setNewRelatedRole] = useState("");
    const styles = useResponsiveStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Mobile devices
    const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md")); // Tablets
    const isDesktopSmall = useMediaQuery(theme.breakpoints.between("md", "lg")); // Small desktops
    const isDesktopLarge = useMediaQuery(theme.breakpoints.up("lg")); // Large desktops

    const nityaincCheck = /^[a-zA-Z0-9_.+-]+@(nityainc)\.com$/;
    const isNityainc = nityaincCheck.test(currentUser?.username);



    useEffect(() => {
        const fetch = async () => {
            // const jobData = await getJobDetails();
            // await setjobDetails(jobData.data[0].jobDetails);
            // await jobDetailsFromJobView(jobData.data[0].jobDetails);
            // setLoading(false);
            // //   let skil = jobDetails?.ai_skills === "" ? jobDetails?.skillNames : jobDetails?.ai_skills
            // const jobSkilss = jobData.data[0].jobDetails?.ai_skills?.split(",");
            // await setSkills(jobSkilss);
        };
        fetch();

    }, [job_id]);

    const getJobDetails = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`${Key.domain}/Jobs/get_job_details`, {
                id: job_id,
            });
            return response.data;
        } catch (error) {
            console.log("getJobViewDetails: error", error);
            return null;
        }
    };

    const scrollToSkillMatch = () => {
        if (skillMatchRef.current) {
            gridRef.current.scrollTo({
                top: skillMatchRef.current.offsetTop,
                behavior: "smooth",
            });
        }
    };

    const handleScroll = () => {
        const scrollTop = gridRef.current.scrollTop;
        setShowScrollButton(scrollTop > 100); // Adjust the threshold as needed
    };
    const scrollToTop = () => {
        if (gridRef.current) {
            gridRef.current.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }
    };

    const handleRolesClose = () => {
        setOpenRoles(false);
    };

    const handleRoleAdd = () => {
        if (roles !== "") {
            let splittedRoles = [];
            if (roles.includes(",")) {
                splittedRoles = roles.split(",");
            } else {
                splittedRoles.push(roles);
            }

            let urlRoles = splittedRoles.join("_");
            fetch(`${Key.domain}/Jobs/insert_roles_by_nityainc_recruiters`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    roles: roles,
                    job_title: jobDetails?.title,
                    uid: parseInt(sessionStorage.getItem("id")),
                }),
            }).then((response) => {
                response.json().then((resdata) => {
                    if (resdata.msg === "Related Titles Added Sucessfully!") {
                        handleRolesClose();
                        let msg = resdata.msg;
                        let roleId = resdata.data.insertId ? resdata.data.insertId : resdata.data
                        toast(<ToastMessage message={ msg } />);

                        setTimeout(() => {
                            window.open(
                                "linkedin-resumes://" + urlRoles + "/" + roleId + "/" + sessionStorage.getItem("id"),
                                "_blank"
                            );
                        }, 2500);
                    } else {
                        let msg = resdata.msg;
                        toast(<ToastMessage message={ msg } />);
                    }
                });
            });
        } else {
            let msg = "Related Titles Cannot be Empty";
            toast(<ToastMessage message={ msg } />);
        }
    };


    const handleAddRelatedJobTitles = () => {
        setRelatedRoles((prev) => [...prev, newRelatedRole])
        setNewRelatedRole("")
    }


    const handleChange = (event) => {
        const skill = event.target.name;
        if (event.target.checked) {
            setSelectedRelatedTitles((prevSkills) => {
                const updatedSkills = [...prevSkills, skill];
                setRoles(updatedSkills.join(',')); // Update roles based on the new state
                return updatedSkills; // Return the new state
            });
        } else {
            // Remove skill if unchecked
            setSelectedRelatedTitles((prevSkills) => {
                const updatedSkills = prevSkills.filter((s) => s !== skill);
                setRoles(updatedSkills.join(',')); // Update roles based on the new state
                return updatedSkills; // Return the new state
            });
        }
    };

    return (
        <Paper
            elevation={ 1 }
            sx={ {
                maxHeight: window.innerHeight,
                overflowY: "scroll",
                position: "relative",
            } }
            ref={ gridRef }
        >
            { showToolbar && Object.keys(jobDetails).length !== 0 && (
                <Toolbar
                    sx={ {
                        position: "sticky",
                        top: 0,
                        zIndex: 3,
                        backgroundColor: "#fff",
                        borderTopLeftRadius: "8px",
                        borderTopRightRadius: "8px",
                        borderBottom: "1px solid #ddd",
                    } }
                >
                    <Card sx={ { boxShadow: "none", width: "100%" } }>
                        <Box
                            sx={ {
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                            } }
                        >
                            <Stack direction="column">
                                <Typography
                                    component="body"
                                    paragraph
                                    sx={ {
                                        textTransform: "capitalize",
                                        fontWeight: 600,
                                        cursor: "pointer",
                                        margin: 0,
                                        "&:hover": {
                                            textDecoration: "underline",
                                            textDecorationColor: "#2196f3",
                                        },
                                    } }
                                >
                                    { jobDetails?.title }
                                </Typography>
                                <Breadcrumbs separator={ <Bullet /> } aria-label="breadcrumb">
                                    { jobDetails?.companyname && (
                                        <Typography
                                            paragraph
                                            sx={ {
                                                margin: 0,
                                                fontWeight: 400,
                                                textTransform: "capitalize",
                                                fontSize: "12px",
                                                cursor: "pointer",
                                                "&:hover": {
                                                    textDecoration: "underline",
                                                },
                                            } }
                                        //   onClick={() =>
                                        //     redirectToCompanyView(jobDetails.companyid)
                                        //   }
                                        >
                                            { jobDetails?.companyname }
                                        </Typography>
                                    ) }
                                    <Typography
                                        paragraph
                                        sx={ {
                                            margin: 0,
                                            fontWeight: 400,
                                            textTransform: "capitalize",
                                            fontSize: "12px",
                                        } }
                                    >{ `${jobDetails?.cityName &&
                                        jobDetails?.cityName !== "N/A" &&
                                        jobDetails?.cityName !== null &&
                                        jobDetails?.stateName &&
                                        jobDetails?.stateName !== "N/A" &&
                                        jobDetails?.stateName !== null &&
                                        jobDetails?.shortRegion &&
                                        jobDetails?.shortRegion !== "N/A" &&
                                        jobDetails?.shortRegion !== null
                                        ? jobDetails?.cityName +
                                        ", " +
                                        jobDetails?.stateName +
                                        ", " +
                                        jobDetails?.shortRegion
                                        : "(Remote)"
                                        }` }</Typography>
                                </Breadcrumbs>
                            </Stack>
                        </Box>
                    </Card>
                </Toolbar>
            ) }
            { loading ? (
                <LoadingSkeletonJobView />
            ) : jobDetails && Object.keys(jobDetails).length !== 0 ? (
                <Grid container spacing={ 1 }>
                    <Grid item xs={ 12 }>
                        <Card sx={ { borderRadius: "8px", boxShadow: "none", p: 2 } }>
                            <Stack direction="column" gap={ 2 }>
                                <Stack direction="column" gap={ 0 }>
                                    <Box
                                        sx={ { display: "flex", justifyContent: "space-between" } }
                                    >
                                        <Tooltip title={ jobDetails?.title }>
                                            <Typography
                                                component="body"
                                                // variant="h5"
                                                sx={ {
                                                    textTransform: "capitalize",
                                                    fontWeight: 500,
                                                    fontSize: styles.fontSize24,
                                                    width: isNityainc
                                                        ? isDesktopLarge
                                                            ? "250px"
                                                            : isDesktopSmall
                                                                ? '200px'
                                                                : "180px"
                                                        : null,
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                } }
                                            >
                                                { jobDetails?.title }
                                            </Typography>
                                        </Tooltip>

                                    </Box>
                                    <Modal
                                        open={ openRoles }
                                        onClose={ handleRolesClose }
                                        style={ {
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",

                                        } }
                                    >
                                        <div
                                            style={ {
                                                backgroundColor: "#fff",
                                                padding: "20px",
                                                borderRadius: "8px",
                                            } }
                                        >
                                            <div
                                                style={ {
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    marginBottom: "10px",
                                                    width: 500,
                                                } }
                                            >
                                                <Typography variant="h6">Related Job Titles</Typography>



                                                <IconButton
                                                    edge="end"
                                                    color="inherit"
                                                    onClick={ handleRolesClose }
                                                    aria-label="close"
                                                >
                                                    <CloseRounded />
                                                </IconButton>

                                            </div>
                                            <Paper elevation={ 3 }
                                                component="form"
                                                sx={ { p: '2px 4px', display: 'flex', alignItems: 'center', width: "100%" } }
                                            >
                                                <InputBase
                                                    sx={ { ml: 1, } }
                                                    placeholder="Add Related Titles Here"
                                                    inputProps={ { 'aria-label': 'Add Related Titles Here' } }
                                                    fullWidth
                                                    onChange={ (e) => setNewRelatedRole(e.target.value) }
                                                    value={ newRelatedRole }
                                                />
                                                <IconButton type="button" sx={ { p: '10px' } } aria-label="search" onClick={ () => handleAddRelatedJobTitles() }>
                                                    <AddIcon />
                                                </IconButton>
                                            </Paper>
                                            <br />
                                            <div style={ { display: "flex" } }>


                                                {/* First Column */ }
                                                <div style={ { marginRight: "20px" } }>
                                                    { relatedRoles.slice(0, Math.ceil(relatedRoles.length / 2)).map((skill, index) => (
                                                        <FormControlLabel
                                                            key={ index }
                                                            style={ {
                                                                display: "flex",
                                                                alignItems: "center",
                                                                marginBottom: "10px",
                                                                width: 240, // Adjust the width of the column as needed
                                                                overflowY: "scroll"
                                                            } }
                                                            control={
                                                                <Checkbox
                                                                    checked={ selectedRelatedTitles.includes(skill) }
                                                                    onChange={ handleChange }
                                                                    name={ skill }
                                                                />
                                                            }
                                                            label={ skill }
                                                        />
                                                    )) }
                                                </div>

                                                {/* Second Column */ }
                                                <div>
                                                    { relatedRoles.slice(Math.ceil(relatedRoles.length / 2)).map((skill, index) => (
                                                        <FormControlLabel
                                                            key={ index }
                                                            style={ {
                                                                display: "flex",
                                                                alignItems: "center",
                                                                marginBottom: "10px",
                                                                width: 240, // Adjust the width of the column as needed
                                                                overflowY: "scroll"
                                                            } }
                                                            control={
                                                                <Checkbox
                                                                    checked={ selectedRelatedTitles.includes(skill) }
                                                                    onChange={ handleChange }
                                                                    name={ skill }
                                                                />
                                                            }
                                                            label={ skill }
                                                        />
                                                    )) }
                                                </div>
                                            </div>

                                            <div
                                                style={ { display: "flex", justifyContent: "flex-end" } }
                                            >
                                                <Button
                                                    variant="contained"
                                                    onClick={ () => handleRoleAdd() }
                                                    sx={ {
                                                        background: "#406882",
                                                        "&:hover": {
                                                            bgcolor: "#406882",
                                                        },
                                                    } }
                                                >
                                                    Add
                                                </Button>
                                            </div>
                                        </div>
                                    </Modal>
                                    <Breadcrumbs separator={ <Bullet /> } aria-label="breadcrumb">
                                        { jobDetails?.companyname && (
                                            <Typography
                                                paragraph
                                                sx={ {
                                                    margin: 0,
                                                    fontWeight: 400,
                                                    textTransform: "capitalize",
                                                    fontSize: styles.fontSize14,
                                                } }
                                            //   onClick={() => redirectToCompanyView(jobDetails.companyid)}
                                            >
                                                { jobDetails?.companyname }
                                            </Typography>
                                        ) }
                                        <Typography
                                            paragraph
                                            sx={ {
                                                margin: 0,
                                                fontWeight: 400,
                                                textTransform: "capitalize",
                                                fontSize: styles.fontSize14,
                                            } }
                                        >{ `${jobDetails?.cityName &&
                                            jobDetails?.cityName !== "N/A" &&
                                            jobDetails?.cityName !== null &&
                                            jobDetails?.stateName &&
                                            jobDetails?.stateName !== "N/A" &&
                                            jobDetails?.stateName !== null &&
                                            jobDetails?.shortRegion &&
                                            jobDetails?.shortRegion !== "N/A" &&
                                            jobDetails?.shortRegion !== null
                                            ? jobDetails?.cityName +
                                            ", " +
                                            // jobDetails?.stateName +
                                            // ", " +
                                            jobDetails?.shortRegion
                                            : "(Remote)"
                                            }` }</Typography>
                                        <Typography
                                            paragraph
                                            sx={ {
                                                margin: 0,
                                                color: "#757575",
                                                fontWeight: 500,
                                                fontSize: styles.fontSize14,
                                            } }
                                        >
                                            { jobDetails.modified !== undefined &&
                                                jobDetails.modified !== null &&
                                                // ? moment(jobDetails.modified).fromNow() !==
                                                //   "Invalid date" ?
                                                moment(jobDetails.modified).fromNow()
                                                //   : moment("2022-03-31T06:26:04.138Z").fromNow()
                                                // : moment("2022-03-31T06:26:04.138Z").fromNow()
                                            }
                                        </Typography>
                                    </Breadcrumbs>
                                </Stack>
                                <Box sx={ { display: "flex", justifyContent: "space-between" } }>
                                    <Stack direction="column" gap={ 1 }>
                                        <Typography
                                            paragraph
                                            sx={ {
                                                margin: 0,
                                                display: "flex",
                                                alignItems: "end",
                                                gap: "2px",
                                                fontSize: styles.fontSize14,
                                                minWidth: "80px",
                                                maxWidth: styles.jobDetailsWidth400,
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                            } }
                                        >
                                            <BusinessCenterRounded
                                                sx={ {
                                                    height: styles.icon20,
                                                    width: styles.icon20,
                                                    color: "#757575",
                                                    marginRight: "5px",
                                                } }
                                            />
                                            { jobDetails.jobtypetitle !== undefined &&
                                                jobDetails.jobtypetitle !== null
                                                ? jobDetails.jobtypetitle
                                                : //   .join(",")
                                                //   .split(",")
                                                //   .join(", ")
                                                "Full Time" }
                                            &nbsp;&nbsp;
                                            { jobDetails.noofjobs !== undefined &&
                                                jobDetails.noofjobs !== null &&
                                                parseInt(jobDetails.noofjobs) !== 0
                                                ? `(Positions : ${jobDetails.noofjobs})`
                                                : "(Positions : 1)" }
                                        </Typography>

                                        <Typography
                                            paragraph
                                            sx={ {
                                                margin: 0,
                                                fontSize: styles.fontSize14,
                                                display: "flex",
                                                alignItems: "end",
                                                gap: "2px",
                                            } }
                                        >
                                            <MdFlight
                                                style={ {
                                                    height: styles.icon20,
                                                    width: styles.icon20,
                                                    color: "#757575",
                                                    marginRight: "5px",
                                                } }
                                            />
                                            { jobDetails.visatypeName !== undefined &&
                                                jobDetails.visatypeName !== null
                                                ? jobDetails.visatypeName
                                                : //   .join(",")
                                                //   .split(",")
                                                //   .join(", ")
                                                "H1" }
                                        </Typography>
                                        <Typography
                                            paragraph
                                            sx={ {
                                                margin: 0,
                                                fontSize: styles.fontSize14,
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "2px",
                                            } }
                                        >
                                            <FaUserGraduate
                                                style={ {
                                                    height: styles.icon20,
                                                    width: styles.icon20,
                                                    color: "#757575",
                                                    marginRight: "5px",
                                                } }
                                            />
                                            { jobDetails.qualifications !== undefined &&
                                                jobDetails.qualifications !== null &&
                                                jobDetails.qualifications !== ""
                                                ? jobDetails.qualifications
                                                : "Bachelor's Of Degree" }
                                        </Typography>
                                        <Typography
                                            paragraph
                                            sx={ {
                                                margin: 0,
                                                fontSize: styles.fontSize14,
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "2px",
                                            } }
                                        >
                                            <MdOutlineWorkHistory
                                                style={ {
                                                    height: styles.icon20,
                                                    width: styles.icon20,
                                                    color: "#757575",
                                                    marginRight: "5px",
                                                } }
                                            />
                                            { jobDetails?.experience === "0"
                                                ? "0 - 3 Years"
                                                : jobDetails?.experience }
                                        </Typography>
                                        <Box
                                            sx={ {
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "2px",
                                            } }
                                        >
                                            <ChecklistRounded
                                                sx={ {
                                                    height: styles.icon20,
                                                    width: styles.icon20,
                                                    color: "#757575",
                                                    marginRight: "5px",
                                                } }
                                            />
                                            <Typography
                                                paragraph
                                                sx={ {
                                                    margin: 0,
                                                    fontSize: styles.fontSize14,

                                                    maxWidth: styles.jobDetailsWidth400,
                                                    cursor: "pointer",
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    // textOverflow: "ellipsis",
                                                    "&:hover": {
                                                        textDecoration: "underline",
                                                        color: '#406882'
                                                    },
                                                } }
                                                // onClick={ scrollToSkillMatch }
                                            >
                                                { jobDetails?.ai_skills === ""
                                                    ? jobDetails?.skillNames
                                                    : jobDetails?.ai_skills }
                                            </Typography>
                                        </Box>
                                    </Stack>
                                </Box>
                            </Stack>
                        </Card>
                    </Grid>
                    <Grid item xs={ 12 }>
                        <Card sx={ { boxShadow: "none", p: 2 } }>
                            <Typography paragraph sx={ { fontWeight: 500, fontSize: styles.fontSize16 } }>
                                About the jobss
                            </Typography>
                            <Typography paragraph sx={ { fontSize: styles.fontSize14, } }>
                                { jobDetails.description !== null &&
                                    jobDetails.description !== undefined &&
                                    jobDetails.description
                                    ? !jobDetails.description.includes("ahref") &&
                                        !jobDetails.description.includes("divclass") &&
                                        !jobDetails.description.includes("aclass") &&
                                        !jobDetails.description.includes("pclass") &&
                                        !jobDetails.description.includes("liclass")
                                        ? parse(jobDetails.description)
                                        : jobDetails.description.replace(/<[^>]+>/g, "")
                                    : "" }
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={ 12 }>
                        <div ref={ skillMatchRef } style={ { padding: '5px' } }>
                            <Box sx={ { display: "flex", justifyContent: "center", mb: 25 } }>
                                <TableContainer
                                    component={ Paper }
                                    sx={ { maxHeight: 400, maxWidth: "400px" } }
                                >
                                    <Table
                                        aria-label="customized table"
                                        stickyHeader
                                        size="small"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell
                                                    colSpan={ 2 }
                                                    sx={ { textAlign: "center", fontSize: styles.fontSize14 } }
                                                >
                                                    Job Skills
                                                </StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            { skills?.length > 0 ? (
                                                skills?.map((row, index) => {
                                                    if (index % 2 === 0) {
                                                        return (
                                                            <StyledTableRow key={ index }>
                                                                <TableCell sx={ { fontSize: styles.fontSize14 } }>{ row }</TableCell>
                                                                { skills[index + 1] && (
                                                                    <TableCell sx={ { fontSize: styles.fontSize14 } }>{ skills[index + 1] }</TableCell>
                                                                ) }
                                                            </StyledTableRow>
                                                        );
                                                    }
                                                    return null;
                                                })
                                            ) : (
                                                <Typography
                                                    paragraph
                                                    sx={ {
                                                        margin: 0,
                                                        paddingTop: 2,
                                                        textAlign: "center",
                                                    } }
                                                >
                                                    Skills Not found
                                                </Typography>
                                            ) }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </div>
                    </Grid>
                </Grid>
            ) : (
                <Card>No Data Found...</Card>
            ) }
            {/* Scroll to Top Button */ }
            { showScrollButton && (
                <Box sx={ { ml: 2 } } component={ Paper } elevation={ 3 }>
                    <IconButton
                        color="primary"
                        onClick={ scrollToTop }
                        sx={ {
                            position: "fixed",
                            bottom: "16px",
                            // right: "16px",

                            backgroundImage:
                                "linear-gradient(360deg, #406882 0%, #518aa7 50%, #6d9ec6 100%)",
                            color: "#fff",
                            "&:hover": {
                                backgroundColor: "#406882",
                            },
                        } }
                    >
                        <KeyboardDoubleArrowUpRounded />
                    </IconButton>
                </Box>
            ) }
        </Paper>
    );
};

export default JobViewManual;

const Bullet = styled("span")({
    display: "inline-block",
    width: "3px",
    height: "3px",
    borderRadius: "50%",
    backgroundColor: "currentColor",
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "rgba(64, 104, 130, 0.9)",
        //  theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));
