import {
    Box, Grid,
    IconButton, Modal,
    TextField, Tooltip,
    styled
} from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import Key from "../clientVariables.json";
import { useDebounce } from '../utils/use-debounce';

import JobViewManual from './JobViewManual';


const ActiveJobsList = () => {
    const navigate = useHistory();

    const [isAuthenticated, setAuth] = useState(() => {
        let storedData = sessionStorage.getItem('manualAuth');
        return storedData ? JSON.parse(storedData) : null;
    })
    const [searchKeyword, setSearchKeyword] = useState('');
    const debounceValue = useDebounce(searchKeyword, 500)
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 10,
        page: 0,
    });
    const [jobList, setJobList] = useState({
        isLoading: false,
        data: [],
        total: 0
    })
    const [jobView, toggleJobView] = useState(false);
    const [jobModalDetails, setJobModalDetails] = useState({})
    const handleEdit = (jobId, jobDetails) => {
        navigate.push({ pathname: '/manual/job', state: { jobId: jobId, solrJobDetails: jobDetails } })
    }

    const handleInputChange = (event) => {
        setSearchKeyword(event.target.value);
        setPaginationModel(prev => ({ ...prev, page: 0 }))
    };

    const getAllJobs = async (pageNo, searchKeyword) => {
        setJobList(prev => ({ ...prev, isLoading: true }));

        let page = (pageNo * 10);

        try {
            let searchJobsUrl;
            if (searchKeyword !== "" && /^\d+$/.test(searchKeyword)) {
                searchJobsUrl = `${Key.Solr_url}/${Key.solrJobs_collection
                    }/select?fq=status:(1)&fq=id:"${searchKeyword}"&rows=10&start=${page}&fq=industry_type:"IT"&sort=modified%20desc&wt=json`;
            } else if (searchKeyword !== "") {
                searchJobsUrl = `${Key.Solr_url}/${Key.solrJobs_collection
                    }/select?fq=status:(1)&fq=title:"${searchKeyword}"&rows=10&start=${page}&fq=industry_type:"IT"&sort=modified%20desc&wt=json`;
            } else {
                searchJobsUrl = `${Key.Solr_url}/${Key.solrJobs_collection
                    }/select?fq=status:(1)&rows=10&start=${page}&fq=industry_type:"IT"&sort=modified%20desc&wt=json`;
            }
            const res = await axios.post(`${Key.domain}/solr/solr_jobs`, { url: searchJobsUrl }, {
                Accept: "application/json",
                "Content-Type": "application/json",
            })
            if (res?.status === 200) {
                const newData = res.data?.data?.response?.docs;
                const newTotal = res.data?.data?.response?.numFound;
                setJobList(prev => ({
                    ...prev,
                    isLoading: false,
                    data: newData ?? [],
                    total: newTotal
                }));

            }
            // }
        } catch (err) {
            console.log(err);
        } finally {
            setJobList(prev => ({ ...prev, isLoading: false }));
        }
    };

    const manualLogin = async (e) => {
        e.preventDefault();
        console.log(e.target[0].value)
        const res = await axios.post(`${Key.domain}/manual/login`, {
            username: e.target[0].value,
            password: e.target[1].value
        })

        if (res.status === 200) {
            setAuth((prev) => {
                return { ...prev, isAuthenticated: true }
            })
            sessionStorage.setItem('manualAuth', JSON.stringify({ isAuthenticated: true }));
            getAllJobs(paginationModel.page, searchKeyword)
        }
    }

    useEffect(() => {
        if (isAuthenticated) {
            getAllJobs(paginationModel.page, searchKeyword)
        }
    }, [paginationModel.page, debounceValue])

    const style = {
        position: "absolute",
        top: "10%",
        left: "50%",
        transform: "translate(-50%, -10%)",
        width: 800,
        bgcolor: "background.paper",
        border: "1px solid #ddd",
        boxShadow: 24,
        height: "60vh",
        // p: 4,
        borderRadius: "8px",
    };
    const Bullet = styled("span")({
        display: "inline-block",
        width: "3px",
        height: "3px",
        borderRadius: "50%",
        backgroundColor: "currentColor",
    });
    const columns = [
        { field: 'id', headerName: 'Job ID', flex: 1, valueGetter: (params) => params ?? "N/A" },
        { field: 'title', headerName: 'Title', flex: 1, valueGetter: (params) => params ?? "N/A" }, ,
        // { field: 'ai_skills', headerName: 'AI-SKills', flex: 1, valueGetter: (params) => params ?? "N/A" },
        { field: 'companyName', headerName: 'Company', flex: 1, valueGetter: (params) => params ?? "N/A" },
        { field: 'experience', headerName: 'Experience', flex: 1, valueGetter: (params) => params ?? "N/A" },
        { field: 'cityName', headerName: 'City', flex: 1, valueGetter: (params) => params ?? "N/A" },
        { field: 'stateName', headerName: 'State', flex: 1, valueGetter: (params) => params ?? "N/A" },
        {
            field: 'created', headerName: 'Created At', flex: 1,
            valueFormatter: (params) => {
                return moment(params.value).format("MMM DD, YYYY HH:mm A")
            },
        },
        {
            field: 'modified', headerName: 'Updated At',
            flex: 1,
            valueFormatter: (params) =>
                moment(params.value).format("MMM DD, YYYY HH:mm A"),
        },
        {
            field: "edit",
            headerName: "Edit",
            flex: 1,
            renderCell: (params) => (
                <Tooltip title={ "Edit Job" }>
                    <IconButton onClick={ () => handleEdit(params.row.id, params.row) }>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
            ),
        }

    ]

    return (<>
        { isAuthenticated?.isAuthenticated ?
            <div className="container" style={ { paddingTop: "38px" } }>
                <Modal
                    open={ jobView }
                    onClose={ () => toggleJobView(false) }
                    
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={ style }>
                        <JobViewManual jobDetails={ jobModalDetails } />
                        { console.log(jobModalDetails) }

                    </Box>
                </Modal>
                <Grid sx={ { width: '60%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '1.5rem' } }>
                    <TextField sx={ { flex: '0.8' } }
                        value={ searchKeyword }
                        label="Search Jobs with ids and titles.."
                        variant="outlined"
                        onChange={ handleInputChange }
                    />
                </Grid>

                <DataGrid
                    onRowClick={ (data) => { toggleJobView(true); setJobModalDetails(data.row) } }
                    disableColumnSorting={ true }
                    disableColumnFilter={ true }
                    autoHeight
                    rows={ jobList.data }
                    rowCount={ jobList.total }
                    loading={ jobList.isLoading }
                    rowsPerPageOptions={ [20] }
                    paginationModel={ paginationModel }
                    onPaginationModelChange={ setPaginationModel }
                    paginationMode="server"
                    columns={ columns }
                />

            </div>
            : <div >
                <form onSubmit={ manualLogin } style={ { display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '4rem', rowGap: '1rem' } }>
                    <input name='username' type="text" placeholder='Username' />
                    <input name='password' type="password" placeholder='Password' />
                    <button type='submit' >Login</button>
                </form>
            </div>
        }
    </>


    )
}

export default ActiveJobsList