import Key from "../clientVariables.json";
import moment from "moment";
import { job_types_data, us_states_data, visa_types_data } from "./commonData";
import { toast } from "react-toastify";

function containsSpecialCharacter(str) {
  const specialCharRegex = /[^a-zA-Z0-9\s]/;
  return specialCharRegex.test(str);
}

function splitBySpecialCharacters(input) {
  const specialCharRegex = /[^a-zA-Z0-9\s]+/;
  const parts = input.split(specialCharRegex);
  const cleanedParts = parts
    .map((part) => part.trim())
    .filter((part) => part !== "");
  return cleanedParts;
}

function getMappedDates(dateOption) {
  const today = new Date();
  let startdate;

  if (dateOption == "1") {
    startdate = moment(today)
      .subtract(1, "d")
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss[Z]");
  } else if (dateOption == "2") {
    startdate = moment(today)
      .subtract(7, "d")
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss[Z]");
  } else if (dateOption == "3") {
    startdate = moment(today)
      .subtract(30, "d")
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss[Z]");
  } else {
    startdate = null;
  }

  return startdate ? `${startdate} TO NOW` : null;
}

const removeItems = [
  "skill",
  "technology",
  "developer",
  "programmer",
  "architect",
  "designer",
  "consultant",
  "administrator",
  "coach",
  "lead",
  "manager",
  "master",
  "specalist",
  "modeler",
  "director",
  "hacker",
  "engineer",
  "analyst",
  "dba",
  "tester",
  "senior",
  "sr",
  "junior",
  "jr",
  "knowledge",
  "or",
  "with",
  "to",
  "and",
  "front",
  "end",
  "back",
];

function processSearchTitle(title) {
  const wordRegex = /[^\W\d_]+/g;
  const words = title.match(wordRegex);
  // console.log('processSearchTitle words', words);

  if (words) {
    const filteredWords = words.filter(
      (word) => isNaN(word) && !removeItems.includes(word.toLowerCase())
    );
    const splittingSpecialCharacter = splitBySpecialCharacters(title);
    // console.log('processSearchTitle splittingSpecialCharacter', splittingSpecialCharacter);

    const finalTitleWords = filteredWords.concat(splittingSpecialCharacter);
    //  .map(word => `"${word}"`)
    //  .join(" OR ");
    const finalFilter = finalTitleWords
      .filter((word) => !removeItems.includes(word.toLowerCase()))
      .map((word) => `"${word}"`)
      .join(" OR ");
    // console.log('processSearchTitle finalTitleWords', finalTitleWords, finalFilter);

    return finalFilter;
  } else {
    return title;
  }
}

function processSearchTitleV1(title) {
  const wordRegex = /[^\W\d_]+/g;
  const words = title.match(wordRegex);
  // console.log('processSearchTitle words', words);

  if (words) {
    // const filteredWords = words.filter(
    //   (word) => isNaN(word) && !removeItems.includes(word.toLowerCase())
    // );
    const splittingSpecialCharacter = splitBySpecialCharacters(title);
    // console.log('processSearchTitle splittingSpecialCharacter', splittingSpecialCharacter);

    // const finalTitleWords = filteredWords.concat(splittingSpecialCharacter)
    //  .map(word => `"${word}"`)
    //  .join(" OR ");
    const finalFilter = splittingSpecialCharacter
      .map((word) => `"${word}"`)
      .join(" OR ");
    // console.log('processSearchTitle finalTitleWords', finalTitleWords, finalFilter);

    return finalFilter;
  } else {
    return title;
  }
}

function buildLocationFilter(location) {
  let locationFilter = "";
  if (location && location.includes("L_O_C")) {
    const multipleLocations = location.split(" L_O_C ");
    let shortRegion_ = [];
    let city_ = [];
    let state_ = [];

    multipleLocations.map((location) => {
      let loc = location.split(/[,]+/);
      if (loc.length > 1) {
        const city = loc[0].trim();
        const regionOrState = loc[1].trim();
        if (regionOrState.length === 2) {
          shortRegion_.push(`"${regionOrState}"`);
          city_.push(`"${city}"`);
        } else {
          state_.push(`"${regionOrState}"`);
          city_.push(`"${city}"`);
        }
      } else {
        const singleLocation = loc[0].trim();
        if (singleLocation.length === 2) {
          shortRegion_.push(`"${singleLocation}"`);
        } else {
          city_.push(`"${singleLocation}"`);
        }
      }
    });

    locationFilter = `${
      city_.length > 0 ? `&fq=res_cityName:(${city_.join(" OR ")})` : ""
    }${state_.length > 0 ? `&fq=res_stateName:(${state_.join(" OR ")})` : ""}`;
  } else if (location) {
    const loc = location.split(/[,]+/);
    if (loc.length > 1) {
      const city = loc[0].trim();
      const regionOrState = loc[1].trim();
      if (regionOrState.length === 2) {
        locationFilter = `&fq=shortRegion:(${regionOrState})&fq=res_cityName:(${city})`;
      } else {
        locationFilter = `&fq=res_stateName:(${regionOrState})&fq=res_cityName:(${city})`;
      }
    } else {
      const singleLocation = loc[0].trim();
      if (singleLocation.length === 2) {
        locationFilter = `&fq=shortRegion:(${singleLocation})`;
      } else {
        locationFilter = `&fq=res_cityName:(${singleLocation})`;
      }
    }
  }

  return locationFilter;
}

export const resumeQueryBuilder = (
  searchParams,
  filterParams,
  paginationParams
) => {
  const solrBaseUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select`;
  let query = `rows=${paginationParams?.rows || 10}&start=${
    paginationParams?.start || 0
  }&fq=search_flag:(1)&sort=lastmodified desc&wt=json`;

  let searchQuery = [];
  if (searchParams.resumeTitle) {
    let ctitle = searchParams.resumeTitle.toLowerCase();
    if (ctitle.includes("c++")) {
      let splitting = [];
      ctitle.split(",").map((value) => splitting.push(`"${value}"`));
      searchQuery.push(`application_title:(${splitting.join(" OR ")})`);
    } else {
      const searchText = processSearchTitle(searchParams.resumeTitle);
      // console.log("processSearchTitle", searchText);
      searchQuery.push(`application_title:(${searchText})`);
    }
  }
  if (searchParams.res_stateName) {
    const searchText = searchParams.res_stateName
      .split(" OR ")
      .map((part) => `"${part}"`)
      .join(" OR ");
    searchQuery.push(`res_stateName:(${searchText})`);
  }

  Object.entries(searchParams).forEach(([key, value]) => {
    if (key !== "resumeTitle" && key !== "res_stateName" && value) {
      searchQuery.push(`${key}:${value}`);
    }
  });

  if (searchQuery.length === 0) {
    searchQuery.push("*:*");
  }

  query = `q=(${searchQuery.join(" AND ")})&${query}`;

  const filterQueries = Object.entries(filterParams).map(([key, value]) => {
    if (key === "lastmodified") {
      const dateRange = getMappedDates(value);
      return dateRange ? `&fq=${key}:[${dateRange}]` : "";
    } else {
      return `&fq=${key}:(${value})`;
    }
  });

  if (filterQueries.length > 0) {
    query += filterQueries.join("");
  }

  if (searchParams.location) {
    query += buildLocationFilter(searchParams.location);
  }

  return `${solrBaseUrl}?${query}`;
};

export const buildFacetQuery = (
  filterParams = {},
  searchParams = {},
  paginationParams = {},
  facet_counts
) => {
  const { start, rows } = paginationParams || {};
  const solrBaseUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select?q=(*:*)`;
  // console.log("facet_counts111", facet_counts?.facet_fields?.jobtype);

  let query = "";
  const jobTypeID = [];
  const visaTypeID = [];
  const stateID = [];
  const cityID = [];

  const addIDToArray = (param, data, idArray, field, paramName) => {
    if (param === "*:*") {
      // Ensure searchParams[paramName] exists before proceeding
      if (searchParams[paramName]) {
        data.forEach((item) => {
          if (item[field].includes(searchParams[paramName])) {
            idArray.push(item.id);
          }
        });
      }
    } else {
      if (param?.includes(" OR ")) {
        idArray.push(...param.split(" OR "));
      } else if (param) {
        idArray.push(param);
      }
    }

    // console.log("check1 11", param, data, idArray, field, paramName);
    // console.log("check1 22", jobTypeID, visaTypeID, stateID);
  };

  // const addIDToArray = (param, data, idArray, field, paramName) => {
  //   if (param === "*:*") {
  //     data.forEach((item) => {
  //       if (item[field].includes(searchParams[paramName])) {
  //         idArray.push(item.id);
  //       }
  //     });
  //   } else {
  //     if(param?.includes(" OR ")){
  //       idArray.push(...param?.split(" OR "));
  //     }else{
  //     idArray.push(param);
  //     }
  //   }
  // };

  addIDToArray(
    filterParams.jobtype,
    job_types_data,
    jobTypeID,
    "title",
    "jobType"
  );
  addIDToArray(
    filterParams.visatype,
    visa_types_data,
    visaTypeID,
    "type_code",
    "visaType"
  );
  addIDToArray(filterParams.state, us_states_data, stateID, "name", "state");

  if (
    filterParams.city &&
    filterParams.city !== "*:*" &&
    filterParams.city !== ""
  ) {
    cityID.push(...filterParams.city?.split(" OR "));
  }

  if (searchParams.title) {
    const searchText = processSearchTitleV1(searchParams.title);
    const searchText2 = processSearchTitle(searchParams.title);
    if (
      searchParams.title.toLowerCase().includes("c++") ||
      searchParams.title.toLowerCase().includes("c#")
    ) {
      query += `&fq=application_title:("${searchParams.title}")`;
    } else {
      query += `&fq=application_title:("${searchParams.title}" OR ${searchText2})`;
    }
  }

  if (stateID.length > 0) {
    query += `&fq=location_state:(${stateID.join(" OR ")})`;
  }

  if (cityID.length > 0) {
    query += `&fq=address_city:(${cityID.join(" OR ")})`;
  }

  if (jobTypeID.length > 0) {
    query += `&fq=jobtype:(${jobTypeID.join(" OR ")})`;
  }
  if (visaTypeID.length > 0) {
    query += `&fq=visatype:(${visaTypeID.join(" OR ")})`;
  }
  if (searchParams.id) {
    const cleanedId = searchParams.id.replace('#', '');
    query += `&fq=id:(${cleanedId})`;
  }
  if (searchParams.email) {
    const escapedEmail = searchParams.email.replace(/([+\-&|!(){}\[\]^"~*?:\\/])/g, '\\$1');
    query += `&fq=contactemail:("${escapedEmail}")`;
  }

  // // if (jobTypeID.length > 0) {
  // //   query += `&fq=jobtype:(${jobTypeID.join(" OR ")})`;
  // // }
  // const jobTypeCounts = {};
  // for (let i = 0; i < facet_counts?.facet_fields?.jobtype.length; i += 2) {
  //   jobTypeCounts[facet_counts.facet_fields.jobtype[i]] =
  //     facet_counts.facet_fields.jobtype[i + 1];
  // }

  // if (jobTypeID.length > 0) {
  //   const matchingJobTypes = [];

  //   jobTypeID.forEach((id) => {
  //     Object.keys(jobTypeCounts).forEach((key) => {
  //       const keySplit = key.split(",");
  //       if (keySplit.length === 1) {
  //         if (keySplit[0] == id) {
  //           // matchingJobTypes.push(`(${keySplit[0]})`);
  //           matchingJobTypes.push(`(${key})`);
  //         }
  //       } else if (keySplit.length > 1) {
  //         keySplit.forEach((splitKey) => {
  //           if (splitKey == id) {
  //             matchingJobTypes.push(`(${key})`);
  //           }
  //         });
  //       }
  //     });
  //   });

  //   if (matchingJobTypes.length > 0) {
  //     const jobtypeQuery = matchingJobTypes.join(" OR ");
  //     query += `&fq=jobtype:(${jobtypeQuery})`;
  //   }else {
  //     toast.warning("job type not found based on your search try another job type")
  //   }
  // }

  // //   if (jobTypeID.length > 0) {
  // //     const matchingJobTypes = [];

  // //     jobTypeID.forEach((id) => {
  // //       Object.keys(jobTypeCounts).forEach((key) => {
  // //         const keySplit = key.split(",")
  // //         if (keySplit.length === 1) {
  // //           if(key[0] == id){
  // //             matchingJobTypes.push(`(${key[0]})`);
  // //           }
  // //         }else if(keySplit.length > 1){

  // //         }
  // //       });
  // //     });
  // // // console.log("matchingJobTypes", matchingJobTypes, jobTypeCounts, facet_counts?.facet_fields?.jobtype);

  // //     if (matchingJobTypes.length > 0) {
  // //       const jobtypeQuery = matchingJobTypes.join(" OR ");
  // //       query += `&fq=jobtype:(${jobtypeQuery})`;
  // //     }
  // //   }

  // const visaTypeCounts = {};
  // for (let i = 0; i < facet_counts?.facet_fields?.visatype.length; i += 2) {
  //   visaTypeCounts[facet_counts.facet_fields.visatype[i]] =
  //     facet_counts.facet_fields.visatype[i + 1];
  // }
  // // console.log("check visaTypeCounts", visaTypeCounts, facet_counts?.facet_fields?.visatype);
  // if (visaTypeID.length > 0) {
  //   const matchingVisaTypes = [];

  //   visaTypeID.forEach((id) => {
  //     Object.keys(visaTypeCounts).forEach((key) => {
  //       const keySplit = key.split(",");
  //       if (keySplit.length === 1) {
  //         if (keySplit[0] == id) {
  //           // matchingVisaTypes.push(`(${keySplit[0]})`);
  //           matchingVisaTypes.push(`(${key})`);
  //         }
  //       } else if (keySplit.length > 1) {
  //         keySplit.forEach((splitKey) => {
  //           if (splitKey == id) {
  //             matchingVisaTypes.push(`(${key})`);
  //           }
  //         });
  //       }
  //     });
  //   });
  //   // console.log("check matchingVisaTypes",matchingVisaTypes);

  //   if (matchingVisaTypes.length > 0) {
  //     const visaTypeQuery = matchingVisaTypes.join(" OR ");
  //     query += `&fq=visatype:(${visaTypeQuery})`;
  //     // console.log("check visatype query", query);
  //   }else {
  //     toast.warning("visa type not found based on your search try another visa type")
  //   }
  // }

  // //   if (visaTypeID.length > 0) {
  // //     const matchingVisaTypes = [];

  // //     visaTypeID.forEach((id) => {
  // //       Object.keys(visaTypeCounts).forEach((key) => {
  // //         if (key.includes(id)) {
  // //           matchingVisaTypes.push(`(${key})`);
  // //         }
  // //       });
  // //     });
  // // // console.log("matchingJobTypes", matchingJobTypes, jobTypeCounts, facet_counts?.facet_fields?.jobtype);

  // //     if (matchingVisaTypes.length > 0) {
  // //       const jobtypeQuery = matchingVisaTypes.join(" OR ");
  // //       query += `&fq=visatype:(${jobtypeQuery})`;
  // //     }
  // //   }

  // // if (visaTypeID.length > 0) {
  // //   query += `&fq=visatype:(${visaTypeID.join(" OR ")})`;
  // // }

  if (filterParams.lastmodified) {
    const dateRange = getMappedDates(filterParams.lastmodified);
    query += `&fq=lastmodified:[${dateRange}]`;
  }

  // if(filterParams.total_experience && filterParams.total_experience !== "*:*"){
  //   query += `&fq=total_experience:[${filterParams.total_experience}]`;
  // }
  if (
    filterParams.total_experience &&
    filterParams.total_experience !== "*:*"
  ) {
    const totalExperience = String(filterParams.total_experience);
    if (totalExperience.includes(",")) {
      const ranges = totalExperience.split(",");
      const rangeQueries = ranges.map(
        (range) => `total_experience:[${range.trim()}]`
      );
      const combinedQuery = rangeQueries.join(" OR ");
      query += `&fq=(${combinedQuery})`;
    } else {
      query += `&fq=total_experience:[${totalExperience.trim()}]`;
    }
  }

  query += `&facet=true&facet.pivot=location_state,address_city&facet.field=location_state&facet.field=jobtype&facet.field=visatype&facet.field=total_experience&facet.mincount=1&facet.sort=count`;

  return `${solrBaseUrl}${query}&rows=${rows}&start=${start}&fq=search_flag:(1)&sort=lastmodified desc&wt=json`;
};

// export const buildFacetQuery = (
//   filterParams,
//   searchParams,
//   paginationParams
// ) => {
//   const { start, rows } = paginationParams;
//   const solrBaseUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select?q=*:*&rows=${rows}&start=${start}&fq=search_flag:(1)&sort=lastmodified desc&wt=json`;
//   let query = "";
//   const jobTypeID = [];
//   const visaTypeID = [];
//   const stateID = [];
//   const cityID = [];

//   const addIDToArray = (param, data, idArray, field, paramName) => {
//     if (param === "*:*") {
//       data.forEach((item) => {
//         if (item[field].includes(searchParams[paramName])) {
//           idArray.push(item.id);
//         }
//       });
//     } else {
//       idArray.push(...param?.split(" OR "));
//     }
//   };

//   addIDToArray(
//     filterParams.jobtype,
//     job_types_data,
//     jobTypeID,
//     "title",
//     "jobType"
//   );
//   addIDToArray(
//     filterParams.visatype,
//     visa_types_data,
//     visaTypeID,
//     "type_code",
//     "visaType"
//   );
//   addIDToArray(filterParams.state, us_states_data, stateID, "name", "state");
//   if (filterParams.city && filterParams.city !== "*:*" && filterParams.city !== "") {
//     cityID.push(...filterParams.city?.split(" OR "));
//   }

//   if (searchParams.title) {
//     const searchText = processSearchTitleV1(searchParams.title);
//     const searchText2 = processSearchTitle(searchParams.title);
//     query += `&fq=application_title:(${searchText} OR ${searchText2})`;
//   }
//   if (stateID.length > 0) {
//     query += `&fq=location_state:(${stateID.join(" OR ")})`;
//   }
//   if (cityID.length > 0) {
//     query += `&fq=address_city:(${cityID.join(" OR ")})`;
//   }
//   if (jobTypeID.length > 0) {
//     query += `&fq=jobtype:(${jobTypeID.join(" OR ")})`;
//   }
//   if (visaTypeID.length > 0) {
//     query += `&fq=visatype:(${visaTypeID.join(" OR ")})`;
//   }
//   if(filterParams.lastmodified){
//     const dateRange = getMappedDates(filterParams.lastmodified);
//     query += `&fq=lastmodified:[${dateRange}]`;
//     console.log(query);

//   }

//   query += `&facet=true&facet.pivot=location_state,address_city&facet.field=location_state&facet.field=jobtype&facet.field=visatype&facet.field=total_experience&facet.mincount=1&facet.sort=count`;

//   return `${solrBaseUrl}${query}`;
// };
