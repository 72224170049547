import React, { useEffect } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Card } from "@mui/material"
import axios from "axios";
import Key from "../src/clientVariables.json"
import parse from "html-react-parser";
import moment from 'moment';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [resumeDetails, setResumeDetails] = React.useState([]);
  const [educationDetails, setEducationDetails] = React.useState([]);
  const [certificateDetails, setCertificateDetails] = React.useState([]);
  const [projectdetails, setProjectDetails] = React.useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    props.handleClose()
  };


  const downloadOrViewResume = (attachmentname, filetype) => {
    console.log(filetype);
    fetch(
      `${Key.files_domain}/MyFiles/resume_attachments/${resumeDetails.uid}/${resumeDetails.id}/${resumeDetails.filename}`,
      {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + window.localStorage["Access_Token"],
        },
      }
    )
      .then((resdata) => {
        console.log(resdata);
        console.log(resdata.url);
        // if (
        //   filetype === ".pdf" ||
        //   filetype === ".jpg" ||
        //   filetype === ".jpeg" ||
        //   filetype === ".png"
        // ) {
          //Build a URL from the file
          const fileURL = resdata.url;

          console.log(fileURL);
          //Open the URL on new Window
          const pdfWindow = window.open();
          pdfWindow.location.href = fileURL;
       // } 
        
       /* else {
          return resdata.blob().then((b) => {
            var a = document.createElement("a");
            a.href = URL.createObjectURL(b);
            a.setAttribute("download", attachmentname);
            a.click();
          });
        }*/
      })
      .catch((e) => {
        console.log(e);
      });
  };


  useEffect(() => {
    console.log(props.resumeId)

    axios
      .post(`${Key.domain}/parser/get_parser_resume_details`, {
        resumeid: props.resumeId
      })
      .then((response) => {
        console.log(response.data.data);
        setResumeDetails(response.data.data.resumedetails)
        setEducationDetails(response.data.data.educationdetails)
        setCertificateDetails(response.data.data.certificatedetails)
        setProjectDetails(response.data.data.projectdetails)
      })

  }, [props.resumeId])

  return (
    <div>

      <Dialog
        fullScreen
        fullWidth
        open={props.show}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">

            </Typography>

            <Button autoFocus color="inherit" onClick={downloadOrViewResume}>
            VIEW PDF
          </Button>&nbsp;&nbsp;&nbsp;&nbsp;


            <Button autoFocus color="inherit" onClick={handleClose}>
              Close
            </Button>
          
          </Toolbar>
        </AppBar>
        <div className='container' >
          <div className='row'>
            <div className='col-md-8 col-sm-8 col-lg-8'>
              <Card style={{ padding: "12px" }}>
                <h5><b>{resumeDetails.full_name ? resumeDetails.full_name : "N/A"}</b></h5>
                <ul>
                  <li style={{marginLeft:"-29px"}}>{resumeDetails.application_title} | {resumeDetails.total_experience} Years | {resumeDetails.cityname},{resumeDetails.statename},{resumeDetails.countryname}.</li>
                </ul>




                <h5><b>Skills</b></h5>
                <p>{resumeDetails.ai_skills ? resumeDetails.ai_skills : (resumeDetails.skillNames ? resumeDetails.skillNames : "N/A")}</p>

                <h5><b>Summary</b></h5>
                <p>{resumeDetails.summary ? parse(resumeDetails.summary) : "N/A"}</p>

              </Card>
              <Card style={{ padding: "12px" }}>
                <h5 className='mb-3'><b>Projects</b></h5>
                {projectdetails && projectdetails.length !== 0 ? (
                projectdetails.map(project =>
                  <div>
                    <div style={{ lineHeight: "12px" }}>
                      <p><b>Title</b> : {project.pro_title ? project.pro_title : "N/A"}</p>

                      <p><b>Role</b> : {project.pro_role ? project.pro_role : "N/A"}</p>

                      <p><b>Client</b> : {project.pro_client ? project.pro_client : "N/A"}</p>

                      <p><b>Location</b> : {project.pro_address_full ? project.pro_address_full : "N/A"}</p>

                      <p><b>Start Date</b> : {project.pro_startdate
                        ? moment(project.pro_startdate).format("MMM YYYY")
                        : "N/A"}</p>

                      <p><b>End Date</b> : {project.pro_enddate
                        ? moment(project.pro_enddate).format("MMM YYYY")
                        : "Till Date"}</p>
                    </div>
                    <p><b>Description</b> : </p>
                    <p>{parse(project.pro_description)}</p>
                    <hr/>
                  </div>
                 
                )) :(<div className="mt-3 mb-4">Projects not available</div>)}
                
              </Card>


              <Card style={{ padding: "12px" }}>
                <h5><b>Education</b></h5>
                {educationDetails && educationDetails.length !== 0 ? (
                  educationDetails.map((edu, idx) => (
                    <div
                      className="d-flex justify-content-between "
                      style={{ fontSize: "16px", margin: "20px 0px 20px 0px" }}
                    >

                      <ul className="list-group">
                        <li className="list-item" style={{ marginLeft: "10px" }}>
                          <span style={{ marginRight: "5px" }}>
                            {edu.edu_course
                              ? edu.edu_course + " from " + edu.edu_university
                              : "not available"}
                          </span>
                          in the year available
                          <span style={{ marginLeft: "5px" }}>
                            {edu.edu_passing_year
                              ? edu.edu_passing_year
                              : "not available"}
                          </span>
                        </li>
                      </ul>

                      <div>



                      </div>
                    </div>
                  ))
                ) : (
                  <div className="mt-3 mb-4">Education not available</div>
                )}
              </Card>


              <Card style={{ padding: "12px" }}>
                <h5><b>Cetificates</b></h5>
                {certificateDetails !== undefined && certificateDetails.length !== 0 ? (
                  certificateDetails.map((cert, i) => (
                    <p
                      class="card-text "
                      style={{ fontSize: "16px", margin: "0 0 0 10px" }}
                    >
                      {(cert.cer_name !== "undefined" && cert.cer_name !== null && cert.cer_name !== "")
                        ? cert.cer_name
                        : ""}
                      {cert.cer_name !== "undefined" && cert.cer_name !== null && cert.cer_name !== "" ? <span style={{ marginRight: "5px" }}> in the year</span> : ""}
                      <span>{cert.cer_name !== "undefined" && cert.cer_name !== null && cert.cer_name !== "" ? cert.cer_year : ""}</span>




                    </p>
                  ))
                ) : (
                  <p class="card-text mt-2 mb-3" style={{ fontSize: "16px" }}>

                    Certificates not available
                  </p>
                )}
              </Card>
            </div>

            <div className='col-md-4 col-sm-4 col-lg-4'>
              <Card style={{ padding: "12px" }}>
                <h5>Job Details</h5>
                <hr />
                <p><b>Job Type</b> : {resumeDetails.jobtypeNames ? resumeDetails.jobtypeNames : "N/A"}</p>
                <p><b>Salary Type</b> : {resumeDetails.jobsalaryrangetypeName ? resumeDetails.jobsalaryrangetypeName : "N/A"}</p>
                <p><b>Visa type</b> : {resumeDetails.visatype_name ? resumeDetails.visatype_name : "N/A"}</p>
              </Card>


              <Card style={{ padding: "12px" }}>
                <h5>Contact Details</h5>
                <hr />
                <p><b>Phone</b> : {resumeDetails.home_phone ? resumeDetails.home_phone : "N/A"}</p>
                <p><b>Email</b> : {resumeDetails.email_address ? resumeDetails.email_address : "N/A"}</p>
                <p><b>Skype Id</b> : {resumeDetails.skype_id ? resumeDetails.skype_id : "N/A"}</p>
              </Card>


              <Card style={{ padding: "12px" }}>

                <p><b>Resume Id</b> : {resumeDetails.id}</p>
                <p><b>Uploaded By</b> : {resumeDetails.uploaded_by ? resumeDetails.uploaded_by : "N/A"}</p>
                <p><b>Modified By</b> : {resumeDetails.uploaded_by ? resumeDetails.uploaded_by : "N/A"}</p>
                <p><b>Modified On</b> : {moment(resumeDetails.last_modified).format('YYYY-MM-DD HH:mm A')}</p>
              </Card>

            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
