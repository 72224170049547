import React, { useState, useEffect, useRef } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; import { IoIosAdd } from "react-icons/io";
import { AiFillDelete } from 'react-icons/ai';
import { Card } from "@mui/material";
import axios from "axios"
import Key from './clientVariables.json'
import moment from "moment"
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { showLoader } from "./Components/Actions/loader.actions";
import { hideLoader } from "./Components/Actions/loader.actions";

function Projectstab(props) {

  const formikRef = useRef();

  const [addProject, setAddProject] = useState([{}])
  const [count, setCount] = useState(0)
  const [projectsList, setProjectsList] = useState([{ country: 233 }])
  const [countries, setCountries] = useState([])
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [insertedResumeid, setInsertedResumeId] = useState(null)
  const [insertedProjectId, setInsertedProjectId] = useState(null)
  const [currentUserdetails, setCurrentUserDetails] = useState(null)
  const [isRegisterViaResume, setIsregisterViaResume] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false)

  useEffect(() => {
    setIsregisterViaResume(props.isRegisterViaResume)
   // setProjectsList(props.projectDetails)
  }, [props.isRegisterViaResume])

  useEffect(() => {
    setCurrentUserDetails(props.currentUserdetails)
  }, [props.currentUserdetails])


  const handleAddProject = (count) => {
    props.dispatch(showLoader())
    setProjectsList([...projectsList, { country: 233 }])
    setCount(count + 1)

  }
  const handleRemoveProject = (idx) => {
    props.dispatch(showLoader())
    setProjectsList(projectsList.filter((project, index) => index !== idx));

  }

  useEffect(() => {
    if (projectsList !== null && projectsList.length > 0) {
      // console.log(projectsList)

      if (formikRef.current) {
        projectsList.map((pro, index) => {
          formikRef.current.setFieldValue(
            "client" + index,
            pro.pro_client !== undefined ? pro.pro_client : ''
          );
          formikRef.current.setFieldValue(
            "role" + index,
            pro.pro_role !== undefined ? pro.pro_role : ''
          );
          formikRef.current.setFieldValue(
            "state" + index,
            pro.pro_state !== undefined ? pro.pro_state : ''
          );
          formikRef.current.setFieldValue(
            "country" + index,
            pro.country !== undefined ? pro.country : 233
          );
          formikRef.current.setFieldValue(
            "city" + index,
            pro.city !== undefined ? pro.city : ''
          );
          formikRef.current.setFieldValue(
            "startdate" + index,
            pro.pro_startdate ? pro.pro_startdate : ''
          );
          formikRef.current.setFieldValue(
            "enddate" + index,
            pro.pro_enddate ? pro.pro_enddate : ''
          );
          formikRef.current.setFieldValue(
            "summary" + index,
            pro.pro_description ? pro.pro_description.replace(/["']/g, "") : null
          );
          formikRef.current.setFieldValue(
            "projectTitle" + index,
            pro.pro_title ? pro.pro_title.replace(/["']/g, "") :''
          );
        })

      }
    }
    props.dispatch(hideLoader())

  }, [projectsList])
  useEffect(() => {

    axios
    .post(`${Key.domain}/parser/get_parser_resume_details`, {
      resumeid: 594605
    })
    .then(async response => {
      console.log(response)
      if (response && response.status === 200 && response.data.data && response.data.data.resumedetails) {
        setProjectsList(response.data.data.projectdetails)
       
      }
    })


    if (props.projectDetails) {
    //  setProjectsList(props.projectDetails)
      setAddProject(props.projectDetails)

      // if (formikRef.current) {
      //   props.projectDetails.map((pro,index)=>{
      //     formikRef.current.setFieldValue(
      //       "client"+index,
      //       pro.organization!==undefined?pro.organization:''
      //     );
      //     formikRef.current.setFieldValue(
      //       "role"+index,
      //       pro.jobtitle!==undefined?pro.jobtitle:''
      //     );
      //     formikRef.current.setFieldValue(
      //       "state"+index,
      //       ''
      //     );
      //     formikRef.current.setFieldValue(
      //       "country"+index,
      //       ''
      //     );
      //     formikRef.current.setFieldValue(
      //       "city"+index,
      //       ''
      //     );
      //     formikRef.current.setFieldValue(
      //       "startdate"+index,
      //       pro.date_start&&moment(pro.date_start).format("yyyy-MM-DD")!=="Invalid date"?moment(pro.date_start).format("yyyy-MM-DD"):''
      //     );
      //     formikRef.current.setFieldValue(
      //       "enddate"+index,
      //       pro.date_end&&moment(pro.date_end).format("yyyy-MM-DD")!=="Invalid date"?moment(pro.date_end).format("yyyy-MM-DD"):''
      //     );
      //     formikRef.current.setFieldValue(
      //       "summary"+index,
      //       pro.description ? pro.description.replace(/["']/g, "") : null
      //     );
      //   })

      // }
    }
  }, [props.projectDetails])


  useEffect(() => {
    axios.get(`${Key.domain}/commonAPIs/get_all_countries`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      },
    })
      .then((resdata) => {
        console.log(resdata.data.data)
        setCountries(resdata.data.data)
        getStates(233, 0)

      })
  }, [])

  const getStates = (countryid, index) => {

    console.log(index)
    axios.post(`${Key.domain}/commonAPIs/get_states_by_countryid`, {
      country_id: countryid,
    })
      .then(response => {
        console.log(response)
        setStates(response.data.data)
        if (formikRef.current) {

          formikRef.current.setFieldValue(
            "country" + index,
            countryid || 233
          );
        }
      })



  }

  const getCities = (stateid, index) => {
    //  const selectedState = e.target.value;
    // console.log(selectedState)
    axios.post(`${Key.domain}/commonAPIs/get_cities_by_stateid`, {
      state_id: stateid,
    })
      .then(response => {
        console.log(response)
        setCities(response.data.data)
        if (formikRef.current) {

          formikRef.current.setFieldValue(
            "state" + index,
            stateid
          );
          formikRef.current.setFieldValue(
            "dropdowncities" + index,
            response.data.data
          );
        }
      })
  }

  const handleInputChange = (e, index, values) => {
    // console.log(values)
    var projects = [...projectsList]
    var name = e.target.name
    if (name === "client" + index) {
      projects[index]["organization"] = e.target.value
    }
    if (name === "projectTitle" + index) {
      projects[index]["projectTitle"] = e.target.value;
    }
    if (name === "role" + index) {
      projects[index]["jobtitle"] = e.target.value
    }
    if (name === "country" + index) {
      projects[index]["country"] = e.target.value
    }
    if (name === "state" + index) {
      projects[index]["state"] = e.target.value
    }
    if (name === "city" + index) {
      projects[index]["city"] = e.target.value
    }
    if (name === "startdate" + index) {
      projects[index]["date_start"] = e.target.value
    }
    if (name === "enddate" + index) {
      projects[index]["date_end"] = e.target.value
    }
    setAddProject(projects)
    console.log(projects)
  }
  const initialValues = {}
  projectsList.map((pro, index) => {
    initialValues["client" + index] = ''
    initialValues["projectTitle" + index] = ''
    initialValues["role" + index] = ''
    initialValues["state" + index] = ''
    initialValues["city" + index] = ''
    initialValues["country" + index] = 233
    initialValues["startdate" + index] = ''
    initialValues["enddate" + index] = ''
    initialValues["summary" + index] = ''
  })
  const myschema = {}

  //  }
  projectsList.map((pro, index) => {
    myschema["client" + index] = Yup.string().required("Client Name is Required")
    myschema["projectTitle" + index] = Yup.string().required("Project title is Required")
    myschema["role" + index] = Yup.string().required(" Role is Required")
    myschema["country" + index] = Yup.string().required(" Country is Required")
    myschema["state" + index] = Yup.string().required(" State is Required")
    myschema["city" + index] = Yup.string().required("City is Required")
    myschema["startdate" + index] = Yup.string().required(" Start date is Required")
    myschema["enddate" + index] = Yup.string().required(" End date is Required")
  })

  const verifyProjectDetails = () => {
    setButtonLoading(true)
    if (projectsList.length > 0) {
      axios.post(`${Key.domain}/parser/add_js_projects_via_parser`, {
        "user_id": currentUserdetails ? currentUserdetails.id : props.currentUser.id,
        "session_token": currentUserdetails ? currentUserdetails.session_token : props.currentUser.session_token,
        "project_id": insertedProjectId,
        "resumeid": insertedResumeid,
        "projects_data": projectsList

      }).then(response => {
        setButtonLoading(false)
console.log(response)
        if (response.status === 200 && response.data && response.data.success === 1 && response.data.data && response.data.data[0].insertId > 0) {
          // alert("Verified successfully!")
          setInsertedProjectId(response.data.data.insertId)
          // props.setInsertid(response.data.data.insertId)
          props.setDataInParent(projectsList);
          props.setIsEducationTabDisabled(false)
          props.movetoNextTab(3)
        }
      })
    } else {
      props.setDataInParent(projectsList);
      props.setIsEducationTabDisabled(false)
      setButtonLoading(false)
      props.movetoNextTab(3)
    }

  }
  useEffect(() => {
    setInsertedResumeId(props.insertedResumeId)
  }, [props.insertedResumeId])

  const onTextEditorchanged = (value, index) => {
    // console.log(value)
    var projects = [...projectsList]
    projects[index]["description"] = value
    setAddProject(projects)

    if (formikRef.current) {
      formikRef.current.setFieldValue(
        "summary" + index,
        value
      )
    }
  }
  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={Yup.object().shape(myschema

      )}
      onSubmit={(fields) => {
        // console.log(projectsList)
        // alert("SUCCESS!! :-)\n\n" + JSON.stringify(projectsList, null, 4));
        verifyProjectDetails()

      }}
      render={({ errors, status, touched, values }) => (
        <Card>
          <Form id="addresume" style={{overflowY:"scroll",height:"657px"}}>
            <div className="addicon" style={{ float: "right" }} >
              <IoIosAdd style={{ fill: "#406882" }} onClick={() => handleAddProject(count)} />
            </div>
            {projectsList.map((project, index) => {
              return (
                <div className="row mt-4" key={index}>
                  <span className="delete-icon" ><AiFillDelete style={{ fill: "#406882" }} onClick={() => handleRemoveProject(index)} /></span>
                  <div className="col-md-4">
                    <div className="form-group1">
                      <label htmlFor={"client" + index}>
                        Client <span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        name={"client" + index}
                        type="text"
                        placeholder="Client"
                        className={
                          "form-control" +
                          (errors["client" + index] && touched["client" + index] ? " is-invalid" : "")
                        }
                        onChange={(e) => {
                          handleInputChange(e, index);
                          console.log(values["client" + index], values)
                          formikRef.current.setFieldValue(
                            "client" + index,
                            e.target.value
                          );
                        }}
                        value={project.organization ? project.organization : values["client" + index]}
                      />
                      <ErrorMessage
                        name={"client" + index}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group1">
                      <label htmlFor={"projectTitle" + index}>
                        Project Title <span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        name={"projectTitle" + index}
                        type="text"
                        placeholder="Project Title"
                        className={
                          "form-control" +
                          (errors["projectTitle" + index] &&
                            touched["projectTitle" + index]
                            ? " is-invalid"
                            : "")
                        }
                        // value={project.jobtitle}
                        onChange={(e) => {
                          console.log(e.target.value);
                          formikRef.current.setFieldValue(
                            "projectTitle" + index,
                            e.target.value
                          );
                          handleInputChange(e, index, values);
                        }}
                      />
                      <ErrorMessage
                        name={"projectTitle" + index}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group1">
                      <label htmlFor="role">
                        Role <span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        name={"role" + index}
                        type="text"
                        placeholder="Role"
                        className={
                          "form-control" +
                          (errors["role" + index] && touched["role" + index] ? " is-invalid" : "")
                        }
                        value={project.pro_role}
                        onChange={(e) => {
                          formikRef.current.setFieldValue(
                            "role" + index,
                            e.target.value
                          )
                          handleInputChange(e, index, values)
                        }}
                      />
                      <ErrorMessage
                        name={"role" + index}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group1">
                      <label htmlFor="Country">
                        {" "}
                        Country <span style={{ color: "red" }}>*</span>
                      </label>
                      <Field name={"country" + index} as="select" className={
                        "form-select form-control" +
                        (errors["country" + index] && touched["country" + index] ? " is-invalid" : "")
                      }
                        onChange={(e) => {
                          handleInputChange(e, index)
                          getStates(e.target.value, index)
                        }}
                        defaultValue={values["country" + index]}
                      >
                        <option selected>Select Country</option>
                        {countries.map(country =>
                          <option value={country.id}>{country.name}</option>
                        )}



                      </Field>
                      <ErrorMessage
                        name={"country" + index}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group1">
                      <label htmlFor={"state" + index}>
                        {" "}
                        State <span style={{ color: "red" }}>*</span>
                      </label>
                      <Field name={"state" + index} as="select" className={
                        "form-select form-control" +
                        (errors["state" + index] && touched["state" + index] ? " is-invalid" : "")
                      }
                        onChange={(e) => {
                          handleInputChange(e, index)
                          getCities(e.target.value, index)
                        }}
                        value={project.state ? project.state : values["state" + index]}
                      >
                        <option selected>Select State</option>
                        {
                          states.map(states_list =>
                            <option value={states_list.id}>{states_list.name}</option>
                          )
                        }



                      </Field>
                      <ErrorMessage
                        name={"state" + index}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group1">
                      <label htmlFor="city">
                        {" "}
                        City <span style={{ color: "red" }}>*</span>
                      </label>
                      <Field name={"city" + index} as="select" className={
                        "form-select form-control" +
                        (errors["city" + index] && touched["city" + index] ? " is-invalid" : "")
                      }
                        onChange={(e) => {
                          handleInputChange(e, index)
                          formikRef.current.setFieldValue(
                            "city" + index,
                            e.target.value
                          );
                        }}
                        value={project.city ? project.city : values["city" + index]}

                      >
                        <option selected>Select City</option>
                        {values["dropdowncities" + index] ?
                          values["dropdowncities" + index].map(city =>
                            <option value={city.id}>{city.cityName}</option>
                          )
                          :
                          cities.map(city =>
                            <option value={city.id}>{city.cityName}</option>
                          )}



                      </Field>
                      <ErrorMessage
                        name={"city" + index}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group1">
                      <label htmlFor={"startdate" + index}>
                        Start Date <span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        name={"startdate" + index}
                        type="text"
                        className={
                          "form-control" +
                          (errors["startdate" + index] && touched["startdate" + index]
                            ? " is-invalid"
                            : "")
                        }
                        onChange={(e) => {
                          handleInputChange(e, index)

                          formikRef.current.setFieldValue(
                            "startdate" + index,
                            e.target.value
                          );
                        }}
                        // value={project.date_start && moment(project.date_start).format("yyyy-MM-DD") !== "Invalid date" ? moment(project.date_start).format("yyyy-MM-DD") : values["startdate" + index]}
                        value={project.date_start ? project.date_start : values["startdate" + index]}
                      />
                      <ErrorMessage
                        name={"startdate" + index}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group1">
                      <label htmlFor="enddate">
                        End Date <span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        name={"enddate" + index}
                        // type={project.date_end&&(project.date_end.toLowerCase()==="till date"||project.date_end.toLowerCase()==="present")?"text":"date"}
                        type="text"
                        className={
                          "form-control" +
                          (errors["enddate" + index] && touched["enddate" + index] ? " is-invalid" : "")
                        }
                        onChange={(e) => {
                          handleInputChange(e, index)

                          formikRef.current.setFieldValue(
                            "enddate" + index,
                            e.target.value
                          );
                        }}
                        // value={project.date_end?(project.date_end.toLowerCase()==="till date"||project.date_end.toLowerCase()==="present")?project.date_end:project.date_end && moment(project.date_end).format("yyyy-MM-DD") !== "Invalid date" ? moment(project.date_end).format("yyyy-MM-DD") : values["enddate" + index]: values["enddate" + index]}
                        value={project.date_end ? project.date_end : values["enddate" + index]}
                      />
                      <ErrorMessage
                        name={"enddate" + index}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group1">
                      <label htmlFor="summary">Description</label>
                      <ReactQuill theme="snow"
                     
                        onChange={(e) => {
                          onTextEditorchanged(e, index)

                        }}
                        value={values.summary+ index}
                        init={{
                          height: 200,
                          menubar: false,

                          statusbar: false,
                          plugins: ['code', 'lists'],

                          
                          toolbar: 'undo redo | casechange blocks | bold italic  | ' +
                          'alignleft aligncenter alignright alignjustify | ' +
                          'bullist | '
                        }}
                        name={"summary" + index}

                      />
                      <ErrorMessage
                        name={"summary" + index}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>


                </div>
              )
            })}
            <div className="form-group1">
              {!buttonLoading ?
                <button
                  type="submit"
                  className="btn btn-primary mr-2"
                  id="update_profile"
                >
                  Submit
                </button>
                :
                <button
                  type="submit"
                  className="btn btn-primary mr-2"
                  id="update_profile"
                  disabled
                >
                  Submitting....
                </button>
              }
            </div>
          </Form>
        </Card>
      )}
    />
  );

}
function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.UserReducer.user,
    isLoading: state.UserReducer.isLoginLoading,
    iserror: state.UserReducer.error,
  };
}
export default withRouter(connect(mapStateToProps, null)(Projectstab))
