import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { GrAttachment } from "react-icons/gr";
import { ReactMultiEmail } from "react-multi-email";
import * as Yup from "yup";
import axios from "axios";
import Key from "../../clientVariables.json";

import "react-multi-email/style.css";

const ShareResume = (props) => {

  const [emails, setEmails] = useState([])
  const [flag, setFlag] = useState(1)
  const [ccError, setCcError] = useState(false)


  const handleAttachment = () => {
    // alert("Please,attach document");
    // toast(<ToastMessage message="Please,attach document" />)


  };


  const sendMaill = (fields) => {
    setFlag(0)

    if (emails.length === 0) {
      setCcError(true)
    }

    else {
      axios
        .post(`${Key.files_domain}/Jobs/share_applicant_details`, {
          js_id: props.user_id,
          resume_id: props.res_id,
          usermail: props.usermail,
          subject: fields.subject,
          message: fields.message,
          attachment: props.filename,
          to: emails,

        })
        .then((response) => {
          props.handlepopUpClose();
          props.updateViewStatus(props.res_id)
        })
    }

  }



  return (
    <div>
      <Formik
        initialValues={{
          subject: "",
          cc: "",
          message: "",
        }}
        // validationSchema={}
        validationSchema={Yup.object().shape({
          subject: Yup.string().required("Subject is Required"),
          // cc: Yup.string().required("mail required"),
          message: Yup.string().required("Message is Required")
        })}
        onSubmit={(fields) => {
          sendMaill(fields);
        }}
        render={({ errors, status, touched, values }) => (
          <div className="container">
            <Form autoComplete="off">
              <div className="row">
                <div className="col-12">
                  <div className="form-group mb-2" >
                    <label>
                      Subject<span style={{ color: "red" }}>*</span>
                    </label>
                    <Field
                      name="subject"
                      type="text"
                      // placeholder="Company Name"
                      className={
                        "mt-1 form-control" +
                        (errors.subject && touched.subject ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="subject"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="mb-2">
                    <label className="mb-1">
                      To<span style={{ color: "red" }}>*</span>
                    </label>
                    <ReactMultiEmail
                      placeholder="Input your Email Address"
                      emails={emails}
                      onChange={(emails) => {
                        setEmails(emails)
                      }}

                      getLabel={(
                        email,
                        index,
                        removeEmail) => {
                        return (
                          <div data-tag key={index}>
                            <div data-tag-item>
                              {email}
                            </div>
                            {/* {email} */}
                            <span
                              data-tag-handle
                              onClick={() => removeEmail(index)}
                            >
                              ×
                            </span>
                          </div>
                        );
                      }}
                    />
                    {ccError && emails.length === 0 ? <div ><p style={{ color: '#dc3545', fontSize: "14px", marginTop: "8px" }}>mail required</p></div> : ""}

                  </div>
                  <div className="form-group mt-2" >
                    <label>
                      Message<span style={{ color: "red" }}>*</span>
                    </label>
                    <Field
                      name="message"
                      type="text"
                      as="textarea"
                      placeholder="Message Content"
                      className={
                        "mt-1 form-control" +
                        (errors.message && touched.message ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="message"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label>
                      Attachment<span style={{ color: "red" }}>*</span>
                    </label>
                    <span
                      className="ms-4"
                      style={{ color: "#406882", cursor: "pointer" }}
                    >
                      <GrAttachment onClick={handleAttachment} />
                    </span>
                  </div>
                  <div className="mt-4">
                    <p>
                      <span
                        style={{
                          padding: "5px",
                          border: "1px solid",
                          borderRadius: "5px",
                        }}
                      >
                        {props.filename}
                      </span>
                    </p>
                  </div>
                  <div className="col-md-2">
                    <button
                      // onClick={sendMaill}
                      className="btn  me-2"
                      id="theme-button"
                    >
                      Share
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        )}
      />
    </div>
  );
};

export default ShareResume;
