export const job_types_data = [
  {
    id: 8,
    title: "Need H1B",
  },
  {
    id: 9,
    title: "Hourly Employee",
  },
  {
    id: 15,
    title: "Contract to Hire - W2",
  },
  {
    id: 14,
    title: "Contract to Hire - Corp-to-Corp",
  },
  {
    id: 13,
    title: "Contract - Independent",
  },
  {
    id: 11,
    title: "Contract - Corp-to-Corp",
  },
  {
    id: 1,
    title: "Full-Time",
  },
  {
    id: 16,
    title: "Contract to Hire - Independent",
  },
  {
    id: 2,
    title: "Part-Time",
  },
  {
    id: 3,
    title: "Internship",
  },
  {
    id: 4,
    title: "W2 - Permanent",
  },
  {
    id: 5,
    title: "W2 - Contract",
  },
  {
    id: 6,
    title: "1099-Contract",
  },
  {
    id: 7,
    title: "Contract to Hire",
  },
  {
    id: 10,
    title: "Contract Based",
  },
];

export const visa_types_data = [
  {
    id: 1,
    type_name: "GC",
    type_code: "GC - Green Card",
    created: "2018-10-01T19:06:42.000Z",
    isactive: 1,
    isdefault: 1,
    ordering: 2,
    status: 1,
  },
  {
    id: 2,
    type_name: "USC",
    type_code: "USC - U.S. Citizen",
    created: "2018-10-01T19:06:42.000Z",
    isactive: 1,
    isdefault: 0,
    ordering: 3,
    status: 1,
  },
  {
    id: 3,
    type_name: "H1",
    type_code: "H1",
    created: "2018-10-01T19:06:42.000Z",
    isactive: 1,
    isdefault: 0,
    ordering: 1,
    status: 1,
  },
  {
    id: 4,
    type_name: "OPT",
    type_code: "OPT - Optional Practical Training",
    created: "2018-10-01T19:06:42.000Z",
    isactive: 1,
    isdefault: 0,
    ordering: 4,
    status: 1,
  },
  {
    id: 5,
    type_name: "F1",
    type_code: "F1",
    created: "2021-12-23T10:45:04.000Z",
    isactive: 1,
    isdefault: 0,
    ordering: 7,
    status: 0,
  },
  {
    id: 6,
    type_name: "TN",
    type_code: "TN",
    created: "2021-12-23T10:45:04.000Z",
    isactive: 1,
    isdefault: 0,
    ordering: 6,
    status: 1,
  },
  {
    id: 7,
    type_name: "EAD",
    type_code: "EAD - Employment Authorization Document",
    created: "2021-12-23T10:45:04.000Z",
    isactive: 1,
    isdefault: 0,
    ordering: 5,
    status: 1,
  },
  {
    id: 8,
    type_name: "Others",
    type_code: "Others",
    created: "2021-12-23T10:45:04.000Z",
    isactive: 1,
    isdefault: 0,
    ordering: 8,
    status: 1,
  },
  {
    id: 9,
    type_name: "L1",
    type_code: "L1",
    created: "2023-01-10T10:45:04.000Z",
    isactive: 1,
    isdefault: 0,
    ordering: 9,
    status: 1,
  },
  {
    id: 10,
    type_name: "GC - EAD",
    type_code: "GC - EAD",
    created: "2023-01-10T10:45:04.000Z",
    isactive: 1,
    isdefault: 0,
    ordering: 10,
    status: 1,
  },
  {
    id: 11,
    type_name: "H4 - EAD",
    type_code: "H4 - EAD",
    created: "2023-01-10T10:45:04.000Z",
    isactive: 1,
    isdefault: 0,
    ordering: 11,
    status: 1,
  },
  {
    id: 12,
    type_name: "H4",
    type_code: "H4",
    created: "2023-01-10T10:45:04.000Z",
    isactive: 1,
    isdefault: 0,
    ordering: 12,
    status: 1,
  },
  {
    id: 13,
    type_name: "H1B",
    type_code: "H1B",
    created: "2023-01-10T10:45:04.000Z",
    isactive: 1,
    isdefault: 0,
    ordering: 13,
    status: 1,
  },
  {
    id: 14,
    type_name: "L2 - EAD",
    type_code: "L2 - EAD",
    created: "2023-01-10T10:45:04.000Z",
    isactive: 1,
    isdefault: 0,
    ordering: 14,
    status: 1,
  },
  {
    id: 15,
    type_name: "OPT - EAD",
    type_code: "OPT - EAD",
    created: "2023-01-10T10:45:04.000Z",
    isactive: 1,
    isdefault: 0,
    ordering: 15,
    status: 1,
  },
  {
    id: 16,
    type_name: "CPT",
    type_code: "CPT - Curricular Practical Training",
    created: "2023-01-10T10:45:04.000Z",
    isactive: 1,
    isdefault: 0,
    ordering: 16,
    status: 1,
  },
];

export const us_states_data = [
  {
    id: 1456,
    name: "Alabama",
    countryid: 233,
    country_code: "US",
    fips_code: "01",
    shortRegion: "AL",
    type: "state",
    latitude: 32.3182314,
    longitude: -86.902298,
    created_at: "2019-10-05T23:18:42.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q173",
    status: "1",
  },
  {
    id: 1400,
    name: "Alaska",
    countryid: 233,
    country_code: "US",
    fips_code: "02",
    shortRegion: "AK",
    type: "state",
    latitude: 64.2008413,
    longitude: -149.4936733,
    created_at: "2019-10-05T23:18:41.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q797",
    status: "1",
  },
  {
    id: 1434,
    name: "Arizona",
    countryid: 233,
    country_code: "US",
    fips_code: "04",
    shortRegion: "AZ",
    type: "state",
    latitude: 34.0489281,
    longitude: -111.0937311,
    created_at: "2019-10-05T23:18:42.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q816",
    status: "1",
  },
  {
    id: 1444,
    name: "Arkansas",
    countryid: 233,
    country_code: "US",
    fips_code: "05",
    shortRegion: "AR",
    type: "state",
    latitude: 35.20105,
    longitude: -91.8318334,
    created_at: "2019-10-05T23:18:42.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q1612",
    status: "1",
  },
  {
    id: 1416,
    name: "California",
    countryid: 233,
    country_code: "US",
    fips_code: "06",
    shortRegion: "CA",
    type: "state",
    latitude: 36.778261,
    longitude: -119.4179324,
    created_at: "2019-10-05T23:18:42.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q99",
    status: "1",
  },
  {
    id: 1450,
    name: "Colorado",
    countryid: 233,
    country_code: "US",
    fips_code: "08",
    shortRegion: "CO",
    type: "state",
    latitude: 39.5500507,
    longitude: -105.7820674,
    created_at: "2019-10-05T23:18:42.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q1261",
    status: "1",
  },
  {
    id: 1435,
    name: "Connecticut",
    countryid: 233,
    country_code: "US",
    fips_code: "09",
    shortRegion: "CT",
    type: "state",
    latitude: 41.6032207,
    longitude: -73.087749,
    created_at: "2019-10-05T23:18:42.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q779",
    status: "1",
  },
  {
    id: 1399,
    name: "Delaware",
    countryid: 233,
    country_code: "US",
    fips_code: "10",
    shortRegion: "DE",
    type: "state",
    latitude: 38.9108325,
    longitude: -75.5276699,
    created_at: "2019-10-05T23:18:41.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q1393",
    status: "1",
  },
  {
    id: 1437,
    name: "District of Columbia",
    countryid: 233,
    country_code: "US",
    fips_code: "11",
    shortRegion: "DC",
    type: "district",
    latitude: 38.9071923,
    longitude: -77.0368707,
    created_at: "2019-10-05T23:18:42.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q3551781",
    status: "1",
  },
  {
    id: 1436,
    name: "Florida",
    countryid: 233,
    country_code: "US",
    fips_code: "12",
    shortRegion: "FL",
    type: "state",
    latitude: 27.6648274,
    longitude: -81.5157535,
    created_at: "2019-10-05T23:18:42.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q812",
    status: "1",
  },
  {
    id: 1455,
    name: "Georgia",
    countryid: 233,
    country_code: "US",
    fips_code: "13",
    shortRegion: "GA",
    type: "state",
    latitude: 32.1656221,
    longitude: -82.9000751,
    created_at: "2019-10-05T23:18:42.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q1428",
    status: "1",
  },
  {
    id: 1460,
    name: "Idaho",
    countryid: 233,
    country_code: "US",
    fips_code: "16",
    shortRegion: "ID",
    type: "state",
    latitude: 44.0682019,
    longitude: -114.7420408,
    created_at: "2019-10-05T23:18:42.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q1221",
    status: "1",
  },
  {
    id: 1425,
    name: "Illinois",
    countryid: 233,
    country_code: "US",
    fips_code: "17",
    shortRegion: "IL",
    type: "state",
    latitude: 40.6331249,
    longitude: -89.3985283,
    created_at: "2019-10-05T23:18:42.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q1204",
    status: "1",
  },
  {
    id: 1440,
    name: "Indiana",
    countryid: 233,
    country_code: "US",
    fips_code: "18",
    shortRegion: "IN",
    type: "state",
    latitude: 40.2671941,
    longitude: -86.1349019,
    created_at: "2019-10-05T23:18:42.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q1415",
    status: "1",
  },
  {
    id: 1459,
    name: "Iowa",
    countryid: 233,
    country_code: "US",
    fips_code: "19",
    shortRegion: "IA",
    type: "state",
    latitude: 41.8780025,
    longitude: -93.097702,
    created_at: "2019-10-05T23:18:42.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q1546",
    status: "1",
  },
  {
    id: 1406,
    name: "Kansas",
    countryid: 233,
    country_code: "US",
    fips_code: "20",
    shortRegion: "KS",
    type: "state",
    latitude: 39.011902,
    longitude: -98.4842465,
    created_at: "2019-10-05T23:18:41.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q1558",
    status: "1",
  },
  {
    id: 1419,
    name: "Kentucky",
    countryid: 233,
    country_code: "US",
    fips_code: "21",
    shortRegion: "KY",
    type: "state",
    latitude: 37.8393332,
    longitude: -84.2700179,
    created_at: "2019-10-05T23:18:42.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q1603",
    status: "1",
  },
  {
    id: 1457,
    name: "Louisiana",
    countryid: 233,
    country_code: "US",
    fips_code: "22",
    shortRegion: "LA",
    type: "state",
    latitude: 30.9842977,
    longitude: -91.9623327,
    created_at: "2019-10-05T23:18:42.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q1588",
    status: "1",
  },
  {
    id: 1453,
    name: "Maine",
    countryid: 233,
    country_code: "US",
    fips_code: "23",
    shortRegion: "ME",
    type: "state",
    latitude: 45.253783,
    longitude: -69.4454689,
    created_at: "2019-10-05T23:18:42.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q724",
    status: "1",
  },
  {
    id: 1401,
    name: "Maryland",
    countryid: 233,
    country_code: "US",
    fips_code: "24",
    shortRegion: "MD",
    type: "state",
    latitude: 39.0457549,
    longitude: -76.6412712,
    created_at: "2019-10-05T23:18:41.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q1391",
    status: "1",
  },
  {
    id: 1433,
    name: "Massachusetts",
    countryid: 233,
    country_code: "US",
    fips_code: "25",
    shortRegion: "MA",
    type: "state",
    latitude: 42.4072107,
    longitude: -71.3824374,
    created_at: "2019-10-05T23:18:42.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q771",
    status: "1",
  },
  {
    id: 1426,
    name: "Michigan",
    countryid: 233,
    country_code: "US",
    fips_code: "26",
    shortRegion: "MI",
    type: "state",
    latitude: 44.3148443,
    longitude: -85.6023643,
    created_at: "2019-10-05T23:18:42.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q1166",
    status: "1",
  },
  {
    id: 1420,
    name: "Minnesota",
    countryid: 233,
    country_code: "US",
    fips_code: "27",
    shortRegion: "MN",
    type: "state",
    latitude: 46.729553,
    longitude: -94.6858998,
    created_at: "2019-10-05T23:18:42.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q1527",
    status: "1",
  },
  {
    id: 1430,
    name: "Mississippi",
    countryid: 233,
    country_code: "US",
    fips_code: "28",
    shortRegion: "MS",
    type: "state",
    latitude: 32.3546679,
    longitude: -89.3985283,
    created_at: "2019-10-05T23:18:42.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q1494",
    status: "1",
  },
  {
    id: 1451,
    name: "Missouri",
    countryid: 233,
    country_code: "US",
    fips_code: "29",
    shortRegion: "MO",
    type: "state",
    latitude: 37.9642529,
    longitude: -91.8318334,
    created_at: "2019-10-05T23:18:42.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q1581",
    status: "1",
  },
  {
    id: 1446,
    name: "Montana",
    countryid: 233,
    country_code: "US",
    fips_code: "30",
    shortRegion: "MT",
    type: "state",
    latitude: 46.8796822,
    longitude: -110.3625658,
    created_at: "2019-10-05T23:18:42.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q1212",
    status: "1",
  },
  {
    id: 1408,
    name: "Nebraska",
    countryid: 233,
    country_code: "US",
    fips_code: "31",
    shortRegion: "NE",
    type: "state",
    latitude: 41.4925374,
    longitude: -99.9018131,
    created_at: "2019-10-05T23:18:41.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q1553",
    status: "1",
  },
  {
    id: 1458,
    name: "Nevada",
    countryid: 233,
    country_code: "US",
    fips_code: "32",
    shortRegion: "NV",
    type: "state",
    latitude: 38.8026097,
    longitude: -116.419389,
    created_at: "2019-10-05T23:18:42.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q1227",
    status: "1",
  },
  {
    id: 1404,
    name: "New Hampshire",
    countryid: 233,
    country_code: "US",
    fips_code: "33",
    shortRegion: "NH",
    type: "state",
    latitude: 43.1938516,
    longitude: -71.5723953,
    created_at: "2019-10-05T23:18:41.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q759",
    status: "1",
  },
  {
    id: 1417,
    name: "New Jersey",
    countryid: 233,
    country_code: "US",
    fips_code: "34",
    shortRegion: "NJ",
    type: "state",
    latitude: 40.0583238,
    longitude: -74.4056612,
    created_at: "2019-10-05T23:18:42.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q1408",
    status: "1",
  },
  {
    id: 1423,
    name: "New Mexico",
    countryid: 233,
    country_code: "US",
    fips_code: "35",
    shortRegion: "NM",
    type: "state",
    latitude: 34.5199402,
    longitude: -105.8700901,
    created_at: "2019-10-05T23:18:42.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q1522",
    status: "1",
  },
  {
    id: 1452,
    name: "New York",
    countryid: 233,
    country_code: "US",
    fips_code: "36",
    shortRegion: "NY",
    type: "state",
    latitude: 40.7127753,
    longitude: -74.0059728,
    created_at: "2019-10-05T23:18:42.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q1384",
    status: "1",
  },
  {
    id: 1447,
    name: "North Carolina",
    countryid: 233,
    country_code: "US",
    fips_code: "37",
    shortRegion: "NC",
    type: "state",
    latitude: 35.7595731,
    longitude: -79.0192997,
    created_at: "2019-10-05T23:18:42.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q1454",
    status: "1",
  },
  {
    id: 1418,
    name: "North Dakota",
    countryid: 233,
    country_code: "US",
    fips_code: "38",
    shortRegion: "ND",
    type: "state",
    latitude: 47.5514926,
    longitude: -101.0020119,
    created_at: "2019-10-05T23:18:42.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q1207",
    status: "1",
  },
  {
    id: 4851,
    name: "Ohio",
    countryid: 233,
    country_code: "US",
    fips_code: "39",
    shortRegion: "OH",
    type: "state",
    latitude: 40.4172871,
    longitude: -82.907123,
    created_at: "2019-11-13T05:30:00.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q1397",
    status: "1",
  },
  {
    id: 1421,
    name: "Oklahoma",
    countryid: 233,
    country_code: "US",
    fips_code: "40",
    shortRegion: "OK",
    type: "state",
    latitude: 35.4675602,
    longitude: -97.5164276,
    created_at: "2019-10-05T23:18:42.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q1649",
    status: "1",
  },
  {
    id: 1415,
    name: "Oregon",
    countryid: 233,
    country_code: "US",
    fips_code: "41",
    shortRegion: "OR",
    type: "state",
    latitude: 43.8041334,
    longitude: -120.5542012,
    created_at: "2019-10-05T23:18:41.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q824",
    status: "1",
  },
  {
    id: 1422,
    name: "Pennsylvania",
    countryid: 233,
    country_code: "US",
    fips_code: "42",
    shortRegion: "PA",
    type: "state",
    latitude: 41.2033216,
    longitude: -77.1945247,
    created_at: "2019-10-05T23:18:42.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q1400",
    status: "1",
  },
  {
    id: 1461,
    name: "Rhode Island",
    countryid: 233,
    country_code: "US",
    fips_code: "44",
    shortRegion: "RI",
    type: "state",
    latitude: 41.5800945,
    longitude: -71.4774291,
    created_at: "2019-10-05T23:18:42.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q1387",
    status: "1",
  },
  {
    id: 1443,
    name: "South Carolina",
    countryid: 233,
    country_code: "US",
    fips_code: "45",
    shortRegion: "SC",
    type: "state",
    latitude: 33.836081,
    longitude: -81.1637245,
    created_at: "2019-10-05T23:18:42.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q1456",
    status: "1",
  },
  {
    id: 1445,
    name: "South Dakota",
    countryid: 233,
    country_code: "US",
    fips_code: "46",
    shortRegion: "SD",
    type: "state",
    latitude: 43.9695148,
    longitude: -99.9018131,
    created_at: "2019-10-05T23:18:42.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q1211",
    status: "1",
  },
  {
    id: 1454,
    name: "Tennessee",
    countryid: 233,
    country_code: "US",
    fips_code: "47",
    shortRegion: "TN",
    type: "state",
    latitude: 35.5174913,
    longitude: -86.5804473,
    created_at: "2019-10-05T23:18:42.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q1509",
    status: "1",
  },
  {
    id: 1407,
    name: "Texas",
    countryid: 233,
    country_code: "US",
    fips_code: "48",
    shortRegion: "TX",
    type: "state",
    latitude: 31.9685988,
    longitude: -99.9018131,
    created_at: "2019-10-05T23:18:41.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q1439",
    status: "1",
  },
  {
    id: 1414,
    name: "Utah",
    countryid: 233,
    country_code: "US",
    fips_code: "49",
    shortRegion: "UT",
    type: "state",
    latitude: 39.3209801,
    longitude: -111.0937311,
    created_at: "2019-10-05T23:18:41.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q829",
    status: "1",
  },
  {
    id: 1409,
    name: "Vermont",
    countryid: 233,
    country_code: "US",
    fips_code: "50",
    shortRegion: "VT",
    type: "state",
    latitude: 44.5588028,
    longitude: -72.5778415,
    created_at: "2019-10-05T23:18:41.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q16551",
    status: "1",
  },
  {
    id: 1427,
    name: "Virginia",
    countryid: 233,
    country_code: "US",
    fips_code: "51",
    shortRegion: "VA",
    type: "state",
    latitude: 37.4315734,
    longitude: -78.6568942,
    created_at: "2019-10-05T23:18:42.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q1370",
    status: "1",
  },
  {
    id: 1462,
    name: "Washington",
    countryid: 233,
    country_code: "US",
    fips_code: "53",
    shortRegion: "WA",
    type: "state",
    latitude: 47.7510741,
    longitude: -120.7401385,
    created_at: "2019-10-05T23:18:42.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q1223",
    status: "1",
  },
  {
    id: 1429,
    name: "West Virginia",
    countryid: 233,
    country_code: "US",
    fips_code: "54",
    shortRegion: "WV",
    type: "state",
    latitude: 38.5976262,
    longitude: -80.4549026,
    created_at: "2019-10-05T23:18:42.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q1371",
    status: "1",
  },
  {
    id: 1441,
    name: "Wisconsin",
    countryid: 233,
    country_code: "US",
    fips_code: "55",
    shortRegion: "WI",
    type: "state",
    latitude: 43.7844397,
    longitude: -88.7878678,
    created_at: "2019-10-05T23:18:42.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q1537",
    status: "1",
  },
  {
    id: 1442,
    name: "Wyoming",
    countryid: 233,
    country_code: "US",
    fips_code: "56",
    shortRegion: "WY",
    type: "state",
    latitude: 43.0759678,
    longitude: -107.2902839,
    created_at: "2019-10-05T23:18:42.000Z",
    updated_at: "2022-03-13T15:59:55.000Z",
    flag: 1,
    wikiDataId: "Q1214",
    status: "1",
  },
];
