import moment from "moment";
import Key from "../clientVariables.json";

export const buildSolrQuery = ({
  role,
  company,
  skills,
  id,
  location,
  filterValues,
  pageStartNum,
  rowsPerPage,
  redirectionSearchJobsUrl,
  primaryResumeDetails,
  searchType,
}) => {
  // const queryParams = {
  //   role: "desired_role",
  //   company: "desired_company",
  //   skills: "desired_skills",
  //   id: "desired_id",
  //   location: "desired_location",
  //   job_type: "desired_job_type",
  //   date: "desired_date",
  //   visa_type: "desired_visa_type",
  //   relocation: "desired_relocation",
  // };

  const skillRelatedWords = [
    "skill",
    "technology",
    "developer",
    "programmer",
    "architect",
    "designer",
    "consultant",
    "administrator",
    "coach",
    "lead",
    "manager",
    "master",
    "specalist",
    "modeler",
    "director",
    "hacker",
    "engineer",
    "analyst",
    "dba",
    "tester",
    "senior",
    "sr",
    "junior",
    "jr",
    "knowledge",
    "or",
    "with",
    "to",
    "and",
    "front",
    "end",
    "back",
    // "frontend",
    // "backend",
  ];

  function getMappedDates(dateOption) {
    let mappedDates;
    const today = new Date();

    if (dateOption === "1") {
      let startdate = moment(today)
        .subtract(1, "d")
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss[Z]");
      mappedDates = startdate + " TO NOW";
    } else if (dateOption === "2") {
      let startdate = moment(today)
        .subtract(7, "d")
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss[Z]");
      mappedDates = startdate + " TO NOW";
    } else if (dateOption === "3") {
      let startdate = moment(today)
        .subtract(30, "d")
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss[Z]");
      mappedDates = startdate + " TO NOW";
    } else {
      mappedDates = null;
    }

    return mappedDates;
  }

  try {
    let searchUrl;
    // let short_region = null;
    // let state_name = null;
    // let city_name = null;

    // if (
    //   (redirectionSearchJobsUrl.shouldRedirect &&
    //     redirectionSearchJobsUrl.location !== "") ||
    //   location
    // ) {
    //   const loc =
    //     redirectionSearchJobsUrl?.location !== null
    //       ? redirectionSearchJobsUrl.location.split(/[,]+/)
    //       : location && location !== ""
    //       ? location
    //       : "";
    //   if (loc?.length > 1) {
    //     city_name = loc[0];
    //     if (loc[1].trim().length === 2) {
    //       short_region = loc[1];
    //     } else {
    //       state_name = loc[1];
    //     }
    //   } else {
    //     if (loc[0]?.length === 2) {
    //       short_region = loc[0];
    //     } else {
    //       city_name = loc[0];
    //     }
    //   }
    // }

    let locationFilter = "*:*";
    console.log("location 111", location);
    if (
      (redirectionSearchJobsUrl?.location &&
        redirectionSearchJobsUrl?.location !== null &&
        redirectionSearchJobsUrl?.location !== "") ||
      location
    ) {
      console.log("loc 1 redirectionSearchJobsUrl?.location", redirectionSearchJobsUrl?.location);

      let multipleLocations;
      // if(redirectionSearchJobsUrl?.location.includes("L_O_C")){
      //   multipleLocations = redirectionSearchJobsUrl?.location.split(" L_O_C ");
      //   multipleLocations.map((loc) => {
      //     let shortRegion_ = [];
      //     let city_ = [];
      //     let state_ = [];
      //     if (loc.length > 1) {
      //       const city = loc[0].trim();
      //       const regionOrState = loc[1].trim();
    
      //       if (regionOrState.length === 2) {
      //         shortRegion_.push(regionOrState);
      //         city_.push(city);
      //         // locationFilter = `&fq=shortRegion:(${regionOrState})&fq=cityName:(${city})`;
      //       } else {
      //         state_.push(regionOrState);
      //         city_.push(city);
      //         // locationFilter = `&fq=stateName:(${regionOrState})&fq=cityName:(${city})`;
      //       }
      //     } else {
      //       const singleLocation = loc[0].trim();
      //       if (singleLocation.length === 2) {
      //         shortRegion_.push(singleLocation)
      //         // locationFilter = `&fq=shortRegion:(${singleLocation})`;
      //       } else {
      //         city_.push(singleLocation);
      //         // locationFilter = `&fq=cityName:(${singleLocation})`;
      //       }
      //     }
      //     locationFilter = `
      //     ${city_.length > 0 && city_.length === 1 ? `&fq=cityName:(${city_[0]})` : `&fq=cityName:(${city_.join(" OR ")})`}
      //     ${state_.length > 0 && state_.length === 1 ? `&fq=stateName:(${state_[0]})` : `&fq=stateName:(${state_.join(" OR ")})`}
      //     ${shortRegion_.length > 0 && shortRegion_.length === 1 ? `&fq=stateName:(${shortRegion_[0]})` : `&fq=stateName:(${shortRegion_.join(" OR ")})`}
      //     `
      //   })
      // }
      if (redirectionSearchJobsUrl?.location.includes("L_O_C")) {
        const multipleLocations = redirectionSearchJobsUrl?.location.split(" L_O_C ");
        console.log('multipleLocations',multipleLocations);
        let shortRegion_ = [];
        let city_ = [];
        let state_ = [];

        multipleLocations.map((location) => {
          console.log("multipleLocations single", location);

          let loc = location?.split(/[,]+/);
          if (loc.length > 1) {
            // const [city, regionOrState] = loc.trim().split(' ');
            const city = loc[0].trim();
        const regionOrState = loc[1].trim();
            if (regionOrState.length === 2) {
              shortRegion_.push(`"${regionOrState}"`);
              city_.push(`"${city}"`);
              
            } else {
              state_.push(`"${regionOrState}"`);
              city_.push(`"${city}"`);
              
              console.log("state city", city, regionOrState);
            }
          } else {
            const singleLocation = loc[0].trim();
            if (singleLocation.length === 2) {
              shortRegion_.push(`"${singleLocation}"`)
            } else {
              city_.push(`"${singleLocation}"`);
            }
          }
        
        });


        
        locationFilter = `${city_.length > 0 && city_.length === 1 ? `&&fq=cityName:(${city_[0]})` : `&&fq=cityName:(${city_.join(" OR ")})`}${state_.length > 0 && state_.length === 1 ? `&&fq=stateName:(${state_[0]})` : `&&fq=stateName:(${state_.join(" OR ")})`}`;
      }
      // ${shortRegion_.length > 0 && shortRegion_.length === 1 ? `&fq=shortRegion:(${shortRegion_[0]})` : `&fq=shortRegion:(${shortRegion_.join(" OR ")})`}
      else {
        const loc =
        redirectionSearchJobsUrl?.location?.split(/[,]+/) ||
        location?.split(/[,]+/);

      if (loc.length > 1) {
        const city = loc[0].trim();
        const regionOrState = loc[1].trim();

        if (regionOrState.length === 2) {
          locationFilter = `&fq=shortRegion:(${regionOrState})&fq=cityName:(${city})`;
        } else {
          locationFilter = `&fq=stateName:(${regionOrState})&fq=cityName:(${city})`;
        }
      } else {
        const singleLocation = loc[0].trim();
        if (singleLocation.length === 2) {
          locationFilter = `&fq=shortRegion:(${singleLocation})`;
        } else {
          locationFilter = `&fq=cityName:(${singleLocation})`;
        }
      }
      }





      console.log("loc 1 locationFilter", redirectionSearchJobsUrl?.location, locationFilter);
    }

    let searchText = redirectionSearchJobsUrl.shouldRedirect
      ? redirectionSearchJobsUrl.searchText &&
        redirectionSearchJobsUrl.searchText !== ""
        ? redirectionSearchJobsUrl.searchText
        // : primaryResumeDetails?.application_title
      : null : null;

      let companySearchText = redirectionSearchJobsUrl.shouldRedirect && redirectionSearchJobsUrl?.companyName ? redirectionSearchJobsUrl?.companyName : null;

      // console.log("title 1st", searchText);

    if (skills) {
      const wordRegex = /\b\w+\b/g;
      const words = searchText.match(wordRegex);
      const filteredWords = words.filter(
        (word) => !skillRelatedWords.includes(word.toLowerCase())
      );
      const filteredText = filteredWords.join(" ");
      searchText = filteredText;
    }
    // console.log("title 2nd", searchText);

    let mainQuery = "*:*";
    let completeInputTextQuery = "*:*";
    let completeInputTextQuery2 = "*:*";
    let separateTitleQuery = "*:*";
    let companyNameQuery = "*:*"

    if (searchText) {
      const title = redirectionSearchJobsUrl.shouldRedirect
        ? redirectionSearchJobsUrl.searchText &&
          redirectionSearchJobsUrl.searchText !== ""
          ? redirectionSearchJobsUrl.searchText
          : primaryResumeDetails?.application_title
        : "";

      // const searchTextArray = title
      //   .replace(/[^\w\s]/g, "")
      //   .split(/\s+/)
      //   .filter((word) => !skillRelatedWords.includes(word.toLowerCase()));

      const searchTextArray = title
  .replace(/[^\w\s\-_]/g, "")
  .split(/\s+/)
  .filter((word) => !skillRelatedWords.includes(word.toLowerCase()));

      // console.log("searchTextArray", searchTextArray);
      const combinations = [];

      let hasDeveloper = false;

      for (let i = 0; i < searchTextArray.length; i++) {
        if (i < searchTextArray.length - 1) {
          combinations.push(`${searchTextArray[i]} ${searchTextArray[i + 1]}`);
        }

        if (i > 0) {
          combinations.push(`${searchTextArray[i - 1]} ${searchTextArray[i]}`);
        }

        // Check if the word is "developer" and append it to combinations
        if (title.toLowerCase().includes("developer")) {
          hasDeveloper = true;
        }
      }

      // If "developer" is found in searchText, append it to combinations
      if (hasDeveloper) {
        for (let i = 0; i < combinations.length; i++) {
          combinations[i] = `${combinations[i]} developer`;
        }
      }

      // console.log("combinations", combinations);
      const filteredCombinations = combinations.filter(
        (combination) => !combination.toLowerCase().includes("lead")
      );

      console.log("filteredCombinations 1", filteredCombinations);

      const uniqueCombinations = [...new Set(filteredCombinations)];

      console.log("filteredCombinations 2", uniqueCombinations);

      completeInputTextQuery = uniqueCombinations.join(" OR ");

      completeInputTextQuery2 = uniqueCombinations.map((word) => `"${word}"`).join(" OR ")

      // if (redirectionSearchJobsUrl?.searchType === "title") {
      //   mainQuery = `(${searchText})`;
      // } else {
      //   mainQuery = `(${searchText}) OR companyName:"${searchText}" OR id:(${searchText})`;
      // }
      if (searchText) {
        const cleanedText = searchText.replace(/[^\w\s]/g, "");


        // if(cleanedText.toLowerCase().includes("node")){
        //     cleanedText = cleanedText + " NodeJS Node "
        // }else if(cleanedText.toLowerCase().includes("react")){
        //   cleanedText = cleanedText + " ReactJS React "
        // }else if(cleanedText.toLowerCase().includes("angular")){
        //   cleanedText = cleanedText + " AngularJS Angular "
        // }

        const splitTitle = !cleanedText.toLowerCase().includes("developer")
          ? cleanedText.split(/\s+/).join(" OR ") 
          : cleanedText;
          separateTitleQuery = splitTitle?.split(" OR ").map((word) => `"${word}"`).join(" OR ");
          // console.log("separateQuery", separateQuery);
         
          console.log('splitTitle', splitTitle, cleanedText);
          searchText.includes("c")
        const titleQuery = `title:("${redirectionSearchJobsUrl.searchText.includes("c++") ? redirectionSearchJobsUrl.searchText : searchText }" OR ${splitTitle})^10`;
        const idQuery = `id:("${searchText}")`;

        // console.log("title query 1", cleanedText)
        // console.log("title query 2", splitTitle)
        // console.log("title query 3", titleQuery)

        // if (redirectionSearchJobsUrl?.searchType === "company") {
        //   mainQuery = `"${companyNameQuery}^10"`;
        // } else {
        //   mainQuery = `${titleQuery} OR ${companyNameQuery} OR ${idQuery}`;
        // }
        mainQuery = `${titleQuery} OR ${idQuery}`;
      }

    }

    if(companySearchText){
      if(companySearchText.includes(" OR ")){
       const multiCompanyQuery =  companySearchText?.split(" OR ").map((word) => `"${word}"`).join(" OR ");
        companyNameQuery = `&fq=companyName:("${multiCompanyQuery}")`;
        
      }else{
        companyNameQuery = `&fq=companyName:("${companySearchText}")`;
      }
       
    }

    //  if ( redirectionSearchJobsUrl?.location &&
    //   redirectionSearchJobsUrl?.location !== null &&
    //   redirectionSearchJobsUrl?.location !== "") {
    //     mainQuery += ` ${locationFilter}`;
    // }

if(!redirectionSearchJobsUrl.shouldRedirect && Object.keys(filterValues).length === 0){
  const workMode = sessionStorage.getItem("remote_jobs") ? true : false;
  // const isRemoteQuery = ``
  searchUrl = `${Key.Solr_url}/${Key.solrJobs_collection}/select?q=(*:*)&rows=${rowsPerPage}&start=${pageStartNum}${workMode ? `&fq=(workmode:${workMode})OR(is_remote:1)` : ''}&fq=status:(1)&fq=industry_type:"IT"&sort=modified%20desc&wt=json`;
}
   else if (Object.keys(filterValues).length === 0) {
      const workMode = sessionStorage.getItem("remote_jobs") ? true : false;
      searchUrl = `${Key.Solr_url}/${Key.solrJobs_collection}/select?q=(${mainQuery})${companyNameQuery !== "*:*" ? companyNameQuery : '' }${!workMode ? (locationFilter && locationFilter !== "*:*" ? locationFilter : "") : `&fq=(workmode:${workMode})OR(is_remote:1)`}&rows=${rowsPerPage}&start=${pageStartNum}&fq=status:(1)&fq=industry_type:"IT"&sort=modified%20desc&wt=json`;
    } else {
      console.log("filter values", filterValues); //&fq=workmode:(true)
//       const sortBy = filterValues?.["Sort By"] === "2" ? "relavance" : "recent";
//       const jobTypes = (filterValues?.["Job Type"] || []).join(" OR ");
//       const visaTypes = (filterValues?.["Visa Type"] || []).join(" OR ");
//       const travelTypes = (filterValues?.["Travel"] || []).join(" OR ");
//       const mappedDates = getMappedDates(filterValues["Posted Date"]);
//       const workMode = filterValues?.["Work Mode"] === "1" || sessionStorage.getItem("remote_jobs") ? true : false;
//       const sortByParam =
//         sortBy === "relavance" ? "score%20desc" : "modified%20desc";
// console.log("workmode", workMode);
//       searchUrl = `${Key.Solr_url}/${
//         Key.solrJobs_collection
//       }/select?q=${mainQuery}&fq=${completeInputTextQuery2}&${workMode ? "*:*" : locationFilter}&fq=jobtype:(${
//         jobTypes ? jobTypes : "*:*"
//       })&fq=workmode:(${workMode})&fq=workpermitName:(${
//         visaTypes ? visaTypes : "*:*"
//       })&fq=requiredtravel:(${
//         travelTypes ? travelTypes : "*:*"
//       })&fq=modified:[${
//         mappedDates !== null ? mappedDates : "* TO *"
//       }]&fq=status:(1)&rows=${rowsPerPage}&start=${pageStartNum}&sort=${sortByParam}&wt=jsonq.op=OR`;
const sortBy = filterValues?.["Sort By"] === "2" ? "relavance" : "recent";
const workMode = filterValues?.["Work Mode"] === "1" || sessionStorage.getItem("remote_jobs") === true ? true : false;
const sortByParam =
  sortBy === "relavance" ? "score%20desc" : "modified%20desc";

let fqParams = [];

console.log(filterValues);

if (filterValues) {
  if (filterValues["Job Type"] && filterValues["Job Type"]?.length > 0) {
    const jobTypes = filterValues["Job Type"].join(" OR ");
    fqParams.push(`jobtype:(${jobTypes})`);
  }

  if (filterValues["Visa Type"] && filterValues["Visa Type"].length > 0) {
    const visaTypes =  filterValues["Visa Type"].join(" OR ");
    fqParams.push(`workpermitName:(${visaTypes})`);
  }

  if (filterValues["Travel"] && filterValues["Travel"].length > 0) {
    const travelTypes = filterValues["Travel"].join(" OR ");
    fqParams.push(`requiredtravel:(${travelTypes})`);
  }

  if (filterValues["Posted Date"]) {
    const mappedDates = getMappedDates(filterValues["Posted Date"]);
    if (mappedDates) {
      fqParams.push(`modified:[${mappedDates}]`);
    }
  }

  if (filterValues["Work Mode"] === "1" || sessionStorage.getItem("remote_jobs")) {
    fqParams.push(`(workmode:${workMode})OR(is_remote:1)`);
  }
}

let fqQueryString = fqParams.length > 0 ? `&fq=${fqParams.join("&fq=")}` : "";

searchUrl = `${Key.Solr_url}/${Key.solrJobs_collection}/select?q=${mainQuery}${companyNameQuery !== "*:*" ? companyNameQuery : '' }${fqQueryString}${workMode ? "" : locationFilter && locationFilter !== "*:*" ? locationFilter : ""}&fq=status:(1)&fq=industry_type:"IT"&rows=${rowsPerPage}&start=${pageStartNum}&sort=${sortByParam}&wt=json`;

    }
    return searchUrl;
  } catch (error) {
    console.error("Error building Solr query:", error);
    return null;
  }
};
