
import React, { useState, useEffect, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
import axios from "axios";
import Key from "../../clientVariables.json";
import ToastMessage from '../../Components/ToastMessage';
import { toast } from "react-toastify";

const LoginSchema = Yup.object().shape({
	/*	email: Yup.string()
			.email("Invalid email address format")
			.required("Email is Required"), */
	subject: Yup.string()

		.required("Subject is Required"),
	description: Yup.string().required(" Description is Required"),

});

// class App extends React.Component {

function MailCandidate(props) {
	console.log(props)
	const formikRef = useRef();

	const sendMaill = (fields) => {
		console.log(fields, props.tomail)
		axios
			.post(`${Key.domain}/Jobs/mail_to_candidate`, {

				subject: fields?.subject,
				message: fields?.description.concat(" ").concat("revert Back to: " + props.fromempEmail),
				to : (props?.selectedCandidates && props?.selectedCandidates?.length > 0 && props?.selectedCandidates !== undefined && props?.selectedCandidates !== ''
				  ) ? props.selectedCandidates?.map((item) => item.email) 
					: (props?.selectedResumes && props?.selectedResumes?.length > 0 &&  props?.selectedResumes !== undefined && props?.selectedResumes !== '')
					  ? props?.selectedResumes?.map((item) => item.email_address)
					  : [props?.tomail]
				//to: props.selectedCandidates && props.selectedCandidates !== undefined && props.selectedCandidates !== '' ? (props.selectedCandidates.map((item) => item.email)) : props.selectedResumes.map((item) => item.email_address),

			})
			.then((response) => {
				console.log(response)
				let msg = "Mail sent !"
				toast(
					<ToastMessage message={msg} />
				)

				props.handlemailClose();
			})
	}
	const onTextEditorchanged = (value) => {

		formikRef.current.setFieldValue("description", value);

	};

	return (
		<div className="container">
			<div className="row">
				<div className="col-lg-12">
					<Formik
						innerRef={formikRef}
						initialValues={{ subject: "", description: "" }}
						validationSchema={LoginSchema}
						onSubmit={(values) => {
							console.log(values);

							sendMaill(values)
						}}
					>
						{({ touched, errors, isSubmitting, values }) =>

							<div>

								<Form>
									<div className="form-group">
										<label htmlFor="email">To<span style={{ color: "red" }}>*</span></label>

										<p>{props?.selectedCandidates ? (props?.selectedCandidates?.map((item) => item.First_Name + '  ' + item.Last_Name + '  ,')) : props?.selectedResumes?.map((item) =>  item.email_address + ', ' )} </p>
                                        {props?.selectedCandidates === undefined ?(<p>{props.tomail !== undefined ?  props.tomail : ""}</p>) :""}
										{/* <Field
											type="email"
											name="email"
											placeholder="Enter email"
											autocomplete="off"
											className={`mt-2 form-control
						${touched.email && errors.email ? "is-invalid" : ""}`}
											disabled
										/>

										<ErrorMessage
											component="div"
											name="email"
											className="invalid-feedback"
										/> */}
									</div>

									<div className="form-group">
										<label htmlFor="Subject" className="mt-3">
											Subject<span style={{ color: "red" }}>*</span>
										</label>
										<Field
											type="text"
											name="subject"
											placeholder="Subject"
											className={`mt-2 form-control
						${touched.subject && errors.subject
													? "is-invalid"
													: ""
												}`}
										/>
										<ErrorMessage
											component="div"
											name="subject"
											className="invalid-feedback"
										/>
									</div>

									<div className="form-group" style={{ marginTop: "15px" }}>
										<label htmlFor="description">
											Description<span style={{ color: "red" }}>*</span>
											{/* <span style={{ color: "red" }}>*</span> */}
										</label>&nbsp;&nbsp;&nbsp;


										<ReactQuill theme="snow"
											onChange={(e) =>
												onTextEditorchanged(e)

											}
											name="description"
											value={values.description}
											className={
												"form-select form-control" +
												(errors.description && touched.description ? " is-invalid" : "")
											}
										/>
										{/* {errors.description && touched.description ? (
											<div
												style={{
													width: "100%",
													marginTop: "0.25rem",
													fontSize: ".875em",
													color: "#dc3545",
												}}
											>
												{" "}
												Description is required
											</div>
										) : (
											""
										)} */}

										<ErrorMessage
											name="description"
											component="div"
											className="invalid-feedback"
										/>

									</div>


									<button
										type="submit"
										className="btn btn-primary btn-block mt-4"
									// onClick={this.sendMaill()}
									style={{float:'right', padding:'5px 20px 5px 20px'}}

									>
										Send
									</button>
								</Form>
							</div>



						}
					</Formik>
				</div>
			</div>
		</div>
	);

}

export default MailCandidate;







