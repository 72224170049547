import React, { useEffect, useState } from "react";
import Key from "../../clientVariables.json";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Card, CardHeader, CardContent, Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import ToastMessage from "../../Components/ToastMessage";

function Alertcandidate(props) {
  const [alertCreated, setAlertCreated] = useState(false);
  const [jobtitlealert, setjobtitle] = useState("");
  const [citynamealert, setcityname] = useState("");
  const [jobtitle, setJobTitle] = useState(
    sessionStorage.getItem("resumeTitle") === null
      ? props.jobtitle
      : sessionStorage.getItem("resumeTitle")
  );
  const [inputResumeTitle, setInputResumeTitle] = useState(
    sessionStorage.getItem("inputResumeTitle")
  );
  console.log(props);
  console.log(jobtitle, inputResumeTitle);
  useEffect(() => {
    console.log(props.alertMessage);
    sessionStorage.getItem("resumeTitle", props.jobtitle);
    setAlertCreated(false);
    console.log(
      sessionStorage.getItem("resumeTitle"),
      "sdfde",
      sessionStorage.getItem("inputResumeTitle")
    );
    setJobTitle(sessionStorage.getItem("resumeTitle"));
    setInputResumeTitle(sessionStorage.getItem("inputResumeTitle"));
    setcityname(props.joblocation !== null ? props.joblocation : "");
  }, [
    sessionStorage.getItem("resumeTitle"),
    sessionStorage.getItem("inputResumeTitle"),
    props.jobtitle,
    props.alertMessage,
  ]);

  const submit = () => {
    let jobtitle =
      sessionStorage.getItem("resumeTitle") !== null
        ? sessionStorage.getItem("resumeTitle")
        : props.jobtitle;
    let joblocation = sessionStorage.getItem("cityname");

    console.log(jobtitle, joblocation);
    if (
      (jobtitle && jobtitle !== "null" && jobtitle !== "") ||
      (joblocation && joblocation !== "null" && joblocation !== "")
    ) {
      if (
        jobtitlealert === sessionStorage.getItem("resumeTitle") &&
        citynamealert === sessionStorage.getItem("cityname")
      ) {
        // alert("Alert already created")

        let msg = "Email Search Alert created ";
        toast(<ToastMessage message={msg} />);
      } else {
        setjobtitle(jobtitle);
        setcityname(joblocation);

        fetch(`${Key.domain}/empdashboard/insert_emp_saved_searches`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: props.currentUser.id,
            session_token: props.currentUser.session_token,
            email: props.currentUser.username,
            keyword:
              sessionStorage.getItem("resumeTitle") !== null
                ? sessionStorage.getItem("resumeTitle").replaceAll('"', "")
                : props.jobtitle,
            location: joblocation,
            query: localStorage.getItem("resumeQuery") || "",
            jobtype: localStorage.getItem("resumejobtypeFlages") || "",
            date: localStorage.getItem("resumeDateValue") || "",
            visa: localStorage.getItem("resumevisatypeFlages") || "",
            exp: localStorage.getItem("resumeExpFlages") || "",
            qual: localStorage.getItem("resumeQualificationFlages") || "",
            usertype: props.currentUser.usertype,
            search_type: props.currentUser.usertype === "5" ? "job" : "resume",
            // exp:sessionStorage.getItem("resumeExpFlages"),
          }),
        }).then((response) => {
          console.log(response);
          // alert("Alert created successfully")

          let msg = "Alert created successfully";
          toast(<ToastMessage message={msg} />);
          // setAlertCreated(true)
        });
      }
    } else {
      let msg = "Please a Search Keyword";
      toast(<ToastMessage message={msg} />);
    }
  };

  const StyledCardContent = styled(CardContent)(({ theme }) => ({
    padding: theme.spacing(4), // Adjust padding as needed
    // You can add other custom styles here
  }));

  return (
    <div>
      {/* <Card>
        <Card.Header style={{ backgroundColor: "#406882" }}>
          Candidate Activity
        </Card.Header>
        <Card.Body
          className="create-alert"
          // style={{ padding: "145px" }}
        >
          <Card.Title>
            Create{" "}
            {props.jobType === "5" && props.jobType !== undefined
              ? "Job "
              : "Resume "}{" "}
            Alert
          </Card.Title>
          <Card.Text>
            Sign up for an email alert to be notified of new{" "}
            {props.jobType === "5" && props.jobType !== undefined
              ? "Jobs "
              : "Resumes "}{" "}
            matching this skill set.
          </Card.Text>
          <Button
            variant="primary"
            disabled={
              jobtitle !== "" ||
              (inputResumeTitle !== "" && inputResumeTitle !== null) ||
              props.alertMessage !== ""
                ? false
                : true
            }
            onClick={submit}
          >
            {alertCreated ? "Alert Created" : "Create Alert"}
          </Button>
        </Card.Body>
      </Card> */}

      <Card>
        <CardHeader sx={{ backgroundColor: "#406882" }}>
          <Typography>Candidate Activity</Typography>
        </CardHeader>
        <StyledCardContent>
          <Typography>
            Create{" "}
            {props.jobType === "5" && props.jobType !== undefined
              ? "Job "
              : "Resume "}
            Alert
          </Typography>
          <CardContent>
            Sign up for an email alert to be notified of new{" "}
            {props.jobType === "5" && props.jobType !== undefined
              ? "Jobs "
              : "Resumes "}
            matching this skill set.
          </CardContent>
          <Button
            variant="contained"
            color="primary"
            disabled={
              props.jobtitle !== "" ||
              (inputResumeTitle !== "" && inputResumeTitle !== null) ||
              props.alertMessage !== ""
                ? false
                : true
            }
            onClick={submit}
          >
            {props.alertCreated ? "Alert Created" : "Create Alert"}
          </Button>
        </StyledCardContent>
      </Card>
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.UserReducer.user,
  };
}
export default connect(mapStateToProps, null)(Alertcandidate);
