import React, { useState, useEffect } from "react";
import "../../../src/Components/searchJobs/searchJobPage.css";
import Footer from "../Jobseeker/Footer";
import adTwo from "../../Images/ad-22.jpg";
import axios from "axios";
import Searchblock from "./AdvancedSearchBlock";
import Key from "../../clientVariables.json";
import moment from "moment";
import parse from "html-react-parser";
import Sendcandidatetomail from "../searchResumes/sendcandidatetomail";
import Messagepopup from "../searchResumes/Messagepopup";
import Notepopup from "../searchResumes/Notepopup";
import imageOne from "../../Images/SearchJobs_Img/g-logo.png";
import imageTwo from "../../Images/SearchJobs_Img/image-2.png";
import imageThree from "../../Images/SearchJobs_Img/image-3.png";
import imageFour from "../../Images/SearchJobs_Img/image-4.png";
import imageFive from "../../Images/SearchJobs_Img/image-5.png";
import adOne2 from "../../Images/dashboard_ad.jpg";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Badge as MuiBadge } from "@mui/material";
import Pagination from "./AdvancedResumePagination";
import Navbar from "../Employer/Empnavbar";
import HomeNavbar from "../Navbar";
import Alertcandidate from "../searchResumes/Alertcandidate";
import { BiArrowBack } from "react-icons/bi";
import ResumesLogo from "../../../src/Images/resumefileicon.png";
import ShareResume from "../Employer/ShareResume";
import ShareResumeMultiple from "../Employer/ShareResumeMultiple";
import { toast } from "react-toastify";
import ToastMessage from "../../Components/ToastMessage";
import Rating from "@mui/material/Rating";
import { BsDownload } from "react-icons/bs";
import {
  AiOutlineMail,
  AiOutlineEye,
  AiOutlineDownload,
  AiOutlineShareAlt,
  AiFillEye,
} from "react-icons/ai";
import { TiGroup, TiGroupOutline } from "react-icons/ti";
import { FiMessageSquare } from "react-icons/fi";
import EmpResumeFilters from "./AdvancedResumeFilter";

//for loader
import { showLoader } from "../Actions/loader.actions";
import { hideLoader } from "../Actions/loader.actions";

//for alert popup
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Highlighter from "react-highlight-words";
import { TiEdit } from "react-icons/ti";

// for json skills data
import oracleAppsjsonData from "./OraAppsTechTreeMS.json";
import oraclejsonData from "./OracleTechTree.json";
import dotnetjsonData from "./DotNetTechTree.json";
import javajsonData from "./JavaBaseTechTree.json";
import applicationjsonData from "./ApplicationDevTechTree.json";
import androidjsonData from "./AndroidTechTree.json";
import angularjsonData from "./AngularTechTree.json";
import bigdatajsonData from "./BigDataTechTree.json";
import cloudjsonData from "./CloudTechTree.json";
import BIjsonData from "./BITechTree.json";
import DIjsonData from "./DataIntegrationTechTree.json";
import Datawarehousejsondata from "./DataWarehouseTechTree.json";
import DataBasejsondata from "./DataBaseTechTree.json";
import Devopsjsondata from "./DevopsTechTree.json";
import Hadoopjsondata from "./HadoopTechTree.json";
import SystemAdminjsondata from "./SystemAdminTechTree.json";
import UIjsondata from "./UITechTree.json";
import UXjsondata from "./UXTechTree.json";
import Networkjsondata from "./NetworkTechTree.json";
import SAPjsondata from "./SAPTechTree.json";
import Informaticajsondata from "./InformaticaTechTree.json";
import Salesforcejsondata from "./SalesforceTechTree.json";
import { Tooltip } from "@mui/material";

const SearchJobsTwo = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);
  const [fileNotFound, setFileNotFOund] = React.useState(false);

  const [resumes, setResumes] = useState([]);
  const [start, setStart] = useState(6);
  const [filterLoading, setFolderLoading] = useState(false);
  const [groupIds, setGroupIds] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [numFound, setNumFound] = useState(0);
  const [resumeChecked, setResumeChecked] = useState();
  const [selectedResumes, setSelectedResumes] = useState([]);
  const [totalViewsCount, setTotalViewsCount] = useState(0);
  const [allResumeViews, setAllResumeViews] = useState([]);
  const [groupByallResumeViews, setGroupByAllResumeViews] = useState([]);

  const [currentresid, setcurrentresid] = useState("");
  const [currentresfile, setcurrentresfile] = useState("");
  const [currentresuid, setcurrentresuid] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [isFilterChecked, setIsFilterChecked] = useState(false);
  const images = [imageOne, imageTwo, imageThree, imageFour, imageFive];
  const [show, setShow] = useState(false);
  const [showMultiple, setShowMultiple] = useState(false);
  const [tomail, settomail] = useState("");
  const [candfullname, setcandfullname] = useState("");
  const [resumeViewsLeft, setResumeViewsLEft] = useState(0);

  const [selectedResIds, setSelectedResids] = useState([]);

  const [messageshow, setmessageShow] = useState(false);
  const [noteShow, setNoteShow] = useState(false);
  const [mailshow, setmailShow] = useState(false);
  const [packageExpired, setPackageExpired] = useState(false);
  const [limitExceeded, setLimitExceeded] = useState(false);
  const [groupMembersIds, setGroupMemebersIds] = useState([]);
  const [noteResumeId, setNoteResumeId] = useState("");
  const [jsondata, setJsondata] = useState([]);
  const [selectedjsondata, setSelectedjsondata] = useState([]);
  const [currenttab, setcurrenttab] = useState(0);
  const [paginationRefresh, setPaginationRefresh] = useState(true);
  const [msgresumeid, setmsgresumeid] = useState("");

  const handlemessageShow = (resumeId) => {
    setmsgresumeid(resumeId);

    if (sessionStorage.getItem("id") !== null) {
      setmessageShow(true);
    } else {
      history.push({
        pathname: "/EmpLogin",
      });
    }
  };

  const handleNoteShow = (resumeId) => {
    setNoteResumeId(resumeId);
    if (sessionStorage.getItem("id") !== null) {
      setNoteShow(true);
    } else {
      history.push({
        pathname: "/EmpLogin",
      });
    }
  };

  const handlePaginationRefresh = () => {
    setPaginationRefresh(false);
    setTimeout(() => {
      setPaginationRefresh(true);
    }, 30);
  };

  const jobtype = sessionStorage.getItem("filtered-jobtypes");
  const history = useHistory();

  const handleCloseAlert = () => {
    setOpen(false);
  };

  const handleCloseResumeNotfound = () => {
    setFileNotFOund(false);
  };

  const checkEmployerLimit = (rid) => {
    //get user viewed resumes count and allowed resume views count
    axios
      .post(`${Key.domain}/users/check_employer_package_limit`, {
        user_id: props.currentUser.id,
        session_token: props.currentUser.session_token,
        action_to_check: "resume_views",
      })
      .then((resdata) => {
        if (
          resdata.status === 200 &&
          resdata.data.success === 1 &&
          resdata.data.data !== undefined &&
          resdata.data.data !== null &&
          resdata.data.data !== undefined &&
          resdata.data.data !== "" &&
          resdata.data.data[0] !== null &&
          resdata.data.data[0] !== undefined &&
          resdata.data.data[0] !== "" &&
          resdata.data.data[0].resumes_viewed !== null &&
          resdata.data.data[0].resumes_viewed !== undefined &&
          resdata.data.data[0].resumes_viewed !== "" &&
          resdata.data.data[0].resumeviews_allowed !== null &&
          resdata.data.data[0].resumeviews_allowed !== undefined &&
          resdata.data.data[0].resumeviews_allowed !== ""
        ) {
          if (resdata.data.data[0].package_expired) {
            setPackageExpired(true);
          } else {
            const leftResumeViews =
              parseInt(resdata.data.data[0].resumeviews_allowed) -
              parseInt(resdata.data.data[0].resumes_viewed);
            setResumeViewsLEft(leftResumeViews);

            let admin_id = sessionStorage.getItem("admin_id");
            let ca_id =
              admin_id !== "" &&
              admin_id !== 0 &&
              admin_id !== null &&
              admin_id !== undefined &&
              admin_id !== "0"
                ? admin_id
                : sessionStorage.getItem("id");
            axios
              .post(`${Key.domain}/resume/update_resume_viewedStatus`, {
                user_id: sessionStorage.getItem("id"),
                resumeid: rid,
                ca_id: ca_id,
                resume_type: "regular",
                views: 1,
                session_token: props.currentUser.session_token,
              })
              .then((response) => {
                checkEmployerLimit();
              });
          }
        } else {
          throw new Error("No views yet");
        }
      })
      .catch((err) => {
        console.log(err); // TODO: Error handling
      });
  };
  useEffect(() => {
    //get all recruiter under same employer API
    if (props.currentUser !== null) {
      checkEmployerLimit();

      axios
        .post(`${Key.domain}/resume/get_all_recruiters_ByUserGroup`, {
          user_id: props.currentUser.id,
          ca_id: props.currentUser.ca_id,
          session_token: props.currentUser.session_token,
        })
        .then((response) => {
          setGroupMemebersIds(response.data.data);
          if (
            response.status === 200 &&
            response.data &&
            response.data.success === 1 &&
            response.data.data &&
            response.data.data.length &&
            response.data.data.length > 0
          ) {
            let recruiters = response.data.data.filter(
              (res) => res !== props.currentUser.id && res !== 0
            );

            setGroupIds(recruiters);
          }
        });
    }

    /*axios
      .get(`${Key.domain}/Home/get-top-companies-jobs`, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      })
      .then((response) => {
        var searchdata = response.data.data;
        setJobs(searchdata);

        setTopJob(searchdata[0]);
        const jobbs = searchdata.slice(1);
        setTopJobs(jobbs);
      });*/

    // if(props.location.state.getPagination === true){
    //   alert("fdf")
    //   handlePaginationRefresh()
    // }

    return () => {
      sessionStorage.removeItem("resResumeTitle");
    };
  }, []);

  const clearRoleBasedBtns = () => {
    setJsondata([]);
  };

  useEffect(() => {
    //console.log(sessionStorage.getItem("resumeTitle"))
    if (sessionStorage.getItem("resumeTitle") !== null) {
      if (
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes("oracle ebs") ||
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes("oracle erp") ||
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes("oracle apps") ||
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes("oracle r12") ||
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes("oracle applications") ||
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes("oracle pl/sql")
      ) {
        setJsondata(oracleAppsjsonData);
        sessionStorage.setItem(
          "technology_jsondata",
          JSON.stringify(oracleAppsjsonData)
        );
      } else if (
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes("oracle") ||
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes("oracle dba") ||
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes("oracle developer")
      ) {
        setJsondata(oraclejsonData);
        sessionStorage.setItem(
          "technology_jsondata",
          JSON.stringify(oraclejsonData)
        );
      } else if (
        sessionStorage.getItem("resumeTitle").toLowerCase().includes(". net") ||
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes("dot net") ||
        sessionStorage.getItem("resumeTitle").toLowerCase().includes(".net") ||
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes(".net developer")
      ) {
        setJsondata(dotnetjsonData);
        sessionStorage.setItem(
          "technology_jsondata",
          JSON.stringify(dotnetjsonData)
        );
      } else if (
        sessionStorage.getItem("resumeTitle").toLowerCase().includes("java") ||
        sessionStorage.getItem("resumeTitle").toLowerCase().includes(" java") ||
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes("java developer")
      ) {
        setJsondata(javajsonData);
        sessionStorage.setItem(
          "technology_jsondata",
          JSON.stringify(javajsonData)
        );
      } else if (
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes("application") ||
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes(" application") ||
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes("application developer")
      ) {
        setJsondata(applicationjsonData);
        sessionStorage.setItem(
          "technology_jsondata",
          JSON.stringify(applicationjsonData)
        );
        //sessionStorage.setItem("Selectedjsondata", JSON.stringify(applicationjsonData[0])  );
      } else if (
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes("android") ||
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes(" android") ||
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes("android developer")
      ) {
        setJsondata(androidjsonData);
        sessionStorage.setItem(
          "technology_jsondata",
          JSON.stringify(androidjsonData)
        );
      } else if (
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes("angular") ||
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes(" angular") ||
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes("angular developer")
      ) {
        setJsondata(angularjsonData);
        sessionStorage.setItem(
          "technology_jsondata",
          JSON.stringify(angularjsonData)
        );
        //  sessionStorage.setItem("Selectedjsondata", JSON.stringify(angularjsonData[0])  );
      } else if (
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes("bigdata") ||
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes(" bigdata") ||
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes("bigdata developer") ||
        sessionStorage.getItem("resumeTitle").toLowerCase().includes("big data")
      ) {
        setJsondata(bigdatajsonData);
        sessionStorage.setItem(
          "technology_jsondata",
          JSON.stringify(bigdatajsonData)
        );
      } else if (
        sessionStorage.getItem("resumeTitle").toLowerCase().includes("cloud") ||
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes(" cloud") ||
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes("cloud developer")
      ) {
        setJsondata(cloudjsonData);
        sessionStorage.setItem(
          "technology_jsondata",
          JSON.stringify(cloudjsonData)
        );
      } else if (
        sessionStorage.getItem("resumeTitle").toLowerCase().includes("bi") ||
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes("business intelligence") ||
        sessionStorage.getItem("resumeTitle").toLowerCase().includes("cognos")
      ) {
        setJsondata(BIjsonData);
        sessionStorage.setItem(
          "technology_jsondata",
          JSON.stringify(BIjsonData)
        );
      } else if (
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes("data integration") ||
        sessionStorage.getItem("resumeTitle").toLowerCase().includes("di") ||
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes(" data integration")
      ) {
        setJsondata(DIjsonData);
        sessionStorage.setItem(
          "technology_jsondata",
          JSON.stringify(DIjsonData)
        );
      } else if (
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes("data warehouse") ||
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes("data ware house") ||
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes(" data warehouse")
      ) {
        setJsondata(Datawarehousejsondata);
        sessionStorage.setItem(
          "technology_jsondata",
          JSON.stringify(Datawarehousejsondata)
        );
      } else if (
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes("data base") ||
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes("database") ||
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes(" database")
      ) {
        setJsondata(DataBasejsondata);
        sessionStorage.setItem(
          "technology_jsondata",
          JSON.stringify(DataBasejsondata)
        );
      } else if (
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes("devops") ||
        sessionStorage.getItem("resumeTitle").toLowerCase().includes(" devops")
      ) {
        setJsondata(Devopsjsondata);
        sessionStorage.setItem(
          "technology_jsondata",
          JSON.stringify(Devopsjsondata)
        );
      } else if (
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes("hadoop") ||
        sessionStorage.getItem("resumeTitle").toLowerCase().includes(" hadoop")
      ) {
        setJsondata(Hadoopjsondata);
        sessionStorage.setItem(
          "technology_jsondata",
          JSON.stringify(Hadoopjsondata)
        );
      } else if (
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes("system") ||
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes("system admin")
      ) {
        setJsondata(SystemAdminjsondata);
        sessionStorage.setItem(
          "technology_jsondata",
          JSON.stringify(SystemAdminjsondata)
        );
      } else if (
        sessionStorage.getItem("resumeTitle").toLowerCase().includes("ui") ||
        sessionStorage.getItem("resumeTitle").toLowerCase().includes(" ui")
      ) {
        setJsondata(UIjsondata);
        sessionStorage.setItem(
          "technology_jsondata",
          JSON.stringify(UIjsondata)
        );
      } else if (
        sessionStorage.getItem("resumeTitle").toLowerCase().includes("sap") ||
        sessionStorage.getItem("resumeTitle").toLowerCase().includes(" sap")
      ) {
        setJsondata(SAPjsondata);
        sessionStorage.setItem(
          "technology_jsondata",
          JSON.stringify(SAPjsondata)
        );
      } else if (
        sessionStorage.getItem("resumeTitle").toLowerCase().includes("ux") ||
        sessionStorage.getItem("resumeTitle").toLowerCase().includes(" ux")
      ) {
        setJsondata(UXjsondata);
        sessionStorage.setItem(
          "technology_jsondata",
          JSON.stringify(UXjsondata)
        );
      } else if (
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes("network") ||
        sessionStorage.getItem("resumeTitle").toLowerCase().includes(" network")
      ) {
        setJsondata(Networkjsondata);
        sessionStorage.setItem(
          "technology_jsondata",
          JSON.stringify(Networkjsondata)
        );
      } else if (
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes("informatica") ||
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes(" informatica")
      ) {
        setJsondata(Informaticajsondata);
        sessionStorage.setItem(
          "technology_jsondata",
          JSON.stringify(Informaticajsondata)
        );
      } else if (
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes("salesforce") ||
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes(" salesforce") ||
        sessionStorage
          .getItem("resumeTitle")
          .toLowerCase()
          .includes("sales force")
      ) {
        setJsondata(Salesforcejsondata);
        sessionStorage.setItem(
          "technology_jsondata",
          JSON.stringify(Salesforcejsondata)
        );
      } else if (
        JSON.parse(sessionStorage.getItem("Selectedjsondata")) !== null
      ) {
        var jobRole = JSON.parse(
          sessionStorage.getItem("Selectedjsondata")
        ).JobRole;
        if (
          jobRole.toLowerCase().includes("oracle ebs") ||
          jobRole.toLowerCase().includes("oracle erp") ||
          jobRole.toLowerCase().includes("oracle apps")
        ) {
          setJsondata(oracleAppsjsonData);
          setcurrenttab(sessionStorage.getItem("currenttab"));
        } else if (
          jobRole.toLowerCase().includes("oracle") ||
          jobRole.toLowerCase().includes(".net") ||
          jobRole.toLowerCase().includes("dot net")
        ) {
          setJsondata(oraclejsonData);
          setcurrenttab(sessionStorage.getItem("currenttab"));
        } else if (
          jobRole.toLowerCase().includes(". net") ||
          jobRole.toLowerCase().includes(".net") ||
          jobRole.toLowerCase().includes("dot net")
        ) {
          setJsondata(dotnetjsonData);
          setcurrenttab(sessionStorage.getItem("currenttab"));
        } else if (
          jobRole.toLowerCase().includes("java") ||
          jobRole.toLowerCase().includes("java developer")
        ) {
          setJsondata(javajsonData);
          setcurrenttab(sessionStorage.getItem("currenttab"));
        } else if (
          jobRole.toLowerCase().includes("application") ||
          jobRole.toLowerCase().includes("application developer")
        ) {
          setJsondata(applicationjsonData);
          setcurrenttab(sessionStorage.getItem("currenttab"));
        } else if (
          jobRole.toLowerCase().includes("android") ||
          jobRole.toLowerCase().includes("android developer")
        ) {
          setJsondata(androidjsonData);
          setcurrenttab(sessionStorage.getItem("currenttab"));
        } else if (
          jobRole.toLowerCase().includes("angular") ||
          jobRole.toLowerCase().includes("angular developer")
        ) {
          setJsondata(angularjsonData);
          setcurrenttab(sessionStorage.getItem("currenttab"));
        } else if (
          jobRole.toLowerCase().includes("bigdata") ||
          jobRole.toLowerCase().includes(" bigdata") ||
          jobRole.toLowerCase().includes("bigdata developer") ||
          jobRole.toLowerCase().includes("big data")
        ) {
          setJsondata(bigdatajsonData);
          setcurrenttab(sessionStorage.getItem("currenttab"));
        } else if (
          jobRole.toLowerCase().includes("cloud") ||
          jobRole.toLowerCase().includes("cloud developer") ||
          jobRole.toLowerCase().includes(" cloud")
        ) {
          setJsondata(cloudjsonData);
          setcurrenttab(sessionStorage.getItem("currenttab"));
        } else if (
          jobRole.toLowerCase().includes("bi") ||
          jobRole.toLowerCase().includes("business intelligence") ||
          jobRole.toLowerCase().includes("cognos")
        ) {
          setJsondata(BIjsonData);
          setcurrenttab(sessionStorage.getItem("currenttab"));
        } else if (
          jobRole.toLowerCase().includes("di") ||
          jobRole.toLowerCase().includes("data integration") ||
          jobRole.toLowerCase().includes(" data integration")
        ) {
          setJsondata(DIjsonData);
          setcurrenttab(sessionStorage.getItem("currenttab"));
        } else if (
          jobRole.toLowerCase().includes("data warehouse") ||
          jobRole.toLowerCase().includes("data ware house") ||
          jobRole.toLowerCase().includes(" data warehouse")
        ) {
          setJsondata(Datawarehousejsondata);
          setcurrenttab(sessionStorage.getItem("currenttab"));
        } else if (
          jobRole.toLowerCase().includes("data base") ||
          jobRole.toLowerCase().includes("database") ||
          jobRole.toLowerCase().includes(" data base")
        ) {
          setJsondata(DataBasejsondata);
          setcurrenttab(sessionStorage.getItem("currenttab"));
        } else if (
          jobRole.toLowerCase().includes("devops") ||
          jobRole.toLowerCase().includes(" devops")
        ) {
          setJsondata(Devopsjsondata);
          setcurrenttab(sessionStorage.getItem("currenttab"));
        } else if (
          jobRole.toLowerCase().includes("hadoop") ||
          jobRole.toLowerCase().includes(" hadoop")
        ) {
          setJsondata(Hadoopjsondata);
          setcurrenttab(sessionStorage.getItem("currenttab"));
        } else if (
          jobRole.toLowerCase().includes("system") ||
          jobRole.toLowerCase().includes("system admin")
        ) {
          setJsondata(SystemAdminjsondata);
          setcurrenttab(sessionStorage.getItem("currenttab"));
        } else if (
          jobRole.toLowerCase().includes("ui") ||
          jobRole.toLowerCase().includes(" ui")
        ) {
          setJsondata(UIjsondata);
          setcurrenttab(sessionStorage.getItem("currenttab"));
        } else if (
          jobRole.toLowerCase().includes("sap") ||
          jobRole.toLowerCase().includes(" sap")
        ) {
          setJsondata(SAPjsondata);
          setcurrenttab(sessionStorage.getItem("currenttab"));
        } else if (
          jobRole.toLowerCase().includes("ux") ||
          jobRole.toLowerCase().includes(" ux")
        ) {
          setJsondata(UXjsondata);
          setcurrenttab(sessionStorage.getItem("currenttab"));
        } else if (
          jobRole.toLowerCase().includes("network") ||
          jobRole.toLowerCase().includes(" network")
        ) {
          setJsondata(Networkjsondata);
          setcurrenttab(sessionStorage.getItem("currenttab"));
        } else if (
          jobRole.toLowerCase().includes("informatica") ||
          jobRole.toLowerCase().includes(" informatica")
        ) {
          setJsondata(Informaticajsondata);
          setcurrenttab(sessionStorage.getItem("currenttab"));
        } else if (
          jobRole.toLowerCase().includes("salesforce") ||
          jobRole.toLowerCase().includes(" salesforce") ||
          sessionStorage
            .getItem("resumeTitle")
            .toLowerCase()
            .includes("sales force")
        ) {
          setJsondata(Salesforcejsondata);
          setcurrenttab(sessionStorage.getItem("currenttab"));
        }
      } else {
        setJsondata([]);
      }
    }
    if (
      props.location.state !== undefined &&
      props.location.state.response !== null
    ) {
      setResumes(props.location.state.response);
      setResumeChecked(false);

      // setIsJobtypeChecked(props.location.state.jobtypeChecked)
    }

    if (
      props.location.state &&
      props.location.state.filterChecked !== undefined
    ) {
      setResumes(props.location.state.response);
    }
    if (props.currentUser !== null) {
      if (props.currentUser.package_id === 1) {
        setTotalViewsCount(20);
      } else if (props.currentUser.package_id === 2) {
        setTotalViewsCount(100);
      } else if (props.currentUser.package_id === 3) {
        setTotalViewsCount(200);
      } else if (props.currentUser.package_id === 4) {
        setTotalViewsCount(300);
      } else if (props.currentUser.package_id === 5) {
        setTotalViewsCount(400);
      }

      fetch(`${Key.domain}/resume/get_all_resume_views`, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          user_id: sessionStorage.getItem("id"),
          session_token: props.currentUser.session_token,
        }),
      })
        .then((response) => response.json()) //converting data into json format
        .then((resdata) => {
          setAllResumeViews(resdata.data.map((data) => data.resume_id));
        });

      fetch(`${Key.domain}/resume/get_all_resume_views_byGroup`, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          user_id: sessionStorage.getItem("id"),
          session_token: props.currentUser.session_token,
          ca_id: props.currentUser.ca_id,
        }),
      })
        .then((response) => response.json()) //converting data into json format
        .then((resdata) => {
          //setGroupByAllResumeViews(resdata.data.map((data) => data.resume_id));
          // console.log(resdata.data)
          setGroupByAllResumeViews(resdata.data);
        });
    }
    props.hideLoader();
  }, [props.location.state]);

  useEffect(() => {
    if (props.location.state.setCurrentTab === "yes") {
      setcurrenttab(sessionStorage.getItem("currenttab"));
    }
  }, [props.location.state.setCurrentTab]);

  const onSubmit = (resumes) => {
    setResumes(resumes);
  };

  const onFilterChecked = (isJobtypeChecked) => {
    setIsFilterChecked(isJobtypeChecked);
  };
  const updateViewStatusMultiple = (ids) => {
    if (ids && ids !== undefined && ids.length > 0) {
      ids.map((id) => updateViewStatus(id));
    }
  };

  const updateViewStatus = (rid) => {
    checkEmployerLimit(rid);
  };

  const jobTitleClicked = (resume_id) => {
    var id = parseInt(resume_id);
    if (props.currentUser !== null) {
      axios
        .post(`${Key.domain}/resume/get_resumes_list_viewed_by_currentUser`, {
          user_id: props.currentUser.id,
          session_token: props.currentUser.session_token,
        })
        .then((response) => {
          if (
            response.data.success === 1 &&
            response.data.data !== undefined &&
            response.data.data !== null &&
            response.data.data !== undefined &&
            response.data.data !== ""
          ) {
            if (
              response.data.data &&
              response.data.data.length &&
              response.data.data.length > 0
            ) {
              //get user viewed resumes count and allowed resume views count
              axios
                .post(`${Key.domain}/users/check_employer_package_limit`, {
                  user_id: props.currentUser.id,
                  session_token: props.currentUser.session_token,
                  action_to_check: "resume_views",
                })
                .then((resdata) => {
                  if (
                    resdata.status === 200 &&
                    resdata.data.success === 1 &&
                    resdata.data.data !== undefined &&
                    resdata.data.data !== null &&
                    resdata.data.data !== undefined &&
                    resdata.data.data !== ""
                  ) {
                    var resumes = response.data.data;
                    var sortedResumes = resumes.some(
                      (res) => parseInt(res.resume_id) === parseInt(id)
                    );

                    if (sortedResumes) {
                      checkLimit();
                    } else if (resdata.data.data[0].package_expired) {
                      setPackageExpired(true);
                      setOpen(true);
                    } else {
                      /*  if (!sortedResumes && response.data.data.length >= parseInt(resdata.data.data[0].resumeviews_allowed)) {
                        setOpen(true)
                      } */
                      if (resdata.data.limitExceeded) {
                        setLimitExceeded(true);
                        setOpen(true);
                      } else {
                        checkLimit();
                      }
                    }
                  } else {
                    throw new Error("No views yet");
                  }
                })
                .catch((err) => {
                  console.log(err); // TODO: Error handling
                  checkLimit();
                });
            } else {
              throw new Error("No views yet");
            }
          }
        })
        .catch((err) => {
          console.log(err);
          checkLimit();
        });

      function checkLimit() {
        if (id !== null && id !== undefined) {
          axios
            .post(
              `${Key.domain}/resume/check_view_resumes_limit_based_on_package_id`,
              {
                ca_id: sessionStorage.getItem("id"),
                session_token: props.currentUser.session_token,
              }
            )
            .then((response) => {
              var resdata = response.data.data;
              //alert(resdata.length)
              if (resdata.length < totalViewsCount || resdata.length === 0) {
                history.push({
                  pathname: "/resume/view/resume_" + id,
                  state: {
                    skills: props.location.state.skills,
                    isFromAdvanceSearch: "yes",
                    //filterChecked: props.location.state.filterChecked === true ? true : false
                  },
                });
                updateViewStatus(id); // id is resumeid
              } else if (allResumeViews.includes(id)) {
                history.push({
                  pathname: "/resume/view/resume_" + id,
                  state: {
                    skills: props.location.state.skills,
                    isFromAdvanceSearch: "yes",
                  },
                });
                updateViewStatus(id); // id is resumeid
              } else {
                alert("You exceeded your resumes view limit");
              }
            });
        }
      }
    } else {
      sessionStorage.setItem(
        "redirectToResumeView",
        "/resume/view/resume_" + resume_id
      );
      // sessionStorage.setItem("resume_id",resume_id)
      history.push({
        pathname: "/EmpLogin",
      });
    }
  };

  const multipleDownloadOrViewResume = () => {
    if (props.currentUser !== null) {
      if (resumeViewsLeft > 0) {
        if (resumeViewsLeft < selectedResumes.length) {
          let msg = "";
          if (resumeViewsLeft === 1) {
            msg = "You can download only " + resumeViewsLeft + " resume";
          } else {
            msg = "You can download only " + resumeViewsLeft + " resumes";
          }
          toast(<ToastMessage message={msg} />);
        } else {
          if (selectedResumes.length > 0) {
            for (let i = 0; i < selectedResumes.length; i++) {
              axios
                .post(`${Key.domain}/resume/get_js_resume_details`, {
                  user_id: sessionStorage.getItem("id"),
                  resumeid: selectedResumes[i].id,
                  session_token: props.currentUser.session_token,
                })
                .then((response) => {
                  let resdetails = response.data.data.resumedetails;
                  var filename = resdetails.filename;
                  var resumeid = resdetails.id;
                  var uploader_id = resdetails.uploader_id;

                  fetch(
                    `${Key.files_domain}/MyFiles/resume_attachments/${uploader_id}/${resumeid}/${filename}`,
                    {
                      method: "GET", // *GET, POST, PUT, DELETE, etc.
                      headers: {
                        "Content-Type": "application/json",
                        Authorization:
                          "Bearer " + window.localStorage["Access_Token"],
                      },
                    }
                  )
                    .then((resdata) => {
                      let msg = "resume downloaded";
                      toast(<ToastMessage message={msg} />);

                      return resdata.blob().then((b) => {
                        var a = document.createElement("a");
                        a.href = URL.createObjectURL(b);
                        const targetFilename =
                          b.type &&
                          b.type !== undefined &&
                          b.type.toLowerCase().includes("pdf")
                            ? filename.split(".").slice(0, -1).join(".") +
                              ".pdf"
                            : b.type.toLowerCase().includes("docx")
                            ? filename.split(".").slice(0, -1).join(".") +
                              ".docx"
                            : b.type.toLowerCase().includes("doc")
                            ? filename.split(".").slice(0, -1).join(".") +
                              ".doc"
                            : filename.split(".").slice(0, -1).join(".") +
                              ".pdf";
                        a.setAttribute(
                          "download",
                          targetFilename ? targetFilename : filename
                        );
                        a.click();
                        updateViewStatus(resumeid);
                      });
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                });
            }
          } else {
            let msg = "Select a resume to download";
            toast(<ToastMessage message={msg} />);
          }
        }
      } else {
        setOpen(true);
      }
    } else {
      history.push({
        pathname: "/EmpLogin",
      });
    }
  };

  const downloadOrViewResume = (resumeId, uploader_id) => {
    if (props.currentUser !== null) {
      if (resumeViewsLeft > 0) {
        axios
          .post(`${Key.domain}/resume/get_js_resume_details`, {
            user_id: sessionStorage.getItem("id"),
            resumeid: resumeId,
            session_token: props.currentUser.session_token,
          })
          .then((response) => {
            let resdetails = response.data.data.resumedetails;
            var filename = resdetails.filename;
            let extension = filename.split(".").pop();

            fetch(
              `${Key.files_domain}/MyFiles/resume_attachments/${uploader_id}/${resumeId}/${filename}`,
              {
                method: "GET", // *GET, POST, PUT, DELETE, etc.
                headers: {
                  "Content-Type": "application/json",
                  Authorization:
                    "Bearer " + window.localStorage["Access_Token"],
                },
              }
            )
              .then((resdata) => {
                let msg = "resume downloaded";
                toast(<ToastMessage message={msg} />);

                return resdata.blob().then((b) => {
                  var a = document.createElement("a");
                  a.href = URL.createObjectURL(b);
                  const targetFilename =
                    b.type &&
                    b.type !== undefined &&
                    b.type.toLowerCase().includes("pdf")
                      ? filename.split(".").slice(0, -1).join(".") + ".pdf"
                      : b.type.toLowerCase().includes("docx")
                      ? filename.split(".").slice(0, -1).join(".") + ".docx"
                      : b.type.toLowerCase().includes("doc")
                      ? filename.split(".").slice(0, -1).join(".") + ".doc"
                      : filename.split(".").slice(0, -1).join(".") + ".pdf";
                  a.setAttribute(
                    "download",
                    targetFilename ? targetFilename : filename
                  );
                  a.click();
                  updateViewStatus(resumeId);
                });
              })
              .catch((e) => {
                console.log(e);
              });
          });
      } else {
        setOpen(true);
      }
    } else {
      history.push({
        pathname: "/EmpLogin",
      });
    }
  };

  const handleChange = (e, resumeId) => {
    const { name, value, checked } = e.target;

    if (name === "allCheck") {
      let resumeslist = resumes.map((list) => {
        setResumeChecked(checked);
        return { ...list, isChecked: checked };
      });
      setResumes(resumeslist);
      let objs = resumeslist.filter((x) => x.isChecked === true);
      let filteredobjs = objs.filter((x) => x.filename !== "");
      var ids = filteredobjs.map((obj) => obj.id);
      setSelectedResids(ids);

      setSelectedResumes(filteredobjs);
    } else if (name === String(resumeId)) {
      let newResumes = resumes.map((list) =>
        list.id === resumeId ? { ...list, isChecked: checked } : list
      );
      setResumes(newResumes);

      let obj = newResumes.filter((x) => x.isChecked === true);
      let filteredobjs = obj.filter((x) => x.filename !== "");
      var ids = filteredobjs.map((obj) => obj.id);
      setSelectedResids(ids);
      setSelectedResumes(filteredobjs);
      obj.length > 1 ? setResumeChecked(checked) : setResumeChecked(false);
    }
  };

  const handleRedirect = () => {
    // props.history.location.goBack();
    sessionStorage.removeItem("inputResumeTitle");
    sessionStorage.removeItem("resumeCityName");
    sessionStorage.removeItem("resumeTitle");
    sessionStorage.removeItem("cityname");

    history.push("/Empdashboard");
  };

  const onPageChanged = (data) => {
    setResumeChecked(false);
    const { currentPage, totalPages, pageLimit } = data;
    const resumesPerPage =
      sessionStorage.getItem("resumesPerPage") !== null
        ? parseInt(sessionStorage.getItem("resumesPerPage"))
        : 10;

    setCurrentPage(currentPage);

    let pageChange = currentPage + 1;

    if (start == null) {
      // if (sessionStorage.getItem("startValue") === null) {
      //   sessionStorage.setItem("startValue", 0);
      // }
      var increment =
        start + parseInt(sessionStorage.getItem("startValue"))
          ? parseInt(sessionStorage.getItem("startValue"))
          : 0;
      setStart(increment);
      sessionStorage.setItem("startValue", increment);
    } else if (currentPage < pageChange) {
      increment = pageChange * resumesPerPage - resumesPerPage - resumesPerPage;
      if (increment == -resumesPerPage) {
        //alert("iam executed")
        increment = increment + resumesPerPage;
        setStart(increment);
        sessionStorage.setItem("startValue", increment);
      }
      setStart(increment);
      sessionStorage.setItem("startValue", increment);
    }

    var jobtype = sessionStorage.getItem("advanceresumefiltered-jobtypes");
    var visatype = sessionStorage.getItem("advanceresumefiltered-visatypes");
    var qualifications = sessionStorage.getItem(
      "advanceresumefiltered-qualifications"
    );
    var experience = sessionStorage.getItem("advanceresumefiltered-exptypes");
    var cityname =
      sessionStorage.getItem("resumeCityName") &&
      sessionStorage.getItem("resumeCityName") !== null &&
      sessionStorage.getItem("resumeCityName") !== "null"
        ? sessionStorage.getItem("resumeCityName")
        : "";
    var inputResumeTitle =
      resumeTitle !== "" && resumeTitle !== undefined && resumeTitle !== null
        ? resumeTitle
        : "";

    var willingToRelocate =
      props.location.state &&
      props.location.state.willingToRelocate !== undefined
        ? props.location.state.willingToRelocate
        : false;
    var skillNDescriptionSearch =
      props.location.state && props.location.state.skills !== undefined
        ? props.location.state.skills
        : "* TO *";
    var skillstoHighlight = sessionStorage.getItem("skillstoHighlight");
    var advancedSkills = sessionStorage.getItem("advancedSearch-skills");
    var resumeTitle =
      sessionStorage.getItem("resumeTitle") !== null &&
      sessionStorage.getItem("resumeTitle") !== "" &&
      sessionStorage.getItem("resumeTitle") !== undefined
        ? sessionStorage.getItem("resumeTitle")
        : "*%3A*";
    var Mappeddates = sessionStorage.getItem(
      "advanceresumefiltered-mappedDates"
    );
    var willing_to_relocate = sessionStorage.getItem(
      "advanceresumefiltered-willingToRelocate"
    );

    if (
      inputResumeTitle === null ||
      inputResumeTitle === undefined ||
      inputResumeTitle === ""
    ) {
      sessionStorage.removeItem("jobtitle");
      localStorage.removeItem("jobtitle");
    }

    const inputlocationcity = sessionStorage.getItem("resumeCityName");

    if (props.location.state !== undefined) {
      if (props.location.state.filterChecked !== true) {
        if (
          inputlocationcity !== "" &&
          inputResumeTitle !== "" &&
          inputlocationcity !== "null" &&
          inputResumeTitle !== "null" &&
          inputlocationcity !== null
        ) {
          // var searchResumesUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select?q=${inputResumeTitle}&fq=${inputlocationcity}&fq=resume_string:(${advancedSkills ? advancedSkills : '*:*'})&fq=(${advancedSkills ? advancedSkills : '*:*'})&rows=${resumesPerPage}&start=${sessionStorage.getItem("startValue") ? sessionStorage.getItem("startValue") : 0}&fq=jobtype:(${jobtype ? jobtype : "*:*"})&fq=${skillNDescriptionSearch!== undefined ? skillNDescriptionSearch : '* TO *'}&sort=created%20desc&wt=json`;
          var searchResumesUrl = `${Key.Solr_url}/${
            Key.solrResumes_collection
          }/select?q=${inputResumeTitle}&fq=${inputlocationcity}&fq=(${
            advancedSkills ? advancedSkills : "*:*"
          })&fq=search_flag:(1)&rows=${resumesPerPage}&start=${
            sessionStorage.getItem("startValue")
              ? sessionStorage.getItem("startValue")
              : 0
          }&fq=jobtype:(${jobtype ? jobtype : "*:*"})&fq=${
            skillNDescriptionSearch !== undefined
              ? skillNDescriptionSearch
              : "* TO *"
          }&fq=search_flag:(1)&sort=created%20desc&wt=json`;
        } else if (
          (inputlocationcity === "" ||
            inputlocationcity === null ||
            inputlocationcity === "null") &&
          inputResumeTitle !== ""
        ) {
          searchResumesUrl = `${Key.Solr_url}/${
            Key.solrResumes_collection
          }/select?q=application_title:${inputResumeTitle}&fq=(${
            advancedSkills ? advancedSkills : "*:*"
          })&fq=search_flag:(1)&rows=${resumesPerPage}&start=${
            sessionStorage.getItem("startValue")
              ? sessionStorage.getItem("startValue")
              : 0
          }&fq=jobtype:(${jobtype ? jobtype : "*:*"})&fq=${
            skillNDescriptionSearch !== undefined
              ? skillNDescriptionSearch
              : "* TO *"
          }&fq=search_flag:(1)&sort=created%20desc&wt=json`;
        } else if (
          inputlocationcity !== "" &&
          inputlocationcity !== null &&
          inputlocationcity !== "null" &&
          inputlocationcity !== undefined &&
          (inputResumeTitle === "" || inputResumeTitle === null)
        ) {
          searchResumesUrl = `${Key.Solr_url}/${
            Key.solrResumes_collection
          }/select?&fq=${inputlocationcity}&fq=(${
            advancedSkills ? advancedSkills : "*:*"
          })&fq=search_flag:(1)&rows=${resumesPerPage}&start=${
            sessionStorage.getItem("startValue")
              ? sessionStorage.getItem("startValue")
              : 0
          }&fq=jobtype:(${jobtype ? jobtype : "*:*"})&fq=${
            skillNDescriptionSearch !== undefined
              ? skillNDescriptionSearch
              : "* TO *"
          }&fq=search_flag:(1)&sort=created%20desc&wt=json`;
        } else {
          var filteredResumeIds =
            sessionStorage.getItem("AdvanceResumeIds") !== null
              ? sessionStorage.getItem("AdvanceResumeIds")
              : "*:*";

          searchResumesUrl = `${Key.Solr_url}/${
            Key.solrResumes_collection
          }/select?q=application_title:(${resumeTitle})&fq=id:(${filteredResumeIds})&fq=(${
            advancedSkills ? advancedSkills : "*:*"
          })&fq=search_flag:(1)&rows=${resumesPerPage}&start=${
            sessionStorage.getItem("startValue")
              ? sessionStorage.getItem("startValue")
              : 0
          }&sort=created%20desc&wt=json`;
        }
      } else {
        var filteredResumeIds =
          sessionStorage.getItem("AdvanceResumeIds") !== null
            ? sessionStorage.getItem("AdvanceResumeIds")
            : "*:*";
        var application_title =
          sessionStorage.getItem("resumeTitle") !== null &&
          sessionStorage.getItem("resumeTitle") !== "" &&
          sessionStorage.getItem("resumeTitle") !== undefined
            ? sessionStorage.getItem("resumeTitle")
            : "*%3A*";
        searchResumesUrl = `${Key.Solr_url}/${
          Key.solrResumes_collection
        }/select?q=application_title:(${application_title})&fq=id:(${filteredResumeIds})&fq=search_flag:(1)&rows=${resumesPerPage}&start=${
          sessionStorage.getItem("startValue")
            ? sessionStorage.getItem("startValue")
            : 0
        }&fq=jobtype:(${jobtype ? jobtype : "*:*"})&fq=res_visatypeName:(${
          visatype ? visatype : "*:*"
        })&fq=total_experience:(${
          experience ? experience : "*:*"
        })&fq=qualificationTitle:(${
          qualifications ? qualifications : "*:*"
        })&fq=(${
          advancedSkills ? advancedSkills : "*:*"
        })&fq=willing_to_relocate:(${
          willing_to_relocate === "1" ? "1" : "*:*"
        })&fq=created:[${
          Mappeddates !== null ? Mappeddates : "* TO *"
        }]&fq=search_flag:(1)&sort=created%20desc&wt=json`;
      }

      localStorage.setItem("resumeQuery", searchResumesUrl);
      fetch(`${Key.domain}/solr/solr_resumes`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ url: searchResumesUrl }),
      }).then((response) => {
        response.json().then((resdata) => {
          sessionStorage.setItem("resumeTitle", inputResumeTitle);
          sessionStorage.setItem("resumeCityName", inputlocationcity);
          sessionStorage.setItem(
            "resumesCount",
            resdata.data.response.numFound
          );
          setNumFound(resdata.data.response.numFound);
          setResumes(resdata.data.response.docs);
        });
      });
    }
  };

  const handleShow = (resid, resfile, resuid) => {
    if (sessionStorage.getItem("id") !== null) {
      // console.log(packageExpired)
      if (resumeViewsLeft > 0) {
        axios
          .post(`${Key.domain}/resume/get_js_resume_details`, {
            user_id: sessionStorage.getItem("id"),
            resumeid: resid,
            session_token: props.currentUser.session_token,
          })
          .then((response) => {
            let resdetails = response.data.data.resumedetails;
            if (
              resdetails.filename !== "" &&
              resdetails.filename !== null &&
              resdetails.filename !== undefined &&
              resdetails.filename !== "null"
            ) {
              setcurrentresid(resid);
              setcurrentresfile(resdetails.filename);
              setcurrentresuid(resuid);
              setShow(true);
            } else {
              setFileNotFOund(true);
            }
          })
          .catch((error) => {
            setFileNotFOund(true);
          });
      } else {
        setOpen(true);
      }
    } else {
      history.push({
        pathname: "/EmpLogin",
      });
    }
  };

  const advancedRoleClicked = (AdvanceResumeIds) => {
    var filteredResumeIds = sessionStorage.getItem("AdvanceResumeIds");

    var searchResumesUrl = `${Key.Solr_url}/${
      Key.solrResumes_collection
    }/select?fq=id:(${AdvanceResumeIds})&fq=search_flag:(1)&rows=10&start=${sessionStorage.getItem(
      "startValue"
    )}&sort=created%20desc&wt=json`;

    fetch(`${Key.domain}/solr/solr_resumes`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ url: searchResumesUrl }),
    }).then((response) => {
      response.json().then((resdata) => {
        const retrivedData = resdata.data.response.docs;
        sessionStorage.setItem("resumesCount", resdata.data.response.numFound);

        setResumes(retrivedData);
      });
    });
  };

  const handleShowMultiple = () => {
    if (sessionStorage.getItem("id") !== null) {
      if (resumeViewsLeft > 0) {
        if (selectedResumes.length > 0) {
          if (resumeViewsLeft < selectedResumes.length) {
            let msg = "";
            if (resumeViewsLeft === 1) {
              msg = "You can share only " + resumeViewsLeft + " resume";
            } else {
              msg = "You can share only " + resumeViewsLeft + " resumes";
            }

            toast(<ToastMessage message={msg} />);
          } else {
            axios
              .post(`${Key.domain}/resume/get_resumefiles_multiple`, {
                user_id: sessionStorage.getItem("id"),
                resumeids: selectedResIds,
                session_token: props.currentUser.session_token,
              })
              .then((response) => {
                let resdetails = response.data.data;

                setSelectedResumes(resdetails);
                // setcurrentresid(resid);
                // setcurrentresfile(resdetails.filename);
                // setcurrentresuid(resuid);
                setShowMultiple(true);
              });
          }
        } else {
          let msg = "Select a resume to share";
          toast(<ToastMessage message={msg} />);
        }
      } else {
        setOpen(true);
      }
    } else {
      history.push({
        pathname: "/EmpLogin",
      });
    }
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleCloseMultiple = () => {
    setShowMultiple(false);
  };
  const handlemessageClose = () => {
    setmessageShow(false);
  };

  const handleNoteClose = () => {
    setNoteShow(false);
  };

  const handlemailClose = () => {
    setmailShow(false);
  };

  const handlemailShow = (mail, name) => {
    if (sessionStorage.getItem("id") !== null) {
      settomail(mail);
      setmailShow(true);
      setcandfullname(name);
    } else {
      history.push({
        pathname: "/EmpLogin",
      });
    }
  };

  const changeRowsPerPage = (value) => {
    sessionStorage.setItem("resumesPerPage", value);
  };

  const handleTab = (value, item) => {
    localStorage.removeItem("skillFlages");
    sessionStorage.removeItem("advancedSearch-skills");
    sessionStorage.removeItem("resumeTitle");
    sessionStorage.removeItem("inputResumeTitle");
    sessionStorage.setItem("Selectedjsondata", JSON.stringify(item));
    sessionStorage.setItem("currenttab", value);
    setSelectedjsondata(item);
    setcurrenttab(value);
    setFolderLoading(true);
    setTimeout(() => {
      setFolderLoading(false);
    }, [100]);

    axios
      .post(`${Key.domain}/mysql/mysql_advanced_search_resumes`, {
        job_role_id: item.RoleId,
      })
      .then((response) => {
        let resdata = response.data.data;
        var resumeIds = resdata.map((resume) => resume.resumeid);

        var AdvanceResumeIds =
          resumeIds !== null && resumeIds.map((fid) => `${fid}`).join(" OR ");
        sessionStorage.setItem("AdvanceResumeIds", AdvanceResumeIds);

        advancedRoleClicked(AdvanceResumeIds);
      })
      .catch((error) => {});
  };

  // console.log(jsondata)
  // console.log(sessionStorage.getItem("AdvanceResumeIds"))
  const json_Data =
    jsondata.length > 0
      ? jsondata
      : sessionStorage.getItem("technology_jsondata")
      ? JSON.parse(sessionStorage.getItem("technology_jsondata"))
      : [];

  const style = {
    backgroundColor: "#406882",
    borderColor: "#f3f5fa",
    color: "white",
  };
  const _style = {
    backgroundColor: "#ff6414",
    borderColor: "#f3f5fa",
    color: "white",
  };

  // console.log(JSON.parse(localStorage.getItem("skillFlages")))
  // console.log(localStorage.getItem("skillFlages"))
  const checked =
    resumes.length > 0
      ? !resumes.some((list) => list?.isChecked !== true)
      : false;

  return (
    <div>
      {props.currentUser !== null ? <Navbar /> : <HomeNavbar />}
      {sessionStorage.getItem("id") !== null ? (
        <Dialog open={show} onClose={handleClose} fullWidth>
          <DialogTitle
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <DialogContentText variant="h6">Share</DialogContentText>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <ShareResume
              handlepopUpClose={handleClose}
              filename={currentresfile}
              res_id={currentresid}
              user_id={currentresuid}
              usermail={
                props.currentUser.username !== null
                  ? props.currentUser.username
                  : ""
              }
              updateViewStatus={updateViewStatus}
            />
          </DialogContent>
        </Dialog>
      ) : null}

      {/* for alert message*/}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        fullWidth
        onClose={handleCloseAlert}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {packageExpired ? "Package Expired!" : "Limit exceeded!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {packageExpired ? (
              <span>
                Your package is expired. Please{" "}
                <a
                  href="/Contactsales"
                  style={{
                    cursor: "pointer",
                    color: "blue",
                    textDecoration: "underline",
                  }}
                >
                  ContactSales
                </a>{" "}
                to upgrade your package!
              </span>
            ) : (
              <span>
                You have exceeded your free limit to view, download or share
                resumes. Please{" "}
                <a
                  href="/Contactsales"
                  style={{
                    cursor: "pointer",
                    color: "blue",
                    textDecoration: "underline",
                  }}
                >
                  ContactSales
                </a>{" "}
                to upgrade your package!
              </span>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>

      {/* for resume file not found alert message*/}
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        open={fileNotFound}
        onClose={handleCloseResumeNotfound}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Attachment Not Found"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{"Resume Attachment not found"}</DialogContentText>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>

      {sessionStorage.getItem("id") !== null ? (
        <Dialog
          open={mailshow}
          centered
          size="lg"
          onClose={handlemailClose}
          fullWidth
        >
          <DialogTitle
            id="responsive-dialog-title"
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <DialogContentText variant="h6" sx={{ fontSize: "18px" }}>
              Send Mail To Candidate
            </DialogContentText>
            <IconButton aria-label="close" onClick={handlemailClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Sendcandidatetomail
              handlemailClose={handlemailClose}
              tomail={tomail}
              candfullname={candfullname}
              fromempEmail={props.currentUser.username}
            />
          </DialogContent>
        </Dialog>
      ) : null}

      {sessionStorage.getItem("id") !== null ? (
        <Dialog open={showMultiple} onClose={handleCloseMultiple} fullWidth>
          {/* <Modal closeButton>
            <Modal.Title>Share </Modal.Title>
          </Modal> */}
          <DialogTitle
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <DialogContentText variant="h6">Share</DialogContentText>
            <IconButton aria-label="close" onClick={handleCloseMultiple}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <ShareResumeMultiple
              handleCloseMultiple={handleCloseMultiple}
              // filename={currentresfile}
              // res_id={currentresid}
              // user_id={currentresuid}
              selectedResumes={selectedResumes}
              usermail={
                props.currentUser.username !== null
                  ? props.currentUser.username
                  : ""
              }
              updateViewStatus={updateViewStatusMultiple}
            />
          </DialogContent>
        </Dialog>
      ) : null}

      <Searchblock
        onSubmit={onSubmit}
        fulltime={
          props.location.state && props.location.state.fulltime !== undefined
            ? props.location.state.fulltime
            : false
        }
        contract={
          props.location.state && props.location.state.contract !== undefined
            ? props.location.state.contract
            : false
        }
        jobtitle={sessionStorage.getItem("jobtitle")}
        cityName={sessionStorage.getItem("cityname")}
        clearRoleBasedBtns={clearRoleBasedBtns}
        handlePaginationRefresh={handlePaginationRefresh}
      />

      <div id="searchJobPage" style={{ height: "40%" }}>
        <button class="feedback2" onClick={handleRedirect}>
          <BiArrowBack size={18} />
        </button>

        {/* courses buttons */}

        <div>
          <br />
          <div className="container-fluid">
            <div className="row">
              {json_Data.length > 0
                ? json_Data.map((item, index) => (
                    <div className="col-lg-4" style={{ width: "auto" }}>
                      <button
                        type="button"
                        className="btn btn"
                        style={
                          index ===
                          parseInt(sessionStorage.getItem("currenttab"))
                            ? _style
                            : style
                        }
                        onClick={() => handleTab(index, item)}
                      >
                        {item.JobRole}
                      </button>
                    </div>
                  ))
                : ""}
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-3">
              <div>
                {/* <Filters /> */}
                {filterLoading === false ? (
                  <EmpResumeFilters
                    handlePaginationRefresh={handlePaginationRefresh}
                    onFilterChecked={onFilterChecked}
                    jsonFilterData={sessionStorage.getItem("Selectedjsondata")}
                    currenttab={sessionStorage.getItem("currenttab")}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
            <div
              className="col-sm-12 col-md-12 col-lg-9"
              style={{
                marginTop:
                  (JSON.parse(localStorage.getItem("advancedSearch-skills")) !==
                    null &&
                    JSON.parse(localStorage.getItem("advancedSearch-skills"))
                      .length > 0) ||
                  JSON.parse(localStorage.getItem("advancedSearch-skills")) !==
                    null
                    ? "30px"
                    : "0",
              }}
            >
              <p
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  marginRight: "20px",
                }}
              ></p>

              <div className="row">
                <div className="col">
                  <div>
                    {numFound !== 0 ||
                    sessionStorage.getItem("resumesCount") !== "0" ? (
                      <div className="row">
                        <div className="col-md-6">
                          <button type="button" className="btn btn-primary">
                            <div className="form-check">
                              {resumeChecked ? "UnSelect All" : "Select All"}
                              <input
                                type="checkbox"
                                className="form-check-input"
                                onClick={handleChange}
                                name="allCheck"
                                checked={checked}
                              />
                            </div>
                          </button>
                          &nbsp;
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => multipleDownloadOrViewResume()}
                          >
                            <BsDownload />
                            &nbsp;Download
                          </button>
                          &nbsp;
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => handleShowMultiple()}
                          >
                            <AiOutlineShareAlt />
                            &nbsp;Share
                          </button>
                          &nbsp;
                          {props.currentUser !== null && (
                            <button
                              type="button"
                              className="btn btn-primary"
                              disabled
                            >
                              &nbsp;Resume views left{" "}
                              <MuiBadge bg="secondary">
                                {resumeViewsLeft}
                              </MuiBadge>
                            </button>
                          )}
                          <br />
                          <br />
                        </div>
                        <div className="col-md-6">
                          <div style={{ float: "right", display: "flex" }}>
                            {paginationRefresh ? (
                              <div
                                style={{ color: "#406882", marginTop: "18px" }}
                              >
                                Page {currentPage} of{" "}
                                {sessionStorage.getItem("resumesCount")} Resumes
                              </div>
                            ) : (
                              ""
                            )}

                            <div
                              style={{
                                justifyContent: "space-between",
                                display: "flex",
                              }}
                            >
                              {paginationRefresh === true ? (
                                <Pagination
                                  totalRecords={parseInt(
                                    sessionStorage.getItem("resumesCount")
                                  )}
                                  pageLimit={
                                    sessionStorage.getItem("resumesPerPage") !==
                                    null
                                      ? sessionStorage.getItem("resumesPerPage")
                                      : 10
                                  }
                                  pageNeighbours={1}
                                  onPageChanged={onPageChanged}
                                  handlePaginationRefresh={
                                    handlePaginationRefresh
                                  }
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {pageLoading
                      ? ""
                      : resumes.map((res, idx) => {
                          return (
                            <div class="card mb-3" key={res.id}>
                              {res.viewed_by ? (
                                res.viewed_by.includes(
                                  sessionStorage.getItem("id")
                                ) ? (
                                  <MuiBadge className="resumeViewedStatus">
                                    Viewed
                                  </MuiBadge>
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )}

                              <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-1 logo">
                                  <div className="form-check check-box">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      name={res.id}
                                      id="check"
                                      checked={res?.isChecked}
                                      onClick={(e) => handleChange(e, res.id)}
                                    />
                                  </div>
                                  <img src={ResumesLogo} />
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-11">
                                  <div
                                    className="card-body"
                                    style={{ lineHeight: "30px" }}
                                  >
                                    <p
                                      className="resume-rating"
                                      // style={{
                                      //   display: "flex",
                                      //   justifyContent: "end",
                                      //   marginRight: "20px",
                                      //   marginTop: "0",
                                      //   marginBottom: "0",
                                      // }}
                                    >
                                      Rating :{" "}
                                      <span className="ms-2">
                                        {res.profile_rating
                                          ? res.profile_rating < 3.5
                                            ? "3.5"
                                            : res.profile_rating
                                          : "3.5"}{" "}
                                      </span>
                                      <div
                                        style={{
                                          marginTop: "2px",
                                        }}
                                      >
                                        <Rating
                                          name="size-small"
                                          size="small"
                                          color="#000"
                                          value={
                                            res.profile_rating
                                              ? res.profile_rating < 3.5
                                                ? "3.5"
                                                : res.profile_rating
                                              : "3.5"
                                          }
                                          precision={0.1}
                                          readOnly
                                        />
                                      </div>
                                    </p>

                                    <h6
                                      className="card-title"
                                      style={{
                                        cursor: "pointer",
                                        color: "#406882",
                                        fontWeight: "bold",
                                      }}
                                      onClick={() => jobTitleClicked(res.id)}
                                    >
                                      {res.full_name}
                                    </h6>
                                    <p
                                      className="card-text"
                                      id="job__description"
                                      style={{
                                        fontWeight: "600",
                                        marginBottom: "0",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontWeight: "600",
                                          marginBottom: "0",
                                        }}
                                      >
                                        <Highlighter
                                          highlightStyle={{
                                            backgroundColor: "#95ffbe",
                                          }}
                                          searchWords={
                                            sessionStorage.getItem(
                                              "skillstoHighlight"
                                            ) !== undefined &&
                                            sessionStorage.getItem(
                                              "skillstoHighlight"
                                            ) !== null &&
                                            sessionStorage.getItem(
                                              "skillstoHighlight"
                                            ) !== ""
                                              ? sessionStorage
                                                  .getItem("skillstoHighlight")
                                                  .split(",")
                                              : []
                                          }
                                          autoEscape={true}
                                          textToHighlight={
                                            res.application_title
                                          }
                                        />
                                      </span>
                                    </p>
                                    <p
                                      className="card-text"
                                      id="job__description"
                                      style={{ marginBottom: "0" }}
                                    >
                                      <span
                                        style={{
                                          marginBottom: "0",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Summary
                                      </span>{" "}
                                      :{" "}
                                      {/* res.summary &&
                                      res.summary !== "null" &&
                                      res.summary !== "undefined" &&
                                      res.summary !== ""
                                      ? parse(
                                        res.summary
                                          .slice(0, 750)
                                          .replace(/<[^>]+>/g, "")
                                      ) + "..."
                                      : "NOT AVAILABLE" */}
                                      <Highlighter
                                        highlightStyle={{
                                          backgroundColor: "#95ffbe",
                                        }}
                                        searchWords={
                                          sessionStorage.getItem(
                                            "skillstoHighlight"
                                          ) !== undefined &&
                                          sessionStorage.getItem(
                                            "skillstoHighlight"
                                          ) !== null &&
                                          sessionStorage.getItem(
                                            "skillstoHighlight"
                                          ) !== ""
                                            ? sessionStorage
                                                .getItem("skillstoHighlight")
                                                .split(",")
                                            : []
                                        }
                                        autoEscape={true}
                                        textToHighlight={
                                          res.summary &&
                                          res.summary !== "null" &&
                                          res.summary !== "undefined" &&
                                          res.summary !== ""
                                            ? parse(
                                                res.summary
                                                  .slice(0, 750)
                                                  .replace(/<[^>]+>/g, "")
                                              ) + "..."
                                            : "NOT AVAILABLE"
                                        }
                                      />
                                    </p>
                                    <p
                                      className="card-text mb-0 mt-0"
                                      style={{
                                        fontSize: "14px",
                                        marginBottom: "0",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontWeight: "600",
                                          marginBottom: "0",
                                        }}
                                      >
                                        Key Skills
                                      </span>{" "}
                                      :{" "}
                                      <span
                                        style={{
                                          marginLeft: "4px",
                                          letterSpacing: "0.2px",
                                        }}
                                      >
                                        {" "}
                                        <Highlighter
                                          highlightStyle={{
                                            backgroundColor: "#95ffbe",
                                          }}
                                          searchWords={
                                            sessionStorage.getItem(
                                              "skillstoHighlight"
                                            ) !== undefined &&
                                            sessionStorage.getItem(
                                              "skillstoHighlight"
                                            ) !== null &&
                                            sessionStorage.getItem(
                                              "skillstoHighlight"
                                            ) !== ""
                                              ? sessionStorage
                                                  .getItem("skillstoHighlight")
                                                  .split(",")
                                              : []
                                          }
                                          autoEscape={true}
                                          textToHighlight={
                                            res.skillName
                                              ? res.skillName
                                                  .join(",")
                                                  .split(",")
                                                  .join(", ")
                                                  .slice(0, 100)
                                              : "N/A"
                                          }
                                        />
                                      </span>
                                    </p>
                                    <p
                                      className="card-text mb-0 mt-0"
                                      style={{
                                        fontSize: "14px",
                                        marginBottom: "0",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontWeight: "600",
                                          marginBottom: "0",
                                        }}
                                      >
                                        Willing to Relocate
                                      </span>{" "}
                                      :{" "}
                                      <span
                                        style={{
                                          marginLeft: "4px",
                                          letterSpacing: "0.2px",
                                        }}
                                      >
                                        {" "}
                                        {res.willing_to_relocate == "0"
                                          ? "No"
                                          : res.willing_to_relocate == "1"
                                          ? "Yes"
                                          : "No"}
                                      </span>
                                    </p>
                                  </div>

                                  <div className="card-footer bottom">
                                    <small className="text-muted">
                                      Location&nbsp;:&nbsp;
                                      {res.res_cityName ? (
                                        <span>
                                          {res.res_cityName},&nbsp;{" "}
                                          {res.res_shortRegion}
                                        </span>
                                      ) : (
                                        "N/A"
                                      )}
                                    </small>
                                    <small className="text-muted">
                                      Job Type&nbsp;:&nbsp;
                                      {res.res_jobtypeName
                                        ? res.res_jobtypeName
                                            .join(",")
                                            .split(",")
                                            .join(", ")
                                        : "N/A"}
                                    </small>
                                    <small className="text-muted">
                                      Visa Type&nbsp;:&nbsp;
                                      {res.res_visatypeName
                                        ? res.res_visatypeName
                                        : res.visatype}
                                    </small>
                                    <small className="text-muted">
                                      Posted&nbsp;:&nbsp;
                                      {moment(res.created).fromNow()}
                                    </small>
                                  </div>
                                  <div
                                    className="mb-2"
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      fontSize: "20px",
                                    }}
                                  >
                                    <span style={{ display: "inline-flex" }}>
                                      <Tooltip title="Share" followCursor>
                                        <AiOutlineShareAlt
                                          className="mx-2"
                                          onClick={() =>
                                            handleShow(
                                              res.id,
                                              res.filename,
                                              res.uid
                                            )
                                          }
                                        />
                                      </Tooltip>

                                      <Tooltip
                                        title="Send mail to candidate"
                                        followCursor
                                      >
                                        {/* <AiOutlineMail className="mx-2" onClick={handlemailShow} /> */}
                                        <AiOutlineMail
                                          className="mx-2"
                                          onClick={() =>
                                            handlemailShow(
                                              res.email_address,
                                              res.full_name
                                            )
                                          }
                                        />
                                      </Tooltip>
                                      <Tooltip title="Message" followCursor>
                                        <FiMessageSquare
                                          className="mx-2"
                                          onClick={() =>
                                            handlemessageShow(res.id)
                                          }
                                        />
                                      </Tooltip>
                                      <Tooltip title="Short Notes" followCursor>
                                        <TiEdit
                                          className="mx-2"
                                          onClick={() => handleNoteShow(res.id)}
                                        />
                                      </Tooltip>
                                    </span>

                                    <span
                                      style={{
                                        marginRight: "20px",
                                        display: "inline-flex",
                                      }}
                                    >
                                      {/* viewed by Person i.e. login user */}

                                      {allResumeViews
                                        .join(",")
                                        .includes(res.id) ? (
                                        <Tooltip title="Viewed" followCursor>
                                          <AiFillEye
                                            className="mx-2"
                                            style={{ color: "#406882" }}
                                          />
                                        </Tooltip>
                                      ) : (
                                        <Tooltip
                                          title="Not Viewed"
                                          followCursor
                                        >
                                          <AiOutlineEye className="mx-2" />
                                        </Tooltip>
                                      )}

                                      {/* viewed by user's group i.e current user's group */}
                                      {/* groupMembersIds.length!==0 && groupMembersIds.join(",").includes(res.viewed_by) */}

                                      {groupByallResumeViews
                                        .map((item) => item.resume_id)
                                        .join(",")
                                        .includes(res.id) ? (
                                        <Tooltip
                                          title={groupByallResumeViews.map(
                                            (item) =>
                                              item.resume_id == res.id
                                                ? "Viewed By Group Member : " +
                                                  item.RecruiterName
                                                : ""
                                          )}
                                          followCursor
                                        >
                                          <TiGroup
                                            className="mx-2"
                                            style={{ color: "#406882" }}
                                          />
                                        </Tooltip>
                                      ) : (
                                        <Tooltip
                                          title="Group Members Not Viewed"
                                          followCursor
                                        >
                                          <TiGroupOutline className="mx-2" />
                                        </Tooltip>
                                      )}

                                      <Tooltip title="Download" followCursor>
                                        <AiOutlineDownload
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            downloadOrViewResume(
                                              res.id,
                                              res.uid
                                            )
                                          }
                                        />
                                      </Tooltip>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                    {numFound !== 0 ||
                    sessionStorage.getItem("resumesCount") !== "0" ? (
                      <div className="row">
                        <div className="col-sm-12 col-md-8">
                          {paginationRefresh ? (
                            <div
                              style={{ color: "#406882", marginTop: "18px" }}
                            >
                              Page {currentPage} of{" "}
                              {sessionStorage.getItem("resumesCount")} Resumes
                            </div>
                          ) : (
                            ""
                          )}

                          <div
                            style={{
                              justifyContent: "space-between",
                              display: "flex",
                            }}
                          >
                            {paginationRefresh === true ? (
                              <Pagination
                                totalRecords={parseInt(
                                  sessionStorage.getItem("resumesCount")
                                )}
                                pageLimit={
                                  sessionStorage.getItem("resumesPerPage") !==
                                  null
                                    ? sessionStorage.getItem("resumesPerPage")
                                    : 10
                                }
                                pageNeighbours={1}
                                onPageChanged={onPageChanged}
                                handlePaginationRefresh={
                                  handlePaginationRefresh
                                }
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div
                          className="col-sm-12 col-md-4"
                          style={{ paddingTop: "6px" }}
                        >
                          <div
                            class="form-group row"
                            id="rows-perpage"
                            // style={{ marginLeft: "40px" }}
                          >
                            <label
                              for="staticEmail"
                              class="col-sm-8 col-form-label"
                              style={{ width: "140px" }}
                            >
                              Rows Per Page
                            </label>
                            <div class="col-sm-2">
                              <select
                                class="form-select form-select mb-0"
                                aria-label=".form-select-lg example"
                                style={{ width: "80px" }}
                                onChange={(e) => {
                                  changeRowsPerPage(e.target.value);
                                  onPageChanged(
                                    sessionStorage.getItem("currentsearchpage"),
                                    "",
                                    sessionStorage.getItem("resumesPerPage")
                                  );
                                }}
                              >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                              </select>
                            </div>
                          </div>
                          <div class="form-group row"></div>
                        </div>
                      </div>
                    ) : (
                      <div className="container" style={{ paddingTop: "2%" }}>
                        <div
                          class="alert alert-success"
                          role="alert"
                          style={{ display: "flex", placeContent: "center" }}
                        >
                          <h5>No Resumes Found !</h5>
                        </div>
                      </div>
                    )}
                  </div>
                  {numFound !== 0 ||
                  sessionStorage.getItem("resumesCount") !== "0" ? (
                    <div className="row">
                      <div className="col-md-6 adds-images">
                        <img src={adOne2} className="img-fluid" />
                      </div>
                      <div className="col-md-6 adds-images">
                        <img src={adTwo} className="img-fluid" />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <br />
                  {numFound !== 0 ||
                  sessionStorage.getItem("resumesCount") !== "0" ? (
                    <Dialog
                      open={messageshow}
                      centered
                      fullWidth
                      onClose={handlemessageClose}
                    >
                      {/* <Modal.Header closeButton>
                        <Modal.Title style={{ fontSize: "18px" }}>
                          Message
                        </Modal.Title>
                      </Modal.Header> */}

                      <DialogTitle
                        id="responsive-dialog-title"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <DialogContentText
                          variant="h6"
                          style={{ fontSize: "18px" }}
                        >
                          Message
                        </DialogContentText>
                        <IconButton
                          aria-label="close"
                          onClick={handlemessageClose}
                        >
                          <CloseIcon />
                        </IconButton>
                      </DialogTitle>

                      <DialogContent>
                        <Messagepopup
                          handlemessageClose={handlemessageClose}
                          msgresumeid={msgresumeid}
                        />
                      </DialogContent>
                    </Dialog>
                  ) : (
                    ""
                  )}
                  {numFound !== 0 ||
                  sessionStorage.getItem("resumesCount") !== "0" ? (
                    <Dialog
                      open={noteShow}
                      centered
                      onClose={handleNoteClose}
                      size="sm"
                      fullWidth
                    >
                      {/* <Modal.Header closeButton>
                        <Modal.Title  >
                          Note
                        </Modal.Title>
                      </Modal.Header> */}
                      <DialogTitle
                        id="responsive-dialog-title"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <DialogContentText
                          variant="h6"
                          style={{ fontSize: "18px" }}
                        >
                          Note
                        </DialogContentText>
                        <IconButton
                          aria-label="close"
                          onClick={handleNoteClose}
                        >
                          <CloseIcon />
                        </IconButton>
                      </DialogTitle>
                      <DialogContent>
                        <Notepopup
                          handleNoteClose={handleNoteClose}
                          noteResumeId={noteResumeId}
                        />
                      </DialogContent>
                    </Dialog>
                  ) : (
                    ""
                  )}

                  {props.currentUser !== null ? (
                    numFound !== 0 ||
                    sessionStorage.getItem("resumesCount") !== "0" ? (
                      <Alertcandidate
                        joblocation={
                          props.location.state &&
                          props.location.state.inputjoblocation !== undefined
                            ? props.location.state.inputjoblocation
                            : ""
                        }
                        jobtitle={
                          props.location.state &&
                          props.location.state.inputResumeTitle !== undefined
                            ? props.location.state.inputResumeTitle
                            : ""
                        }
                      />
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  <div></div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

//Get props from state
function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.UserReducer.user,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchJobsTwo)
);

//!res.viewed_by_group
//?

//   <Tooltip
//     content="Group Members Not Viewed"
//     direction="left"
//   >
//     <img
//       src={viewdbygroupIcon}
//       className="mx-2"
//     />
//   </Tooltip>
// ) : (
//   <Tooltip
//     content="Group Member Viewed"
//     direction="left"
//   >
//     <AiFillEye
//       className="mx-2"
//       style={{ color: "#406882" }}
//     />
//   </Tooltip>
// )}*/
