import React, { useState, useEffect } from "react";
import axios from "axios";
import Key from "../src/clientVariables.json";
import moment from "moment";
import * as XLSX from "xlsx";
import EditIcon from "@mui/icons-material/Edit";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  showLoader,
  hideLoader,
} from "../src/Components/Actions/loader.actions";
import Parseresumeview from "./Parseresumeview";
import { DataGrid } from "@mui/x-data-grid";
import { Grid, Button } from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  TextField
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { CSVLink } from "react-csv";

function Parserresumeslist(props) {
  const [show, setShow] = useState(false);
  const [resumes, setResumes] = useState([]);
  const [resumeId, setResumeId] = useState("");
  const [selectedResumeIds, setSelectedResumeIds] = useState([]);
  const [xlData, setXlData] = useState([]);
  const [xlFile, setXlFile] = useState();
  const [count, setCount] = useState(parseInt(100))
  const [searchKeyword, setSearchKeyword] = useState("");
  const [showfile, setShowfile] = useState(false);
  const handleShowfile = () => setShowfile(true);
  const handleClosefile = () => setShowfile(false);
  const handleClose = () => setShow(false);
  const [page, setPage] = useState(0)
  const [uid, setUid] = useState(0)
  const pageSize = 20; 
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0
})
const [paginationModel, setPaginationModel] = React.useState({
  pageSize: 20,
  page: 0,
});

  useEffect(() => {
    getData(props.location.state ? props.location.state.paginationNumber : page, searchKeyword);
  }, []);

  const getData = (pageNo, keyword) => {
    props.showLoader();
    //setPage(value)
    setPageState(prev => ({ ...prev, isLoading: true }));
    let page = (pageNo * 10);
    console.log("page",page)
    axios
      .post(`${Key.domain}/parser/get_all_resumes`, {
        infinite_scroll_index: page,
        search_keyword: keyword,
      })
      .then((response) => {
        setResumes(response?.data.Resumes?.Resumes);
        let resumeData = response?.data.Resumes?.Resumes
        setCount(response?.data.Resumes?.Resumes_count?.resumesCount)
         let resumeCount = response?.data.Resumes?.Resumes_count?.resumesCount
        props.hideLoader();
        setPageState(prev => ({
          ...prev,
          isLoading: false,
          data: resumeData ?? [],
          total: resumeCount
      }));
      });
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleEdit = (resumeid) => {
    props.history.push({
      pathname: "/Parserresumestabs",
      state: {
        resumeid: resumeid,
        paginationNumber: page
      },
    });
  };

  const deleteResumes = (resumeIds) => {
    axios
      .post(`${Key.domain}/parser/parser_delete_resume`, {
        resumeid: resumeIds,
      })
      .then((response) => {
        console.log(response);
      });
  };

  const readExcel = (file) => {
    setXlFile(file);
  };
  useEffect(() => {
    getData(paginationModel?.page , searchKeyword)
}, [paginationModel?.page,searchKeyword])

  const handleXlUpload = () => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(xlFile);
      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        console.log(data);
        resolve(data);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      setXlData(d);
    });
    fetch(`${Key.domain}/parser/readResumesExcelFile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        postdata: xlData,
      }),
    })
      .then((response) => response.json()) //converting data into json format
      .then((response) => {
        if (response.success === 1) {
          var resumeIds = xlData.map((data) => data.ResumeId);
          console.log(resumeIds);
          if (resumeIds) {
            for (let i = 0; i <= resumeIds.length; i++) {
              axios
                .post(`${Key.domain}/parser/get_parser_resume_details`, {
                  resumeid: resumeIds[i],
                })
                .then(async (response) => {
                  if (
                    response &&
                    response.status === 200 &&
                    response.data.data &&
                    response.data.data.resumedetails
                  ) {
                    var resumedetails = response.data.data.resumedetails;
                    axios
                      .post(`${Key.domain}/resume/js_add_resume_to_solr`, {
                        created:
                          resumedetails.created &&
                          resumedetails.created !== undefined &&
                          resumedetails.created !== null
                            ? resumedetails.created
                            : null,
                        user_id: resumedetails.uid,
                        // session_token: jssession_token,
                        resume_id: resumeIds[i],
                        application_title: xlData[i].ApplicationTitle,
                        email_address: xlData[i].email_address || "",
                        search_flag: 1,
                        full_name: xlData[i].full_name || "",
                        res_fileName:
                          resumedetails.filename
                            .split(".")
                            .slice(0, -1)
                            .join(".") + ".pdf" || "",
                        location_state: resumedetails.location_state || "",
                        address_city: resumedetails.address_city || "",
                        searchable:
                          resumedetails.searchable === 1 ? true : false,
                        section_skills: resumedetails.section_skills || "",
                        ai_skills: resumedetails.ai_skills || "",
                        summary: xlData[i].Summary || "",
                        home_phone: xlData[i].Phone || "",
                        jobtype: resumedetails.jobtype || "",
                        total_experience: xlData[i].total_experience || "",
                        visatype: resumedetails.visatype || "",
                        OPT_issued_year: "2000",
                        grad_year: resumedetails.graduation_year || "",
                        res_stateName: xlData[i].stateName || "",
                        res_cityName: xlData[i].cityName || "",
                        res_jobtypeName: xlData[i].jobtypeName || "",
                        skillName: xlData[i].skillName || "",
                        profile_rating: resumedetails.profile_rating || "3.5",
                        country: resumedetails.country_id || "",
                        qualificationId:
                          resumedetails.heighestfinisheducation || "",
                        linkedin_url: resumedetails.linkedin_url || "",
                        salary: resumedetails.desired_salary || "",
                        salary_type: resumedetails.salary_type || "",
                        res_countryName: resumedetails.countryname || "",
                        res_shortRegion: xlData[i].shortRegion || "",
                        res_visatypeName: xlData[i].visatypeName || "",
                        res_salarrytypeName:
                          resumedetails.jobsalaryrangetypeName || "",
                        qualificationTitle:
                          resumedetails.qualififcationtitle || "",
                        willingToRelocate:
                          resumedetails.willing_to_relocate !== undefined &&
                          resumedetails.willing_to_relocate === 1
                            ? true
                            : false,
                        viewed_by:
                          resumedetails.viewed_by &&
                          resumedetails.viewed_by !== undefined
                            ? resumedetails.viewed_by
                            : "",
                      })
                      .then(async (response) => {
                        console.log(response);
                      });
                  }
                });
            }
          }
        }
      });
  };

  const columns = [
    { field: "id", headerName: "Resume Id", flex: 1 },
    { field: "application_title", headerName: "Application Title", flex: 1 },
    { field: "full_name", headerName: "Full Name", flex: 1 },
    { field: "location", headerName: "Location", flex: 1 },
    { field: "email_address", headerName: "Email", flex: 1 },
    { field: "home_phone", headerName: "Phone no", flex: 1 },
    { field: "visatypenames", headerName: "Visatype", flex: 1 },
    { field: "total_experience", headerName: "Exp", flex: 1 },
    {
      field: "created",
      headerName: "Created",
      flex: 1,
      valueFormatter: (params) =>
        params.value ? moment(params.value).format("MMM DD, YYYY HH:mm A") : "",
    },
    {
      field: "last_modified",
      headerName: "Last Modified",
      flex: 1,
      valueFormatter: (params) =>
        params.value ? moment(params.value).format("MMM DD, YYYY HH:mm A") : "",
    },
    {
      field: "edit",
      headerName: "Edit",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={"Edit Resume"}>
          <IconButton onClick={() => handleEdit(params.row.id)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  const rows = resumes.map((resume) => ({
    id: resume.id,
    application_title: resume.application_title,
    full_name: resume.full_name,
    location: resume.location,
    email_address: resume.email_address,
    home_phone: resume.home_phone,
    visatypenames: resume.visatypenames,
    total_experience: resume.total_experience,
    created: resume.created,
    last_modified: resume.last_modified,
  }));
  const handleInputChange = (event) => {
    console.log(event)
    let searchWord = event.target.value.toLowerCase()
    setSearchKeyword(searchWord);
    setPaginationModel(prev => ({ ...prev, page: 0 }))
};

  return (
    <div className="container" style={{ paddingTop: "38px" }}>
      <Dialog
        open={show}
        onClose={() => setShow(false)}
        backdrop="static"
        fullWidth
        maxWidth="md"
        centered
        disableEscapeKeyDown
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          {" "}
          <DialogContentText variant="h6">Update Excel Data</DialogContentText>
          <IconButton aria-label="close" onClick={() => setShow(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            style={{ width: "50vw" }}
            component="form"
            method="post"
            encType="multipart/form-data"
            action="/upload"
          >
            <input
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={(e) => {
                const file = e.target.files[0];
                readExcel(file);
              }}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleClosefile}>
            Close
          </Button>
          <Button variant="contaned" color="success" onClick={handleXlUpload}>
            Upload
          </Button>
        </DialogActions>
      </Dialog>
      <Parseresumeview
        show={show}
        handleClose={handleClose}
        resumeId={resumeId}
      />
      <React.Fragment>
        <Grid sx={{ width: '60%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '1.5rem' }}>
          <CSVLink
            data={resumes}
            filename={"ResumesData.csv"}
            className="btn btn-primary"
          >
            Export
          </CSVLink>

          <TextField sx={{ flex: '0.8' }}
             value={ searchKeyword }
            label="Search Jobs with ids and titles.."
            variant="outlined"
          onChange={ handleInputChange }
          />
        </Grid>
      </React.Fragment>
      <DataGrid
        autoHeight
        rows={rows}
        rowCount={ pageState.total }
        loading={ pageState.isLoading }
        columns={columns}
        checkboxSelection
        paginationModel={ paginationModel }
        onPaginationModelChange={ setPaginationModel }
        paginationMode="server"
        onRowClick={ (data) => { setShow(true); setResumeId(data?.id) }}
        onSelectionModelChange={(selection) => setSelectedResumeIds(selection)}
        rowsPerPageOptions={ [10] }
        pageSize={pageSize} 
       
        // components={{
        //   Toolbar: () => ( 
        //     <React.Fragment>
        //       <CSVLink data={resumes} filename={"ResumesData.csv"} className="btn btn-primary">
        //         Export
        //       </CSVLink>
        //     </React.Fragment>
        //   )
        // }}
      />
      <br />
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default withRouter(connect(null, mapDispatchToProps)(Parserresumeslist));

// import React, { useState, useEffect } from 'react';
// import axios from "axios";
// import Key from "../src/clientVariables.json";
// import moment from "moment";
// import * as XLSX from "xlsx";
// import EditIcon from '@mui/icons-material/Edit';
// import { IconButton, Tooltip } from "@mui/material";
// import { withRouter } from "react-router-dom";
// import { connect } from "react-redux";
// import { showLoader, hideLoader } from "../src/Components/Actions/loader.actions";
// import Parseresumeview from "./Parseresumeview";
// import Parsertoolbar from "./Parsertoolbar";
// import { DataGrid } from '@mui/x-data-grid';

// function Parserresumeslist(props) {
//   const [show, setShow] = useState(false);
//   const [resumes, setResumes] = useState([]);
//   const [resumeId, setResumeId] = useState("");
//   const [selectedResumeIds, setSelectedResumeIds] = useState([]);
//   const [xlData, setXlData] = useState([]);
//   const [xlFile, setXlFile] = useState();
//   const [searchKeyword, setSearchKeyword] = useState("");
//   const [showfile, setShowfile] = useState(false);
//     const handleShowfile = () => setShowfile(true);
//   const handleClosefile = () => setShowfile(false);
//   const handleClose = () => {
//     setShow(false);
//   };

//   useEffect(() => {
//     getData();
//   }, []);

//   const getData = () => {
//     props.showLoader();
//     axios.post(`${Key.domain}/parser/get_all_resumes`, {
//       "infinite_scroll_index": 0,
//       "search_keyword": searchKeyword
//     }).then((response) => {
//       setResumes(response.data.Resumes.Resumes);
//       props.hideLoader();
//     });
//   }

//   const handleEdit = (resumeid) => {
//     props.history.push({
//       pathname: "/Parserresumestabs",
//       state: {
//         resumeid: resumeid
//       }
//     });
//   }

//   const deleteResumes = (resumeIds) => {
//     axios.post(`${Key.domain}/parser/parser_delete_resume`, {
//       "resumeid": resumeIds
//     }).then((response) => {
//       console.log(response);
//     });
//   }

//     const readExcel = (file) => {
//     setXlFile(file)
//   };

//   const handleXlUpload = () => {
//     const promise = new Promise((resolve, reject) => {
//       const fileReader = new FileReader();
//       fileReader.readAsArrayBuffer(xlFile);
//       fileReader.onload = (e) => {
//         const bufferArray = e.target.result;
//         const wb = XLSX.read(bufferArray, { type: "buffer" });
//         const wsname = wb.SheetNames[0];
//         const ws = wb.Sheets[wsname];
//         const data = XLSX.utils.sheet_to_json(ws);
//         console.log(data);
//         resolve(data);
//       };
//       fileReader.onerror = (error) => {
//         reject(error);
//       };
//     });

//     promise.then((d) => {
//       setXlData(d);
//     });
//     fetch(`${Key.domain}/parser/readResumesExcelFile`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Accept: "application/json"
//       },
//       body: JSON.stringify({
//         postdata: xlData,
//       })
//     }).then(response => response.json()) //converting data into json format
//       .then((response) => {
//         if (response.success === 1) {
//           var resumeIds = xlData.map(data => data.ResumeId)
//           console.log(resumeIds)
//           if (resumeIds) {
//             for (let i = 0; i <= resumeIds.length; i++) {
//               axios
//                 .post(`${Key.domain}/parser/get_parser_resume_details`, {
//                   resumeid: resumeIds[i],
//                 })
//                 .then(async response => {
//                   if (response && response.status === 200 && response.data.data && response.data.data.resumedetails) {
//                     var resumedetails = response.data.data.resumedetails
//                     axios.post(`${Key.domain}/resume/js_add_resume_to_solr`, {
//                       created: resumedetails.created && resumedetails.created !== undefined && resumedetails.created !== null ? resumedetails.created : null,
//                       user_id: resumedetails.uid,
//                       // session_token: jssession_token,
//                       resume_id: resumeIds[i],
//                       application_title: xlData[i].ApplicationTitle,
//                       email_address: xlData[i].email_address || '',
//                       search_flag: 1,
//                       full_name: xlData[i].full_name || '',
//                       res_fileName: resumedetails.filename.split('.').slice(0, -1).join('.') + ".pdf" || '',
//                       location_state: resumedetails.location_state || '',
//                       address_city: resumedetails.address_city || '',
//                       searchable: resumedetails.searchable === 1 ? true : false,
//                       section_skills: resumedetails.section_skills || '',
//                       ai_skills: resumedetails.ai_skills || '',
//                       summary: xlData[i].Summary || '',
//                       home_phone: xlData[i].Phone || '',
//                       jobtype: resumedetails.jobtype || '',
//                       total_experience: xlData[i].total_experience || '',
//                       visatype: resumedetails.visatype || '',
//                       OPT_issued_year: "2000",
//                       grad_year: resumedetails.graduation_year || '',
//                       res_stateName: xlData[i].stateName || '',
//                       res_cityName: xlData[i].cityName || '',
//                       res_jobtypeName: xlData[i].jobtypeName || '',
//                       skillName: xlData[i].skillName || '',
//                       profile_rating: resumedetails.profile_rating || '3.5',
//                       country: resumedetails.country_id || '',
//                       qualificationId: resumedetails.heighestfinisheducation || '',
//                       linkedin_url: resumedetails.linkedin_url || '',
//                       salary: resumedetails.desired_salary || '',
//                       salary_type: resumedetails.salary_type || '',
//                       res_countryName: resumedetails.countryname || "",
//                       res_shortRegion: xlData[i].shortRegion || '',
//                       res_visatypeName: xlData[i].visatypeName || '',
//                       res_salarrytypeName: resumedetails.jobsalaryrangetypeName || '',
//                       qualificationTitle: resumedetails.qualififcationtitle || '',
//                       willingToRelocate: resumedetails.willing_to_relocate !== undefined && resumedetails.willing_to_relocate === 1 ? true : false,
//                       viewed_by: resumedetails.viewed_by && resumedetails.viewed_by !== undefined ? resumedetails.viewed_by : ""
//                     })
//                       .then(async (response) => {
//                         console.log(response);
//                       });
//                   }
//                 })
//             }
//           }
//         }
//       })
//   }
//   const columns = [
//     { field: 'id', headerName: 'Resume Id', flex: 1 },
//     { field: 'application_title', headerName: 'Application Title', flex: 1 },
//     { field: 'full_name', headerName: 'Full Name', flex: 1 },
//     { field: 'location', headerName: 'Location', flex: 1 },
//     { field: 'email_address', headerName: 'Email', flex: 1 },
//     { field: 'home_phone', headerName: 'Phone no', flex: 1 },
//     { field: 'visatypenames', headerName: 'Visatype', flex: 1 },
//     { field: 'total_experience', headerName: 'Exp', flex: 1 },
//     {
//       field: 'created',
//       headerName: 'Created',
//       flex: 1,
//       valueFormatter: (params) => params.value ? moment(params.value).format('MMM DD, YYYY HH:mm A') : ''
//     },
//     {
//       field: 'last_modified',
//       headerName: 'Last Modified',
//       flex: 1,
//       valueFormatter: (params) => params.value ? moment(params.value).format('MMM DD, YYYY HH:mm A') : ''
//     },
//     {
//       field: 'edit',
//       headerName: 'Edit',
//       flex: 1,
//       renderCell: (params) => (
//         <Tooltip title={"Edit Resume"}>
//           <IconButton onClick={() => handleEdit(params.row.id)}>
//             <EditIcon />
//           </IconButton>
//         </Tooltip>
//       ),
//     }
//   ];

//   const rows = resumes.map((resume) => ({
//     id: resume.id,
//     application_title: resume.application_title,
//     full_name: resume.full_name,
//     location: resume.location,
//     email_address: resume.email_address,
//     home_phone: resume.home_phone,
//     visatypenames: resume.visatypenames,
//     total_experience: resume.total_experience,
//     created: resume.created,
//     last_modified: resume.last_modified
//   }));

//   return (
//     <div className='container' style={{ paddingTop: "38px" }}>
//       <Modal
//         show={show}
//         onHide={() => setShow(false)}
//         backdrop="static"
//         centered
//         keyboard={false}
//       >
//                 <Modal.Header closeButton>
//           <Modal.Title>Update Excel Data</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form style={{ width: "50vw" }} method="post" enctype="multipart/form-data" action="/upload" >
//             <input
//               type="file"
//               accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
//               onChange={(e) => {
//                 const file = e.target.files[0];
//                 readExcel(file);
//               }}
//             />
//           </Form>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleClosefile}>
//             Close
//           </Button>
//           <Button variant="primary" onClick={handleXlUpload}>Upload</Button>
//         </Modal.Footer>
//       </Modal>
//       <Parseresumeview show={show} handleClose={handleClose} resumeId={resumeId} />
//       <DataGrid
//         rows={rows}
//         columns={columns}
//         checkboxSelection
//         onRowClick={(row) => setResumeId(row.id)}
//         onSelectionModelChange={(selection) => setSelectedResumeIds(selection)}
//         components={{
//           Toolbar: () => (
//             <Parsertoolbar selectedResumeIds={selectedResumeIds} />
//           )
//         }}
//       />
//       <br />
//     </div>
//   );
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     showLoader: () => dispatch(showLoader()),
//     hideLoader: () => dispatch(hideLoader()),
//   };
// };

// export default withRouter(connect(null, mapDispatchToProps)(Parserresumeslist));

// import React, { useState, useEffect } from 'react'
// import MUIDataTable from "mui-datatables";
// import Parseresumeview from "./Parseresumeview"
// import axios from "axios";
// import Key from "../src/clientVariables.json"
// import Parseresumetabs from "./Parserresumestabs"
// import moment from "moment";
// import Parsertoolbar from "./Parsertoolbar"
// import Toolbarparser from './Toolbarparser';
// import * as XLSX from "xlsx";
// import EditIcon from '@mui/icons-material/Edit';
// import { Pagination, IconButton, Tooltip } from "@mui/material";
// import { withRouter } from "react-router-dom";
// import { connect } from "react-redux";
// import { showLoader } from "../src/Components/Actions/loader.actions";
// import { hideLoader } from "../src/Components/Actions/loader.actions";

// function Parserresumeslist(props) {
//   const [show, setShow] = useState(false);
//   const [showfile, setShowfile] = useState(false);
//   const [resumes, setResumes] = useState([]);
//   const [xlData, setXlData] = useState([]);
//   const [page, setPage] = useState(0)
//   const [uid, setUid] = useState(0)
//   const [count, setCount] = useState(parseInt(100))
//   const [resumeId, setResumeId] = useState("")
//   const [selectedResumeIds, setSelectedResumeIds] = useState([]);
//   const [xlFile, setXlFile] = useState();
//   const [searchKeyword, setSearchKeyword] = useState("");
//   const handleShowfile = () => setShowfile(true);
//   const handleClosefile = () => setShowfile(false);
//   const handleClose = () => {
//     setShow(false);
//   };
//   useEffect(() => {
//     getData(props.location.state ? props.location.state.paginationNumber : page, searchKeyword)
//   }, [])
//   const getData = (value, keyword) => {
//     props.showLoader();
//     console.log(value)
//     setPage(value)
//     axios.post(`${Key.domain}/parser/get_all_resumes`, {
//       "infinite_scroll_index": value,
//       "search_keyword": keyword

//     })
//       .then((response) => {
//         setResumes(response.data.Resumes.Resumes)
//         setCount(response.data.Resumes.Resumes_count.resumesCount)
//         props.hideLoader();
//       })
//     window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
//   }
//   const deleteResumes = (resumeIds) => {
//     axios
//       .post(`${Key.domain}/parser/parser_delete_resume`, {
//         "resumeid": resumeIds
//       })
//       .then((response) => {
//         console.log(response);
//       })
//   }
//   const readExcel = (file) => {
//     setXlFile(file)
//   };
//   const handleEdit = (resumeid) => {
//     props.history.push({
//       pathname: "/Parserresumestabs",
//       state: {
//         resumeid: resumeid,
//         paginationNumber: page
//       }
//     });
//   }
//   const handleXlUpload = () => {
// const promise = new Promise((resolve, reject) => {
//   const fileReader = new FileReader();
//   fileReader.readAsArrayBuffer(xlFile);
//   fileReader.onload = (e) => {
//     const bufferArray = e.target.result;
//     const wb = XLSX.read(bufferArray, { type: "buffer" });
//     const wsname = wb.SheetNames[0];
//     const ws = wb.Sheets[wsname];
//     const data = XLSX.utils.sheet_to_json(ws);
//     console.log(data);
//     resolve(data);
//   };
//   fileReader.onerror = (error) => {
//     reject(error);
//   };
// });

// promise.then((d) => {
//   setXlData(d);
// });
// fetch(`${Key.domain}/parser/readResumesExcelFile`, {
//   method: "POST",
//   headers: {
//     "Content-Type": "application/json",
//     Accept: "application/json"
//   },
//   body: JSON.stringify({
//     postdata: xlData,
//   })
// }).then(response => response.json()) //converting data into json format
//   .then((response) => {
//     if (response.success === 1) {
//       var resumeIds = xlData.map(data => data.ResumeId)
//       console.log(resumeIds)
//       if (resumeIds) {
//         for (let i = 0; i <= resumeIds.length; i++) {
//           axios
//             .post(`${Key.domain}/parser/get_parser_resume_details`, {
//               resumeid: resumeIds[i],
//             })
//             .then(async response => {
//               if (response && response.status === 200 && response.data.data && response.data.data.resumedetails) {
//                 var resumedetails = response.data.data.resumedetails
//                 axios.post(`${Key.domain}/resume/js_add_resume_to_solr`, {
//                   created: resumedetails.created && resumedetails.created !== undefined && resumedetails.created !== null ? resumedetails.created : null,
//                   user_id: resumedetails.uid,
//                   // session_token: jssession_token,
//                   resume_id: resumeIds[i],
//                   application_title: xlData[i].ApplicationTitle,
//                   email_address: xlData[i].email_address || '',
//                   search_flag: 1,
//                   full_name: xlData[i].full_name || '',
//                   res_fileName: resumedetails.filename.split('.').slice(0, -1).join('.') + ".pdf" || '',
//                   location_state: resumedetails.location_state || '',
//                   address_city: resumedetails.address_city || '',
//                   searchable: resumedetails.searchable === 1 ? true : false,
//                   section_skills: resumedetails.section_skills || '',
//                   ai_skills: resumedetails.ai_skills || '',
//                   summary: xlData[i].Summary || '',
//                   home_phone: xlData[i].Phone || '',
//                   jobtype: resumedetails.jobtype || '',
//                   total_experience: xlData[i].total_experience || '',
//                   visatype: resumedetails.visatype || '',
//                   OPT_issued_year: "2000",
//                   grad_year: resumedetails.graduation_year || '',
//                   res_stateName: xlData[i].stateName || '',
//                   res_cityName: xlData[i].cityName || '',
//                   res_jobtypeName: xlData[i].jobtypeName || '',
//                   skillName: xlData[i].skillName || '',
//                   profile_rating: resumedetails.profile_rating || '3.5',
//                   country: resumedetails.country_id || '',
//                   qualificationId: resumedetails.heighestfinisheducation || '',
//                   linkedin_url: resumedetails.linkedin_url || '',
//                   salary: resumedetails.desired_salary || '',
//                   salary_type: resumedetails.salary_type || '',
//                   res_countryName: resumedetails.countryname || "",
//                   res_shortRegion: xlData[i].shortRegion || '',
//                   res_visatypeName: xlData[i].visatypeName || '',
//                   res_salarrytypeName: resumedetails.jobsalaryrangetypeName || '',
//                   qualificationTitle: resumedetails.qualififcationtitle || '',
//                   willingToRelocate: resumedetails.willing_to_relocate !== undefined && resumedetails.willing_to_relocate === 1 ? true : false,
//                   viewed_by: resumedetails.viewed_by && resumedetails.viewed_by !== undefined ? resumedetails.viewed_by : ""
//                 })
//                   .then(async (response) => {
//                     console.log(response);
//                   });
//               }
//             })
//         }
//       }
//     }
//   })
//   }
//   const columns = [
//     {
//       name: "id",
//       label: "Resume Id",
//       options: {
//         filter: true,
//         sort: false,
//         customBodyRender: (value, tableMeta, updateValue) => {
//           return <p style={{ cursor: 'pointer' }}>{value}</p>
//         },
//       }
//     },
//     {
//       name: "application_title",
//       label: "Application Title",
//       options: {
//         filter: true,
//         sort: false,
//         customBodyRender: (value, tableMeta, updateValue) => {
//           return <p style={{ cursor: 'pointer' }}>{value}</p>
//         },
//       }
//     },
//     {
//       name: "full_name",
//       label: "Full Name",
//       options: {
//         filter: true,
//         sort: false,
//         customBodyRender: (value, tableMeta, updateValue) => {
//           return <p style={{ cursor: 'pointer' }}>{value}</p>
//         },
//       }
//     },
//     {
//       name: "location",
//       label: "Location",
//       options: {
//         filter: true,
//         sort: false,
//       }
//     },
//     {
//       name: "email_address",
//       label: "Email",
//       options: {
//         filter: true,
//         sort: false,
//       }
//     },

//     {
//       name: "home_phone",
//       label: "Phone no",
//       options: {
//         filter: true,
//         sort: false,
//       }
//     },
//     {
//       name: "visatypenames",
//       label: "Visatype",
//       options: {
//         filter: true,
//         sort: false,
//       }
//     },
//     {
//       name: "total_experience",
//       label: "Exp",
//       options: {
//         filter: true,
//         sort: false,
//       }
//     },
//     {
//       name: "created",
//       label: "Created",
//       options: {
//         filter: true,
//         sort: false,
//         customBodyRender: (value, tableMeta, updateValue) => {
//           // var usaTime = new Date().toLocaleString("en-US", { timeZone: "America/New_York" });
//           // usaTime = new Date(usaTime);
//           // var todaydate = moment(usaTime).format('L');
//           return moment(value).format('MMM DD, YYYY HH:mm A')
//         },
//       }
//     },
//     {
//       name: "last_modified",
//       label: "Last Modified",
//       options: {
//         filter: true,
//         sort: false,
//         customBodyRender: (value, tableMeta, updateValue) => {
//           // var usaTime = new Date().toLocaleString("en-US", { timeZone: "America/New_York" });
//           // usaTime = new Date(usaTime);
//           // var todaydate = moment(usaTime).format('L');
//           return moment(value).format('MMM DD, YYYY HH:mm A')
//         },
//       }
//     },
//     {
//       name: "edit",
//       label: "Edit",
//       options: {
//         filter: true,
//         sort: false,
//         customBodyRender: (value, tableMeta, updateValue) => {
//           return <Tooltip title={"Edit Resume"}>
//             <IconButton onClick={() => handleEdit(tableMeta.rowData[0])}>
//               <EditIcon />
//             </IconButton>
//           </Tooltip>
//         },
//       }
//     }
//   ];

//   const options = {
//     filterType: 'checkbox',
//     onCellClick: (cellIndex, rowIndex) => {
//       if (cellIndex === 8) {
//       }
//       setShow(true);
//     },
//     filter: false,
//     rowsPerPageOptions: false,
//     count: count,
//     serverSide: false,
//     print: false,
//     viewColumns: false,
//     download: false,
//     pagination: false,
//     onChangePage: (currentPage) => {
//       console.log(currentPage)
//       setPage(parseInt(sessionStorage.getItem("parserResumesPageNumber")))
//       sessionStorage.setItem("parserResumesPageNumber", currentPage)
//       getData(parseInt(sessionStorage.getItem("parserResumesPageNumber")) + 1, searchKeyword)
//       window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
//     },
//     onRowsDelete: (rowData, rowState) => {
//       var resumeIds = rowData.data.map((row) => {
//         return resumes[row.index].id;
//       });
//       if (window.confirm("Are you sure you want to delete resume?")) {
//         console.log(resumeIds)
//         deleteResumes(resumeIds);
//       }
//     },
//     onRowClick: (rowData, rowState) => {
//       var row = resumes[rowState.dataIndex];
//       console.log(row)
//       setResumeId(row.id)
//       setUid(row.uid)
//     },
//     onRowsSelect: (currentRowsSelected, allRowsSelected) => {
//       console.log(currentRowsSelected)
//       var shotlists = allRowsSelected.map((row) => {
//         return resumes[row.dataIndex];
//       });
//       console.log(shotlists.map(row => row.id))
//       setSelectedResumeIds(shotlists.map(row => row.id))
//     },
//     customToolbar: () => {
//       return (
//         <Toolbarparser active={false} isAddJob={true} handleShowfile={handleShowfile} />
//       );
//     },
//     customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
//       <Parsertoolbar
//         selectedResumeIds={selectedResumeIds}
//       />
//     ),
//     customFooter: (
//       count, page, rowsPerPage, changeRowsPerPage, changePage) => {
//       return <div>
//         <br />
//         <Pagination
//           siblingCount={5}
//           boundaryCount={2}
//           shape="rounded"
//           variant="outlined"
//           color="primary"
//           count={Math.ceil(count / 20)}
//           onChange={(e, value) => {
//             getData(value, searchKeyword);
//             sessionStorage.setItem("parsedResumesPagination", value)
//           }}
//           defaultPage={parseInt(sessionStorage.getItem("parsedResumesPagination"))}
//           style={{ float: "right" }}
//         />
//         <br /><br />
//       </div>
//     },
//     customSearchRender: (searchText, handleSearch) => {
//       return (
//         <input
//           type="text"
//           class="form-control"
//           value={searchKeyword}
//           placeholder="Search with id, skill or email"
//           onChange={e => {
//             console.log(e.target.value);
//             getData(0, e.target.value);
//             setSearchKeyword(e.target.value);
//           }} />
//       );
//     },
//   };
//   return (
//     <div className='container' style={{ paddingTop: "38px" }}>
//       <Modal
//         show={showfile}
//         onHide={handleClosefile}
//         backdrop="static"
//         centered
//         keyboard={false}
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>Update Excel Data</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form style={{ width: "50vw" }} method="post" enctype="multipart/form-data" action="/upload" >
//             <input
//               type="file"
//               accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
//               onChange={(e) => {
//                 const file = e.target.files[0];
//                 readExcel(file);
//               }}
//             />
//           </Form>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleClosefile}>
//             Close
//           </Button>
//           <Button variant="primary" onClick={handleXlUpload}>Upload</Button>
//         </Modal.Footer>
//       </Modal>
//       <Parseresumeview show={show} handleClose={handleClose} resumeId={resumeId} />
//       <MUIDataTable
//         title={"Resumes"}
//         data={resumes}
//         columns={columns}
//         options={options}
//       />
//       <br />
//     </div>
//   )
// }
// const mapDispatchToProps = (dispatch) => {
//   return {
//     showLoader: () => dispatch(showLoader()),
//     hideLoader: () => dispatch(hideLoader()),
//   };
// };
// export default withRouter(connect(null, mapDispatchToProps)(Parserresumeslist));
