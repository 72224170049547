import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Input,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import {
  BusinessCenterOutlined,
  BusinessCenterRounded,
  BusinessRounded,
  CloseRounded,
  HistoryOutlined,
  KeyboardReturnRounded,
  LocationOnOutlined,
  LocationOnRounded,
  StarBorderRounded,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { alpha } from "@mui/material";
import { useHistory } from "react-router-dom";

import Key from "../../../../clientVariables.json";
import { useResponsiveStyles } from "../../../../Styles/responsiveStyles";
import {
  clearResumeSearchResults,
  fetchResumeResults,
  updateResumeSearchParams,
} from "../../../Actions/resumeSearchActions";
import { resumeQueryBuilder } from "../../../../utils/resumeQueryBuilder";
import { getSolrFilterCountsByURL } from "../../../../utils/facetQueryBuilder";

const CustomTextField = styled(Input)(({ theme }) => ({
  "& .MuiInputBase-input::placeholder": {
    color: alpha(theme.palette.text.primary, 0.5),
  },
}));

const SearchComonent = ({ closeHandler }) => {
  const styles = useResponsiveStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const searchState = useSelector((state) => state.searchResumeReducer);
  const {
    searchParams = {},
    filterParams = {},
    paginationParams = {},
    results = [],
    selectedResume = null,
    error,
  } = searchState;
  const [searchValue, setSearchValue] = useState("");
  const [locationData, setLocationData] = useState([]);
  const [jobTitlesData, setJobTitlesData] = useState([]);
  const [chipsTitle, setChipsTitle] = useState([]);
  const [chipsLocation, setChipsLocation] = useState([]);
  const [stateList, setStateList] = useState([]);

  useEffect(() => {
    dispatch(clearResumeSearchResults());
    //getLocationByCity('');
    getJobTitles("");
    getStates();

    if (searchParams?.resumeTitle && searchParams.resumeTitle !== "") {
      const titles = searchParams.resumeTitle.split(",");
      setChipsTitle(titles);
      // const fetch = async() => {
      //   await getLocationByCityFromSolr();
      // }
      // fetch();
    }
    if (searchParams?.res_stateName && searchParams.res_stateName !== "") {
      const locs = searchParams.res_stateName.split("OR");
      setChipsLocation(locs);
    }
  }, []);

  useEffect(() => {
    const fetch = async () => {
      // console.log('chips title check', chipsTitle);
      const titlesJoin = chipsTitle.join(",");
      dispatch(updateResumeSearchParams({ ["resumeTitle"]: titlesJoin }));
      await getLocationByCityFromSolr(titlesJoin);
    };
    if (chipsTitle.length > 0) {
      fetch();
    }
  }, [chipsTitle]);

  useEffect(() => {
    const locationJoinWith__ = chipsLocation.join("__");
    let cityLoc = "";
    let stateLoc = "";
    let shortLoc = "";

    if (locationJoinWith__.includes("__")) {
      const multipleLocations = locationJoinWith__.split("__");
      // console.log('multipleLocations', multipleLocations);

      let shortRegion_ = [];
      let city_ = [];
      let state_ = [];

      multipleLocations.forEach((location) => {
        // console.log("multipleLocations single", location);

        const loc = location.split(/[,]+/).map((part) => part.trim());
        if (loc.length > 1) {
          const [city, regionOrState] = loc;
          if (regionOrState.length === 2) {
            shortRegion_.push(`${regionOrState}`);
            city_.push(`"${city}"`);
          } else {
            state_.push(`${regionOrState}`);
            city_.push(`${city}`);
            // console.log("state city", city, regionOrState);
          }
        } else {
          const singleLocation = loc[0];
          if (singleLocation.length === 2) {
            shortRegion_.push(`${singleLocation}`);
          } else {
            city_.push(`${singleLocation}`);
          }
        }
      });

      cityLoc = city_.join(" OR ");
      stateLoc = state_.join(" OR ");
      shortLoc = shortRegion_.join(" OR ");
    } else {
      const loc = locationJoinWith__.split(/[,]+/).map((part) => part.trim());
      if (loc.length > 1) {
        const [city, regionOrState] = loc;
        if (regionOrState.length === 2) {
          shortLoc = regionOrState;
          cityLoc = city;
        } else {
          stateLoc = regionOrState;
          cityLoc = city;
        }
      } else {
        const singleLocation = loc[0];
        if (singleLocation.length === 2) {
          shortLoc = singleLocation;
        } else {
          cityLoc = singleLocation;
        }
      }
    }

    if (cityLoc) {
      // dispatch(updateResumeSearchParams({ ["res_cityName"]: cityLoc }));
      dispatch(updateResumeSearchParams({ ["res_stateName"]: cityLoc }));
    }
    if (stateLoc) {
      dispatch(updateResumeSearchParams({ ["res_stateName"]: stateLoc }));
    }
    if (shortLoc) {
      dispatch(updateResumeSearchParams({ ["res_shortRegion"]: shortLoc }));
    }
    // console.log("City : ", cityLoc);
    // console.log("State : ", stateLoc);
    // console.log("Short : ", shortLoc);
  }, [chipsLocation]);

  // const handleInputChange = (event) => {
  //   const value = event.target.value;
  //   setSearchValue(value);
  //   // console.log(value);
  //   if(value.includes(',')){
  //     const splitValue = value.split(',');
  //     if(splitValue.length === 0){
  //     getJobTitles(splitValue[0]);
  //     }else if(splitValue.length > 1){
  //       getJobTitles(splitValue[0].trim());
  //      // getLocationByCity(splitValue[1].trim());
  //     }
  //   }else{
  //     getJobTitles(value);
  //     //getLocationByCity(value);
  //   }
  // };
  const handleInputChange = (event) => {
    try {
      const value = event.target.value;
      setSearchValue(value);

      const sanitizedValue = value.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");

      if (sanitizedValue.includes(",")) {
        const splitValue = sanitizedValue.split(",");

        if (splitValue.length === 0) {
          getJobTitles(splitValue[0]);
        } else if (splitValue.length > 1) {
          getJobTitles(splitValue[0].trim());
          // getLocationByCity(splitValue[1].trim());
        }
      } else {
        getJobTitles(sanitizedValue);
        // getLocationByCity(sanitizedValue);
      }
    } catch (error) {
      console.error("Error handling input change:", error);
    }
  };

  const handleAddChip = (value, type) => {
    console.log("Sorted statesCounts:", value);
    if (type === "title" && value.trim() !== "") {
      setChipsTitle([...chipsTitle, value.trim()]);
      setSearchValue("");
    } else if (type === "location" && value.trim() !== "") {
      setChipsLocation([...chipsLocation, value.trim()]);
      // setSearchValue('');
    }
  };

  const handleDeleteChip = (chipToDelete) => () => {
    setChipsLocation((chips) => chips.filter((chip) => chip !== chipToDelete));
    setChipsTitle((chips) => chips.filter((chip) => chip !== chipToDelete));
  };

  const handleSubmit = async () => {
    // const paginationParams = {
    //     start: 0,
    //     rows: 10
    //   };
    // const solrUrl = resumeQueryBuilder(searchParams, filterParams, paginationParams);
    // console.log('solrUrl',solrUrl);
    // dispatch(fetchResumeResults(solrUrl));
    // dispatch(fetchResumeResults());

    let checkId = checkInput(searchValue);
    if (checkId === "Number") {
      dispatch(updateResumeSearchParams({ ["id"]: searchValue }));
      await history.push("/employer/resumes");
    } else {
      if (chipsTitle.length === 0 && chipsLocation.length === 0) {
        dispatch(updateResumeSearchParams({ ["resumeTitle"]: searchValue }));
        dispatch(updateResumeSearchParams({ ["res_stateName"]: "" }));
      } else {
        if (chipsTitle.length === 0) {
          dispatch(updateResumeSearchParams({ ["resumeTitle"]: searchValue }));
        }
        if (chipsLocation.length === 0) {
          dispatch(updateResumeSearchParams({ ["res_stateName"]: "" }));
        }
      }

      await history.push("/employer/resumes");
    }

    closeHandler();
    setSearchValue("");
  };
  function checkInput(input) {
    if (typeof input === "number") {
      return "Number";
    }

    if (typeof input !== "string") {
      return "Invalid input";
    }

    // Remove whitespace from the input
    const trimmedInput = input.trim();

    // Check if the entire input is a number
    if (!isNaN(trimmedInput) && trimmedInput !== "") {
      return "Number";
    }

    // Check if the input contains both numbers and letters
    const hasLetters = /[a-zA-Z]/.test(trimmedInput);
    const hasNumbers = /\d/.test(trimmedInput);

    if (hasLetters && hasNumbers) {
      return "Both";
    }

    if (hasLetters) {
      return "Text";
    }

    return "Unknown type";
  }

  const getLocationByCityFromSolr = async (titlesJoin) => {
    try {
      // console.log("search params check", titlesJoin);
      const response = await getSolrFilterCountsByURL(
        "res_shortRegion",
        { resumeTitle: titlesJoin },
        {}
      );
      // console.log(response, stateList);
      let statesCounts = [];

      if (response) {
        stateList.forEach((data) => {
          const short = data.shortRegion.toLowerCase();
          statesCounts.push({
            stateName: data.name,
            count: getCount(short, response),
          });
        });

        statesCounts.sort((a, b) => b.count - a.count);
      }

      console.log("Sorted statesCounts:", statesCounts);

      await setLocationData(statesCounts);
    } catch (error) {
      console.error(error);
    }
  };

  const getStates = () => {
    fetch(`${Key.domain}/commonAPIs/get_states_by_countryid`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        country_id: 233,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setStateList(data.data);
      })
      .catch((error) => {
        console.error("Error fetching states:", error);
      });
  };

  const getCount = (countOfItem, obj) => {
    // console.log('getCount', countOfItem, obj);
    let count = 0;
    if (obj && countOfItem && countOfItem !== undefined && obj !== undefined) {
      const key = countOfItem.toString();
      if (obj.hasOwnProperty(key)) {
        count = obj[key];
      }
    }

    return count;
  };

  const getLocationByCity = async (value) => {
    try {
      const solrBaseUrl = `${Key.Solr_url}/${
        Key.solrResumes_collection
      }/select?fl=res_cityName%2C%20res_stateName%2C%20res_shortRegion&fq=application_title${
        value ? value : "*:*"
      }&q.op=OR&q=*%3A*`;
      // https://solr.jobsnprofiles.com/solr/jnp_resumes_v2/select?fl=res_cityName%2C%20res_stateName%2C%20res_shortRegion&fq=application_title%3A%20java%20developer&q.op=OR&q=*%3A*
      // https://solr.jobsnprofiles.com/solr/jnp_resumes_v2/select?fl=res_cityName%2C%20res_stateName%2C%20res_shortRegion&q.op=OR&q=application_title%3A%20java%20developer
      const response = await axios.post(`${Key.domain}/Jobs/get_joblocations`, {
        statename: value,
      });
      if (response.status === 200) {
        // console.log(response.data.data);
        // setLocationData(response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getJobTitles = async (value) => {
    try {
      const response = await axios.post(
        `${Key.domain}/mysql/get_resume_titles`,
        {
          title: value,
        }
      );
      if (response.status === 200) {
        const titles = response.data.data;
        setJobTitlesData(titles);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const highlightMatch = (text) => {
    let value = searchValue.toLowerCase();

    if (value.includes("c++") || value?.includes("c ++")) {
      return <span key={text}>{text}</span>;
    }
    const regex = new RegExp(`(${searchValue})`, "gi");

    return text.split(regex).map((part, index) =>
      regex.test(part) ? (
        <span
          key={index}
          className="highlighted"
          style={{
            color: "#1E88E5",
            //   textDecoration: "underline"
          }}
        >
          {part}
        </span>
      ) : (
        <span key={index}>{part}</span>
      )
    );
  };

  const highlightMatchLocations = (text) => {
    if (
      searchValue.toLowerCase() === "c++" ||
      searchValue.toLowerCase() === "c+++"
    ) {
      return;
    }
    const regex = new RegExp(`(${searchValue})`, "gi");
    const parts = text.split(regex);
    return (
      <>
        {parts.map((part, index) => {
          if (regex.test(part)) {
            return (
              <span
                key={index}
                className="highlighted"
                style={{
                  color: "#1E88E5",
                  // textDecoration: "underline"
                }}
              >
                {part}
              </span>
            );
          } else if (index % 2 === 0) {
            return part;
          } else {
            const cityState = JSON.parse(part);
            return (
              <span
                key={index}
                style={{
                  color: "#1E88E5",
                  // textDecoration: "underline"
                }}
              >
                {cityState.cityName}, {cityState.stateName}
              </span>
            );
          }
        })}
      </>
    );
  };

  return (
    <Box sx={{ minWidth: "800px" }}>
      <Toolbar
        sx={{
          minHeight: "60px !important",
          position: "sticky",
          top: 0,
          zIndex: 3,
          bgcolor: "#fff",
          borderTopLeftRadius: "6px",
          borderTopRightRadius: "6px",
        }}
      >
        <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
          <CustomTextField
            autoFocus
            placeholder="Search for Resume title"
            fullWidth
            disableUnderline
            value={searchValue}
            onChange={handleInputChange}
            // onKeyDown={(event) => {
            //   if (event.key === 'Enter') {
            //     handleAddChip();
            //   }
            // }}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon color="primary" />
              </InputAdornment>
            }
            sx={{
              "& .MuiInput-underline:before": {
                borderBottom: "none",
              },
              "& .MuiInput-underline:after": {
                borderBottom: "none",
              },
            }}
          />
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{ fontSize: styles.fontSize13 }}
          >
            Find
          </Button>
        </Box>
      </Toolbar>
      <Divider />
      {(chipsTitle?.length > 0 || chipsLocation?.length > 0) && (
        <Grid container sx={{ p: 2 }}>
          {chipsTitle?.length > 0 && (
            <Grid item xs={12}>
              <Typography
                paragraph
                sx={{
                  fontSize: styles.fontSize12,
                  letterSpacing: "0.1rem",
                  color: "#576375",
                  fontWeight: 600,
                  m: 0,
                }}
              >
                Resume Titles:
              </Typography>
              <div>
                {chipsTitle.map((chip, index) => (
                  <Chip
                    key={index}
                    label={chip}
                    onDelete={handleDeleteChip(chip)}
                    style={{
                      marginRight: "5px",
                      marginBottom: "5px",
                      fontSize: "9px",
                    }}
                  />
                ))}
              </div>
            </Grid>
          )}
          {chipsLocation?.length > 0 && (
            <Grid item xs={12}>
              <Typography
                paragraph
                sx={{
                  fontSize: styles.fontSize12,
                  letterSpacing: "0.1rem",
                  color: "#576375",
                  fontWeight: 600,
                  m: 0,
                }}
              >
                Location:
              </Typography>
              <div>
                {chipsLocation?.map((chip, index) => (
                  <Chip
                    key={index}
                    label={chip}
                    onDelete={handleDeleteChip(chip)}
                    style={{
                      marginRight: "5px",
                      marginBottom: "5px",
                      fontSize: "9px",
                    }}
                  />
                ))}
              </div>
            </Grid>
          )}
        </Grid>
      )}
      <Box sx={{ p: 2 }}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Typography
              paragraph
              sx={{
                fontSize: "12px",
                letterSpacing: "0.1rem",
                color: "#576375",
                fontWeight: 600,
              }}
            >
              Resume Titles
            </Typography>
            <List sx={{ maxHeight: 300, overflow: "auto" }}>
              {jobTitlesData?.length > 0 &&
                jobTitlesData.map((data, index) => (
                  <ListItem
                    key={index}
                    onClick={() => handleAddChip(data.title, "title")}
                    sx={{
                      border: "1px solid rgba(229, 234, 242, 0.5)",
                      mb: "6px",
                      backgroundColor: "#f3f6f966",
                      borderRadius: "10px",
                      cursor: "pointer",
                      ".keyboardIcon": {
                        display: "none",
                      },
                      "&:hover": {
                        color: "#1E88E5",
                        backgroundColor: "#E3F2FD",
                        border: "1px solid #64b5f6",
                        ".keyboardIcon": {
                          display: "block",
                        },
                        "& .highlighted": {
                          textDecoration: "underline",
                        },
                        "& .hoverColor": {
                          color: "#1E88E5",
                        },
                      },
                    }}
                  >
                    <BusinessCenterOutlined
                      className="hoverColor"
                      sx={{ fontSize: 18, mr: 2, color: "#6f7f95" }}
                    />
                    <ListItemText
                      primary={highlightMatch(data.title)}
                      primaryTypographyProps={{ fontSize: "13px" }}
                    />
                    <Box sx={{ flexGrow: 1 }} />
                    <KeyboardReturnRounded
                      sx={{ color: "#1E88E5" }}
                      className="keyboardIcon"
                    />
                  </ListItem>
                ))}
            </List>
          </Grid>
          <Grid item xs={6}>
            <Typography
              paragraph
              sx={{
                fontSize: "12px",
                letterSpacing: "0.1rem",
                color: "#576375",
                fontWeight: 600,
              }}
            >
              Resumes By States
            </Typography>
            <List sx={{ maxHeight: 300, overflow: "auto" }}>
              {locationData.length > 0 ? (
                locationData.map((data, index) => (
                  <ListItem
                    key={`loc${index}`}
                    // onClick={() => handleAddChip(`${data.res_cityName}, ${data.res_stateName}`, "location")}
                    onClick={() =>
                      handleAddChip(`${data.stateName}`, "location")
                    }
                    sx={{
                      border: "1px solid rgba(229, 234, 242, 0.5)",
                      mb: "6px",
                      backgroundColor: "#f3f6f966",
                      borderRadius: "10px",
                      cursor: "pointer",
                      ".keyboardIcon": {
                        display: "none",
                      },
                      "&:hover": {
                        color: "#1E88E5",
                        backgroundColor: "#E3F2FD",
                        border: "1px solid #64b5f6",
                        ".keyboardIcon": {
                          display: "block",
                        },
                        "& .highlighted": {
                          textDecoration: "underline",
                        },
                        "& .hoverColor": {
                          color: "#1E88E5",
                        },
                      },
                    }}
                  >
                    <LocationOnOutlined
                      className="hoverColor"
                      sx={{ fontSize: 18, mr: 2, color: "#6f7f95" }}
                    />
                    <ListItemText
                      primary={highlightMatchLocations(
                        `${data.stateName} (${data.count})`
                      )}
                      primaryTypographyProps={{ fontSize: "13px" }}
                    />
                    <Box sx={{ flexGrow: 1 }} />
                    <KeyboardReturnRounded
                      sx={{ color: "#1E88E5" }}
                      className="keyboardIcon"
                    />
                  </ListItem>
                ))
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 300,
                    width: "100%",
                  }}
                >
                  <Box>
                    <Typography variant="body1">
                      Select Resume title for states
                    </Typography>
                  </Box>
                </Box>
              )}
            </List>
          </Grid>
          {/* <Grid item xs={4}>
            <Typography
              paragraph
              sx={{
                fontSize: "12px",
                letterSpacing: "0.1rem",
                color: "#576375",
                fontWeight: 600,
              }}
            >
              Companies
            </Typography>
            <List sx={{ maxHeight: 300, overflow: "auto" }}>
              {companyNames.length > 0 &&
                companyNames.map((data, index) => (
                  <ListItem
                    key={`com${index}`}
                    onClick={() => jobsPageHanlder(data.name, "company")}
                    sx={{
                      border: "1px solid rgba(229, 234, 242, 0.5)",
                      mb: "6px",
                      backgroundColor: "#f3f6f966",
                      borderRadius: "10px",
                      cursor: "pointer",
                      ".keyboardIcon": {
                        display: "none",
                      },
                      "&:hover": {
                        color: "#1E88E5",
                        backgroundColor: "#E3F2FD",
                        border: "1px solid #64b5f6",
                        ".keyboardIcon": {
                          display: "block",
                        },
                        "& .highlighted": {
                          textDecoration: "underline",
                        },
                        "& .hoverColor": {
                            color:'#1E88E5'
                        },
                      },
                    }}
                  >
                    <BusinessRounded className="hoverColor"
                      sx={{ fontSize: 18, mr: 2, color: "#6f7f95" }}
                    />
                    <ListItemText
                      primary={highlightMatch(data.name)}
                      primaryTypographyProps={{ fontSize: "13px" }}
                    />
                    <Box sx={{ flexGrow: 1 }} />
                    <KeyboardReturnRounded
                      sx={{ color: "#1E88E5" }}
                      className="keyboardIcon"
                    />
                  </ListItem>
                ))}
            </List>
            </Grid> */}
        </Grid>
      </Box>
    </Box>
  );
};

export default SearchComonent;
