import React, { useState, useEffect, useContext } from "react";
import "../../Styles/Jsdashboard.css";
import "../../Styles/dropdown.css";
import { Container, Grid } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Key from "../../clientVariables.json";
import { useHistory } from "react-router";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { showLoader } from "../../Components/Actions/loader.actions";
import { FormControl, Select, MenuItem, InputLabel } from "@mui/material";

import { hideLoader } from "../../Components/Actions/loader.actions";
import moment from "moment";
import SearchContext from "../../../src/resumeBuilder/SearchContext";

function Jssearchblock(props) {
  const history = useHistory();
  const searchResContext = useContext(SearchContext);
  const [resumes, setResumes] = useState([]);

  const [resumeTitle, setResumeTitle] = useState("");
  const [cityName, setCityName] = useState("");
  const [fulltime, setFullTime] = useState(false);
  const [contract, setContract] = useState(false);
  const [citySuggestions, setCitySuggestions] = useState([]);
  const [titleSuggestions, setTitleSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [showTitleSuggestions, setShowTitleSuggestions] = useState(false);
  const [jobTypeId, setJobTypeId] = useState([]);
  const [visatypeId, setVisatypeId] = useState([]);
  const [visatypeNames, setVisatypeNames] = useState([]);
  const jobtypes = [
    { value: 1, label: "Full-Time" },
    { value: 2, label: "Part-Time" },
    { value: 10, label: "Contract" },
    { value: 3, label: "Internship" },
  ];

  const visatypes = [
    { id: 3, text: "H1", value: "3" },
    { id: 5, text: "F1", value: "5" },
    { id: 6, text: "TN", value: "6" },
    { id: 9, text: "L1", value: "9" },
    { id: 12, text: "H4", value: "12" },
    { id: 13, text: "H1B", value: "13" },
    { id: 10, text: "GC - EAD", value: "10" },
    { id: 11, text: "H4 - EAD", value: "11" },
    { id: 14, text: "L2 - EAD", value: "14" },
    { id: 15, text: "OPT - EAD", value: "15" },
    { id: 1, text: "GC - Green Card", value: "1" },
    { id: 2, text: "USC- U.S. Citizen ", value: "2" },
    { id: 4, text: "OPT - Optional Practical Training ", value: "4" },
    { id: 16, text: "CPT - Curricular Practical Training", value: "16" },
    { id: 7, text: "EAD - Employment Authorization Document", value: "7" },
    { id: 8, text: "Others", value: "8" },
  ];

  useEffect(() => {
    setFullTime(props.fulltime !== undefined ? props.fulltime : false);
    setContract(props.contract !== undefined ? props.contract : false);
    props.showLoader();
    if (
      window.location.pathname !== "/" &&
      window.location.pathname !== "/Jsdashboard" &&
      props.onSubmit !== undefined
    ) {
      props.onSubmit(resumes);
    }
    setResumeTitle(
      sessionStorage.getItem("resResumeTitle")
        ? sessionStorage.getItem("resResumeTitle")
        : sessionStorage.getItem("resumeTitle")
        ? sessionStorage.getItem("resumeTitle")
        : ""
    );
    setCityName(
      sessionStorage.getItem("resumeCityName")
        ? sessionStorage.getItem("resumeCityName").replace(/"/g, "")
        : ""
    );
    //console.log(props.jobtitle, sessionStorage.getItem("jobtitle"));
  }, [resumes]);

  const clearItems = () => {
    sessionStorage.removeItem("resumefiltered-jobtypes");
    localStorage.removeItem("resumejobtypeFlages");
    localStorage.setItem("resumeFilterResetValue", 1);

    sessionStorage.removeItem("resumefiltered-visatypes");
    localStorage.removeItem("resumevisatypeFlages");

    sessionStorage.removeItem("resumefiltered-experience");
    localStorage.removeItem("resumeExpFlages");

    sessionStorage.removeItem("resumefiltered-country");

    localStorage.removeItem("resumeQualificationFlages");
    sessionStorage.removeItem("resumefiltered-qualification");
    localStorage.removeItem("resumeCountryFlages");

    sessionStorage.removeItem("resumeMappedDates");

    sessionStorage.removeItem("currentsearchpage");
    localStorage.removeItem("resumeSortBy");
    localStorage.removeItem("resumeSortByDate");

    sessionStorage.removeItem("resumefiltered-salary");
    sessionStorage.removeItem("resumeFiltered-salaryTo");
    sessionStorage.removeItem("resumeFiltered-salaryFrom");
    localStorage.removeItem("resumeFileterChecked");
    sessionStorage.removeItem("AdvanceResumeIds");
    localStorage.removeItem("skillFlages");

    sessionStorage.removeItem("advanceresumefiltered-jobtypes");
    sessionStorage.removeItem("advanceresumefiltered-visatypes");
    sessionStorage.removeItem("advanceresumefiltered-qualifications");
    sessionStorage.removeItem("advanceresumefiltered-exptypes");

    sessionStorage.removeItem("technology_jsondata");
    sessionStorage.removeItem("AdvanceResumeIds");
    sessionStorage.removeItem("currenttab");
    sessionStorage.removeItem("Selectedjsondata");
  };

  const submit = (title, statevalue, cityvalue) => {
    if (
      resumeTitle === "" &&
      resumeTitle !== "oracle ebs" &&
      resumeTitle !== "oracle erp" &&
      resumeTitle !== "oracle apps" &&
      resumeTitle !== "oracle r12" &&
      resumeTitle !== "oracle applications" &&
      resumeTitle !== "oracle pl/sql"
    ) {
      props.clearRoleBasedBtns();
    }

    props.showLoader();
    clearItems();
    localStorage.setItem("resumeFilterResetValue", 1);

    var skillstoHighlight = [];

    //sessionStorage.setItem('inputResumeTitle', resumeTitle)

    if (
      resumeTitle.includes("AND") ||
      resumeTitle.includes("OR") ||
      resumeTitle.includes(",")
    ) {
      if (resumeTitle.includes("AND")) {
        var application_title = resumeTitle.split("AND", 1);
        var str = resumeTitle;
        var skills = str.substring(str.indexOf("AND") + 3);
        sessionStorage.setItem("skillNDescriptionSearch", skills);

        const filtereAND = resumeTitle.replaceAll("AND", ",");
        const filtered = filtereAND.replaceAll(" ", ",");
        skillstoHighlight = filtered.split(",");
        sessionStorage.setItem("skillstoHighlight", skillstoHighlight);
      } else if (resumeTitle.includes("OR")) {
        application_title = resumeTitle.split(" OR ", 1);
        str = resumeTitle;
        var skills = str.substring(str.indexOf(" OR ") + 4);

        const titleTwoContainskeyword =
          skills.toLowerCase().includes("developer") ||
          skills.toLowerCase().includes("programmer") ||
          skills.toLowerCase().includes("architect") ||
          skills.toLowerCase().includes("designer") ||
          skills.toLowerCase().includes("consultant") ||
          skills.toLowerCase().includes("administrator") ||
          skills.toLowerCase().includes("coach") ||
          skills.toLowerCase().includes("lead") ||
          skills.toLowerCase().includes("manager") ||
          skills.toLowerCase().includes("master") ||
          skills.toLowerCase().includes("specalist") ||
          skills.toLowerCase().includes("modeler") ||
          skills.toLowerCase().includes("director") ||
          skills.toLowerCase().includes("hacker") ||
          skills.toLowerCase().includes("engineer") ||
          skills.toLowerCase().includes("analyst") ||
          skills.toLowerCase().includes("dba") ||
          skills.toLowerCase().includes("tester");

        var roleBasedTwoTitles = "";

        if (titleTwoContainskeyword === true) {
          var roleBasedVariabe = resumeTitle.replace(" OR ", '" OR "'); // Eg: Java developer" OR "Python developer
          roleBasedTwoTitles = '"' + roleBasedVariabe + '"'; // Eg: "Java developer" OR "Python developer"
         
          skills = "";
        } else {
          sessionStorage.setItem("skillNDescriptionSearch", skills);
          const filtereAND = resumeTitle.replaceAll(" OR ", ",");
          const filtered = filtereAND.replaceAll(" ", ",");
          skillstoHighlight = filtered.split(",");
          sessionStorage.setItem("skillstoHighlight", skillstoHighlight);
        }
      } else if (resumeTitle.includes(",")) {
        application_title = resumeTitle.split(",", 1);
        str = resumeTitle;
        var skills = str.substring(str.indexOf(",") + 1);
        sessionStorage.setItem("skillNDescriptionSearch", skills);

        const filtereAND = resumeTitle.replaceAll(",", ",");
        const filtered = filtereAND.replaceAll(" ", ",");
        skillstoHighlight = filtered.split(",");
        sessionStorage.setItem("skillstoHighlight", skillstoHighlight);
      }
      if (roleBasedTwoTitles === undefined || roleBasedTwoTitles === "") {

        var resume_title = application_title[0];
      
        var inputResumeTitle = resume_title;

        const string = inputResumeTitle;
        const res =
          string.toLowerCase().includes("developer") ||
          string.toLowerCase().includes("programmer") ||
          string.toLowerCase().includes("architect") ||
          string.toLowerCase().includes("designer") ||
          string.toLowerCase().includes("consultant") ||
          string.toLowerCase().includes("administrator") ||
          string.toLowerCase().includes("coach") ||
          string.toLowerCase().includes("lead") ||
          string.toLowerCase().includes("manager") ||
          string.toLowerCase().includes("master") ||
          string.toLowerCase().includes("specalist") ||
          string.toLowerCase().includes("modeler") ||
          string.toLowerCase().includes("director") ||
          string.toLowerCase().includes("hacker") ||
          string.toLowerCase().includes("engineer") ||
          string.toLowerCase().includes("analyst") ||
          string.toLowerCase().includes("dba") ||
          string.toLowerCase().includes("tester");

        if (res === true) {
          inputResumeTitle = '"' + string.replace(/"/g, "") + '"';
        }
      } else {
        inputResumeTitle = roleBasedTwoTitles;
      }
    } else {
      inputResumeTitle = resumeTitle !== "" ? resumeTitle : "";
      const filtereAND = resumeTitle.replaceAll(" ", ",");
      const filtered = filtereAND.replaceAll(" ", ",");
      skillstoHighlight = filtered.split(",");

      const string = inputResumeTitle;
      const res =
        string.toLowerCase().includes("developer") ||
        string.toLowerCase().includes("programmer") ||
        string.toLowerCase().includes("architect") ||
        string.toLowerCase().includes("designer") ||
        string.toLowerCase().includes("consultant") ||
        string.toLowerCase().includes("administrator") ||
        string.toLowerCase().includes("coach") ||
        string.toLowerCase().includes("lead") ||
        string.toLowerCase().includes("manager") ||
        string.toLowerCase().includes("master") ||
        string.toLowerCase().includes("specalist") ||
        string.toLowerCase().includes("modeler") ||
        string.toLowerCase().includes("director") ||
        string.toLowerCase().includes("hacker") ||
        string.toLowerCase().includes("engineer") ||
        string.toLowerCase().includes("analyst") ||
        string.toLowerCase().includes("dba") ||
        string.toLowerCase().includes("tester");

      if (res === true) {
        inputResumeTitle = '"' + string.replace(/"/g, "") + '"';
      }

      sessionStorage.setItem("skillstoHighlight", skillstoHighlight);
    }

    if (
      inputResumeTitle === null ||
      inputResumeTitle === undefined ||
      inputResumeTitle === ""
    ) {
      sessionStorage.removeItem("jobtitle");
      sessionStorage.removeItem("inputjobtitle");
      localStorage.removeItem("jobtitle");
    }

    const inputlocationcity = cityName;

    if (inputlocationcity !== "" && inputResumeTitle !== "") {
      if (fulltime === true && contract === false) {
        var jobtype = "1";
      } else if (contract === true && fulltime === false) {
        jobtype = "10";
      } else if (fulltime === true && contract === true) {
        jobtype = "1 OR 10";
      }
      const searchResumesUrl = `${Key.Solr_url}/${
        Key.solrResumes_collection
      }/select?q=${inputResumeTitle}&fq=${inputlocationcity}&fq=search_flag:(1)&fq=jobtype:(${
        jobtype ? jobtype : "*:*"
      })&rows=10&start=0&sort=created%20desc&wt=json`;
      localStorage.setItem("resumeQuery", searchResumesUrl);

      fetch(`${Key.domain}/solr/solr_resumes`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ url: searchResumesUrl }),
      }).then((response) => {
        response.json().then((resdata) => {
          sessionStorage.setItem("resumeTitle", inputResumeTitle);
          sessionStorage.setItem("resumeCityName", inputlocationcity);
          sessionStorage.setItem(
            "resumesCount",
            resdata.data.response.numFound
          );

          history.push({
            pathname: "/AdvancedResumeSearch",
            state: {
              response: resdata.data.response.docs,
              jobtitle: true,
              location: true,
              fulltime: fulltime,
              contract: contract,
              homepageJobtypesChecked: jobtype,
              skills: skills,
              skillstoHighlight: skillstoHighlight,
            },
          });
        });
      });
    } else if (
      (inputlocationcity === "" || inputlocationcity === null) &&
      inputResumeTitle !== ""
    ) {
      if (fulltime === true && contract === false) {
        var jobtype = "1";
      } else if (contract === true && fulltime === false) {
        jobtype = "10";
      } else if (fulltime === true && contract === true) {
        jobtype = "1 OR 10";
      }
      const searchResumesUrl = `${Key.Solr_url}/${
        Key.solrResumes_collection
      }/select?q=application_title:${inputResumeTitle}&fq=search_flag:(1)&fq=jobtype:(${
        jobtype ? jobtype : "*:*"
      })&rows=10&start=0&sort=created%20desc&wt=json`;
      localStorage.setItem("resumeQuery", searchResumesUrl);
      fetch(`${Key.domain}/solr/solr_resumes`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ url: searchResumesUrl }),
      }).then((response) => {
        response.json().then((resdata) => {
          sessionStorage.setItem("resumeTitle", inputResumeTitle);
          sessionStorage.setItem("resumeCityName", inputlocationcity);
          sessionStorage.setItem(
            "resumesCount",
            resdata.data.response.numFound
          );
          history.push({
            pathname: "/AdvancedResumeSearch",
            state: {
              response: resdata.data.response.docs,
              jobtitle: true,
              location: false,
              fulltime: fulltime,
              contract: contract,
              homepageJobtypesChecked: jobtype,
              skills: skills,
              skillstoHighlight: skillstoHighlight,
            },
          });
        });
      });
    } else if (
      inputlocationcity !== "" &&
      (inputResumeTitle === "" || inputResumeTitle === null)
    ) {
      if (fulltime === true && contract === false) {
        var jobtype = "1";
      } else if (contract === true && fulltime === false) {
        jobtype = "10";
      } else if (fulltime === true && contract === true) {
        jobtype = "1 OR 10";
      }
      const searchResumesUrl = `${Key.Solr_url}/${
        Key.solrResumes_collection
      }/select?&fq=${inputlocationcity}&fq=search_flag:(1)&fq=jobtype:(${
        jobtype ? jobtype : "*:*"
      })&rows=10&start=0&sort=created%20desc&wt=json`;
      localStorage.setItem("resumeQuery", searchResumesUrl);

      fetch(`${Key.domain}/solr/solr_resumes`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ url: searchResumesUrl }),
      }).then((response) => {
        response.json().then((resdata) => {
          sessionStorage.setItem("resumeTitle", inputResumeTitle);
          sessionStorage.setItem("resumeCityName", inputlocationcity);
          sessionStorage.setItem(
            "resumesCount",
            resdata.data.response.numFound
          );

          history.push({
            pathname: "/AdvancedResumeSearch",
            state: {
              response: resdata.data.response.docs,
              jobtitle: false,
              location: true,
              fulltime: fulltime,
              contract: contract,
              homepageJobtypesChecked: jobtype,
              skills: skills,
            },
          });
        });
      });
    } else {
      if (fulltime === true && contract === false) {
        var jobtype = "1";
      } else if (contract === true && fulltime === false) {
        jobtype = "10";
      } else if (fulltime === true && contract === true) {
        jobtype = "1 OR 10";
      }
      const searchResumesUrl = `${Key.Solr_url}/${
        Key.solrResumes_collection
      }/select?q=*%3A*&fq=search_flag:(1)&fq=jobtype:(${
        jobtype ? jobtype : "*:*"
      })&rows=10&start=0&sort=created%20desc&wt=json`;
      localStorage.setItem("resumeQuery", searchResumesUrl);

      fetch(`${Key.domain}/solr/solr_resumes`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ url: searchResumesUrl }),
      }).then((response) => {
        response.json().then((resdata) => {
          // console.log(resdata.data.response.numFound)
          sessionStorage.setItem("resumeTitle", inputResumeTitle);
          sessionStorage.setItem("resumeCityName", inputlocationcity);
          sessionStorage.setItem(
            "resumesCount",
            resdata.data.response.numFound
          );

          history.push({
            pathname: "/AdvancedResumeSearch",
            state: {
              response: resdata.data.response.docs,
              jobtitle: false,
              location: false,
              fulltime: fulltime,
              contract: contract,
              homepageJobtypesChecked: jobtype,
              skills: skills,
            },
          });
        });
      });
    }
  };

  const getJobtitles = (value) => {
    setShowTitleSuggestions(true);
    axios
      .post(`${Key.domain}/Jobs/get_jobtitles`, {
        jobtitle: value,
      })
      .then((response) => {
        //console.log(response.data.data);
        setTitleSuggestions(response.data.data.map((res) => res.title));
      });
  };

  const getCityNames = (value) => {
    setShowSuggestions(true);
    axios
      .post(`${Key.domain}/Jobs/get_joblocations`, {
        statename: value,
      })
      .then((response) => {
        //console.log(response.data.data);
        setCitySuggestions(
          response.data.data.map((res) =>
            res.cityName.concat(" , ", res.stateName)
          )
        );
      });
  };
  const advanceSearchVisaChange = (visatype) => {
    sessionStorage.setItem("advanceresumefiltered-visatypes", visatype);
    getFilteredResumes();
  };

  const advanceSearchJobTypeChange = (jobtype) => {
    //console.log(jobtype);
    sessionStorage.setItem("advanceresumefiltered-jobtypes", jobtype);
    getFilteredResumes();
  };
  const adavanceSearchExpChange = (exp) => {
    sessionStorage.setItem("advanceresumefiltered-exptypes", exp);
    getFilteredResumes();
  };

  const adavanceSearchQualificationsChange = (qualifications) => {
    sessionStorage.setItem(
      "advanceresumefiltered-qualifications",
      qualifications
    );
    getFilteredResumes();
  };

  const advanceSortByChange = (sortByValue) => {
    sessionStorage.setItem("advanceresumefiltered-sortBy", sortByValue);
    getFilteredResumes(sortByValue);
  };

  const advanceSearchPostedDateChange = (value) => {
    if (value == 1) {
      //this.setState({ dateValue: value })
      localStorage.setItem("resumeDateValue", JSON.stringify(value));
      localStorage.setItem("resumeFileterChecked", JSON.stringify(value));
      localStorage.setItem("resumeSortByDate", "resumeSortByDate");

      const today = new Date();
      var startdate = moment(today)
        .subtract(1, "d")
        .format("YYYY-MM-DD hh:mm:ss");
      var convertedStartDate = moment.utc(moment(startdate)).format();
      var mappeddate = convertedStartDate + " TO " + "NOW";
      //console.log(mappeddate)
      sessionStorage.setItem("advanceresumefiltered-mappedDates", mappeddate);

      getFilteredResumes();
    } else if (value == 2) {
      //  this.setState({ dateValue: value })
      localStorage.setItem("resumeDateValue", JSON.stringify(value));
      localStorage.setItem("resumeFileterChecked", JSON.stringify(value));
      localStorage.setItem("resumeSortByDate", "resumeSortByDate");

      const today = new Date();
      var startdate = moment(today)
        .subtract(7, "d")
        .format("YYYY-MM-DD hh:mm:ss");
      var convertedStartDate = moment.utc(moment(startdate)).format();
      var mappeddate = convertedStartDate + " TO " + "NOW";
      //console.log(mappeddate)

      sessionStorage.setItem("advanceresumefiltered-mappedDates", mappeddate);

      getFilteredResumes();
    } else if (value == 3) {
      // this.setState({ dateValue: value })
      localStorage.setItem("resumeDateValue", JSON.stringify(value));
      localStorage.setItem("resumeFileterChecked", JSON.stringify(value));
      localStorage.setItem("resumeSortByDate", "resumeSortByDate");

      const today = new Date();
      var startdate = moment(today)
        .subtract(30, "d")
        .format("YYYY-MM-DD hh:mm:ss");
      var convertedStartDate = moment.utc(moment(startdate)).format();
      var mappeddate = convertedStartDate + " TO " + "NOW";
      // console.log(mappeddate)

      sessionStorage.setItem("advanceresumefiltered-mappedDates", mappeddate);

      getFilteredResumes();
    } else if (value == 4) {
      //  this.setState({ dateValue: value })
      localStorage.setItem("resumeDateValue", JSON.stringify(value));
      localStorage.setItem("resumeFileterChecked", JSON.stringify(value));
      localStorage.setItem("resumeSortByDate", "resumeSortByDate");

      const today = new Date();
      var startdate = moment(today)
        .subtract(90, "d")
        .format("YYYY-MM-DD hh:mm:ss");
      var convertedStartDate = moment.utc(moment(startdate)).format();
      var mappeddate = convertedStartDate + " TO " + "NOW";
      //console.log(mappeddate)

      sessionStorage.setItem("advanceresumefiltered-mappedDates", mappeddate);

      getFilteredResumes();
    } else if (value == 5) {
      //  this.setState({ dateValue: value })
      localStorage.setItem("resumeDateValue", JSON.stringify(value));
      localStorage.setItem("resumeFileterChecked", JSON.stringify(value));
      localStorage.setItem("resumeSortByDate", "resumeSortByDate");

      const today = new Date();
      var startdate = moment(today)
        .subtract(180, "d")
        .format("YYYY-MM-DD hh:mm:ss");
      var convertedStartDate = moment.utc(moment(startdate)).format();
      var mappeddate = convertedStartDate + " TO " + "NOW";
      //console.log(mappeddate)

      sessionStorage.setItem("advanceresumefiltered-mappedDates", mappeddate);

      getFilteredResumes();
    } else {
      //  this.setState({ dateValue: "" })
      localStorage.setItem("resumeDateValue", JSON.stringify(value));
      localStorage.setItem("resumeFileterChecked", JSON.stringify(value));
      localStorage.removeItem("resumeSortByDate");

      var mappeddate = "*" + " TO " + "NOW";
      //console.log(mappeddate)

      sessionStorage.setItem("advanceresumefiltered-mappedDates", mappeddate);

      getFilteredResumes();
    }
  };

  const handleChangeJobtype = (e) => {
    console.log(e);

    setJobTypeId(Array.isArray(e) ? e.map((x) => x.value) : []);

    var multipleJobtypes = jobTypeId.map((fid) => `${fid}`).join(" OR ");
    sessionStorage.setItem("advanceresumefiltered-jobtypes", multipleJobtypes);
    getFilteredResumes();
  };

  const handleChangeVisatype = (e) => {
    setVisatypeId(Array.isArray(e) ? e.map((x) => x.value) : []);
    setVisatypeNames(Array.isArray(e) ? e.map((x) => x.label) : []);

    var multipleVisatypes = visatypeNames.map((fid) => `${fid}`).join(" OR ");
    sessionStorage.setItem(
      "advanceresumefiltered-visatypes",
      multipleVisatypes
    );
    getFilteredResumes();
  };

  const getFilteredResumes = (sortValue) => {
    props.handlePaginationRefresh();

    var jobtype = sessionStorage.getItem("advanceresumefiltered-jobtypes");
    var visatype = sessionStorage.getItem("advanceresumefiltered-visatypes");
    var qualifications = sessionStorage.getItem(
      "advanceresumefiltered-qualifications"
    );
    var experience = sessionStorage.getItem("advanceresumefiltered-exptypes");
    var resumeTitle =
      sessionStorage.getItem("resumeTitle") !== null &&
      sessionStorage.getItem("resumeTitle") !== ""
        ? sessionStorage.getItem("resumeTitle")
        : sessionStorage.getItem("Selectedjsondata") !== null &&
          sessionStorage.getItem("Selectedjsondata") !== ""
        ? JSON.parse(sessionStorage.getItem("Selectedjsondata")).JobRole
        : "";
    var Mappeddates = sessionStorage.getItem(
      "advanceresumefiltered-mappedDates"
    );
    var filteredResumeIds =
      sessionStorage.getItem("AdvanceResumeIds") !== null
        ? sessionStorage.getItem("AdvanceResumeIds")
        : "*%3A*";
    var advancedSkills = sessionStorage.getItem("advancedSearch-skills");
    var willing_to_relocate = sessionStorage.getItem(
      "advanceresumefiltered-willingToRelocate"
    );
    if (sortValue === "recent") {
      var filterResumesUrl = `${Key.Solr_url}/${
        Key.solrResumes_collection
      }/select?q=${
        resumeTitle ? resumeTitle : "*%3A*"
      }&fq=search_flag:(1)&fq=jobtype:(${
        jobtype ? jobtype : "*:*"
      })&fq=res_visatypeName:(${
        visatype ? visatype : "*:*"
      })&fq=total_experience:(${
        experience ? experience : "*:*"
      })&fq=qualificationTitle:(${
        qualifications ? qualifications : "*:*"
      })&fq=willing_to_relocate:(${
        willing_to_relocate === "1" ? "1" : "*:*"
      })&fq=resume_string:(${advancedSkills ? advancedSkills : "*:*"})&fq=(${
        advancedSkills ? advancedSkills : "*:*"
      })&fq=created:[${
        Mappeddates !== null ? Mappeddates : "* TO *"
      }]&rows=10&start=${0}&sort=lastmodified%20desc,id%20asc&wt=json`;
    } else if (sortValue === "relavance") {
      filterResumesUrl = `${Key.Solr_url}/${
        Key.solrResumes_collection
      }/select?q=${
        resumeTitle ? resumeTitle : "*%3A*"
      }&fq=search_flag:(1)&fq=jobtype:(${
        jobtype ? jobtype : "*:*"
      })&fq=res_visatypeName:(${
        visatype ? visatype : "*:*"
      })&fq=total_experience:(${
        experience ? experience : "*:*"
      })&fq=qualificationTitle:(${
        qualifications ? qualifications : "*:*"
      })&fq=willing_to_relocate:(${
        willing_to_relocate === "1" ? "1" : "*:*"
      })&fq=resume_string:(${advancedSkills ? advancedSkills : "*:*"})&fq=(${
        advancedSkills ? advancedSkills : "*:*"
      })&fq=created:[${
        Mappeddates !== null ? Mappeddates : "* TO *"
      }]&rows=10&start=${0}&wt=json`;
    } else {
      filterResumesUrl = `${Key.Solr_url}/${
        Key.solrResumes_collection
      }/select?fq=id:(${filteredResumeIds})&fq=search_flag:(1)&fq=jobtype:(${
        jobtype ? jobtype : "*:*"
      })&fq=res_visatypeName:(${
        visatype ? visatype : "*:*"
      })&fq=total_experience:(${
        experience ? experience : "*:*"
      })&fq=qualificationTitle:(${
        qualifications ? qualifications : "*:*"
      })&fq=willing_to_relocate:(${
        willing_to_relocate === "1" ? "1" : "*:*"
      })&fq=resume_string:(${advancedSkills ? advancedSkills : "*:*"})&fq=(${
        advancedSkills ? advancedSkills : "*:*"
      })&fq=created:[${
        Mappeddates !== null ? Mappeddates : "* TO *"
      }]&rows=10&start=${0}&sort=lastmodified%20desc,id%20asc&wt=json`;
    }

    //console.log(filterResumesUrl)
    axios
      .post(
        `${Key.domain}/solr/solr_resumes`,
        { url: filterResumesUrl },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((resdata) => {
        //console.log(resdata);
        const retrivedData = resdata.data.data.response.docs;
        //console.log(resdata.data.data.response.numFound);
        sessionStorage.setItem(
          "resumesCount",
          resdata.data.data.response.numFound
        );
        props.history.push({
          pathname: "/AdvancedResumeSearch",
          state: {
            response: retrivedData,
            jobtitle: true,
            location: false,
            filterChecked: true,
          },
        });
      });
  };

  // setResumeTitle(resumeTitle + skillTitles)

  const adavanceSearchRelocateChange = (Relocate) => {
    sessionStorage.setItem("advanceresumefiltered-willingToRelocate", Relocate);
    getFilteredResumes();
  };
  return (
    <div>
      <div className="Search__bg">
        <Container>
          <div className="jsmain_body">
            <Grid container spacing={2}>
              <Grid item sm={8} md={8} lg={8} xs={8}>
                <h2 className="js_title">2,000,000 + RESUMES </h2>
              </Grid>
              <Grid sm={4} md={4} lg={4} xs={4}>
                {/* <span>Advanced Search</span> */}
              </Grid>
              <div class="Searchblocks find-resumes">
                <form autocomplete="on">
                  <Grid container spacing={2}>
                    <Grid sm={12} md={12} lg={4}>
                      <input
                        type="text"
                        class="form-control"
                        onBlur={(e) => {
                          setTimeout(() => {
                            setShowTitleSuggestions(false);
                          }, [300]);
                        }}
                        value={
                          sessionStorage.getItem("inputResumeTitle") !== null &&
                          sessionStorage.getItem("inputResumeTitle") !==
                            undefined
                            ? sessionStorage.getItem("inputResumeTitle")
                            : ""
                        }
                        placeholder="Resume title, Skill"
                        onChange={(e) => {
                          setResumeTitle(e.target.value);
                          getJobtitles(e.target.value);
                          searchResContext.updateSearchResumeTitle(
                            e.target.value
                          );
                          sessionStorage.setItem(
                            "inputResumeTitle",
                            e.target.value
                          );
                        }}
                      />
                      {showTitleSuggestions === true ? (
                        <ul className="sm-12 col-md-4 col-lg-3 suggestionList">
                          {titleSuggestions.map((suggestion) => (
                            <li
                              onClick={() => {
                                setResumeTitle('"' + suggestion + '"');
                                //console.log(resumeTitle);
                                setShowTitleSuggestions(false);
                                sessionStorage.setItem(
                                  "inputResumeTitle",
                                  suggestion
                                );
                              }}
                            >
                              {suggestion}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        ""
                      )}
                    </Grid>

                    <Grid sm={12} md={12} lg={4}>
                      <div class="form-group form-group-2">
                        <input
                          type="text"
                          class="form-control"
                          value={cityName}
                          id="exampleInputPassword1"
                          placeholder="Location"
                          onBlur={(e) => {
                            setTimeout(() => {
                              setShowSuggestions(false);
                            }, [300]);
                          }}
                          onChange={(e) => {
                            setCityName(e.target.value);
                            getCityNames(e.target.value);
                            sessionStorage.setItem(
                              "resumeCityName",
                              e.target.value
                            );
                          }}
                        />
                      </div>
                      {showSuggestions === true ? (
                        <ul className="sm-12 col-md-4 mt-0 col-lg-3 suggestionList">
                          {citySuggestions.map((suggestion) => (
                            <li
                              onClick={() => {
                                setCityName(suggestion);
                                setShowSuggestions(false);
                              }}
                            >
                              {suggestion}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid sm={12} lg={2} md={12} className="select-radius">
                      <select class="form-select form-control mt-1">
                        <option>Select Radius</option>
                        <option>10 Miles</option>
                        <option>20 Miles</option>
                        <option>30 Miles</option>
                        <option>40 Miles</option>
                        <option>50 Miles</option>
                      </select>
                    </Grid>

                    <Grid
                      sm={12}
                      md={12}
                      lg={2}
                      className="find-resumes-button"
                    >
                      <button
                        type="button"
                        class="btn btn-button "
                        id="_button"
                        onClick={submit}
                      >
                        Find Resumes
                      </button>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </Grid>
            <br />
            <div className="row">
              <div className="col-lg-12 dropdown-buttons">
                <ul className="buttons-list">
                  <li>
                    {/* <Form.Select
                      aria-label="Default select example"
                      onChange={(e) => advanceSortByChange(e.target.value)}
                    >
                      <option>Sort By </option>
                      <option value="recent">Recent</option>
                      <option value="relavance">Relavance</option>
                    </Form.Select> */}

                    <FormControl fullWidth>
                      <InputLabel id="posted-date-label">Sort By</InputLabel>
                      <Select
                        labelId="posted-date-label"
                        id="posted-date"
                        onChange={(e) => advanceSortByChange(e.target.value)}
                        label="Posted Date"
                      ></Select>
                      <MenuItem value="">Sort By</MenuItem>
                      <MenuItem value="recent">Recent</MenuItem>
                      <MenuItem value="relavance">relavance</MenuItem>
                    </FormControl>
                  </li>
                  <li className="select-job-type">
                    <Select
                      isMulti
                      closeMenuOnSelect={false}
                      name="Jobtype"
                      placeholder="Jobtype"
                      options={jobtypes}
                      className={"custom-jobtype-select"}
                      onChange={handleChangeJobtype}
                      value={jobtypes.filter((obj) =>
                        jobTypeId.includes(obj.value)
                      )}
                    ></Select>
                  </li>
                  <li>
                    <FormControl fullWidth>
                      <InputLabel id="posted-date-label">
                        Posted Date
                      </InputLabel>
                      <Select
                        labelId="posted-date-label"
                        id="posted-date"
                        onChange={(e) =>
                          advanceSearchPostedDateChange(e.target.value)
                        }
                        label="Posted Date"
                      >
                        <MenuItem value="">Posted Date</MenuItem>
                        <MenuItem value="1">Within a Day</MenuItem>
                        <MenuItem value="2">Within a Week</MenuItem>
                        <MenuItem value="3">Within a Month</MenuItem>
                        <MenuItem value="4">Within 3 Months</MenuItem>
                        <MenuItem value="5">Within 6 Months</MenuItem>
                        <MenuItem value="6">Any time</MenuItem>
                      </Select>
                    </FormControl>
                    {/* <Form.Select
                      aria-label="Default select example"
                      onChange={(e) =>
                        advanceSearchPostedDateChange(e.target.value)
                      }
                    >
                      <option>Posted Date</option>
                      <option value="1">Within a Day</option>
                      <option value="2">Within a Week</option>
                      <option value="3">Within a Month</option>
                      <option value="4">Within 3 Months</option>
                      <option value="5">Within 6 Months</option>
                      <option value="6">Any time</option>
                    </Form.Select> */}
                  </li>
                  <li>
                    <Select
                      isMulti
                      closeMenuOnSelect={false}
                      name="visaType"
                      placeholder="VisaType"
                      options={visatypes}
                      className={"custom-visaType-select"}
                      onChange={handleChangeVisatype}
                      value={visatypes.filter((obj) =>
                        visatypeId.includes(obj.value)
                      )}
                    ></Select>
                  </li>
                  <li>
                    {/* <Form.Select
                      aria-label="Default select example"
                      onChange={(e) => adavanceSearchExpChange(e.target.value)}
                    >
                      <option>Experience</option>
                      <option value="0 OR fresher OR 1 OR 2 OR 3">
                        0-3 Years
                      </option>
                      <option value="4 OR 5 OR 6">4-6 Years</option>
                      <option value="7 OR 8 OR 9 OR 10">7-10 Years</option>
                      <option value="11 OR 12 OR 13 OR 14 OR 15 OR 16 OR 17 OR 18 OR 19 OR 20 OR 21 OR 22 OR 23 OR 24 OR 25 OR 26 OR 27 OR 28 OR 29 OR 30 OR 31">
                        Above 10 years
                      </option>
                    </Form.Select> */}
                    <FormControl fullWidth>
                      <InputLabel id="posted-date-label">Experience</InputLabel>
                      <Select
                        labelId="posted-date-label"
                        id="posted-date"
                        onChange={(e) =>
                          adavanceSearchExpChange(e.target.value)
                        }
                        label="Posted Date"
                      ></Select>

                      <MenuItem value="">Experience</MenuItem>
                      <MenuItem value="4 OR 5 OR 6">7 - 10 Years</MenuItem>
                      <MenuItem value="11 OR 12 OR 13 OR 14 OR 15 OR 16 OR 17 OR 18 OR 19 OR 20 OR 21 OR 22 OR 23 OR 24 OR 25 OR 26 OR 27 OR 28 OR 29 OR 30 OR 31">
                        Above 10 Years
                      </MenuItem>
                    </FormControl>
                  </li>
                  <li>
                    {/* <Form.Select
                      aria-label="Default select example"
                      onChange={(e) =>
                        adavanceSearchQualificationsChange(e.target.value)
                      }
                    >
                      <option>Qualification</option>
                      <option value="1">Bachelors Degree</option>
                      <option value="2">Masters</option>
                      <option value="3">Diploma-Engineering</option>
                      <option value="4">PG Diploma</option>
                      <option value="5">PhD</option>
                    </Form.Select> */}

                    <FormControl fullWidth>
                      <InputLabel id="posted-date-label">
                        Qualification
                      </InputLabel>
                      <Select
                        labelId="posted-date-label"
                        id="posted-date"
                        onChange={(e) =>
                          adavanceSearchQualificationsChange(e.target.value)
                        }
                        label="Posted Date"
                      ></Select>
                      <MenuItem value="">Qualification</MenuItem>
                      <MenuItem value="1">Bachelors Degree</MenuItem>
                      <MenuItem value="2">Masters</MenuItem>
                      <MenuItem value="3">Diploma-Engineering</MenuItem>
                      <MenuItem value="4">PG Diploma</MenuItem>
                      <MenuItem value="5">PhD</MenuItem>
                    </FormControl>
                  </li>
                  <li>
                    {/* <Form.Select
                      aria-label="Default select example"
                      onChange={(e) =>
                        adavanceSearchRelocateChange(e.target.value)
                      }
                    >
                      <option>Willing to Relocate</option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </Form.Select> */}

<FormControl fullWidth>
                      <InputLabel id="posted-date-label">
                      Willing to Relocate
                      </InputLabel>
                      <Select
                        labelId="posted-date-label"
                        id="posted-date"
                        onChange={(e) =>
                          adavanceSearchRelocateChange(e.target.value)
                        }
                        label="Posted Date"
                      >


                      </Select>

                      <MenuItem value="">Willing to Relocate</MenuItem>
                      <MenuItem value="1">yes</MenuItem>
                      <MenuItem value="0">No</MenuItem>

                      </FormControl>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.UserReducer.user,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Jssearchblock)
);
