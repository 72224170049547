import axios from "axios";
import Key from '../clientVariables.json';
import moment from "moment";

const removeItems = [
    "skill", "technology", "developer", "programmer", "architect", "designer",
    "consultant", "administrator", "coach", "lead", "manager", "master",
    "specalist", "modeler", "director", "hacker", "engineer", "analyst",
    "dba", "tester", "senior", "sr", "junior", "jr", "knowledge", "or",
    "with", "to", "and", "front", "end", "back"
  ];

  function splitBySpecialCharacters(input) {
    const specialCharRegex = /[^a-zA-Z0-9\s]+/;
    const parts = input.split(specialCharRegex);
    const cleanedParts = parts.map(part => part.trim()).filter(part => part !== '');
    return cleanedParts;
  }

  // function processSearchTitle(title) {
  //   const wordRegex = /[^\W\d_]+/g;
  //   const words = title.match(wordRegex);
  
  //   if (words) {
  //     const filteredWords = words.filter(
  //       (word) => isNaN(word) && !removeItems.includes(word.toLowerCase())
  //     );
  //     const splittingSpecialCharacter = splitBySpecialCharacters(title);
  //     const finalTitleWords = filteredWords.concat(splittingSpecialCharacter)
  //                                          .map(word => `"${word}"`)
  //                                          .join(" OR ");
  //     return finalTitleWords;
  //   } else {
  //     return title;
  //   }
  // }
  function processSearchTitle(title) {
    const wordRegex = /[^\W\d_]+/g;
    const words = title.match(wordRegex);
    // console.log('processSearchTitle words', words);
  
    if (words) {
      const filteredWords = words.filter(
        (word) => isNaN(word) && !removeItems.includes(word.toLowerCase())
      );
      const splittingSpecialCharacter = splitBySpecialCharacters(title);
    // console.log('processSearchTitle splittingSpecialCharacter', splittingSpecialCharacter);
  
      const finalTitleWords = filteredWords.concat(splittingSpecialCharacter)
                                          //  .map(word => `"${word}"`)
                                          //  .join(" OR ");
      const finalFilter = finalTitleWords.filter((word) =>  !removeItems.includes(word.toLowerCase()))
                                          .map(word => `"${word}"`)
                                          .join(" OR ");
    // console.log('processSearchTitle finalTitleWords', finalTitleWords, finalFilter);
  
      return finalFilter;
    } else {
      return title;
    }
  }

  
function buildLocationFilter(location) {
  let locationFilter = "";
  if (location && location.includes("L_O_C")) {
    const multipleLocations = location.split(" L_O_C ");
    let shortRegion_ = [];
    let city_ = [];
    let state_ = [];

    multipleLocations.map((location) => {
      let loc = location.split(/[,]+/);
      if (loc.length > 1) {
        const city = loc[0].trim();
        const regionOrState = loc[1].trim();
        if (regionOrState.length === 2) {
          shortRegion_.push(`"${regionOrState}"`);
          city_.push(`"${city}"`);
        } else {
          state_.push(`"${regionOrState}"`);
          city_.push(`"${city}"`);
        }
      } else {
        const singleLocation = loc[0].trim();
        if (singleLocation.length === 2) {
          shortRegion_.push(`"${singleLocation}"`);
        } else {
          city_.push(`"${singleLocation}"`);
        }
      }
    });

    locationFilter = `${city_.length > 0 ? `&fq=res_cityName:(${city_.join(" OR ")})` : ""}${state_.length > 0 ? `&fq=res_stateName:(${state_.join(" OR ")})` : ""}`;
  } else if (location) {
    const loc = location.split(/[,]+/);
    if (loc.length > 1) {
      const city = loc[0].trim();
      const regionOrState = loc[1].trim();
      if (regionOrState.length === 2) {
        locationFilter = `&fq=shortRegion:(${regionOrState})&fq=res_cityName:(${city})`;
      } else {
        locationFilter = `&fq=res_stateName:(${regionOrState})&fq=res_cityName:(${city})`;
      }
    } else {
      const singleLocation = loc[0].trim();
      if (singleLocation.length === 2) {
        locationFilter = `&fq=shortRegion:(${singleLocation})`;
      } else {
        locationFilter = `&fq=res_cityName:(${singleLocation})`;
      }
    }
  }

  return locationFilter;
}

function getMappedDates(dateOption) {
  const today = new Date();
  let startdate;

  if (dateOption == "1") {
    startdate = moment(today).subtract(1, "d").utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
  } else if (dateOption == "2") {
    startdate = moment(today).subtract(7, "d").utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
  } else if (dateOption == "3") {
    startdate = moment(today).subtract(30, "d").utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
  } else {
    startdate = null;
  }

  return startdate ? `${startdate} TO NOW` : null;
}


 const getFilterCounts = (field, searchParams, filterParams) => {
    let searchQuery = [];
  const solrBaseUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select`;
  let query = `rows=0&fq=search_flag:(1)&wt=json`;


  if (searchParams.resumeTitle) {
    let ctitle  = searchParams?.resumeTitle?.toLowerCase();
    if(ctitle.includes("c++")){
      let splitting = []
      ctitle.split(",").map(value => splitting.push(`"${value}"`))
      searchQuery.push(`application_title:(${splitting.join(" OR ")})`);
    }
    else {
      const searchText = processSearchTitle(searchParams.resumeTitle);
      searchQuery.push(`application_title:(${searchText})`);
    }
 
  }

  if (searchParams.res_stateName) {
    const searchText = searchParams.res_stateName.split(" OR ").map((part) => `"${part}"`).join(" OR ");
    searchQuery.push(`res_stateName:(${searchText})`);
  }

  
  Object.entries(searchParams).forEach(([key, value]) => {
    if (key !== 'resumeTitle' && key !== 'res_stateName' && value) {
      searchQuery.push(`${key}:${value}`);
    }
  });

  
  if (searchQuery.length === 0) {
    searchQuery.push('*:*');
  }

  query = `q=${searchQuery.join(" AND ")}&${query}`;

  // Apply filterParams
  const filterQueries = Object.entries(filterParams).map(
    ([key, value]) => {
      if (key === 'lastmodified') {
        const dateRange = getMappedDates(value);
        return dateRange ? `&fq=${key}:[${dateRange}]` : '';
      } else {
        return `&fq=${key}:(${value})`;
      }
    }
  );

  
  if (filterQueries.length > 0) {
    query += filterQueries.join("");
  }

  
  if (searchParams.location) {
    query += buildLocationFilter(searchParams.location);
  }



  query = `facet.field=${field}&facet.mincount=1&facet.sort=count&facet=true&${query}`;

  // facet.field=jobtype&facet=true

    console.log(query);

  return `${solrBaseUrl}?${query}`;

  }

 export const getSolrFilterCountsByURL = async(field, searchParams, filterParams) => {
    const url = getFilterCounts(field, searchParams, filterParams);
    try {
        const response = await axios.post(`${Key.domain}/solr/solr_resumes`, {
          url: url,
      });
      if(response.status === 200){
        const data = response.data;
      const jobtypeResponseData = data?.data?.facet_counts?.facet_fields || [];
      const finalData = jobtypeResponseData[field];
      console.log("Job Type Data: 1", finalData);
      const result = {}; 


for (let i = 0; i < finalData.length; i += 2) {
    const key = finalData[i];
    const value = finalData[i + 1];
    result[key] = value;
}


console.log('Job Type Data: 2',result);
return result;
// const combinedData = [];


// jobTypesData && jobTypesData.forEach(item => {
//   if(item.id !== undefined){
//     const key = item.id.toString(); // Convert id to string to match keys in data1
//     if (result.hasOwnProperty(key)) {
//         combinedData.push({
//             id: item.id,
//             title: item.title,
//             value: result[key]
//         });
//     }}
// });

// setJobTypesData(combinedData);
      }
    } catch (error) {
      console.error(error);
    }
  
  }
