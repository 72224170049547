import React, { Component } from "react";
import "../../Styles/loading.css";
import { HashLoader } from "react-spinners";

class PageLoader extends Component {
    state = {};
    render() {
       

        return (
            <div className="loading-container">
                <div className="loading">
                    <HashLoader
                        height='25'
                        width='5'
                        color='#406882'
                        loading={true} size={50} />
                </div>
            </div>
        );
    }
}



export default (PageLoader);