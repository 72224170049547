import DashboardService from "../services/dashboard.service";
import * as types from "../constant/actionTypes";



class DashboardController {

    constructor() {
        this.dashboardService = DashboardService;

    }
    //CRUD METHODS
    updateFileResumeDetails(profiledetails,jobtypeIds, resume_id, uid, session_token) {
        return (dispatch) => {
            return this.dashboardService.updateCandResumeDetails(profiledetails,jobtypeIds,resume_id, uid, session_token)
                .then(res => {
                    return this.dashboardService.getmyfilesResumeDetails(resume_id, uid, session_token).then(response => {
                        dispatch(this.getmyfilesResumeDetailsSuccess(response));
                        dispatch(this.updateFileResumeDetailsSuccess(res));
                        // var load = false;
                        // dispatch(this.setmyFileLoading(load))
                    })
                })
                .catch(error => {
                    throw error;
                });
        };

    }

    updateResumeContactDetails(contactdetails, resume_id, uid, session_token) {
        return (dispatch) => {
            return this.dashboardService.updateResumeContactDetails(contactdetails, resume_id, uid, session_token)
                .then(res => {
                    return this.dashboardService.getmyfilesResumeDetails(resume_id, uid, session_token).then(response => {
                        dispatch(this.getmyfilesResumeDetailsSuccess(response));
                        dispatch(this.updateFileResumeDetailsSuccess(res));
                        // var load = false;
                        // dispatch(this.setmyFileLoading(load))
                    })
                })
                .catch(error => {
                    throw error;
                });
        };

    }


    updateResumeCareerDetails(careerDetails, resume_id, uid, session_token) {
        return (dispatch) => {
            return this.dashboardService.updateResumeCareerDetails(careerDetails, resume_id, uid, session_token)
                .then(res => {
                    return this.dashboardService.getmyfilesResumeDetails(resume_id, uid, session_token).then(response => {
                        dispatch(this.getmyfilesResumeDetailsSuccess(response));
                        dispatch(this.updateFileResumeDetailsSuccess(res));
                        // var load = false;
                        // dispatch(this.setmyFileLoading(load))
                    })
                })
                .catch(error => {
                    throw error;
                });
        };

    }

    updateResumeSummaryDetails(summary, resume_id, uid, session_token) {
        return (dispatch) => {
            return this.dashboardService.updateResumeSummaryDetails(summary, resume_id, uid, session_token)
                .then(res => {
                    return this.dashboardService.getmyfilesResumeDetails(resume_id, uid, session_token).then(response => {
                        dispatch(this.getmyfilesResumeDetailsSuccess(response));
                        dispatch(this.updateFileResumeDetailsSuccess(res));
                        // var load = false;
                        // dispatch(this.setmyFileLoading(load))
                    })
                })
                .catch(error => {
                    throw error;
                });
        };

    }


    updateResumeSkills(skills, resume_id, uid, session_token) {
        return (dispatch) => {
            return this.dashboardService.updateResumeSkills(skills, resume_id, uid, session_token)
                .then(res => {
                    return this.dashboardService.getmyfilesResumeDetails(resume_id, uid, session_token).then(response => {
                        dispatch(this.getmyfilesResumeDetailsSuccess(response));
                        dispatch(this.updateFileResumeDetailsSuccess(res));
                        // var load = false;
                        // dispatch(this.setmyFileLoading(load))
                    })
                })
                .catch(error => {
                    throw error;
                });
        };

    }


    addresumeProjects(projects, resume_id, uid, session_token) {
        return (dispatch) => {
            return this.dashboardService.addResumeProjectDetails(projects, resume_id, uid, session_token)
                .then(res => {
                    return this.dashboardService.getmyfilesResumeDetails(resume_id, uid, session_token).then(response => {
                        dispatch(this.getmyfilesResumeDetailsSuccess(response));
                        dispatch(this.updateFileResumeDetailsSuccess(res));
                        // var load = false;
                        // dispatch(this.setmyFileLoading(load))
                    })
                })
                .catch(error => {
                    throw error;
                });
        };

    }

    updateResumeProjects(projects, projectid, resume_id, uid, session_token) {
        return (dispatch) => {
            return this.dashboardService.updateResumeProjectDetails(projects, projectid, resume_id, uid, session_token)
                .then(res => {
                    return this.dashboardService.getmyfilesResumeDetails(resume_id, uid, session_token).then(response => {
                        dispatch(this.getmyfilesResumeDetailsSuccess(response));
                        dispatch(this.updateFileResumeDetailsSuccess(res));
                        // var load = false;
                        // dispatch(this.setmyFileLoading(load))
                    })
                })
                .catch(error => {
                    throw error;
                });
        };

    }

    addresumeProjects(projects, resume_id, uid, session_token) {
        return (dispatch) => {
            return this.dashboardService.addResumeProjectDetails(projects, resume_id, uid, session_token)
                .then(res => {
                    return this.dashboardService.getmyfilesResumeDetails(resume_id, uid, session_token).then(response => {
                        dispatch(this.getmyfilesResumeDetailsSuccess(response));
                        dispatch(this.updateFileResumeDetailsSuccess(res));
                        // var load = false;
                        // dispatch(this.setmyFileLoading(load))
                    })
                })
                .catch(error => {
                    throw error;
                });
        };

    }


    addEducationDetails(educationdetails, resume_id, education_id, uid, session_token) {
        return (dispatch) => {
            return this.dashboardService.addResumeEducationDetails(educationdetails, resume_id, education_id, uid, session_token)
                .then(res => {
                    return this.dashboardService.getmyfilesResumeDetails(resume_id, uid, session_token).then(response => {
                        dispatch(this.getmyfilesResumeDetailsSuccess(response));
                        dispatch(this.updateFileResumeDetailsSuccess(res));
                        // var load = false;
                        // dispatch(this.setmyFileLoading(load))
                    })
                })
                .catch(error => {
                    throw error;
                });
        };

    }

    deleteResumeEducation(education_id, resume_id, user_id, session_token) {
        return (dispatch) => {
            return this.dashboardService.deleteResumeEducation(education_id, resume_id, user_id, session_token)
                .then(res => {
                    return this.dashboardService.getmyfilesResumeDetails(resume_id, user_id, session_token).then(response => {
                        dispatch(this.getmyfilesResumeDetailsSuccess(response));
                        dispatch(this.updateFileResumeDetailsSuccess(res));
                        // var load = false;
                        // dispatch(this.setmyFileLoading(load))
                    })
                })
                .catch(error => {
                    throw error;
                });
        };

    }


    deleteResumeCertificates(certificate_id, resume_id, user_id, session_token) {
        return (dispatch) => {
            return this.dashboardService.deleteResumeCertificate(certificate_id, resume_id, user_id, session_token)
                .then(res => {
                    return this.dashboardService.getmyfilesResumeDetails(resume_id, user_id, session_token).then(response => {
                        dispatch(this.getmyfilesResumeDetailsSuccess(response));
                        dispatch(this.updateFileResumeDetailsSuccess(res));
                        // var load = false;
                        // dispatch(this.setmyFileLoading(load))
                    })
                })
                .catch(error => {
                    throw error;
                });
        };
    }

    addCertificateDetails(certificatedetails, resume_id, certificateid, uid, session_token) {
        return (dispatch) => {
            return this.dashboardService.addResumeCertificateDetails(certificatedetails, resume_id, certificateid, uid, session_token)
                .then(res => {
                    return this.dashboardService.getmyfilesResumeDetails(resume_id, uid, session_token).then(response => {
                        dispatch(this.getmyfilesResumeDetailsSuccess(response));
                        dispatch(this.updateFileResumeDetailsSuccess(res));
                        // var load = false;
                        // dispatch(this.setmyFileLoading(load))
                    })
                })
                .catch(error => {
                    throw error;
                });
        };

    }



    getmyfilesResumeDetailsSuccess(response) {
        return { type: types.GET_MYFILE_RESUME_DETAILS, payload: response }
    }


    updateFileResumeDetailsSuccess(res) {
        return { type: types.UPDATE_FILEPROFILE_SUCCESS, payload: res }

    }


    getmyfilesResumeDetails(id, uid, session_token) {
        return (dispatch) => {
            return this.dashboardService.getmyfilesResumeDetails(id, uid, session_token)
                .then(response => {
                    // console.log(response)
                    dispatch(this.getmyfilesResumeDetailsSuccess(response));
                    var load = false;
                    dispatch(this.setmyFileLoading(load))
                })
                .catch(error => {
                    throw error;
                });

        }
    }


    deleteResumeProjects(projectid, resume_id, user_id, session_token) {
        console.log(projectid, resume_id, user_id, session_token)
        return (dispatch) => {
            return this.dashboardService.deleteProject(projectid, resume_id, user_id, session_token)
                .then(res => {
                    return this.dashboardService.getmyfilesResumeDetails(resume_id, user_id, session_token).then(response => {
                        dispatch(this.getmyfilesResumeDetailsSuccess(response));
                        dispatch(this.updateFileProjectSuccess(res))
                    })


                }).catch(error => {
                    throw error
                })
        }

    }

    setmyFileLoading1(response) {
        return (dispatch) => {
            dispatch(this.setmyFileLoading(response))
        }

    }

    setmyFileLoading(response) {

        return { type: types.MYFILE_LOADING, payload: response }
    }

}

export default new DashboardController();
