

// import { GET_RESUME_FAILURE, GET_RESUME_SUCCESS, UPDATE_RESUME } from "../Components/Actions/primaryResumeActions";

// const initialState = {
//   resume: {},
//   error: null,
// };

// const primaryResumeReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case UPDATE_RESUME:
//       return {
//         ...state,
//         resume: action.payload,
//       };

//     case GET_RESUME_SUCCESS:
//       return {
//         ...state,
//         resume: action.payload,
//         error: null,
//       };

//     case GET_RESUME_FAILURE:
//       return {
//         ...state,
//         error: action.payload,
//       };

//     default:
//       return state;
//   }
// };

// export default primaryResumeReducer;

// primaryResumeReducer.js

import {
  GET_RESUME_FAILURE,
  GET_RESUME_SUCCESS,
  UPDATE_RESUME_REQUEST,
  UPDATE_RESUME_SUCCESS,
  UPDATE_RESUME_FAILURE,
} from "../Components/Actions/primaryResumeActions";

const initialState = {
  resume: {},
  loading: false,
  error: null,
};

const primaryResumeReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_RESUME_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case UPDATE_RESUME_SUCCESS:
      return {
        ...state,
        resume: action.payload,
        loading: false,
        error: null,
      };

    case UPDATE_RESUME_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_RESUME_SUCCESS:
      return {
        ...state,
        resume: action.payload,
        error: null,
      };

    case GET_RESUME_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default primaryResumeReducer;
