import React, { useState } from "react";
import { Tabs, Tab, Grid } from "@mui/material";
import Personalinformation from "./Parserresumepersonalinformationtab";
import Projectstab from "./Parserresumesprojectstab";
import Qualificationtab from "./Parserresumesqualificationtab";

function Parserresumestabs() {
  const [index, setIndex] = useState(1);
  return (
    <div>
      <div className="container mt-5">
        <Grid container spacing={0}>
          <Grid item md={12}>
            <Tabs
              defaultActiveKey={"profile"}
              id="uncontrolled-tab-example"
              className="mb-3"
              value={index}
              onChange={(event, newIndex) => setIndex(newIndex)}
            >
              <Tab value={1} label="Personal Information">
                <Personalinformation />
              </Tab>
              <Tab value={2} label="Projects">
                <Projectstab />
              </Tab>
              <Tab value={3} label="Qualification">
                <Qualificationtab />
              </Tab>
            </Tabs>

            <div>
              {index === 1 && (
                <>
                  <Personalinformation />
                </>
              )}{" "}
              {index === 2 && (
                <>
                  <Projectstab />
                </>
              )}{" "}
              {index === 3 && (
                <>
                  <Qualificationtab />
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Parserresumestabs;
