
exports.states = ["Delaware",
    "Alaska",
    "Maryland",
    "New Hampshire",
    "Kansas",
    "Texas",
    "Nebraska",
    "Vermont",
    "Hawaii",
    "Utah",
    "Oregon",
    "California",
    "New Jersey",
    "North Dakota",
    "Kentucky",
    "Minnesota",
    "Oklahoma",
    "Pennsylvania",
    "New Mexico",
    "Illinois",
    "Michigan",
    "Virginia",
    "West Virginia",
    "Wyoming",
    "South Carolina",
    "Arkansas",
    "South Dakota",
    "Montana",
    "North Carolina",
    "Puerto Rico",
    "Colorado",
    "Missouri",
    "New York",
    "Maine",
    "Tennessee",
    "Georgia",
    "Alabama",
    "Louisiana",
    "Nevada",
    "Iowa",
    "Idaho",
    "Rhode Island",
    "Washington",
    "Ohio"]
