import React, { useState } from "react";
import "../Styles/feedback.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import FeedbackFormNew from "./FeedbackFormNew";
import { BsXLg } from "react-icons/bs";

function Feedback(props) {
  const [sidebar, setSidebar] = useState(false);

  const handleClick = () => {
    setSidebar(!sidebar);
  };

  const handleSidebar = (value) => {
    setSidebar(value);
  };

  return (
    <div>
      <div id="feedback-form" className="feedback-form">
        <p
          className="feedback-form-btn btn btn-default btn-lg"
          id="OpenForm"
          onClick={handleClick}
        >
          Feedback
        </p>
        <div
          className={sidebar ? "feedback_form_area_animate" : "feedback_form_area"}
        >
          <div
            style={{
              height: "100px",
              backgroundColor: "#406882",
              color: "#fff",
              margin: "0px",
              textAlign: "center",
              borderTopLeftRadius: "15px",
              borderTopRightRadius: "15px"
            }}
          >
            <span
              style={{ float: "right", cursor: "pointer", paddingRight: "10px", paddingTop: '10px' }}
              onClick={() => {
                handleSidebar(false);
              }}
            >
              <BsXLg />
            </span>
            <h5 style={{ paddingTop: "30px" }}>Share Your Feedback</h5>
          </div>
          <FeedbackFormNew sidebar={sidebar} handleSidebar={handleSidebar} />
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.UserReducer.user,
  };
}

export default withRouter(connect(mapStateToProps, null)(Feedback));


// import React, { useState } from "react";
// import "../Styles/feedback.css";
// import Radium, { StyleRoot } from "radium";
// import axios from "axios";
// import Key from "../clientVariables.json";
// import { connect } from "react-redux";
// import { withRouter } from "react-router-dom";
// import FeedbackNew from "./FeedbackNew";

// import { toast } from "react-toastify";
// import { FiChevronRight } from "react-icons/fi";
// import ToastMessage from "./ToastMessage";
// import FeedbackFormNew from "./FeedbackFormNew";
// import { BsXLg } from "react-icons/bs";



// function Feedback(props) {
//   const [sidebar, setSidebar] = useState(false);
//   // const [buttonLoading, setButtonLoading] = useState(false);
//   const [email, setEmail] = useState("");
//   const [mobile, setMobile] = useState("");
//   const [query, setQuery] = useState("");
//   const [name, setName] = useState("");
//   const [subject, setSubject] = useState("");

//   const handleClick = () => {
//     setSidebar(!sidebar);
//   };
//   // const sendMail = () => {
//   //   setButtonLoading(true);
//   //   axios
//   //     .put(`${Key.domain}/commonAPIs/send_feedback`, {
//   //       user_id: props.currentUser.id,
//   //       session_token: props.currentUser.session_token,
//   //       description: query,
//   //       name: name,
//   //       mobileno: mobile,
//   //       email: email,
//   //       subject: subject,
//   //     })
//   //     .then((response) => {
//   //       
//   //       if (response.status === 200 && response.data.data.insertId) {
//   //         axios
//   //           .post(`${Key.domain}/empdashboard/send_feedback_mail`, {
//   //             user_id: props.currentUser.id,
//   //             session_token: props.currentUser.session_token,
//   //             query: query,
//   //             name: name,
//   //             mobile: mobile,
//   //             email: email,
//   //             subject: subject,
//   //           })
//   //           .then((response) => {
//   //             
//   //             setButtonLoading(false);
//   //             if (response && response.status) {
//   //               setSidebar(!sidebar);
//   //               alert("Mail Sent!");
//   //               setEmail("");
//   //               setName("");
//   //               setMobile("");
//   //               setQuery("");
//   //               setSubject("");
//   //             } else {
//   //               alert("Mail not sent!");
//   //               setEmail("");
//   //               setName("");
//   //               setMobile("");
//   //               setQuery("");
//   //               setSubject("");
//   //             }
//   //           });
//   //       } else {
//   //         setButtonLoading(false);
//   //         alert("Error while sending feedback!");
//   //       }
//   //     });
//   // };
//   const handleSidebar = (value) => {
//     setSidebar(value);
//   };
//   return (
//     <div>
//       <div id="feedback-form" class="feedback-form">
//         <p
//           //   href="#"
//           class="feedback-form-btn btn btn-default btn-lg"
//           id="OpenForm"
//           onClick={handleClick}
//         >
//           Feedback
//         </p>
//         <div
//           class={sidebar ? "feedback_form_area_animate" : "feedback_form_area"}
//         >
//           <StyleRoot>
//             <div
//               style={{
//                 height: "100px",
//                 backgroundColor: "#406882",
//                 color: "#fff",
//                 margin: "0px",
               
//                textAlign:"center",
//                 borderTopLeftRadius:"15px",
//                 borderTopRightRadius:"15px"
//               }}
//             >
//               <span
//         style={{ float: "right", cursor: "pointer",paddingRight:"10px",paddingTop:'10px' }}
//         onClick={() => {
//          handleSidebar(false);
         
         
//         }}
//       >
//         <BsXLg />
//       </span>
//               <h5 style={{ paddingTop:"30px"}}>Share Your Feedback</h5>
              
//             </div>
            
//             <FeedbackFormNew  sidebar={sidebar} handleSidebar={handleSidebar}/>
//             {/* <FeedbackNew sidebar={sidebar} handleSidebar={handleSidebar} /> */}
//             {/* <div class="feedback_form_area_inner" style={styles.fadeIn}>
//               <h3 className="titlefeedback">Give Feedback</h3>

//               <form>
//                 <div class="form-group2">
//                   <input
//                     type="text"
//                     required
//                     id=""
//                     placeholder="Name"
//                     class="custom-inp"
//                     value={name}
//                     onChange={(e) => setName(e.target.value)}
//                   />
//                 </div>

//                 <div class="form-group2">
//                   <input
//                     type="email"
//                     required
//                     id=""
//                     placeholder="Email"
//                     class="custom-inp"
//                     value={email}
//                     onChange={(e) => setEmail(e.target.value)}
//                   />
//                 </div>

//                 <div class="form-group2">
//                   <input
//                     type="text"
//                     id=""
//                     placeholder="Mobile"
//                     class="custom-inp"
//                     value={mobile}
//                     onChange={(e) => setMobile(e.target.value)}
//                   />
//                 </div>

//                 <div class="form-group2">
//                   <input
//                     type="text"
//                     placeholder="subject"
//                     id=""
//                     class="custom-inp"
//                     value={subject}
//                     onChange={(e) => setSubject(e.target.value)}
//                   />
//                 </div>

//                 <div class="form-group2">
//                   <textarea
//                     id=""
//                     placeholder="Message"
//                     class="custom-inp-txt"
//                     value={query}
//                     onChange={(e) => setQuery(e.target.value)}
//                   ></textarea>
//                 </div>

//                 <div class="">
//                   {buttonLoading ? (
//                     <>
//                       <Button variant="outline-secondary" id="a1" disabled>
//                         Submitting...
//                       </Button>{" "}
//                     </>
//                   ) : (
//                     <>
//                       <Button
//                         variant="outline-secondary"
//                         id="a1"
//                         onClick={() => sendMail()}
//                       >
//                         Submit
//                       </Button>{" "}
//                     </>
//                   )}
//                 </div>
//               </form>
//             </div> */}
//           </StyleRoot>
//         </div>
//       </div>
//     </div>
//   );
// }

// function mapStateToProps(state, ownProps) {
//   return {
//     currentUser: state.UserReducer.user,
//   };
// }

// export default withRouter(connect(mapStateToProps, null)(Feedback));
