import React from "react";
import { Box, Card, Skeleton, Stack, Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { styled, } from "@mui/material/styles";



const LoadingSkeletonJobView = () => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Card sx={{ borderRadius: "8px", boxShadow: "none", p: 2 }}>
          <Stack direction="column" gap={2} sx={{ width: '100%' }}>
            <Stack direction="column" gap={0} sx={{ width: '100%' }}>
              <Typography
                component="body"
                variant="h4"
                sx={{
                  textTransform: "capitalize",
                  fontWeight: 500,
                  cursor: "pointer",
                  "&:hover": {
                    textDecoration: "underline",
                    textDecorationColor: "#2196f3",
                  },
                }}
              >
                <Skeleton variant="text" width="60%" />
              </Typography>
              <Typography
                paragraph
                sx={{
                  margin: 0,
                  fontWeight: 400,
                  textTransform: "capitalize",
                  fontSize: "14px",
                  cursor: "pointer",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                  display: 'flex', gap: 2
                }}
              >
                <Skeleton variant="text" width="10%" />
                <Skeleton variant="text" width="25%" />
                <Skeleton variant="text" width="35%" />
              </Typography>
              {/* <Typography
                  paragraph
                  sx={{
                    margin: 0,
                    fontWeight: 400,
                    textTransform: "capitalize",
                    fontSize: "14px",
                  }}
                >
                  <Skeleton variant="text" width="20%" />
                </Typography>
                <Typography
                  paragraph
                  sx={{
                    margin: 0,
                    color: "#757575",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  <Skeleton variant="text" width="20%" />
                </Typography> */}
            </Stack>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Stack direction="column" gap={1} sx={{ width: '100%' }}>
                <Typography
                  paragraph
                  sx={{
                    margin: 0,
                    display: "flex",
                    alignItems: "end",
                    gap: "2px",
                    fontSize: "14px",
                    minWidth: "80px",
                    maxWidth: "400px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Skeleton variant="circular" width={20} height={20} />
                  <Skeleton variant="text" width="80%" />
                </Typography>

                <Typography
                  paragraph
                  sx={{
                    margin: 0,
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "end",
                    gap: "2px",
                  }}
                >
                  <Skeleton variant="circular" width={20} height={20} />
                  <Skeleton variant="text" width="60%" />
                </Typography>
                <Typography
                  paragraph
                  sx={{
                    margin: 0,
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    gap: "2px",
                  }}
                >
                  <Skeleton variant="circular" width={20} height={20} />
                  <Skeleton variant="text" width="80%" />
                </Typography>
                <Typography
                  paragraph
                  sx={{
                    margin: 0,
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    gap: "2px",
                  }}
                >
                  <Skeleton variant="circular" width={20} height={20} />
                  <Skeleton variant="text" width="70%" />
                </Typography>
                <Typography
                  paragraph
                  sx={{
                    margin: 0,
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    gap: "2px",
                    maxWidth: "350px",
                    cursor: "pointer",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  <Skeleton variant="circular" width={20} height={20} />
                  <Skeleton variant="text" width="70%" />
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card sx={{ boxShadow: "none", p: 2 }}>
          <Typography paragraph sx={{ fontWeight: 500 }}>
            <Skeleton variant="text" width="50%" />
          </Typography>
          <Typography paragraph sx={{ fontSize: "14px !important" }}>
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="80%" />
          </Typography>
        </Card>
      </Grid>
      <Grid item xs={12}>
        {/* <div ref={skillMatchRef}>
          <Skeleton variant="text" width="100%" height={50} />
        </div> */}
      </Grid>
    </Grid>
  );
};

export default LoadingSkeletonJobView;

// const Bullet = styled("span")({
//   display: "inline-block",
//   width: "3px",
//   height: "3px",
//   borderRadius: "50%",
//   backgroundColor: "currentColor",
// });
