import React, { useState, useEffect, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Card } from "@mui/material";
import axios from "axios";
import Key from "./clientVariables.json";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; import Select from "react-select";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DashboardService from "../src/services/dashboard.service"
function Parserresumepersonalinformationtab(props) {

  const [countries, setCountries] = useState([]);
  const [jobtypeIds, setJobtypeids] = useState([])
  const [jobtypeNames, setJobtypeNames] = useState([]);
  const [jobtypes, setJobtypes] = useState([])
  const [visatypename, setVisaTypeName] = useState('');
  const [visatypes, setVisatypes] = useState([])
  const [states, setStates] = useState([]);
  const [skillsList, setSkills] = useState([]);
  const [skillTitles, setSkilltitles] = useState([]);
  const [skillIds, setSkillids] = useState([]);
  const [cities, setCities] = useState([]);
  const [phoneNo, setPhoneNo] = useState("");
  const [resumeDetails, setResumeDetails] = useState([]);

  const formikRef = useRef();


  const handlePhoneNumberChange = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setPhoneNo(formattedPhoneNumber);
    if (formikRef.current) {
      formikRef.current.setFieldValue("phoneNo", formattedPhoneNumber);
      console.log(formikRef.current);
    }
  }

  const formatPhoneNumber = (value) => {
    if (!value) return value;

    //// clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    setPhoneNo(String(phoneNumber));
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  const handleChange = (e) => {
    setSkillids(Array.isArray(e) ? e.map((x) => x.value) : []);
    setSkilltitles(Array.isArray(e) ? e.map((x) => x.label) : []);
    console.log(skillIds);
  };

  const getCountries = () => {
    axios
      .get(`${Key.domain}/commonAPIs/get_all_countries`, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      })
      .then((resdata) => {
        console.log(resdata.data.data);
        setCountries(resdata.data.data);
        getStates(233);
      });
  };

  const getStates = (countryid, errors) => {
    console.log(errors);
    axios
      .post(`${Key.domain}/commonAPIs/get_states_by_countryid`, {
        country_id: countryid,
      })
      .then((response) => {
        console.log(response);
        setStates(response.data.data);
        if (formikRef.current) {
          formikRef.current.setFieldValue("country", countryid || 233);
        }
      });
  };

  const getCities = (stateid) => {
    //  const selectedState = e.target.value;
    // console.log(selectedState)
    axios
      .post(`${Key.domain}/commonAPIs/get_cities_by_stateid`, {
        state_id: stateid,
      })
      .then((response) => {
        console.log(response);
        setCities(response.data.data);
        if (formikRef.current) {
          formikRef.current.setFieldValue("state", stateid);
        }
      });
  };
  useEffect(() => {
    //alert(props.location.state.paginationNumber)
    axios.get(`${Key.domain}/commonAPIs/get_all_jobtypes`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      },
    })
      .then((resdata) => {
        console.log(resdata.data.data)
        setJobtypes(resdata.data.data.map(skill => ({
          value: skill.id,
          label: skill.title,
        })))
      })


    axios
      .get(`${Key.domain}/commonAPIs/get_all_skills`, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      })
      .then((resdata) => {
        // console.log(resdata.data.data)
        setSkills(
          resdata.data.data.map((skill) => ({
            value: skill.id,
            label: skill.skills,
          }))
        );
      });

    axios.get(`${Key.domain}/commonAPIs/get_all_visatypes`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      },
    })
      .then((resdata) => {
        console.log(resdata.data.data)
        setVisatypes(resdata.data.data)
      })

    getCountries();

    axios
      .post(`${Key.domain}/parser/get_parser_resume_details`, {
        resumeid: props.location.state.resumeid
      })
      .then(async response => {
        console.log(response)
        if (response && response.status === 200 && response.data.data && response.data.data.resumedetails) {
          console.log(response.data.data.resumedetails)
          setResumeDetails(response.data.data.resumedetails)
          setJobtypeids(response.data.data.resumedetails.jobtype)
          setSkillids(response.data.data.resumedetails.section_skills)
          setPhoneNo(response.data.data.resumedetails.home_phone)
          getCities(response.data.data.resumedetails.stateid)
        }
      })



  }, []);

  const onTextEditorchanged = (value) => {
    // console.log(value)
    if (formikRef.current) {
      formikRef.current.setFieldValue("summary", value);
    }
  };


  useEffect(() => {


    if (formikRef.current) {
      formikRef.current.setFieldValue(
        "Applicationtitle",
        resumeDetails.application_title !== undefined
          ? resumeDetails.application_title
          : ""
      );
      formikRef.current.setFieldValue(
        "Fullname",
        resumeDetails.full_name !== undefined
          ? resumeDetails.full_name
          : ""
      );

      formikRef.current.setFieldValue(
        "email",
        resumeDetails.email_address !== undefined
          ? resumeDetails.email_address
          : ""
      );

      formikRef.current.setFieldValue(
        "phoneNo",
        resumeDetails.home_phone !== undefined
          ? resumeDetails.home_phone
          : ""
      );

      formikRef.current.setFieldValue(
        "country",
        resumeDetails.country_id !== undefined
          ? resumeDetails.country_id
          : ""
      );

      formikRef.current.setFieldValue(
        "state",
        resumeDetails.stateid !== undefined
          ? resumeDetails.stateid
          : ""
      );

      formikRef.current.setFieldValue(
        "city",
        resumeDetails.address_city !== undefined
          ? resumeDetails.address_city
          : ""
      );

      formikRef.current.setFieldValue(
        "visatype",
        resumeDetails.visatype !== undefined
          ? resumeDetails.visatype
          : ""
      );

      formikRef.current.setFieldValue(
        "skills",
        1
      );


      formikRef.current.setFieldValue("summary", resumeDetails.summary);
    }
  }, [resumeDetails])


  const Personalinformation = Yup.object().shape({
    Applicationtitle: Yup.string()
      .required("Application Title is Required"),

    Fullname: Yup.string()
      .required("Full Name is Required"),



    country: Yup.string()
      .required("Country is Required"),

    state:
      Yup.string()
        .required("State is Required"),

    city: Yup.string()
      .required("City is Required"),

    email: Yup.string().email().required("Email is Required"),

    jobtype: Yup.array().
      test("is jobtype empty", 'Select from dropdown',
        function (value) {
          console.log(value)
          return new Promise((resolve, reject) => {
            if (jobtypeIds.length && jobtypeIds.length > 0) {
              resolve(true)
            } else {
              resolve(false)
            }
          })
        }),

    phoneNo: Yup.string().required("Mobile no Required"),
  });

  const handleSubmit = async(values) => {
    // console.log(jobtypeIds)
    // console.log(skillTitles)
    // console.log(skillIds)

    console.log(values);
     axios
      .post(`${Key.domain}/parser/update_resume_personaldetails_via_parser `, {
        "application_title": values.Applicationtitle,
        "full_name": values.Fullname,
        "home_phone": values.phoneNo,
        "email_address": values.email,
        "location_state": values.state,
        "location_city": values.city,
        "skills_titles": skillTitles,
        "skills_ids": skillIds,
        "location_country": values.country,
        "summary": values.summary,
        "resumeid": props.location.state.resumeid,
        "visatype": values.visatype,
        "jobtype": jobtypeIds
      })
      .then(async response => {
        console.log(response)
        if (response && response.status === 200 && response.data.data.affectedRows === 1) {

          await DashboardService.updateParsedResumeDetailsToSolr(props.location.state.resumeid,"fromParsedResumesList")
          alert("Resume Updated Successfully")

          props.history.push({
            pathname: "/ParserResumeslist",
            state:{
              paginationNumber: props.location.state && props.location.state.paginationNumber

            }
          });
        }
      })
  }

  const handleJobTypeChange = (e) => {

    setJobtypeids(Array.isArray(e) ? e.map(x => x.value) : []);
    // setJobtypeids(Array.isArray(e) ? e.map(x => x.label) : []);
    setJobtypeNames(Array.isArray(e) ? e.map((x) => x.label) : []);
    if (formikRef.current) {
      formikRef.current.setFieldValue("jobtype", (Array.isArray(e) ? e.map((x) => x.value) : []));
    }

  }

  return (
    <div>
      <Formik
        innerRef={formikRef}
        initialValues={{
          Applicationtitle: "",
          Fullname: "",
          email: "",
          phoneNo: "",
          country: "",
          state: "",
          city: "",
          summary: "",
          jobtype: [],
        }}
        validationSchema={Personalinformation}
        onSubmit={(values) => {
          //console.log(values);
          handleSubmit(values);


        }}
      >
        {({ touched, errors, isSubmitting, values }) =>
          <Card style={{ padding: "1%" }} >
            <div>

              <Form id="addresume">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group1">
                      <label htmlFor="text">
                        Application title{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        type="text"
                        name="Applicationtitle"
                        placeholder="Application Title"
                        autocomplete="off"
                        className={`mt-2 form-control
                                        ${touched.Applicationtitle && errors.Applicationtitle
                            ? "is-invalid"
                            : ""
                          }`}
                      />

                      <ErrorMessage
                        component="div"
                        name="Applicationtitle"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group1">
                      <label htmlFor="text">
                        Full Name{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        type="text"
                        name="Fullname"
                        placeholder="Fullname "
                        autocomplete="off"
                        className={`mt-2 form-control
                                        ${touched.Fullname && errors.Fullname
                            ? "is-invalid"
                            : ""
                          }`}
                      />

                      <ErrorMessage
                        component="div"
                        name="Fullname"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group1">
                      <label htmlFor="email">
                        Email <span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        type="email"
                        name="email"
                        placeholder="Email"
                        className={`mt-2 form-control
                                        ${touched.email && errors.email
                            ? "is-invalid"
                            : ""
                          }`}
                      />

                      <ErrorMessage
                        component="div"
                        name="email"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">

                  <div className="col-md-4">
                    <div className="form-group1">
                      <label htmlFor="text">
                        Country <span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        as="select"
                        name="country"
                        className={
                          "mt-2 form-select form-control" +
                          (errors.country && touched.country
                            ? " is-invalid"
                            : "")
                        }
                        onChange={(e) =>
                          getStates(e.target.value, errors)
                        }
                        defaultValue={values.country}
                      >
                        <option>Select Country</option>
                        {countries.map((country) => (
                          <option value={country.id}>
                            {country.name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        component="div"
                        name="country"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group1">
                      <label htmlFor="text">
                        State <span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        as="select"
                        name="state"
                        className={
                          "mt-2 form-select form-control" +
                          (errors.state && touched.state
                            ? " is-invalid"
                            : "")
                        }
                        onChange={(e) => getCities(e.target.value)}
                      >
                        <option> Select State</option>
                        {states.map((item, index) => (
                          <option value={item.id}>{item.name}</option>
                        ))}
                      </Field>
                      <ErrorMessage
                        component="div"
                        name="state"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group1">
                      <label htmlFor="text">
                        City <span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        as="select"
                        name="city"
                        className={
                          "mt-2 form-select form-control" +
                          (errors.city && touched.city
                            ? " is-invalid"
                            : "")
                        }
                        onChange={(e) => {
                          console.log(values);
                          formikRef.current.setFieldValue(
                            "city",
                            e.target.value
                          );
                        }}
                      >
                        <option> Select City</option>
                        {cities.map((item, index) => (
                          <option value={item.id}>
                            {item.cityName}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        component="div"
                        name="city"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                  <div className='col-md-6 mt-2' >
                    <div className="form-group1">
                      <label htmlFor="jobtype">Job Type <span style={{ color: "red" }}>*</span></label>

                      <Select
                        isMulti
                        style={{ border: "red 1px solid", }}
                        className={"custom-jobtype-select" + (errors.jobtype && touched.jobtype ? "-invalid" : "valid")}
                        name="jobtype"
                        options={jobtypes}
                        value={jobtypes.filter(obj => jobtypeIds.includes(obj.value))}
                        onChange={handleJobTypeChange}
                      >
                        {/* <option selected>Select Job Type</option>
                      {jobtypes.map(jobtype =>
                          <option value={jobtype.id}>{jobtype.title}</option>
                      )} */}


                      </Select>
                      {touched.jobtype && errors.jobtype &&
                        <div class="invalid-feedback" style={{ display: "flex" }}>{errors.jobtype}</div>}

                    </div>
                  </div>

                  <div className='col-md-6 mt-2'>
                    <div className="form-group1">
                      <label htmlFor="visatype">Visa Type/Work Permit</label>

                      <Field name="visatype" as="select" className={
                        "form-select form-control" +
                        (errors.visatype && touched.visatype ? " is-invalid" : "")
                      }
                        onChange={(e) => {
                          formikRef.current.setFieldValue("visatype", e.target.value);
                          setVisaTypeName(e.target[e.target.selectedIndex].id)

                        }}
                      >
                        <option selected>Select Visa Type</option>
                        {visatypes.map(visatype =>
                          <option value={visatype.id} id={visatype.type_name}>{visatype.type_name}</option>

                        )}

                        <ErrorMessage
                          name="visatype"
                          component="div"
                          className="invalid-feedback"
                        />

                      </Field>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="form-group1 mt-2">
                      <label htmlFor="skills">Skills</label>
                      <Select
                        isMulti
                        name="skills"
                        // className={
                        //   // "form-select form-control" +
                        //   (errors.skills && touched.skills ? " is-invalid" : "")
                        // }
                        options={skillsList}
                        onChange={handleChange} // assign onChange function
                        value={skillsList.filter((obj) =>
                          skillIds.includes(obj.value)
                        )} // set selected values
                      />

                      <ErrorMessage
                        name="skills"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group1">
                      <label htmlFor="phoneNo">Mobile <span style={{ color: "red" }}>*</span></label>
                      <Field
                        type="text"
                        name="phoneNo"
                        placeholder="(xxx) xxx-xxxx"
                        autocomplete="off"
                        className={`mt-2 form-control
                                        ${touched.phoneNo && errors.phoneNo ? "is-invalid" : ""}`}
                        onChange={handlePhoneNumberChange}
                        value={phoneNo}
                      />

                      <ErrorMessage
                        component="div"
                        name="phoneNo"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>


                  <div className="col-md-12">
                    <div className="form-group1">
                      <label htmlFor="summary" name="summary">
                        Summary
                      </label>
                      <ReactQuill theme="snow"
                                                
                        placeholder="summary"
                        name="summary"
                        value={values.summary}
                        // initialValue={values.summary}
                        onChange={(e) => {
                          onTextEditorchanged(e);
                        }}
                      />
                      <ErrorMessage
                        name="summary"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                </div>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </Form>

            </div>
          </Card>

        }
      </Formik>
    </div>
  )
}


function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.UserReducer.user,
    isLoading: state.UserReducer.isLoginLoading,
    iserror: state.UserReducer.error,
  };
}
export default withRouter(connect(mapStateToProps, null)(Parserresumepersonalinformationtab))