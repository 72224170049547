import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import ToastMessage from '../../Components/ToastMessage';
import axios from "axios"
import Key from "../../clientVariables.json"
const LoginSchema = Yup.object().shape({

	textarea: Yup.string()

		.required("Note Required"),
});




class Notepopup extends React.Component {

	constructor(props) {
		super(props);

	}
	state = {
		notes: []
	};

	componentDidMount = () => {
		var Url = window.location.pathname;
		var currentUrl = Url.split("/");
		if (currentUrl[3] !== undefined) {
			var currentResumeId = currentUrl[3].split("_");
			var value = currentResumeId[1];
		} else {
			value = ""
		}
		axios
			.post(
				`${Key.domain}/Notifications/get_resume_notes_byGroup`,
				{
					"user_id": sessionStorage.getItem("id"),
					"ca_id": sessionStorage.getItem("ca_id"),
					"resume_id": value === "" ? this.props.noteResumeId : value,

				}
			)
			.then((resdata) => {
				console.log(resdata.data.data)
				this.setState({
					notes: resdata.data.data
				})
			})
	}

	createNotes = (notes) => {
		var Url = window.location.pathname;
		var currentUrl = Url.split("/");
		if (currentUrl[3] !== undefined) {
			var currentResumeId = currentUrl[3].split("_");
			var value = currentResumeId[1];
		} else {
			value = ""
		}
		axios
			.post(
				`${Key.domain}/Notifications/create_note`,
				{
					"user_id": sessionStorage.getItem("id"),
					"ca_id": sessionStorage.getItem("ca_id"),
					"resume_id":  value === "" ? this.props.noteResumeId : value,
 					
					"note": notes

				}
			)
			.then((resdata) => {
				console.log(resdata)

			})

	}


	render() {
		return (
			<div className="container">
				<div className="row">
					{this.state.notes.map(note => <p><b>{note.RecruiterName}</b> <span style={{ fontSize: "13px" }}>{note.note}</span></p>)}
					<div className="col-lg-6">
						<Formik
							initialValues={{ email: "", password: "", textarea: "" }}
							validationSchema={LoginSchema}
							onSubmit={(values) => {
								console.log(values);
								this.createNotes(values.textarea)
								let msg = "Note Saved"
								toast(
									<ToastMessage message={msg} />
								)
								this.props.handleNoteClose()
							}}
						>
							{({ touched, errors, isSubmitting, values }) =>

								<div>
									<Form>


										<div className="form-group">

											<Field
												as="textarea"
												name="textarea"
												placeholder="Enter Note..."
												className={`mt-2 form-control
						${touched.textarea && errors.textarea
														? "is-invalid"
														: ""
													}`}
												style={{ height: "150px", width: "250px" }}

											/>
											<ErrorMessage
												component="div"
												name="textarea"
												className="invalid-feedback"
											/>
										</div>


										<button
											type="submit"
											className="btn btn-primary btn-block mt-4"
										>
											Save
										</button>



									</Form>
								</div>

							}
						</Formik>

					</div>
				</div>
			</div>
		);
	}
}

export default Notepopup;
