import UserService from "../services/user.service";
import * as types from "../constant/actionTypes";

import { toast } from "react-toastify";
import { FiChevronRight } from 'react-icons/fi';
import ToastMessage from '../Components/ToastMessage';



class UserActions {

    constructor() {
        this.userService = UserService;

    }
    //CRUD METHODS


    socialMedia_createUser(newUser) {
        return (dispatch) => {
            return this.userService.socialMedia_createUser(newUser)
                .then(user => {
                    // console.log(user)
                    var updatedUser = user.data
                    if (updatedUser.id) {
                        if (updatedUser.usertype === "5") {

                            window.location.href = "/Jsdashboard"
                            dispatch(this.createUserSuccess(updatedUser));
                        }

                    } else {
                        // alert(" Social media signup failure !");
                        toast(
                            <ToastMessage message="Social media signup failure !" />
                        );

                        sessionStorage.clear();
                        localStorage.clear();

                    }
                })
                .catch(error => {
                    throw error;
                });
        };
    }

    // Create Employer
    createEmployer(newUser) {
        return (dispatch) => {
            return this.userService.createEmployer(newUser)
                .then(user => {
                    console.log(user.data)
                    var updatedUser = user.data.data && user.data.data.length > 0 ? user.data.data[0]:false;
                    if (updatedUser.id) {
                        // alert("Mail sent,check your inbox to confirm!")

                        toast(
                            <ToastMessage message="We will notify you once your account has been activated." />
                        );

                        window.location.href = "/EmpLogin"
                    }         
                    else {
                        // alert("Signup failure !");

                        toast(
                            <ToastMessage message="Email Already Exists!" />
                        );

                        sessionStorage.clear();
                        localStorage.clear();
                    }
                })
                .catch(error => {
                    throw error;
                });
        };
    }


    // Create Benchsales
    createBenchsales(newUser) {
        return (dispatch) => {
            return this.userService.createBenchsales(newUser)
                .then(user => {
                    var updatedUser = user.data.data[0]
                    if (updatedUser.id) {
                        // alert("Mail sent,check your inbox to confirm!")

                        toast(
                            <ToastMessage message="Mail sent,check your inbox to confirm!" />
                        );

                        window.location.href = "/EmpLogin"
                    } else {
                        // alert("Signup failure !");

                        toast(
                            <ToastMessage message="Signup failure !" />
                        );

                        sessionStorage.clear();
                        localStorage.clear();
                    }
                })
                .catch(error => {
                    throw error;
                });
        };
    }


    // Create user
    createUser(newUser) {
        return (dispatch) => {
            return this.userService.createUser(newUser)
                .then(user => {
                    var updatedUser = user.data.data[0]
                    if (updatedUser.id) {
                        if (updatedUser.usertype === "5") {

                            // alert("asjd")
                            window.location.href = "/Jsdashboard"
                            dispatch(this.createUserSuccess(updatedUser));
                        }

                    } else {
                        // alert("Signup failure !");

                        toast(
                            <ToastMessage message="Signup failure !" />
                        );

                        sessionStorage.clear();
                        localStorage.clear();

                    }
                })
                .catch(error => {
                    throw error;
                });
        };
    }

    createUserSuccess(user) {
        return { type: types.CREATE_USER_SUCCESS, payload: user };
    }

    login(username, password, ipaddress, logintype) {
        return (dispatch) => {
            return this.userService.login(username, password, ipaddress, logintype)
                .then(user => {
                    //console.log(user)
                    var isloading = false
                    dispatch(this.LoginLoadingSuccess(isloading))
                    var updatedUser = user.data.data[0]
                    if (user.data.success === 0) {
                        dispatch(this.loginFailure(user.message))
                        sessionStorage.clear();
                        localStorage.clear();
                        var isloading = false
                        return dispatch(this.LoginLoadingSuccess1(isloading))

                    }
                    else {
                        if (updatedUser.id) {
                            if (logintype === 1) {

                                if (updatedUser.usertype === "5" || updatedUser.usertype === "3" || updatedUser.usertype === "" || updatedUser.usertype === null || updatedUser.usertype === undefined) {
                                    // alert("Employer credentials invalid");
                                    toast(
                                        <ToastMessage message="Employer credentials invalid" />
                                    );


                                    // window.location.reload()
                                    sessionStorage.clear();
                                    localStorage.clear();

                                } if (updatedUser.usertype === "2" || updatedUser.usertype === "4" || updatedUser.usertype === "12") {
                                    // window.location.href = "/Empdashboard"
                                    if (sessionStorage.getItem("frompostFreeJobs") !== null) {
                                        window.location.href = sessionStorage.getItem("frompostFreeJobs")
                                    }
                                    else if (sessionStorage.getItem("redirectToResumeView") !== null) {
                                        //console.log(sessionStorage.getItem("redirectToResumeView") + sessionStorage.getItem("resume_id"))
                                        sessionStorage.setItem("redirectToResumeViewCheckPackage", 1)
                                        window.location.href = sessionStorage.getItem("redirectToResumeView")

                                    }
                                    else if(sessionStorage.getItem("packagesurl") !== null){
                                        if(sessionStorage.getItem("packagesurl") !== null && sessionStorage.getItem("usertype") == "2" && sessionStorage.getItem("companyid") != "0" ){
                                            window.location.href= "/Empaccountsettings";
                                        }
                                        else {
                                             window.location.href= "/Empdashboard";
                                        }
                                        
                                    }
                                    dispatch(this.loginSuccess(updatedUser));
                                }


                            } else if (logintype === 2) {

                                if (updatedUser.usertype === "3" || updatedUser.usertype === "5") {

                                    dispatch(this.loginSuccess(updatedUser));

                                } else if (updatedUser.usertype === "2" || updatedUser.usertype === "4" || updatedUser.usertype === "12" || updatedUser.usertype === "" || updatedUser.usertype === null || updatedUser.usertype === undefined) {
                                    toast(
                                        <ToastMessage message="Benchsales credentials invalid" />
                                    );
                                    setTimeout(() => {
                                        window.location.reload()
                                        sessionStorage.clear();
                                        localStorage.clear();
                                    }, 1500);

                                }

                            } else {

                                if (updatedUser.usertype === "5" || updatedUser.usertype === "3") {
                                    // window.location.href = "/Jsdashboard"
                                    if (sessionStorage.getItem("upSkillCourseUrl") !== null) {
                                        window.location.href = sessionStorage.getItem("upSkillCourseUrl")
                                    }

                                    else if (sessionStorage.getItem("jobDetailApplyUrl") !== null) {
                                        window.location.href = sessionStorage.getItem("jobDetailApplyUrl")
                                    }
                                    else if (sessionStorage.getItem("findcompaniesurl") !== null) {
                                        window.location.href = sessionStorage.getItem("findcompaniesurl")
                                    }
                                    else if (sessionStorage.getItem("fromCreateResume") !== null) {
                                        window.location.href = sessionStorage.getItem("fromCreateResume")
                                    }else if(sessionStorage.getItem("companyView") !== null ){
                                        window.location.href = sessionStorage.getItem("companyView")

                                    }

                                    // else {
                                    //     window.location.href = "/Jsdashboard"
                                    // }


                                    dispatch(this.loginSuccess(updatedUser));

                                } if (updatedUser.usertype === "2" || updatedUser.usertype === "4" || updatedUser.usertype === "12" || updatedUser.usertype === "" || updatedUser.usertype === null || updatedUser.usertype === undefined) {
                                    // alert("Jobseeker credentials invalid");
                                    toast(
                                        <ToastMessage message="Jobseeker credentials invalid" />
                                    );
                                    setTimeout(() => {
                                        window.location.reload()
                                        sessionStorage.clear();
                                        localStorage.clear();
                                    }, 1500);

                                }

                            }

                        } else {
                            // alert("Please enter valid user name ");
                            toast(
                                <ToastMessage message="Please Valid User Name " />
                            );

                            sessionStorage.clear();
                            localStorage.clear();

                        }
                    }
                })

        };
    }

    loginSuccess(user) {
        return { type: types.LOGIN_SUCCESS, payload: user };
    }


    formEmpty(msg) {
        return (dispatch) => {
            dispatch(this.loginFailure(msg))
        }
    }

    loginFailure(msg) {
        return { type: types.LOGIN_FAILURE, payload: msg };
    }


    LoginLoadingSuccess(isloading) {
        return (dispatch) => {
            dispatch(this.LoginLoadingSuccess1(isloading));
            //return { type: types.IS_LOADING, payload: isloading };
        }

    }

    LoginLoadingSuccess1(isloading) {
        return { type: types.IS_LOGINLOADING, payload: isloading };
    }

    // Logout
    logout() {
        return (dispatch) => {
            return this.userService.logout().then(() => {
                dispatch(this.logoutSuccess());
            })
        };
    }

    logoutSuccess(user) {
        return { type: types.LOGOUT_SUCCESS };
    }

}

export default new UserActions();
