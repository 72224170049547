import axios from "axios";
import Key from '../clientVariables.json'
import moment from 'moment'

import { toast } from "react-toastify";
import ToastMessage from '../Components/ToastMessage';


const loginListeners = [];


class UserService {

    /**
    * Get logged user
    */

    createUser(user) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${Key.domain}/Users/signup`, user)
                .then(response => {
                    var data = response.data;
                    if (data.success === 1) {
                        if (user.companyid) {
                            // alert("Mail sent,check your inbox to confiddrm!")
                            // window.location.href = "/loginpage"
                            sessionStorage.setItem("userdetailsforpackage", JSON.stringify(user))
                            sessionStorage.setItem("userinserteddetailsforpackage", JSON.stringify(data))
                            //  window.location.href = "/Jsdashboard"
                        }
                        else {

                            toast(
                                <ToastMessage message="Registered Successfully !" />
                            );

                            window.location.href = "/Login"

                            // this.login(user.username, user.password, user.ipaddress).then((user) => {
                            //     resolve(user);
                            // });
                        }
                    } else if (data.success === 0) {
                        // alert(data.data);

                        toast(
                            <ToastMessage message={data.data} />
                        );

                        reject(data.data);
                    }
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
    createEmployer(user) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${Key.files_domain}/users/employer_signup`, user)
                .then(response => {
                    var data = response.data;
                    if (data.success === 1) {
                        if (data.userDetails !== undefined && data.userDetails[0] !== undefined && data.userDetails[0].userid !== undefined && data.userDetails[0].userid !== null) {
                            // alert("Mail sent,check your inbox to confirm!")

                            toast(
                                <ToastMessage message="We will notify you once your account has been activated." />
                            );

                            window.location.href = "/EmpLogin"
                            // sessionStorage.setItem("userdetailsforpackage", JSON.stringify(user))
                            // sessionStorage.setItem("userinserteddetailsforpackage", JSON.stringify(data))
                            //  window.location.href = "/Jsdashboard"
                        }
                        else {
                            resolve(data);
                        }
                    } else if (data.success === 0) {
                        // alert(data.data);
                        toast(
                            <ToastMessage message={data.data} />
                        );

                        reject(data.data);
                    }
                })
                .catch(error => {
                    reject(error);
                });
        });
    }



    createBenchsales(user) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${Key.files_domain}/users/bs_signup`, user)
                .then(response => {
                    var data = response.data;
                    if (data.success === 1) {
                        if (data.userDetails !== undefined && data.userDetails[0] !== undefined && data.userDetails[0].userid !== undefined && data.userDetails[0].userid !== null) {
                            // alert("Mail sent,check your inbox to confirm!")

                            toast(
                                <ToastMessage message="Mail sent,check your inbox to confirm!" />
                            );

                           // window.location.href = "https://bs.jobsnprofiles.com/jnp_redirect"
                           window.open(`${Key.bench_url}/jnp_redirect`, "_blank");
                           //window.open("http://localhost:3000/jnp_redirect", "_blank")

                            // sessionStorage.setItem("userdetailsforpackage", JSON.stringify(user))
                            // sessionStorage.setItem("userinserteddetailsforpackage", JSON.stringify(data))
                            //  window.location.href = "/Jsdashboard"
                        }
                        else {
                            resolve(data);
                        }
                    } else if (data.success === 0) {
                        // alert(data.data);
                        toast(
                            <ToastMessage message={data.data} />
                        );

                        reject(data.data);
                    }
                })
                .catch(error => {
                    reject(error);
                });
        });
    }


    socialMedia_createUser(user) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${Key.files_domain}/users/socialmedia_signup_or_login`, user)
                .then(response => {
                    // console.log(response.data.data)
                    var data = response.data;
                    var userdetails = response.data.data;
                    var user = response;

                    if (data.success === 1) {
                        if (user.companyid) {
                            // alert("Mail sent,check your inbox to confiddrm!")
                            // window.location.href = "/loginpage"
                            sessionStorage.setItem("userdetailsforpackage", JSON.stringify(user))
                            sessionStorage.setItem("userinserteddetailsforpackage", JSON.stringify(data))
                            //  window.location.href = "/Jsdashboard"
                        }
                        else {

                            if (userdetails.id) {
                                if (user.package_id === 0) {
                                    let userdetails = { username: user.username, password: user.password }
                                    sessionStorage.setItem("userdetailsforpackage", JSON.stringify(userdetails))
                                    window.location.href = "/packages"
                                } else {
                                    loginListeners.forEach(fn => fn(userdetails));
                                    sessionStorage.removeItem("userdetailsforpackage")
                                    sessionStorage.setItem("companyid", userdetails.companyid);
                                    sessionStorage.setItem("id", userdetails.id);
                                    sessionStorage.setItem("profileimg", userdetails.image)
                                    localStorage.setItem("profileimg", userdetails.image);

                                    sessionStorage.setItem("ca_id", userdetails.ca_id);
                                    sessionStorage.setItem("email", userdetails.username);
                                    sessionStorage.setItem("lastvisited", userdetails.lastvisitDate);
                                    sessionStorage.setItem("user", JSON.stringify(userdetails));
                                    localStorage.setItem("userToken", userdetails.session_token);
                                    localStorage.setItem("user", JSON.stringify(userdetails));
                                    sessionStorage.setItem("package_id", userdetails.package_id)
                                    sessionStorage.setItem("usertype", userdetails.usertype)

                                    resolve(data);

                                }

                            } else {
                                resolve(user)
                                var loading = false
                                sessionStorage.setItem("isLoading", loading)
                            }
                        }
                    } else if (data.success === 0 && data.userAlreadyExists) {
                        // alert(data.message);
                        toast(
                            <ToastMessage message={data.message} />
                        );

                        reject(data.data);
                    } else if (data.success === 0) {
                        // alert(data.data);

                        toast(
                            <ToastMessage message={data.data} />
                        );

                        reject(data.data);
                    }
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
    getUser() {
        try {
            const user = JSON.parse(
                localStorage.getItem("user")
            );
            let compid = sessionStorage.getItem("companyid") ? sessionStorage.getItem("companyid") : user.companyid
            sessionStorage.setItem("companyid", compid);
            sessionStorage.setItem("id", user.id);
            sessionStorage.setItem("email", user.mail);
            // sessionStorage.setItem("profileimg", user.image)

            sessionStorage.setItem("lastvisited", user.lastvisitDate);
            sessionStorage.setItem("user", JSON.stringify(user));
            return user;
        } catch (e) {
            return null;
        }
    }




    async login(username, password, ipaddress) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${Key.domain}/users/login`, {
                    username: username,
                    password: password,
                    lastvisitDate: moment.utc(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
                    ip_address: ipaddress
                })
                .then(async response => {
                    console.log(response)
                    var user = response
                    if (response.data.error) {
                        // alert(response.data.activationmessage)
                        toast(
                            <ToastMessage message={response.data.error} />
                        );

                        resolve(user)
                    } else {
                        var userdetails = response.data.data[0]
                        if (userdetails.id) {
                            if (user.package_id === 0) {
                                let userdetails = { username: username, password: password }
                                sessionStorage.setItem("userdetailsforpackage", JSON.stringify(userdetails))
                                window.location.href = "/packages"
                            } else {
                                loginListeners.forEach(fn => fn(userdetails));
                                sessionStorage.removeItem("userdetailsforpackage")
                                sessionStorage.setItem("companyid", userdetails.companyid);
                                sessionStorage.setItem("id", userdetails.id);
                                sessionStorage.setItem("profileimg", userdetails.image)
                                localStorage.setItem("profileimg", userdetails.image);

                                sessionStorage.setItem("ca_id", userdetails.ca_id);
                                sessionStorage.setItem("email", userdetails.username);
                                sessionStorage.setItem("lastvisited", userdetails.lastvisitDate);
                                sessionStorage.setItem("user", JSON.stringify(userdetails));
                                sessionStorage.setItem("userToken", userdetails.session_token);
                                localStorage.setItem("user", JSON.stringify(userdetails));
                                sessionStorage.setItem("package_id", userdetails.package_id)
                                sessionStorage.setItem("usertype", userdetails.usertype)
                                sessionStorage.setItem("js_user_role", userdetails.js_user_role)
                                sessionStorage.removeItem("jobtitle");
                                sessionStorage.removeItem("inputjobtitle");
                                sessionStorage.removeItem("resumeTitle");
                                sessionStorage.removeItem("resumeCityName");
                                sessionStorage.removeItem("cityname")
                                sessionStorage.removeItem("inputResumeTitle")

                                resolve(user);

                            }

                        } else {
                            resolve(user)
                            var loading = false
                            sessionStorage.setItem("isLoading", loading)
                        }
                    }
                })
                .catch(error => {
                    console.log(error)
                    // alert("Invalid credentials")
                    toast(
                        <ToastMessage message="Invalid credentials" />
                    );

                    //window.location.reload()

                    resolve(error)

                    reject(error);
                    var loading = false
                    sessionStorage.setItem("isLoading", loading)
                });
        });
    }


    logout() {
        return new Promise((resolve, reject) => {
            axios
                .post(`${Key.domain}/users/logout`, { userid: sessionStorage.getItem("id") })
                .then(response => {
                    ////////console.log.log(response)
                    loginListeners.forEach(fn => fn(null));
                    sessionStorage.clear();
                    localStorage.clear();
                    window.location.href = '/'
                    resolve(true);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
}

export default new UserService();
