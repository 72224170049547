const initialState = {
  shouldRedirect: false,
  redirectTo: null,
};

const myJobsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_REDIRECT":
      return {
        shouldRedirect: true,
        redirectTo: action.payload.path,
      };
    case "CLEAR_REDIRECT":
      return {
        shouldRedirect: false,
        redirectTo: null,
      };
    default:
      return state;
  }
};

export default myJobsReducer;
