import React, { useState, useEffect } from 'react';
import "./searchJobPage.css";
import SearchJoblist from "./SearchJobs_list";
import axios from "axios";
import Key from "../../clientVariables.json";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { showLoader } from "../../Components/Actions/loader.actions";
import { hideLoader } from "../../Components/Actions/loader.actions";
import ResumeChips from "./resumeChips";
import { BsSortUp } from 'react-icons/bs';
import { useTheme , useMediaQuery } from "@mui/material";
const jobtypes = [
  { id: 1, text: "Full-Time", value: "1" },
  { id: 2, text: "Part-Time", value: "2" },
  { id: 10, text: "Contract", value: "10" },
  { id: 3, text: "Internship", value: "3" },
  { id: 4, text: "W2 - Permanent", value: "4" },
  { id: 5, text: "W2 - Contract", value: "5" },
  { id: 6, text: "1099-Contract", value: "6" },
  { id: 7, text: "Contract to Hire", value: "7" },
  { id: 8, text: "Need H1B", value: "8" },
  { id: 9, text: "Hourly Employee", value: "9" },
  { id: 11, text: "Contract - Corp-to-Corp", value: "11" },
  { id: 13, text: "Contract - Independent", value: "13" },
  { id: 14, text: "Contract to Hire - Corp-to-Corp", value: "14" },
  { id: 15, text: "Contract to Hire - W2", value: "15" },
  { id: 16, text: "Contract to Hire - Independent", value: "16" },
    
];

const visatypes = [
  
  { id: 3, text: "H1", value: "3" },
  { id: 5, text: "F1", value: "5" },
  { id: 6, text: "TN", value: "6" },
  { id: 9, text: "L1", value: "9" },
  { id: 12, text: "H4", value: "12" },
  { id: 13, text: "H1B", value: "13" },
  { id: 10, text: "GC - EAD", value: "10" },
  { id: 11, text: "H4 - EAD", value: "11" },
  { id: 14, text: "L2 - EAD", value: "14" },
  { id: 15, text: "OPT - EAD", value: "15" },
  { id: 1, text: "GC - Green Card", value: "1" },
  { id: 2, text: "USC- U.S. Citizen ", value: "2" },
  { id: 4, text: "OPT - Optional Practical Training ", value: "4" },
  { id: 16, text: "CPT - Curricular Practical Training", value: "16" },
  { id: 7, text: "EAD - Employment Authorization Document", value: "7" },
  { id: 8, text: "Others", value: "8" },
  
];


const posteddate = [
  { id: 1, text: "Today", value: "1" },
  { id: 2, text: "Last Week", value: "2" },
  { id: 3, text: "Last Month", value: "3" },
  { id: 4, text: "Anytime", value: "4" },
];

const traveltype = [
  { name: "Not Required", value: "Not Required", id: 0 },
  { name: "25%", value: "25%", id: 1 },
  { name: "50%", value: "50%", id: 2 },
  { name: "75%", value: "75%", id: 3 },
  { name: "100%", value: "100%", id: 4 },
];

const workmode = [
  { name: "Remote Only", value: "true", id: true },
  { name: "Onsite(location)", value: "false", id: false },
];

const Searchjob_filters = (props) => {
  const [jobtypeflages, setJobtypeflages] = useState([]);
  const [emptyJobtypeFlages, setEmptyJobtypeFlages] = useState([]);
  const [emptyJobtypeFlages1, setEmptyJobtypeFlages1] = useState("1");
  const [sortmodal, setSortmodal] = useState(false);
  const [jobtypeValue, setJobtypeValue] = useState("");
  const [Visaflages, setVisaflages] = useState(JSON.parse(localStorage.getItem("visatypeFlages")) || []);
  const [dateValue, setDateValue] = useState("");
  const [Travelflages, setTravelflages] = useState(JSON.parse(localStorage.getItem("traveltypeFlages")) || []);
  const [sortValue, setSortValue] = useState("");
  const [workModeFlages, setWorkModeFlages] = useState(JSON.parse(localStorage.getItem("workModeFlages")) || []);
  const [index, setIndex] = useState(1);
  const [recent, setRecent] = useState(false);
  const [relavance, setRelavance] = useState(false);
  const [jobtypefilterFlag, setJobtypefilterFlag] = useState(0);
  const theme = useTheme();
const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Mobile devices
const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md")); // Tablets
const isDesktopSmall = useMediaQuery(theme.breakpoints.between("md", "lg")); // Small desktops
const isDesktopLarge = useMediaQuery(theme.breakpoints.up("lg")); // Large


  const jobtypeFun = (id) => {
    props.onFilterChecked(true);
    props.showLoader();
    setJobtypeValue(localStorage.getItem("jobs-jobtype-1") || "");
    let checkedBox = id;
    console.log(checkedBox);
    localStorage.removeItem("filterResetValue");

    setJobtypeflages((previousJobtypeflages) => {
      let jobtypeflagesCopy = [...previousJobtypeflages];
      let index = jobtypeflagesCopy?.indexOf(checkedBox);
      
      if (index === -1) {
        jobtypeflagesCopy.push(checkedBox);
        localStorage.setItem("jobtypeFlages", JSON.stringify(jobtypeflagesCopy));
        localStorage.setItem("searchJobsChecked", JSON.stringify(jobtypeflagesCopy));
        let jobtypeMappedIds = jobtypeflagesCopy?.map(fid => `${fid}`).join(" OR ");
        {
          jobtypeflagesCopy?.map((fid, i) => [
            { fid },
            i < jobtypeflagesCopy.length - 1 && " OR ",
          ]);
        }
        sessionStorage.setItem("filtered-jobtypes", jobtypeMappedIds);
        if (jobtypeMappedIds !== "") {
          getFilteredJobs();
        }
      } else {
        jobtypeflagesCopy.splice(index, 1);
        localStorage.setItem("jobtypeFlages", JSON.stringify(jobtypeflagesCopy));
        localStorage.setItem("searchJobsChecked", JSON.stringify(jobtypeflagesCopy));
        let jobtypeMappedIds = jobtypeflagesCopy?.map(fid => `${fid}`).join(" OR ");
        {
          jobtypeflagesCopy?.map((fid, i) => [
            { fid },
            i < jobtypeflagesCopy.length - 1 && " OR ",
          ]);
        }
        sessionStorage.setItem("filtered-jobtypes", jobtypeMappedIds);
        getFilteredJobs();
      }
      
      return jobtypeflagesCopy;
    });
  };
  const visatypeFun = (value) => {
    console.log("visatype called");
    let checkedBox = value;
    props.onFilterChecked(true);
    props.showLoader();
    localStorage.removeItem("filterResetValue");

    setVisaflages((previousVisaflages) => {
      let VisaflagesCopy = [...previousVisaflages];
      let index = VisaflagesCopy?.indexOf(checkedBox);
      
      if (index === -1) {
        VisaflagesCopy.push(checkedBox);
        localStorage.setItem("visatypeFlages", JSON.stringify(VisaflagesCopy));
        localStorage.setItem("searchJobsChecked", JSON.stringify(VisaflagesCopy));
        let visatypeMappedIds = VisaflagesCopy?.map(fid => `${fid}`).join(" OR ");
        {
          VisaflagesCopy?.map((fid, i) => [
            { fid },
            i < VisaflagesCopy.length - 1 && " OR ",
          ]);
        }
        sessionStorage.setItem("filtered-visatypes", visatypeMappedIds);
        if (visatypeMappedIds !== "") {
          getFilteredJobs();
        }
      } else {
        VisaflagesCopy.splice(index, 1);
        localStorage.setItem("visatypeFlages", JSON.stringify(VisaflagesCopy));
        localStorage.setItem("searchJobsChecked", JSON.stringify(VisaflagesCopy));
        let visatypeMappedIds = VisaflagesCopy?.map(fid => `${fid}`).join(" OR ");
        {
          VisaflagesCopy?.map((fid, i) => [
            { fid },
            i < VisaflagesCopy.length - 1 && " OR ",
          ]);
        }
        sessionStorage.setItem("filtered-visatypes", visatypeMappedIds);
        getFilteredJobs();
      }
      
      return VisaflagesCopy;
    });
    const handleOpen = () => {
      setSortmodal(true)
    };
    const handleClose = () => {
      setSortmodal(false)
    };
  };
  const sortby = (sortValue) => {
    console.log(sortValue);
    props.showLoader();

    if (sortValue === "recent") {
      localStorage.setItem("jobsSortBy", "recent");
      setRecent(!recent);
      setRelavance(false);
      setSortValue(sortValue);
      getFilteredJobs(sortValue);
    } else if (sortValue === "relavance") {
      localStorage.setItem("jobsSortBy", "relavance");
      setRelavance(!relavance);
      setRecent(false);
      setSortValue(sortValue);
      getFilteredJobs(sortValue);
    } else if (sortValue === "remove") {
      localStorage.removeItem("jobsSortBy");
      setRelavance(false);
      setRecent(false);
      setSortValue("recent");
      getFilteredJobs("recent");
    } else if (sortValue === "clearRecent" || sortValue === "clearRelavance") {
      localStorage.removeItem("jobsSortBy");
      setRelavance(false);
      setRecent(false);
      setSortValue("recent");
      getFilteredJobs("recent");
    }
    console.log(sortValue);
  };
  const sortByDate = (value) => {
    props.onFilterChecked(true);
    props.showLoader();
    localStorage.removeItem("filterResetValue");

    if (value === 1 || value === 2 || value === 3) {
      setDateValue(value);
      localStorage.setItem("jobDateValue", JSON.stringify(value));
      localStorage.setItem("searchJobsChecked", JSON.stringify(value));
      localStorage.setItem("jobsSortByDate", "jobsSortByDate");

      const today = new Date();
      let startdate;
      if (value === 1) {
        startdate = moment(today).subtract(1, "d").format("YYYY-MM-DD hh:mm:ss");
      } else if (value === 2) {
        startdate = moment(today).subtract(7, "d").format("YYYY-MM-DD hh:mm:ss");
      } else if (value === 3) {
        startdate = moment(today).subtract(30, "d").format("YYYY-MM-DD hh:mm:ss");
      }
      const convertedStartDate = moment.utc(startdate).format();
      const mappeddate = convertedStartDate + " TO " + "NOW";
      console.log(mappeddate);
      sessionStorage.setItem("jobMappedDates", mappeddate);
      getFilteredJobs();
    }  else if (value == 4) {
      setDateValue(value)
      localStorage.setItem("jobDateValue", JSON.stringify(value));
      localStorage.setItem("searchJobsChecked", JSON.stringify(value));
      localStorage.setItem("jobsSortByDate", "jobsSortByDate");

      var mappeddate = "*" + " TO " + "NOW";
      console.log(mappeddate);

      sessionStorage.setItem("jobMappedDates", mappeddate);
      getFilteredJobs();
    } else if (value == "remove") {
      setDateValue("")
      localStorage.setItem("jobDateValue", JSON.stringify(value));
      localStorage.setItem("searchJobsChecked", JSON.stringify(value));
      localStorage.removeItem("jobsSortByDate");

      var mappeddate = "*" + " TO " + "NOW";
      console.log(mappeddate);

      sessionStorage.setItem("jobMappedDates", mappeddate);
      getFilteredJobs();
    }
  };
  const handleworkmode = (value) => {
    props.onFilterChecked(true);
    props.showLoader();
    localStorage.removeItem("filterResetValue");

    let checkedBox = value;
    let updatedWorkModeFlages = [...workModeFlages];
    let index = updatedWorkModeFlages?.indexOf(checkedBox);

    if (index === -1) {
      updatedWorkModeFlages.push(checkedBox);
      localStorage.setItem(
        "workModeFlages",
        JSON.stringify(updatedWorkModeFlages)
      );
      localStorage.setItem(
        "searchJobsChecked",
        JSON.stringify(updatedWorkModeFlages)
      );
      var arr = JSON.parse(localStorage.getItem("workModeFlages"));
      //console.log(arr)
      var workmodeMappedIds = updatedWorkModeFlages?.map((fid) => `${fid}`)
        .join(" OR ");
      {
        updatedWorkModeFlages?.map((fid, i) => [
          { fid },
          i < updatedWorkModeFlages?.length - 1 && " OR ",
        ]);
      }
      //console.log(traveltypeMappedIds)
      sessionStorage.setItem("filtered-workmode", workmodeMappedIds);

      this.getFilteredJobs();
    } else {
      updatedWorkModeFlages?.splice(index, 1);
      localStorage.setItem(
        "workModeFlages",
        JSON.stringify(updatedWorkModeFlages)
      );
      localStorage.setItem(
        "searchJobsChecked",
        JSON.stringify(updatedWorkModeFlages)
      );
      var arr = JSON.parse(localStorage.getItem("workModeFlages"));
      //console.log(arr)
      workmodeMappedIds = updatedWorkModeFlages?.map((fid) => `${fid}`).join(" OR ");
      {
        updatedWorkModeFlages?.map((fid, i) => [
          { fid },
          i < updatedWorkModeFlages?.length - 1 && " OR ",
        ]);
      }
      //console.log(traveltypeMappedIds)
      sessionStorage.setItem("filtered-workmode", workmodeMappedIds);
      getFilteredJobs();
    }

    setWorkModeFlages(updatedWorkModeFlages);

    localStorage.setItem("workModeFlages", JSON.stringify(updatedWorkModeFlages));
    localStorage.setItem("searchJobsChecked", JSON.stringify(updatedWorkModeFlages));

    let workModeMappedIds = updatedWorkModeFlages.join(" OR ");
    sessionStorage.setItem("filtered-workmode", workModeMappedIds);

    getFilteredJobs(); // Define getFilteredJobs function if needed
    return { workModeFlages }; 
  };
  const handleTraveltype = (value) => {
    props.onFilterChecked(true);
    props.showLoader();
    localStorage.removeItem("filterResetValue");

    let checkedBox = value;
    let updatedTravelflages = [...Travelflages];
    let index = updatedTravelflages?.indexOf(checkedBox);

    if (index === -1) {
      updatedTravelflages?.push(checkedBox);
      var traveltypeMappedIds = updatedTravelflages?.map(
        (fid) => `${fid}`
      ).join(" OR ");
      {
        updatedTravelflages?.map((fid, i) => [
          { fid },
          i < updatedTravelflages?.length - 1 && " OR ",
        ]);
      }
      //console.log(traveltypeMappedIds)
      sessionStorage.setItem("filtered-traveltypes", traveltypeMappedIds);

      //getFilteredJobs();
    } else {
      updatedTravelflages?.splice(index, 1);
      traveltypeMappedIds = updatedTravelflages?.map((fid) => `${fid}`).join(" OR ");
      {
        updatedTravelflages?.map((fid, i) => [
          { fid },
          i < updatedTravelflages?.length - 1 && " OR ",
        ]);
      }
    }

    setTravelflages(updatedTravelflages);

    localStorage.setItem("traveltypeFlages", JSON.stringify(updatedTravelflages));
    localStorage.setItem("searchJobsChecked", JSON.stringify(updatedTravelflages));

     traveltypeMappedIds = updatedTravelflages.join(" OR ");
    sessionStorage.setItem("filtered-traveltypes", traveltypeMappedIds);

    getFilteredJobs(); // Define getFilteredJobs function if needed
  };

  const getFilteredJobs = (sortValue)  => {
    var jobtype = sessionStorage.getItem("filtered-jobtypes");
    var visatype = sessionStorage.getItem("filtered-visatypes");
    var traveltype = sessionStorage.getItem("filtered-traveltypes");
    var workmode = sessionStorage.getItem("filtered-workmode");
    var jobtitle = sessionStorage.getItem("jobtitle")
      ? sessionStorage.getItem("jobtitle")
      : "";
    var cityname = sessionStorage.getItem("cityname");
    var inputjobtitle = jobtitle?.replace(/"/g, "");
    var Mappeddates = sessionStorage.getItem("jobMappedDates");

    const str = inputjobtitle;
    //const res = str.includes("developer") || str.includes("programmer") || str.includes('architect') || str.includes("designer") || str.includes('consultant') || str.includes("administrator") || str.includes('coach') || str.includes("lead") || str.includes('manager') || str.includes("master") || str.includes('specalist') || str.includes('modeler') || str.includes('director') || str.includes('hacker');

    var default_Job_role =
      sessionStorage.getItem("id") !== null
        ? '"' + sessionStorage.getItem("job_role") + '"'
        : "*%3A*";
    console.log(default_Job_role);

    // if (res === true) {
    console.log(inputjobtitle);
    inputjobtitle = inputjobtitle !== "" ? '"' + str + '"' : "*%3A*";
    //  }

    if (sortValue !== "" && sortValue !== undefined) {
      if (sortValue === "recent") {
        var filterJobsUrl = `${Key.Solr_url}/${Key.solrJobs_collection}/select?q=title:(${
          inputjobtitle ? inputjobtitle : default_Job_role
        }) OR companyName:(${
          inputjobtitle ? inputjobtitle : default_Job_role
        }) OR id:(${
          inputjobtitle ? inputjobtitle : default_Job_role
        })&fq=${cityname}&fq=jobtype:(${
          jobtype ? jobtype : "*:*"
        })&fq=workpermitName:(${
          visatype ? visatype : "*:*"
        })&fq=requiredtravel:(${traveltype ? traveltype : "*:*"})&fq=created:[${
          Mappeddates !== null ? Mappeddates : "* TO *"
        }]&fq=status:(1)&rows=10&start=${
          this.props.start
        }&sort=created%20desc,id%20asc&wt=json`;
      } else if (sortValue === "relavance") {
        filterJobsUrl = `${Key.Solr_url}/${Key.solrJobs_collection}/select?q=title:(${
          inputjobtitle ? inputjobtitle : default_Job_role
        }) OR companyName:(${
          inputjobtitle ? inputjobtitle : default_Job_role
        }) OR id:(${
          inputjobtitle ? inputjobtitle : default_Job_role
        })&fq=${cityname}&fq=jobtype:(${
          jobtype ? jobtype : "*:*"
        })&fq=workpermitName:(${
          visatype ? visatype : "*:*"
        })&fq=requiredtravel:(${traveltype ? traveltype : "*:*"})&fq=created:[${
          Mappeddates !== null ? Mappeddates : "* TO *"
        }]&fq=status:(1)&rows=10&start=${this.props.start}&wt=json`;
      }
    } else if (workmode !== "" && workmode !== null) {
      filterJobsUrl = `${Key.Solr_url}/${Key.solrJobs_collection}/select?q=title:(${
        inputjobtitle ? inputjobtitle : default_Job_role
      }) OR companyName:(${
        inputjobtitle ? inputjobtitle : default_Job_role
      }) OR id:(${
        inputjobtitle ? inputjobtitle : default_Job_role
      })&fq=${cityname}&fq=jobtype:(${
        jobtype ? jobtype : "*:*"
      })&fq=workpermitName:(${
        visatype ? visatype : "*:*"
      })&fq=requiredtravel:(${
        traveltype ? traveltype : "*:*"
      })&fq=workpermit:(${visatype ? visatype : "*:*"})&fq=created:[${
        Mappeddates !== null ? Mappeddates : "* TO *"
      }]&fq=status:(1)&fq=workmode:(${workmode})&rows=10&start=0&sort=created%20desc,id%20asc&wt=json`;
    } else {
      filterJobsUrl = `${Key.Solr_url}/${Key.solrJobs_collection}/select?q=title:(${
        inputjobtitle ? inputjobtitle : default_Job_role
      }) OR companyName:(${
        inputjobtitle ? inputjobtitle : default_Job_role
      }) OR id:(${
        inputjobtitle ? inputjobtitle : default_Job_role
      }) &fq=${cityname}&fq=jobtype:(${
        jobtype ? jobtype : "*:*"
      })&fq=workpermitName:(${
        visatype ? visatype : "*:*"
      })&fq=requiredtravel:(${traveltype ? traveltype : "*:*"})&fq=created:[${
        Mappeddates !== null ? Mappeddates : "* TO *"
      }]&fq=status:(1)&rows=10&start=0&sort=created%20desc,id%20asc&wt=json`;
    }

    axios
      .post(
        `${Key.domain}/solr/solr_jobs`,
        { url: filterJobsUrl },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((resdata) => {
        console.log(resdata);
        const retrivedData = resdata.data.data.response?.docs;
        console.log(resdata.data.data.response?.numFound);
        sessionStorage.setItem(
          "jobsCount",
          resdata.data.data?.response?.numFound
        );

        this.props.history.push({
          pathname: "/searchJobs_listview",
          state: {
            response: retrivedData,
            jobtitle: true,
            location: false,
            filterChecked: true,
          },
        });
      });
  }

  return(
    <div>
       <ResumeChips
          data={Visaflages}
          data2={jobtypeflages}
          data3={[]}
          data4={[]}
          data5={Travelflages}
          data6={dateValue}
          sortValue={sortValue}
          recent={recent}
          relavance={relavance}
          workModeFlages={workModeFlages}
          jobtypeFunction={jobtypeFun}
          handleVisatype={visatypeFun}
          handleTraveltype={handleTraveltype}
          selectedOptionValue={sortByDate}
          sortby={sortby}
          handleworkmode={handleworkmode}
          jobtypes={jobtypes}
          lastupdated={posteddate}
          //handleLoadingClick={handleLoadingClick}
          visaOptions={visatypes}
          traveltypes={traveltype}
          workmodetypes={workmode}
        />

      {
        (isDesktopLarge || isDesktopSmall) && (
          <div className="filters" style={{ marginTop: "21px" }}>
            <div className="accordion-item">
              <div className="card">
                <div
                  className="accordion-header card-header head"
                  id="headingThree"
                  style={{ backgroundColor: "#fff" }}
                >
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="true"
                    aria-controls="collapseThree"
                    style={{ color: "#000", backgroundColor: "#fff" }}
                  >
                    <b>Sort By</b>
                  </button>
                </div>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body" id="accordion-body">
                    <ul className="list-group">
                      <li className="list-group-item">
                        <input
                          className="form-check-input me-1"
                          type="checkbox"
                          value=""
                          aria-label="..."
                          onClick={(e) => {
                            sortby(
                              recent ? "clearRecent" : "recent"
                            );
                            //this.setJobtypeValue(i)
                            //console.log(e.target.checked)
                          }}
                          checked={
                            localStorage.getItem("jobsSortBy") === "recent"
                              ? true
                              : false
                          }
                        />
                        &nbsp; Recent
                      </li>
                      <li className="list-group-item">
                        <input
                          className="form-check-input me-1"
                          type="checkbox"
                          value=""
                          aria-label="..."
                          onClick={(e) => {
                            sortby(
                              relavance
                                ? "clearRelavance"
                                : "relavance"
                            );
                            //this.setJobtypeValue(i)
                            //console.log(e.target.checked)
                          }}
                          // checked={this.state.relavance}
                          checked={
                            localStorage.getItem("jobsSortBy") === "relavance"
                              ? true
                              : false
                          }
                        />
                        &nbsp; Relavance
                      </li>
                      {/*  <li className="list-group-item">
                                <input
                                    className="form-check-input me-1"
                                    type="checkbox"
                                    value=""
                                    aria-label="..."
                                />&nbsp;
                  Popularity
                </li>*/}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="card">
                <div
                  className="accordion-header card-header head"
                  id="headingsecond"
                  style={{ backgroundColor: "#fff" }}
                >
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsesecond"
                    aria-expanded="true"
                    aria-controls="collapsesecond"
                    style={{ color: "#000", backgroundColor: "#fff" }}
                  >
                    <b>Job Type</b>
                  </button>
                </div>
                <div
                  id="collapsesecond"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingsecond"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body" id="accordion-body">
                    <ul className="list-group ">
                      {jobtypes?.map((jobtype) => (
                        <li className="list-group-item">
                          <input
                            className="form-check-input me-1"
                            type="checkbox"
                            // value={jobtype.id}
                            aria-label="..."
                            onClick={(e) => {
                              localStorage.setItem(
                                "jobs-jobtype",
                                `${e.target.checked}`
                              );
                              setJobtypeflages(
                                parseInt(localStorage.getItem("filterResetValue")) === 1 ? [] : jobtypeflages
                              );
                              setTimeout(() => {
                                jobtypeFun(jobtype?.id);
                              }, 200);
                            }}
                            checked={(
                              JSON.parse(
                                localStorage.getItem("jobtypeFlages")
                              ) || []
                            ).includes(jobtype?.id)}
                          />
                          &nbsp;
                          {jobtype?.text}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="card">
                <div
                  className="accordion-header card-header head"
                  id="headingfour"
                  style={{ backgroundColor: "#fff" }}
                >
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsefour"
                    aria-expanded="true"
                    aria-controls="collapsefour"
                    style={{
                      color: "#000",
                      backgroundColor: "#fff",
                      boxShadow: "none",
                    }}
                  >
                    <b>Posted Date</b>
                  </button>
                </div>
                <div
                  id="collapsefour"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingfour"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body" id="accordion-body">
                    <ul className="list-group">
                      {posteddate?.map((date) => (
                        <li className="list-group-item">
                          <input
                            className="form-check-input me-1"
                            type="checkbox"
                            value=""
                            aria-label="..."
                            onClick={() => {
                              sortByDate(date?.id);
                            }}
                            //  checked={this.state.dateValue === date.id}
                            checked={
                              localStorage.getItem("jobsSortByDate") ===
                                "jobsSortByDate"
                                ? dateValue === date?.id
                                : false
                            }
                          />
                          &nbsp;
                          {date?.text}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="card">
                <div
                  className="accordion-header card-header head"
                  id="headingfive"
                  style={{ backgroundColor: "#fff" }}
                >
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsefive"
                    aria-expanded="true"
                    aria-controls="collapsefive"
                    style={{
                      color: "#000",
                      backgroundColor: "#fff",
                      boxShadow: "none",
                    }}
                  >
                    &nbsp;
                    <b>Visa Type</b>
                  </button>
                </div>
                <div
                  id="collapsefive"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingfive"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body" id="accordion-body">
                    <ul className="list-group">
                      {visatypes?.map((visatype) => (
                        <li className="list-group-item">
                          <input
                            className="form-check-input me-1"
                            type="checkbox"
                            value={visatype.id}
                            aria-label="..."
                            onClick={(e) => {
                              localStorage.setItem(
                                "jobs-visatype",
                                `${e.target.checked}`
                              );
                              //this.setJobtypeValue(i)
                              //console.log(e.target.checked)

                              setVisaflages(
                                parseInt(localStorage.getItem("filterResetValue")) === 1 ? [] : Visaflages
                              );
                              setTimeout(() => {
                                visatypeFun(visatype?.text);
                              }, 200);
                            }}
                            checked={(
                              JSON.parse(
                                localStorage.getItem("visatypeFlages")
                              ) || []
                            ).includes(visatype?.text)}
                          />
                          &nbsp;
                          {visatype?.text}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="card">
                <div
                  className="accordion-header card-header head"
                  id="headingsix"
                  style={{ backgroundColor: "#fff" }}
                >
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsesix"
                    aria-expanded="true"
                    aria-controls="collapsesix"
                    style={{ color: "#000", backgroundColor: "#fff" }}
                  >
                    <b>Work Mode</b>
                  </button>
                </div>
                <div
                  id="collapsesix"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingsix"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body" id="accordion-body">
                    <ul className="list-group">
                      {workmode?.map((workmode) => (
                        <li className="list-group-item">
                          <input
                            className="form-check-input me-1"
                            type="checkbox"
                            value=""
                            aria-label="..."
                            onClick={(e) => {
                              localStorage.setItem(
                                "jobs-visatype",
                                `${e.target.checked}`
                              );
                              //this.setJobtypeValue(i)
                              //console.log(e.target.checked)
                              setWorkModeFlages(
                                parseInt(localStorage.getItem("filterResetValue")) === 1 ? [] : workModeFlages
                              );
                              setTimeout(() => {
                                handleworkmode(workmode?.id);
                              }, 200);
                            }}
                            checked={(
                              JSON.parse(
                                localStorage.getItem("workModeFlages")
                              ) || []
                            ).includes(workmode?.id)}
                          />
                          &nbsp;
                          {workmode?.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="card">
                <div
                  className="accordion-header card-header head"
                  id="headingSeven"
                  style={{ backgroundColor: "#fff" }}
                >
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSeven"
                    aria-expanded="true"
                    aria-controls="collapseSeven"
                    style={{ color: "#000", backgroundColor: "#fff" }}
                  >
                    &nbsp;
                    <b>Travel</b>
                  </button>
                </div>
                <div
                  id="collapseSeven"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingSeven"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body" id="accordion-body">
                    <ul className="list-group">
                      {traveltype?.map((travel) => (
                        <li className="list-group-item">
                          <input
                            className="form-check-input me-1"
                            type="checkbox"
                            value=""
                            aria-label="..."
                            onClick={() => {
                              setTravelflages(
                                parseInt(localStorage.getItem("filterResetValue")) === 1 ? [] : Travelflages
                              );
                              setTimeout(() => {
                                handleTraveltype(travel?.id);
                              }, 200);
                            }}
                            checked={(
                              JSON.parse(
                                localStorage.getItem("traveltypeFlages")
                              ) || []
                            ).includes(travel?.id)}
                          />
                          &nbsp;
                          {travel?.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="card distance-section">
                <div
                  className="accordion-header card-header head"
                  id="headingSix"
                  style={{ backgroundColor: "#fff" }}
                >
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="true"
                    aria-controls="collapseSix"
                    style={{ color: "#000", backgroundColor: "#fff" }}
                  >
                    Distance
                  </button>
                </div>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingSix"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body" id="accordion-body">
                    <div
                      class="btn-group"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button
                        type="button"
                        class={
                          index === 1
                            ? "btn btn-outline-secondary  btn-sm"
                            : "btn btn-secondary-secondary"
                        }
                        onClick={() => setIndex(1)}
                      >
                        Km
                      </button>
                      <button
                        type="button"
                        class={
                          index === 2
                            ? "btn btn-outline-secondary btn-sm"
                            : "btn btn-secondary-warning "
                        }
                        onClick={() => setIndex(2)}
                      >
                        Mile
                      </button>
                    </div>

                    <ul className="list-group">
                      {index === 1 ? (
                        <>
                          <li className="list-group-item">
                            <input
                              className="form-check-input me-1"
                              type="checkbox"
                              value=""
                              aria-label="..."
                            />
                            &nbsp; Within 10km
                          </li>
                          <li className="list-group-item">
                            <input
                              className="form-check-input me-1"
                              type="checkbox"
                              value=""
                              aria-label="..."
                            />
                            &nbsp; Within 20km
                          </li>

                          <li className="list-group-item">
                            <input
                              className="form-check-input me-1"
                              type="checkbox"
                              value=""
                              aria-label="..."
                            />
                            &nbsp; Within 30km
                          </li>
                        </>
                      ) : (
                        <div>
                          <li className="list-group-item">
                            <input
                              className="form-check-input me-1"
                              type="checkbox"
                              value=""
                              aria-label="..."
                            />
                            &nbsp; Within 10Miles
                          </li>
                          <li className="list-group-item">
                            <input
                              className="form-check-input me-1"
                              type="checkbox"
                              value=""
                              aria-label="..."
                            />
                            &nbsp; Within 20Miles
                          </li>

                          <li className="list-group-item">
                            <input
                              className="form-check-input me-1"
                              type="checkbox"
                              value=""
                              aria-label="..."
                            />
                            &nbsp; Within 30Miles
                          </li>
                        </div>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

        )
      }
      {
        (isMobile || isTablet ) && (
          <div className="filters" style={{ marginTop: "21px" }}>
          <div className="accordion-item">
            <div className="card">
              <div
                className="accordion-header card-header head"
                id="headingThree"
                style={{ backgroundColor: "#fff" }}
              >
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="true"
                  aria-controls="collapseThree"
                  style={{ color: "#000", backgroundColor: "#fff" }}
                >
                  <b>Sort By</b>
                </button>
              </div>
              <div
                id="collapseThree"
                className="accordion-collapse collapse show"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body" id="accordion-body">
                  <ul className="list-group">
                    <li className="list-group-item">
                      <input
                        className="form-check-input me-1"
                        type="checkbox"
                        value=""
                        aria-label="..."
                        onClick={(e) => {
                          sortby(
                            recent ? "clearRecent" : "recent"
                          );
                          //this.setJobtypeValue(i)
                          //console.log(e.target.checked)
                        }}
                        checked={
                          localStorage.getItem("jobsSortBy") === "recent"
                            ? true
                            : false
                        }
                      />
                      &nbsp; Recent
                    </li>
                    <li className="list-group-item">
                      <input
                        className="form-check-input me-1"
                        type="checkbox"
                        value=""
                        aria-label="..."
                        onClick={(e) => {
                          sortby(
                            relavance
                              ? "clearRelavance"
                              : "relavance"
                          );
                          //this.setJobtypeValue(i)
                          //console.log(e.target.checked)
                        }}
                        // checked={this.state.relavance}
                        checked={
                          localStorage.getItem("jobsSortBy") === "relavance"
                            ? true
                            : false
                        }
                      />
                      &nbsp; Relavance
                    </li>
                    {/*  <li className="list-group-item">
                              <input
                                  className="form-check-input me-1"
                                  type="checkbox"
                                  value=""
                                  aria-label="..."
                              />&nbsp;
                Popularity
              </li>*/}
                  </ul>
                </div>
              </div>
            </div>

            <div className="card">
              <div
                className="accordion-header card-header head"
                id="headingsecond"
                style={{ backgroundColor: "#fff" }}
              >
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsesecond"
                  aria-expanded="true"
                  aria-controls="collapsesecond"
                  style={{ color: "#000", backgroundColor: "#fff" }}
                >
                  <b>Job Type</b>
                </button>
              </div>
              <div
                id="collapsesecond"
                className="accordion-collapse collapse show"
                aria-labelledby="headingsecond"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body" id="accordion-body">
                  <ul className="list-group ">
                    {jobtypes?.map((jobtype) => (
                      <li className="list-group-item">
                        <input
                          className="form-check-input me-1"
                          type="checkbox"
                          // value={jobtype.id}
                          aria-label="..."
                          onClick={(e) => {
                            localStorage.setItem(
                              "jobs-jobtype",
                              `${e.target.checked}`
                            );
                            setJobtypeflages(
                              parseInt(localStorage.getItem("filterResetValue")) === 1 ? [] : jobtypeflages
                            );
                            setTimeout(() => {
                              jobtypeFun(jobtype?.id);
                            }, 200);
                          }}
                          checked={(
                            JSON.parse(
                              localStorage.getItem("jobtypeFlages")
                            ) || []
                          ).includes(jobtype?.id)}
                        />
                        &nbsp;
                        {jobtype?.text}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            <div className="card">
              <div
                className="accordion-header card-header head"
                id="headingfour"
                style={{ backgroundColor: "#fff" }}
              >
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefour"
                  aria-expanded="true"
                  aria-controls="collapsefour"
                  style={{
                    color: "#000",
                    backgroundColor: "#fff",
                    boxShadow: "none",
                  }}
                >
                  <b>Posted Date</b>
                </button>
              </div>
              <div
                id="collapsefour"
                className="accordion-collapse collapse show"
                aria-labelledby="headingfour"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body" id="accordion-body">
                  <ul className="list-group">
                    {posteddate?.map((date) => (
                      <li className="list-group-item">
                        <input
                          className="form-check-input me-1"
                          type="checkbox"
                          value=""
                          aria-label="..."
                          onClick={() => {
                           sortByDate(date?.id);
                          }}
                          //  checked={this.state.dateValue === date.id}
                          checked={
                            localStorage.getItem("jobsSortByDate") ===
                            "jobsSortByDate"
                              ? dateValue === date?.id
                              : false
                          }
                        />
                        &nbsp;
                        {date?.text}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            <div className="card">
              <div
                className="accordion-header card-header head"
                id="headingfive"
                style={{ backgroundColor: "#fff" }}
              >
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefive"
                  aria-expanded="true"
                  aria-controls="collapsefive"
                  style={{
                    color: "#000",
                    backgroundColor: "#fff",
                    boxShadow: "none",
                  }}
                >
                  &nbsp;
                  <b>Visa Type</b>
                </button>
              </div>
              <div
                id="collapsefive"
                className="accordion-collapse collapse show"
                aria-labelledby="headingfive"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body" id="accordion-body">
                  <ul className="list-group">
                    {visatypes?.map((visatype) => (
                      <li className="list-group-item">
                        <input
                          className="form-check-input me-1"
                          type="checkbox"
                          value={visatype.id}
                          aria-label="..."
                          onClick={(e) => {
                            localStorage.setItem(
                              "jobs-visatype",
                              `${e.target.checked}`
                            );
                            //this.setJobtypeValue(i)
                            //console.log(e.target.checked)

                            setVisaflages(
                              parseInt(localStorage.getItem("filterResetValue")) === 1 ? [] : Visaflages
                            );
                            setTimeout(() => {
                              visatypeFun(visatype?.text);
                            }, 200);
                          }}
                          checked={(
                            JSON.parse(
                              localStorage.getItem("visatypeFlages")
                            ) || []
                          ).includes(visatype?.text)}
                        />
                        &nbsp;
                        {visatype?.text}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            <div className="card">
              <div
                className="accordion-header card-header head"
                id="headingsix"
                style={{ backgroundColor: "#fff" }}
              >
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsesix"
                  aria-expanded="true"
                  aria-controls="collapsesix"
                  style={{ color: "#000", backgroundColor: "#fff" }}
                >
                  <b>Work Mode</b>
                </button>
              </div>
              <div
                id="collapsesix"
                className="accordion-collapse collapse show"
                aria-labelledby="headingsix"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body" id="accordion-body">
                  <ul className="list-group">
                    {workmode?.map((workmode) => (
                      <li className="list-group-item">
                        <input
                          className="form-check-input me-1"
                          type="checkbox"
                          value=""
                          aria-label="..."
                          onClick={(e) => {
                            localStorage.setItem(
                              "jobs-visatype",
                              `${e.target.checked}`
                            );
                            //this.setJobtypeValue(i)
                            //console.log(e.target.checked)
                            setWorkModeFlages(
                              parseInt(localStorage.getItem("filterResetValue")) === 1 ? [] : workModeFlages
                            );
                            setTimeout(() => {
                              handleworkmode(workmode?.id);
                            }, 200);
                          }}
                          checked={(
                            JSON.parse(
                              localStorage.getItem("workModeFlages")
                            ) || []
                          ).includes(workmode?.id)}
                        />
                        &nbsp;
                        {workmode?.name}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            <div className="card">
              <div
                className="accordion-header card-header head"
                id="headingSeven"
                style={{ backgroundColor: "#fff" }}
              >
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="true"
                  aria-controls="collapseSeven"
                  style={{ color: "#000", backgroundColor: "#fff" }}
                >
                  &nbsp;
                  <b>Travel</b>
                </button>
              </div>
              <div
                id="collapseSeven"
                className="accordion-collapse collapse show"
                aria-labelledby="headingSeven"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body" id="accordion-body">
                  <ul className="list-group">
                    {traveltype?.map((travel) => (
                      <li className="list-group-item">
                        <input
                          className="form-check-input me-1"
                          type="checkbox"
                          value=""
                          aria-label="..."
                          onClick={() => {
                            setTravelflages(
                              parseInt(localStorage.getItem("filterResetValue")) === 1 ? [] : Travelflages
                            );
                            setTimeout(() => {
                              handleTraveltype(travel?.id);
                            }, 200);
                          }}
                          checked={(
                            JSON.parse(
                              localStorage.getItem("traveltypeFlages")
                            ) || []
                          ).includes(travel?.id)}
                        />
                        &nbsp;
                        {travel?.name}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            <div className="card distance-section">
              <div
                className="accordion-header card-header head"
                id="headingSix"
                style={{ backgroundColor: "#fff" }}
              >
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="true"
                  aria-controls="collapseSix"
                  style={{ color: "#000", backgroundColor: "#fff" }}
                >
                  Distance
                </button>
              </div>
              <div
                id="collapseSix"
                className="accordion-collapse collapse show"
                aria-labelledby="headingSix"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body" id="accordion-body">
                  <div
                    class="btn-group"
                    role="group"
                    aria-label="Basic example"
                  >
                    <button
                      type="button"
                      class={
                        index === 1
                          ? "btn btn-outline-secondary  btn-sm"
                          : "btn btn-secondary-secondary"
                      }
                      onClick={() => setIndex(1)}
                    >
                      Km
                    </button>
                    <button
                      type="button"
                      class={
                        index === 2
                          ? "btn btn-outline-secondary btn-sm"
                          : "btn btn-secondary-warning "
                      }
                      onClick={() => setIndex(2)}
                    >
                      Mile
                    </button>
                  </div>

                  <ul className="list-group">
                    {index === 1 ? (
                      <>
                        <li className="list-group-item">
                          <input
                            className="form-check-input me-1"
                            type="checkbox"
                            value=""
                            aria-label="..."
                          />
                          &nbsp; Within 10km
                        </li>
                        <li className="list-group-item">
                          <input
                            className="form-check-input me-1"
                            type="checkbox"
                            value=""
                            aria-label="..."
                          />
                          &nbsp; Within 20km
                        </li>

                        <li className="list-group-item">
                          <input
                            className="form-check-input me-1"
                            type="checkbox"
                            value=""
                            aria-label="..."
                          />
                          &nbsp; Within 30km
                        </li>
                      </>
                    ) : (
                      <div>
                        <li className="list-group-item">
                          <input
                            className="form-check-input me-1"
                            type="checkbox"
                            value=""
                            aria-label="..."
                          />
                          &nbsp; Within 10Miles
                        </li>
                        <li className="list-group-item">
                          <input
                            className="form-check-input me-1"
                            type="checkbox"
                            value=""
                            aria-label="..."
                          />
                          &nbsp; Within 20Miles
                        </li>

                        <li className="list-group-item">
                          <input
                            className="form-check-input me-1"
                            type="checkbox"
                            value=""
                            aria-label="..."
                          />
                          &nbsp; Within 30Miles
                        </li>
                      </div>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        )
      }



      </div>

  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default withRouter(connect(null, mapDispatchToProps)(Searchjob_filters));
