import React, { Component } from 'react'
import "../searchResumes/searchResumeFilters.css";
import axios from "axios";
import Key from "../../clientVariables.json";
// import ResumeChips from './resumeFilterChips'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { showLoader } from "../../Components/Actions/loader.actions";
import { hideLoader } from "../../Components/Actions/loader.actions";
import SearchContext from "../../../src/resumeBuilder/SearchContext"
// for json skills data


const jobtypes = [
    { id: 1, text: "Full-Time", value: "0" },
    { id: 2, text: "Part-Time", value: "1" },
    { id: 10, text: "Contract", value: "10" },
    { id: 3, text: "Internship", value: "3" },
];

const visatypes = [
  
    { id: 3, text: "H1", value: "3" },
    { id: 5, text: "F1", value: "5" },
    { id: 6, text: "TN", value: "6" },
    { id: 9, text: "L1", value: "9" },
    { id: 12, text: "H4", value: "12" },
    { id: 13, text: "H1B", value: "13" },
    { id: 10, text: "GC - EAD", value: "10" },
    { id: 11, text: "H4 - EAD", value: "11" },
    { id: 14, text: "L2 - EAD", value: "14" },
    { id: 15, text: "OPT - EAD", value: "15" },
    { id: 1, text: "GC - Green Card", value: "1" },
    { id: 2, text: "USC- U.S. Citizen ", value: "2" },
    { id: 4, text: "OPT - Optional Practical Training ", value: "4" },
    { id: 16, text: "CPT - Curricular Practical Training", value: "16" },
    { id: 7, text: "EAD - Employment Authorization Document", value: "7" },
    { id: 8, text: "Others", value: "8" },
    
  ];
  

const posteddate = [
    { id: 1, text: 'Within a Day', value: '1' },
    { id: 2, text: 'Within a Week', value: '2' },
    { id: 3, text: 'Within a Month', value: '3' },
    { id: 4, text: 'Any time', value: '4' },

]

const qualifications = [
    { id: 1, text: 'Bachelors Degree', value: '1' },
    { id: 2, text: 'PG Diploma', value: '2' },
    { id: 3, text: 'Diploma-Engineering', value: '3' },
    { id: 4, text: 'Masters', value: '4' },
    { id: 5, text: 'PhD', value: '5' },

]

class ResumeFilters extends Component {
    static contextType = SearchContext
    constructor(props) {
        super(props);
        this.state = {
            countries: [],
            Skillflages: JSON.parse(localStorage.getItem("skillFlages")) || [],
            jobtypeValue: "",
            Visaflages: JSON.parse(localStorage.getItem("resumevisatypeFlages")) || [],
            dateValue: JSON.parse(localStorage.getItem("resumeDateValue")) || "",
            Expflages: JSON.parse(localStorage.getItem("resumeExpFlages")) || [],
            QualificationFlages: JSON.parse(localStorage.getItem("resumeQualificationFlages")) || [],
            CountryFlages: JSON.parse(localStorage.getItem("resumeCountryFlages")) || [],
            sortValue: "",
            workModeFlages: JSON.parse(localStorage.getItem("workModeFlages")) || [],
            exp: [

                { name: "0-3 Years", value: "0 OR fresher OR 1 OR 2 OR 3", text: "0-3 Years", id: 1 },

                { name: "4-6 Years", value: "4 OR 5 OR 6", text: "4-6 Years", id: 2 },

                { name: "7-10 Years", value: "7 OR 8 OR 9 OR 10", text: "7-10 Years", id: 3 },

                { name: "Above 10 years", value: "11 OR 12 OR 13 OR 14 OR 15 OR 16 OR 17 OR 18 OR 19 OR 20 OR 21 OR 22 OR 23 OR 24 OR 25 OR 26 OR 27 OR 28 OR 29 OR 30 OR 31", text: "Above 10 years", id: 4 },

            ],

            jsonfilters: {}
        }
    }


    getFilteredResumes = (sortValue) => {
        var skills = sessionStorage.getItem("advancedSearch-skills")
        var jobtitle = sessionStorage.getItem('resumeTitle') ? sessionStorage.getItem('resumeTitle') : ""
        var inputjobtitle = jobtitle.replace(/"/g, '')
        var qualifications = sessionStorage.getItem("resumefiltered-qualification")
        var email = this.state.emailChecked
        var phone = this.state.phoneChecked
        var willingToRelocate = this.state.willingToRelocate

        const str = inputjobtitle
        const res = str.includes("developer") || str.includes("programmer") || str.includes('architect') || str.includes("designer") || str.includes('consultant') || str.includes("administrator") || str.includes('coach') || str.includes("lead") || str.includes('manager') || str.includes("master") || str.includes('specalist') || str.includes('modeler') || str.includes('director') || str.includes('hacker');
        var AdvanceResumeIds = sessionStorage.getItem("AdvanceResumeIds")


        if (res === true) {
            inputjobtitle = '"' + str + '"'
        }

        if (sortValue !== "" && sortValue !== undefined) {
            if (sortValue === "recent") {
                //var filterResumesUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select?fq=id:(${AdvanceResumeIds})&fq=skillName:(${skills ? skills : '*:*'})&fq=resume_string:(${skills ? skills : '*:*'})&fq=(${skills ? skills : '*:*'})&rows=10&start=${0}&sort=lastmodified%20desc,id%20asc&wt=json`
                var filterResumesUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select?fq=id:(${AdvanceResumeIds})&fq=(${skills ? skills : '*:*'})&fq=search_flag:(1)&rows=10&start=${0}&sort=lastmodified%20desc,id%20asc&wt=json`

            } else if (sortValue === "relavance") {
                filterResumesUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select?fq=id:(${AdvanceResumeIds})&fq=(${skills ? skills : '*:*'})&fq=search_flag:(1)&rows=10&start=${0}&sort=lastmodified%20desc,id%20asc&wt=json`
            }
        } else {
            filterResumesUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select?fq=id:(${AdvanceResumeIds})&fq=(${skills ? skills : '*:*'})&fq=search_flag:(1)&rows=10&start=${0}&sort=lastmodified%20desc,id%20asc&wt=json`
        }


        axios.post(`${Key.domain}/solr/solr_resumes`, { url: filterResumesUrl }, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            },
        })
            .then(resdata => {
                const retrivedData = resdata.data.data.response.docs;
                sessionStorage.setItem("resumesCount", resdata.data.data.response.numFound)
                this.props.hideLoader()

                this.props.history.push({
                    pathname: "/AdvancedResumeSearch",
                    state: {
                        response: retrivedData,
                        jobtitle: true,
                        location: false,
                        filterChecked: true,
                        phoneChecked: phone,
                        emailChecked: email,
                        willingToRelocate: willingToRelocate
                    }
                });
                this.props.handlePaginationRefresh()

            });


    }



    stringGeneration = () => {
        const test = this.context
        const skillTitles = sessionStorage.getItem("advancedSearch-skills") ? '"' + sessionStorage.getItem("advancedSearch-skills").replaceAll(' OR ', '" AND "') + '"' : ""
        sessionStorage.setItem("inputResumeTitle", test.searchResumeTitle + " AND " + skillTitles)
        // sessionStorage.setItem("stringGeneratedSkills", skillTitles)
    }



    handleSkillsChange = (value) => {
        let checkedBox = value;
        // this.props.onFilterChecked(true)
        //  this.props.showLoader()
        // localStorage.removeItem("resumeFilterResetValue")
        localStorage.removeItem("resumeFilterResetValue")
        this.setState(previous => {
            let Skillflages = previous.Skillflages;
            let index = Skillflages.indexOf(checkedBox) // check to see if the name is already stored in the array
            if (index === -1) {
                Skillflages.push(checkedBox) // if it isn't stored add it to the array
                // This code is used when checkbox is used instead of dropdown 
                localStorage.setItem("skillFlages", JSON.stringify(this.state.Skillflages));

                var mappedSkills = this.state.Skillflages.map(fid => `${fid}`).join(' AND ')
                { this.state.Skillflages.map((fid, i) => [{ fid }, i < this.state.Skillflages.length - 1 && " AND "]) }
                sessionStorage.setItem("advancedSearch-skills", mappedSkills)

                if (mappedSkills !== '') {
                    this.getFilteredResumes()
                }
                this.stringGeneration()

            }
            else {
                Skillflages.splice(index, 1) // if it is stored then remove it from the array
                localStorage.setItem("skillFlages", JSON.stringify(this.state.Skillflages));
                mappedSkills = Skillflages.map(fid => `${fid}`).join(' AND ')
                { Skillflages.map((fid, i) => [{ fid }, i < Skillflages.length - 1 && " AND "]) }
                sessionStorage.setItem("advancedSearch-skills", mappedSkills)
                this.getFilteredResumes()
                this.stringGeneration()


            }
            return { Skillflages }; // save the new selectedBoxes value in state
        },
        );
    }


    reset = () => {
        // this.props.showLoader()

        sessionStorage.removeItem("resumefiltered-jobtypes")
        localStorage.removeItem("resumejobtypeFlages")
        sessionStorage.removeItem("resumefiltered-visatypes")
        localStorage.removeItem("resumevisatypeFlages")
        sessionStorage.removeItem("resumefiltered-experience")
        localStorage.removeItem("resumeExpFlages")
        sessionStorage.removeItem("resumefiltered-country")
        localStorage.removeItem("resumeQualificationFlages")
        sessionStorage.removeItem("resumefiltered-qualification")
        localStorage.removeItem("resumeCountryFlages")
        sessionStorage.removeItem("resumeMappedDates")
        sessionStorage.removeItem("currentsearchpage")
        localStorage.removeItem("resumeSortBy")
        localStorage.removeItem("resumeSortByDate")

        //  this.props.hideLoader()

    }

    render() {

        return (
            <SearchContext.Consumer>
                {values => {

                    return (
                        <div>
                            {/* <ResumeChips data={this.state.Visaflages} data2={this.state.jobtypeflages} data3={[]} data4={[]} data5={this.state.Travelflages} data6={this.state.dateValue} sortValue={this.state.sortValue} jobtypeFunction={this.jobtypeFun} handleVisatype={this.visatypeFun} handleExpFun={this.handleExp} handleQualifications={this.handleQualifications} selectedOptionValue={this.sortByDate} sortby={this.sortby} handleworkmode={this.handleworkmode} jobtypes={jobtypes} lastupdated={posteddate} handleLoadingClick={this.handleLoadingClick} visaOptions={visatypes} exp={this.state.exp} recent={this.state.recent} relavance={this.state.relavance} qualifications={qualifications} />
{/*  <button className="btn btn-outline-secondary" onClick={() => this.reset()} >
    Reset
</button>*/}

                            <div id="resumeFilters">

                                <div className="accordion-item">
                                    <div className="card">

                                        <div className="accordion-header card-header head" id="TabOne">

                                            <button
                                                className="accordion-button accordion-button-2  fw-bold"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#TabOne"
                                                aria-expanded="true"
                                                aria-controls="TabOne"
                                                style={{ color: "#000", backgroundColor: "#fff" }}
                                            >  &nbsp;
                                                Skills
                                            </button>

                                        </div>


                                        <div
                                            id="TabOne"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="TabOne"
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                <ul className="list-group">
                                                    {sessionStorage.getItem("Selectedjsondata") ?
                                                        JSON.parse(sessionStorage.getItem("Selectedjsondata")).SKILLS.map((item) =>
                                                            <li className="list-group-item">
                                                                <input className="form-check-input me-1" type="checkbox"
                                                                    onClick={(e) => {
                                                                        this.setState({ jobtypeflages: parseInt(localStorage.getItem("resumeFilterResetValue")) === 1 ? [] : this.state.jobtypeflages })

                                                                        setTimeout(() => {
                                                                            this.handleSkillsChange(item);

                                                                        }, 200);

                                                                    }}
                                                                    checked={(JSON.parse(localStorage.getItem("skillFlages")) || []).includes(item)}
                                                                /> &nbsp;  {item}
                                                            </li>
                                                        )

                                                        : ''}

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    {sessionStorage.getItem("Selectedjsondata") ?
                                        JSON.parse(sessionStorage.getItem("Selectedjsondata")).MODULES.map((item) =>
                                            <div className="card">

                                                <div className="accordion-header card-header head" id={item.Title}>

                                                    <button
                                                        className="accordion-button accordion-button-2 fw-bold"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={`#${item.Title}`}
                                                        aria-expanded="true"
                                                        aria-controls={item.Title}
                                                        style={{ color: "#000", backgroundColor: "#fff" }}
                                                    > &nbsp;
                                                        {item.Title}
                                                    </button>

                                                </div>
                                                <div
                                                    id={item.Title}
                                                    className="accordion-collapse collapse show"
                                                    aria-labelledby={item.Title}
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        <ul className="list-group">

                                                            {item.Skills ?
                                                                item.Skills.map((item) =>
                                                                    <li className="list-group-item">
                                                                        <input className="form-check-input me-1" type="checkbox"
                                                                            onClick={(e) => {
                                                                                setTimeout(() => {
                                                                                    this.handleSkillsChange(item);

                                                                                }, 200);

                                                                            }}
                                                                            checked={(JSON.parse(localStorage.getItem("skillFlages")) || []).includes(item)}

                                                                        />&nbsp;  {item}
                                                                    </li>
                                                                )

                                                                 : ''}

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        )

                                        : ''}


                                </div>





                            </div>


                        </div>
                    )
                }}

            </SearchContext.Consumer>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showLoader: () => dispatch(showLoader()),
        hideLoader: () => dispatch(hideLoader()),
    };
};
export default withRouter(connect(null, mapDispatchToProps)(ResumeFilters));
