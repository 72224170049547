const initialState = {
  shouldRedirect: false,
  redirectTo: null,
  searchText: null,
  location: null,
  searchType: null,
};

const jobSearchUrlReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_URL":
      return {
        shouldRedirect: true,
        redirectTo: action.payload.path,
        searchText: action.payload.searchText,
        location: action.payload.location,
        searchType: action.payload.searchType,
        companyName: action.payload.companyName,
      };
    case "CLEAR_URL":
      return {
        shouldRedirect: false,
        redirectTo: null,
      };
    default:
      return state;
  }
};

export default jobSearchUrlReducer;
