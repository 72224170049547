export const setURLjobSearchUrlActions = (path, searchText, location, searchType, companyName) => ({
  type: "SET_URL",
  payload: { path, searchText, location, searchType, companyName },
});

export const clearURLjobSearchUrlActions = () => ({
  type: "CLEAR_URL",
});

export const jobSearchUrlActions = (path, searchText, location, searchType, companyName) => (dispatch) => {
  dispatch(setURLjobSearchUrlActions(path, searchText, location, searchType, companyName));
  dispatch(clearURLjobSearchUrlActions());
};
