import axios from "axios";

import Key from "../../clientVariables.json";
import { resumeQueryBuilder } from "../../utils/resumeQueryBuilder";
import {
  CLEAR_RESUME_SEARCH_RESULTS,
  FETCH_RESUME_RESULTS_FAILURE,
  FETCH_RESUME_RESULTS_SUCCESS,
  SET_SELECTED_RESUME,
  SOLR_QUERY_WITHOUT_FILTERS,
  UPDATE_RESUME_FILTER_PARAMS,
  UPDATE_RESUME_PAGINATION_PARAMS,
  UPDATE_RESUME_SEARCH_PARAMS,
  JS_RESUME_DETAILS_SUCCESS,
  JS_RESUME_DETAILS_FAIL,
} from "./actionTypes";

export const updateResumeSearchParams = (params) => ({
  type: UPDATE_RESUME_SEARCH_PARAMS,
  payload: params,
});

export const updateResumeFilterParams = (params) => ({
  type: UPDATE_RESUME_FILTER_PARAMS,
  payload: params,
});

export const updateResumePaginationParams = (params) => ({
  type: UPDATE_RESUME_PAGINATION_PARAMS,
  payload: params,
});

export const solrQueryWithoutFilters = (query) => ({
  type: SOLR_QUERY_WITHOUT_FILTERS,
  payload: query,
});

export const fetchResumeResults = () => async (dispatch, getState) => {
  try {
    // const { searchParams, filterParams, paginationParams } = getState();
    const state = getState();
    const { searchParams, filterParams, paginationParams } =
      state.searchResumeReducer;
    // console.log('getState:', state);
    const queryUrl = resumeQueryBuilder(
      searchParams,
      filterParams,
      paginationParams
    );
    // console.log("query url", queryUrl);
    // const response = await axios.get(queryUrl);
    const response = await axios.post(`${Key.domain}/solr/solr_resumes`, {
      url: queryUrl,
    });
    dispatch({
      type: FETCH_RESUME_RESULTS_SUCCESS,
      payload: {
        response: response.data.data.response.docs,
        numFound: response.data.data.response.numFound,
      },
    });

    if (response.data.data.response.docs.length > 0) {
      dispatch(setSelectedResume(response.data.data.response.docs[0]));
    }
  } catch (error) {
    dispatch({
      type: FETCH_RESUME_RESULTS_FAILURE,
      payload: error.message,
    });
    console.error(error);
  }
};

export const fetchResumeResultsV1 = (url) => async (dispatch, getState) => {
  try {
    // console.log("Fetch Results: url", url);
    const response = await axios.post(`${Key.domain}/solr/solr_resumes`, {
      url: url,
    });
    // console.log("Fetch Results: 123", response.data.data);
    

    if (response.data.data.response.docs.length > 0) {
      
      dispatch({
        type: FETCH_RESUME_RESULTS_SUCCESS,
        payload: {
          response: response.data.data.response.docs,
          numFound: response.data.data.response.numFound,
          facet_counts: response.data.data.facet_counts,
        },
      });
      dispatch(setSelectedResume(response.data.data.response.docs[0]));
    }
  } catch (error) {
    dispatch({
      type: FETCH_RESUME_RESULTS_FAILURE,
      payload: error.message,
    });
  }
};

// export const fetchResumeResultsV1 = (url) => async (dispatch, getState) => {
//   const state = getState();
//   const { searchParams, filterParams, paginationParams } = state.searchResumeReducer;

//   try {
//     console.log("Fetch Results: url", url);

//     // Make the API request
//     const response = await axios.post(`${Key.domain}/solr/solr_resumes`, {
//       url: url,
//     });

//     console.log("Fetch Results: 123", response.data.data);

//     // Determine whether to use the fetched facet counts or not
//     const useFacetCounts =
//       filterParams.jobtype === "*:*" &&
//       filterParams.visatype === "*:*" &&
//       filterParams.total_experience === "*:*" &&
//       filterParams.state === "*:*";

//     // Dispatch the success action with the fetched data
//     dispatch({
//       type: FETCH_RESUME_RESULTS_SUCCESS,
//       payload: {
//         response: response.data.data.response.docs,
//         numFound: response.data.data.response.numFound,
//         facet_counts: useFacetCounts ? response.data.data.facet_counts : state.searchResumeReducer.facet_counts,
//       },
//     });

//     // Optionally, set the first resume as selected
//     if (response.data.data.response.docs.length > 0) {
//       dispatch(setSelectedResume(response.data.data.response.docs[0]));
//     }
//   } catch (error) {
//     // Dispatch the failure action in case of an error
//     dispatch({
//       type: FETCH_RESUME_RESULTS_FAILURE,
//       payload: error.message,
//     });
//   }
// };

export const setSelectedResume = (resume) => ({
  type: SET_SELECTED_RESUME,
  payload: resume,
});

export const clearResumeSearchResults = () => ({
  type: CLEAR_RESUME_SEARCH_RESULTS,
});

// export const jsResumeDetails = (resumeId) => {
//   let resumedetails ;
//   try{
//     axios.post(`${Key.domain}/resume/get_js_resume_details`, {
//       user_id: null,
//       resumeid: resumeId,
//       session_token: null,
//     })
//     .then((response) => {
//       //console.log(response.data.data)
//       console.log(response.data.data);
//       resumedetails = response.data.data;

//     });
//     dispatch({
//       type: JS_RESUME_DETAILS_SUCCESS,
//       payload: resumedetails,
//     });
//   }catch (err) {
//       dispatch({
//         type: JS_RESUME_DETAILS_FAIL,
//         payload: err,
//       });
//   }
// }
export const jsResumeDetails = (resumeId) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${Key.domain}/resume/get_js_resume_details`,
      {
        user_id: null,
        resumeid: resumeId,
        session_token: null,
      }
    );
    dispatch({
      type: JS_RESUME_DETAILS_SUCCESS,
      payload: response?.data?.data,
    });
  } catch (err) {
    dispatch({
      type: JS_RESUME_DETAILS_FAIL,
      payload: err.message,
    });
  }
};
