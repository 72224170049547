
import React, { Component } from "react";
import { connect } from "react-redux";
import "../../Styles/loading.css"
import HashLoader  from "react-spinners/HashLoader";

class PageLoader extends Component {
    state = {};
    render() {
        const { loading } = this.props;

        console.log(loading)
        if (!loading) return null;

        return (
            <div className="loading-container">
                <div className="loading">
                    <HashLoader
                        height='25'
                        width='5'
                        color='#406882'
                        loading={loading} size={50} />
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({ loading: state.loaderReducer.loading });

export default connect(mapStateToProps)(PageLoader);