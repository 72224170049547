import React, { Component, useContext } from 'react'
import "./searchResumeFilters.css";
import axios from "axios";
import Key from "../../clientVariables.json";
import moment from 'moment'
import ResumeChips from './resumeFilterChips'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { showLoader } from "../../Components/Actions/loader.actions";
import { hideLoader } from "../../Components/Actions/loader.actions";
import SearchContext from "../../../src/resumeBuilder/SearchContext"

const jobtypes = [
    { id: 1, text: "Full-Time", value: "1" },
    { id: 2, text: "Part-Time", value: "2" },
    { id: 10, text: "Contract", value: "10" },
    { id: 3, text: "Internship", value: "3" },
    { id: 4, text: "W2 - Permanent", value: "4" },
    { id: 5, text: "W2 - Contract", value: "5" },
    { id: 6, text: "1099-Contract", value: "6" },
    { id: 7, text: "Contract to Hire", value: "7" },
    { id: 8, text: "Need H1B", value: "8" },
    { id: 9, text: "Hourly Employee", value: "9" },
    { id: 11, text: "Contract - Corp-to-Corp", value: "11" },
    { id: 13, text: "Contract - Independent", value: "13" },
    { id: 14, text: "Contract to Hire - Corp-to-Corp", value: "14" },
    { id: 15, text: "Contract to Hire - W2", value: "15" },
    { id: 16, text: "Contract to Hire - Independent", value: "16" },

];

const visatypes = [

    { id: 3, solr_key: "H1", text: "H1", value: "3" },
    { id: 5, solr_key: "F1", text: "F1", value: "5" },
    { id: 6, solr_key: "TN", text: "TN", value: "6" },
    { id: 9, solr_key: "L1", text: "L1", value: "9" },
    { id: 12, solr_key: "H4", text: "H4", value: "12" },
    { id: 13, solr_key: "H1B", text: "H1B", value: "13" },
    { id: 10, solr_key: "GC - EAD", text: "GC - EAD", value: "10" },
    { id: 11, solr_key: "H4 - EAD", text: "H4 - EAD", value: "11" },
    { id: 14, solr_key: "L2 - EAD", text: "L2 - EAD", value: "14" },
    { id: 15, solr_key: "OPT - EAD", text: "OPT - EAD", value: "15" },
    { id: 1, solr_key: "GC", text: "GC - Green Card", value: "1" },
    { id: 2, solr_key: "USC", text: "USC- U.S. Citizen ", value: "2" },
    { id: 4, solr_key: "OPT", text: "OPT - Optional Practical Training ", value: "4" },
    { id: 16, solr_key: "CPT", text: "CPT - Curricular Practical Training", value: "16" },
    { id: 7, solr_key: "EAD", text: "EAD - Employment Authorization Document", value: "7" },
    { id: 8, solr_key: "Others", text: "Others", value: "8" },

];


const posteddate = [
    { id: 1, text: 'Within a Day', value: '1' },
    { id: 2, text: 'Within a Week', value: '2' },
    { id: 3, text: 'Within a Month', value: '3' },
    { id: 4, text: 'Any time', value: '4' },

]

const qualifications = [
    { id: 1, text: 'Bachelors Degree', value: '1' },
    { id: 2, text: 'PG Diploma', value: '2' },
    { id: 3, text: 'Diploma-Engineering', value: '3' },
    { id: 4, text: 'Masters', value: '4' },
    { id: 5, text: 'PhD', value: '5' },

]

class ResumeFilters extends Component {
    static contextType = SearchContext

    constructor(props) {
        super(props);
        this.state = {
            countries: [],
            jobtypeflages: JSON.parse(localStorage.getItem("resumejobtypeFlages")) || [],
            jobtypeValue: "",
            Visaflages: JSON.parse(localStorage.getItem("resumevisatypeFlages")) || [],
            dateValue: JSON.parse(localStorage.getItem("resumeDateValue")) || "",
            Expflages: JSON.parse(localStorage.getItem("resumeExpFlages")) || [],
            QualificationFlages: JSON.parse(localStorage.getItem("resumeQualificationFlages")) || [],
            CountryFlages: JSON.parse(localStorage.getItem("resumeCountryFlages")) || [],
            sortValue: "",
            workModeFlages: JSON.parse(localStorage.getItem("workModeFlages")) || [],
            exp: [

                { name: "0-3 Years", value: "0 OR 1 OR 2 OR 3", text: "0-3 Years", id: 1 },

                { name: "4-6 Years", value: "4 OR 5 OR 6", text: "4-6 Years", id: 2 },

                { name: "7-10 Years", value: "7 OR 8 OR 9 OR 10", text: "7-10 Years", id: 3 },

                { name: "Above 10 years", value: "11 OR 12 OR 13 OR 14 OR 15 OR 16 OR 17 OR 18 OR 19 OR 20 OR 21 OR 22 OR 23 OR 24 OR 25 OR 26 OR 27 OR 28 OR 29 OR 30 OR 31", text: "Above 10 years", id: 4 },

            ],
            emailChecked: false,
            phoneChecked: false,
            linkedIn: false,
            willingToRelocate: false,
            sortValue: "",
            salaryType: "",
            expectedFrom: "",
            expectedTo: "",
            salaryTypeName: ""
        }
    }

    componentDidMount() {

        axios
            .get(`${Key.domain}/commonAPIs/get_all_countries`, {
                method: "GET", // *GET, POST, PUT, DELETE, etc.
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
                },
            })
            .then((resdata) => {
                this.setState({ countries: resdata.data.data })
            });
    }

    jobtypeFun = (id) => {
        // console.log(id)
        this.props.onFilterChecked(true)
        this.props.showLoader()
        this.setState({ jobtypeValue: localStorage.getItem("jobs-jobtype-1") })
        localStorage.removeItem("resumeFilterResetValue")

        let checkedBox = id;
        this.setState(previous => {
            let jobtypeflages = previous.jobtypeflages;
            let index = jobtypeflages.indexOf(checkedBox) // check to see if the name is already stored in the array
            if (index === -1) {
                jobtypeflages.push(checkedBox) // if it isn't stored add it to the array
                //  this.props.sendJobTypeFunction(this.state.jobtypeflages)
                localStorage.setItem("resumejobtypeFlages", JSON.stringify(this.state.jobtypeflages));
                localStorage.setItem("resumeFileterChecked", JSON.stringify(this.state.jobtypeflages))
                var arr = JSON.parse(localStorage.getItem("resumejobtypeFlages"));
                //console.log(arr)
                var jobtypeMappedIds = this.state.jobtypeflages.map(fid => `${fid}`).join(' OR ')
                { this.state.jobtypeflages.map((fid, i) => [{ fid }, i < this.state.jobtypeflages.length - 1 && " OR "]) }
                //  console.log(jobtypeMappedIds)
                sessionStorage.setItem("resumefiltered-jobtypes", jobtypeMappedIds)
                if (jobtypeMappedIds !== '') {
                    this.getFilteredResumes()
                }
            }
            else {
                jobtypeflages.splice(index, 1) // if it is stored then remove it from the array
                //  this.props.sendJobTypeFunction(this.state.jobtypeflages)
                // this.props.sendFilterFunction(this.state.jobtypeflages, this.state.Travelflages, this.state.Visaflages, this.state.startdate)
                //  console.log(jobtypeflages)
                localStorage.setItem("resumejobtypeFlages", JSON.stringify(this.state.jobtypeflages));
                localStorage.setItem("resumeFileterChecked", JSON.stringify(this.state.jobtypeflages))
                var arr = JSON.parse(localStorage.getItem("resumejobtypeFlages"));
                //console.log(arr)
                jobtypeMappedIds = jobtypeflages.map(fid => `${fid}`).join(' OR ')
                { jobtypeflages.map((fid, i) => [{ fid }, i < jobtypeflages.length - 1 && " OR "]) }
                //console.log(jobtypeMappedIds)
                sessionStorage.setItem("resumefiltered-jobtypes", jobtypeMappedIds)
                this.getFilteredResumes()
            }
            return { jobtypeflages };
        }, () => {
            console.log(this.state.jobtypeflages)
        }

        )

    }


    visatypeFun = (value) => {
        let checkedBox = value;
        this.props.onFilterChecked(true)
        this.props.showLoader()
        localStorage.removeItem("resumeFilterResetValue")

        this.setState(previous => {
            let Visaflages = previous.Visaflages;
            let index = Visaflages.indexOf(checkedBox) // check to see if the name is already stored in the array
            if (index === -1) {
                Visaflages.push(checkedBox) // if it isn't stored add it to the array
                // This code is used when checkbox is used instead of dropdown 
                localStorage.setItem("resumevisatypeFlages", JSON.stringify(this.state.Visaflages));
                localStorage.setItem("resumeFileterChecked", JSON.stringify(this.state.Visaflages))
                var arr = JSON.parse(localStorage.getItem("resumevisatypeFlages"));
                //console.log(arr)
                var visatypeMappedIds = this.state.Visaflages.map(fid => `${fid}`).join(' OR ')
                { this.state.Visaflages.map((fid, i) => [{ fid }, i < this.state.Visaflages.length - 1 && " OR "]) }
                //console.log(visatypeMappedIds)
                sessionStorage.setItem("resumefiltered-visatypes", visatypeMappedIds)

                if (visatypeMappedIds !== '') {
                    this.getFilteredResumes()
                }
                // this.props.sendFilterFunction(this.state.jobtypeflages, this.state.Travelflages, this.state.Visaflages, this.state.startdate)
            }
            else {
                Visaflages.splice(index, 1) // if it is stored then remove it from the array
                // this.props.sendFilterFunction(this.state.jobtypeflages, this.state.Travelflages, this.state.Visaflages, this.state.startdate)
                localStorage.setItem("resumevisatypeFlages", JSON.stringify(this.state.Visaflages));
                localStorage.setItem("resumeFileterChecked", JSON.stringify(this.state.Visaflages))
                var arr = JSON.parse(localStorage.getItem("resumevisatypeFlages"));
                //console.log(arr)
                visatypeMappedIds = Visaflages.map(fid => `${fid}`).join(' OR ')
                { Visaflages.map((fid, i) => [{ fid }, i < Visaflages.length - 1 && " OR "]) }
                //console.log(visatypeMappedIds)
                sessionStorage.setItem("resumefiltered-visatypes", visatypeMappedIds)
                this.getFilteredResumes()


            }
            return { Visaflages }; // save the new selectedBoxes value in state
        },
        );
    }


    handleExp = (value) => {
        let checkedBox = value;
        this.props.onFilterChecked(true)
        this.props.showLoader()
        localStorage.removeItem("resumeFilterResetValue")

        this.setState(previous => {
            let Expflages = previous.Expflages;
            let index = Expflages.indexOf(checkedBox)
            if (index === -1) {
                Expflages.push(checkedBox) // if it isn't stored add it to the array
                localStorage.setItem("resumeExpFlages", JSON.stringify(this.state.Expflages));
                localStorage.setItem("resumeFileterChecked", JSON.stringify(this.state.Expflages))

                //  this.props.sendExpFunction(this.state.Expflages)
                var ExpMappedIds = this.state.Expflages.map(tid => `${tid}`).join(' OR ')
                { this.state.Expflages.map((tid, i) => [{ tid }, i < this.state.Expflages.length - 1 && " OR "]) }
                //console.log(ExpMappedIds)
                sessionStorage.setItem("resumefiltered-experience", ExpMappedIds)
                if (ExpMappedIds !== '') {
                    this.getFilteredResumes()
                }
            }
            else {
                Expflages.splice(index, 1) // if it is stored then remove it from the array
                //  this.props.sendExpFunction(this.state.Expflages)
                localStorage.setItem("resumeExpFlages", JSON.stringify(this.state.Expflages));
                localStorage.setItem("resumeFileterChecked", JSON.stringify(this.state.Expflages))
                var arr = JSON.parse(localStorage.getItem("resumeExpFlages"));
                //console.log(arr)
                ExpMappedIds = Expflages.map(fid => `${fid}`).join(' OR ')
                { Expflages.map((fid, i) => [{ fid }, i < Expflages.length - 1 && " OR "]) }
                //console.log(visatypeMappedIds)
                sessionStorage.setItem("resumefiltered-experience", ExpMappedIds)
                this.getFilteredResumes()

            }
            return { Expflages }; // save the new selectedBoxes value in state
        },
        );
    }



    getFilteredResumes = (sortValue) => {

        var jobtype = sessionStorage.getItem("resumefiltered-jobtypes")
        var visatype = sessionStorage.getItem("resumefiltered-visatypes")
        var experience = sessionStorage.getItem('resumefiltered-experience')
        var workmode = sessionStorage.getItem('filtered-workmode')
        var jobtitle = sessionStorage.getItem('resumeTitle') ? sessionStorage.getItem('resumeTitle') : ""
        var cityname = sessionStorage.getItem("resumeCityName")
        var inputjobtitle = jobtitle.replace(/"/g, '')
        var Mappeddates = sessionStorage.getItem("resumeMappedDates")
        var qualifications = sessionStorage.getItem("resumefiltered-qualification")
        var country = sessionStorage.getItem("resumefiltered-country")
        var salaryType = '"' + sessionStorage.getItem("resumefiltered-salary") + '"'
        var email = this.state.emailChecked
        var phone = this.state.phoneChecked
        var linkedIn = this.state.linkedIn
        var willingToRelocate = this.state.willingToRelocate
        var salaryTo = sessionStorage.getItem("resumeFiltered-salaryTo")
        var salaryFrom = sessionStorage.getItem("resumeFiltered-salaryFrom")
        var skills = sessionStorage.getItem("skillNDescriptionSearch") !== null ? sessionStorage.getItem("skillNDescriptionSearch") : ""
        const str = inputjobtitle
        const res = str.toLowerCase().includes("developer") || str.toLowerCase().includes("programmer") || str.toLowerCase().includes('architect') || str.toLowerCase().includes("designer") || str.toLowerCase().includes('consultant') || str.toLowerCase().includes("administrator") || str.toLowerCase().includes('coach') || str.toLowerCase().includes("lead") || str.toLowerCase().includes('manager') || str.toLowerCase().includes("master") || str.toLowerCase().includes('specalist') || str.toLowerCase().includes('modeler') || str.toLowerCase().includes('director') || str.toLowerCase().includes('hacker') || str.toLowerCase().includes('engineer') || str.toLowerCase().includes('analyst') || str.toLowerCase().includes('dba') || str.toLowerCase().includes('tester');

        const test = this.context
        // console.log(test)


        const skillRelatedWords = [
            "skill",
            "technology",
            "developer",
            "programmer",
            "architect",
            "designer",
            "consultant",
            "administrator",
            "coach",
            "lead",
            "manager",
            "master",
            "specalist",
            "modeler",
            "director",
            "hacker",
            "engineer",
            "analyst",
            "dba",
            "tester",
            "senior",
            "sr",
            "junior",
            "jr",
            "knowledge",
            "or",
            "with",
            "to",
            "and",
            "front",
            "end",
            "back",
            "admin",
            // "frontend",
            // "backend",
        ];
        function splitBySpecialCharacters(input) {
            // Define regex to match any special characters except alphanumeric and space
            const specialCharRegex = /[^a-zA-Z0-9\s]+/;
            
            // Split the input string using the special character regex
            const parts = input.split(specialCharRegex);
          
            // Trim each part and remove empty strings
            const cleanedParts = parts.map(part => part.trim()).filter(part => part !== '');
          
            return cleanedParts;
          }
        const location = cityname !== null ? cityname.split(/[,]+/) : "";
        const city = location[0];
        const state = location[1];


        if (res === true || test.selectedFromSuggestions === true) {
            inputjobtitle = '"' + str + '"'
        }

        sessionStorage.setItem('filter_url_my_jobs', `&fq=jobtype:(${jobtype ? jobtype : '*:*'})&fq=res_visatypeName:(${visatype ? visatype : '*:*'})&fq=lastmodified:[${Mappeddates !== null ? Mappeddates : '* TO *'}]&fq=total_experience:(${experience ? experience : '*:*'})`);
        if (sortValue !== "" && sortValue !== undefined) {
            if (sortValue === "recent") {
                let searchText;
                if (sessionStorage.getItem('inputResumeTitle')) {
                    const wordRegex = /\b\w+\b/g;
                    const title = sessionStorage.getItem('inputResumeTitle');
                    const words = title?.match(wordRegex);
                    const filteredWords = words?.filter(
                        (word) => !skillRelatedWords.includes(word.toLowerCase())
                    );
                    const filteredText = filteredWords.join(" OR ");
                    const splittingSpecialCharacter = splitBySpecialCharacters(title);
                    const finalTitleWords = filteredWords.concat(splittingSpecialCharacter).map(word => `"${word}"`).join(" OR ");
                    // console.log('finalTitleWords', finalTitleWords, splittingSpecialCharacter);
                    searchText = finalTitleWords;

                    // searchText = filteredText;
                }
                var filterResumesUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select?q=application_title:(${searchText ? searchText : "*%3A*"})&fq=res_stateName:(${state !== undefined ? state : "*:*"})&fq=res_cityName:(${city !== undefined && city !== "" ? city : "*:*"})&fq=${skills}&fq=jobtype:(${jobtype ? jobtype : '*:*'})&fq=res_visatypeName:(${visatype ? visatype : '*:*'})&fq=res_visatypeName:(${visatype ? visatype : '*:*'})&fq=lastmodified:[${Mappeddates !== null ? Mappeddates : '* TO *'}]&fq=total_experience:(${experience ? experience : '*:*'})&fq=qualificationTitle:(${qualifications ? qualifications : '*:*'})&fq=country:(${country ? country : '*:*'})&fq=email_address:(${email ? '* TO *' : '*:*'})&fq=home_phone:(${phone ? '* TO *' : '*:*'})&fq=linkedin_url:(${linkedIn ? '* TO *' : '*:*'})&fq=willing_to_relocate:(${willingToRelocate ? '1' : '*:*'})&fq=res_salarrytypeName:(${sessionStorage.getItem("resumefiltered-salary") !== null ? salaryType : '*:*'})&fq=salary:(${[salaryFrom !== null ? salaryFrom + ' TO ' + '*' : '*:*']})&fq=search_flag:(1)&rows=10&start=${0}&sort=lastmodified%20desc,id%20asc&wt=json`

            } else if (sortValue === "relavance") {
                let searchText;
                if (sessionStorage.getItem('inputResumeTitle')) {
                    const wordRegex = /\b\w+\b/g;
                    const title = sessionStorage.getItem('inputResumeTitle');
                    const words = title?.match(wordRegex);
                    if (words) {
                        const filteredWords = words?.filter(
                            (word) => !skillRelatedWords.includes(word.toLowerCase())
                        );
                        const filteredText = filteredWords.join(" OR ");
                        const title = inputjobtitle;
                        // sessionStorage.getItem("inputResumeTitle");
                        let splitString = title.split(/\s*&\s*/);
                        let resultString = splitString.join(" OR ");
                        // searchText = `"${resultString}" OR ${filteredText}`;
        const splittingSpecialCharacter = splitBySpecialCharacters(title);
        const finalTitleWords = filteredWords.concat(splittingSpecialCharacter).map(word => `"${word}"`).join(" OR ");
        // console.log('finalTitleWords', finalTitleWords, splittingSpecialCharacter);
        searchText = finalTitleWords;
                    } else {
                        searchText = inputjobtitle;
                    }

                    // searchText = filteredText;
                }
                filterResumesUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select?q=application_title:(${searchText ? searchText : "*%3A*"})&fq=res_stateName:(${state !== undefined ? state : "*:*"})&fq=res_cityName:(${city !== undefined && city !== "" ? city : "*:*"})&fq=${skills}&fq=jobtype:(${jobtype ? jobtype : '*:*'})&fq=res_visatypeName:(${visatype ? visatype : '*:*'})&fq=res_visatypeName:(${visatype ? visatype : '*:*'})&fq=lastmodified:[${Mappeddates !== null ? Mappeddates : '* TO *'}]&fq=total_experience:(${experience ? experience : '*:*'})&fq=qualificationTitle:(${qualifications ? qualifications : '*:*'})&fq=country:(${country ? country : '*:*'})&fq=email_address:(${email ? '* TO *' : '*:*'})&fq=home_phone:(${phone ? '* TO *' : '*:*'})&fq=linkedin_url:(${linkedIn ? '* TO *' : '*:*'})&fq=willing_to_relocate:(${willingToRelocate ? '1' : '*:*'})&fq=res_salarrytypeName:(${sessionStorage.getItem("resumefiltered-salary") !== null ? salaryType : '*:*'})&fq=salary:(${[salaryFrom !== null ? salaryFrom + ' TO ' + '*' : '*:*']})&fq=search_flag:(1)&rows=10&start=${0}&id%20asc&wt=json`
            }
        } else {
            let searchText;
            //             if(inputjobtitle){
            //                 const wordRegex = /\b\w+\b/g;
            //                 const words = inputjobtitle.match(wordRegex);
            //                 const filteredWords = words.filter(
            //                   (word) => !skillRelatedWords.includes(word.toLowerCase())
            //                 );
            //                 const filteredText = filteredWords.join(" OR ");
            //   searchText = `${inputjobtitle} OR ${filteredText}`;

            //                 // searchText = filteredText;
            //               }
            if (inputjobtitle) {
                const wordRegex = /\b\w+\b/g;
                const words = inputjobtitle.match(wordRegex);
                if (words) {
                    const filteredWords = words.filter(
                        (word) => !skillRelatedWords.includes(word.toLowerCase())
                    );
                    const filteredText = filteredWords.join(" OR ");
                    const title = inputjobtitle;
                    // sessionStorage.getItem("inputResumeTitle");
                    let splitString = title.split(/\s*&\s*/);
                    let resultString = splitString.join(" OR ");
                    // searchText = `"${resultString}" OR ${filteredText}`;
        const splittingSpecialCharacter = splitBySpecialCharacters(title);
        const finalTitleWords = filteredWords.concat(splittingSpecialCharacter).map(word => `"${word}"`).join(" OR ");
        // console.log('finalTitleWords', finalTitleWords, splittingSpecialCharacter);
        searchText = finalTitleWords;
                } else {
                    searchText = inputjobtitle;
                }
            }
            filterResumesUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select?q=application_title:(${searchText ? searchText : "*%3A*"})&fq=res_stateName:(${state !== undefined ? state : "*:*"})&fq=res_cityName:(${city !== undefined && city !== "" ? city : "*:*"})&fq=${skills}&fq=jobtype:(${jobtype ? jobtype : '*:*'})&fq=res_visatypeName:(${visatype ? visatype : '*:*'})&fq=total_experience:(${experience ? experience : '*:*'})&fq=qualificationTitle:(${qualifications ? qualifications : '*:*'})&fq=lastmodified:[${Mappeddates !== null ? Mappeddates : '* TO *'}]&fq=country:(${country ? country : '*:*'})&fq=email_address:(${email ? '* TO *' : '*:*'})&fq=home_phone:(${phone ? '* TO *' : '*:*'})&fq=linkedin_url:(${linkedIn ? '* TO *' : '*:*'})&fq=res_salarrytypeName:(${sessionStorage.getItem("resumefiltered-salary") !== null ? salaryType : '*:*'})&fq=willing_to_relocate:(${willingToRelocate ? '1' : '*:*'})&fq=salary:(${[salaryFrom !== null ? salaryFrom + ' TO ' + '*' : '*:*']})&fq=search_flag:(1)&rows=10&start=0&sort=lastmodified%20desc,id%20asc&wt=json`

        }



        axios.post(`${Key.domain}/solr/solr_resumes`, { url: filterResumesUrl }, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            },
        })
            .then(resdata => {
                console.log('res fil', resdata)
                const retrivedData = resdata.data.data.response.docs;
                console.log(resdata.data.data.response.numFound)
                sessionStorage.setItem("resumesCount", resdata.data.data.response.numFound)
                this.props.hideLoader()
                if (window.location.pathname === "/ResumeSearch_Listview") {
                    this.props.history.push({
                        pathname: "/ResumeSearch_Listview",
                        state: {
                            response: retrivedData,
                            jobtitle: true,
                            location: false,
                            filterChecked: true,
                            phoneChecked: phone,
                            emailChecked: email,
                            willingToRelocate: willingToRelocate
                            //localStorage.getItem("willingtorelocate") && localStorage.getItem("willingtorelocate")

                        }
                    });
                } else if (window.location.pathname === "/employer/ai-search") {
                    this.props.history.push({
                        pathname: "/employer/ai-search",
                        state: {
                            response: retrivedData,
                            jobtitle: true,
                            location: false,
                            filterChecked: true,
                            phoneChecked: phone,
                            emailChecked: email,
                            willingToRelocate: willingToRelocate,
                            //localStorage.getItem("willingtorelocate") && localStorage.getItem("willingtorelocate")
                            numFoundFilter: resdata.data.data.response.numFound
                        }
                    });
                    sessionStorage.setItem(
                        "OpenAIresumesCount",
                        resdata.data.data.response.numFound
                    );
                }
            });


    }


    handleSalaryChange = (event) => {
        console.log(event.target.value);
        // setSalaryTypeName(event.target.value)
        this.setState({ salaryTypeName: event.target.value })
        this.setState({ salaryType: event.target.value })
        sessionStorage.setItem("resumefiltered-salary", event.target.value)
        this.getFilteredResumes()

    };

    handleEmailFilter = () => {
        this.setState({ emailChecked: !this.state.emailChecked })
        this.getFilteredResumes()
        console.log(this.state.emailChecked)
    }

    handlePhoeFilter = () => {
        this.setState({ phoneChecked: !this.state.phoneChecked })
        this.getFilteredResumes()
    }


    handleLinkedInFilter = () => {
        this.setState({ linkedIn: !this.state.linkedIn })
        this.getFilteredResumes()
    }


    handleWillingToRelocateFilter = () => {
        this.setState({ willingToRelocate: !this.state.willingToRelocate })
        localStorage.setItem("willingtorelocate", this.state.willingToRelocate)
        this.getFilteredResumes()
    }

    handleQualifications = (value) => {
        let checkedBox = value;
        this.props.showLoader()
        console.log(checkedBox)
        localStorage.removeItem("resumeFilterResetValue")

        this.setState(previous => {
            let QualificationFlages = previous.QualificationFlages;
            console.log(QualificationFlages)
            let index = QualificationFlages.indexOf(checkedBox)
            if (index === -1) {
                QualificationFlages.push(checkedBox) // if it isn't stored add it to the array
                localStorage.setItem("resumeQualificationFlages", JSON.stringify(this.state.QualificationFlages));
                localStorage.setItem("resumeFileterChecked", JSON.stringify(this.state.QualificationFlages))
                //  this.props.sendExpFunction(this.state.Expflages)
                var QualificationMappedIds = this.state.QualificationFlages.map(tid => `${tid}`).join(' OR ')
                { this.state.QualificationFlages.map((tid, i) => [{ tid }, i < this.state.QualificationFlages.length - 1 && " OR "]) }
                //console.log(ExpMappedIds)
                sessionStorage.setItem("resumefiltered-qualification", QualificationMappedIds)
                if (QualificationMappedIds !== '') {
                    this.getFilteredResumes()
                }
            }
            else {
                QualificationFlages.splice(index, 1) // if it is stored then remove it from the array
                //  this.props.sendExpFunction(this.state.Expflages)
                localStorage.setItem("resumeQualificationFlages", JSON.stringify(this.state.QualificationFlages));
                localStorage.setItem("resumeFileterChecked", JSON.stringify(this.state.QualificationFlages))
                var arr = JSON.parse(localStorage.getItem("resumeQualificationFlages"));
                //console.log(arr)
                QualificationMappedIds = QualificationFlages.map(fid => `${fid}`).join(' OR ')
                { QualificationFlages.map((fid, i) => [{ fid }, i < QualificationFlages.length - 1 && " OR "]) }
                //console.log(visatypeMappedIds)
                sessionStorage.setItem("resumefiltered-qualification", QualificationMappedIds)
                this.getFilteredResumes()

            }
            return { QualificationFlages }; // save the new selectedBoxes value in state
        },
        );
    }

    handleCountryFilter = (value) => {
        let checkedBox = value;
        console.log(checkedBox)
        sessionStorage.setItem("resumefiltered-country", checkedBox)
        if (checkedBox !== '') {
            this.getFilteredResumes()
        }
    }

    sortByDate = (value) => {
        this.props.onFilterChecked(true)
        this.props.showLoader()

        if (value == 1) {
            this.setState({ dateValue: value })
            localStorage.setItem("resumeDateValue", JSON.stringify(value))
            localStorage.setItem("resumeFileterChecked", JSON.stringify(value))
            localStorage.setItem("resumeSortByDate", "resumeSortByDate")

            const today = new Date();
            var startdate = moment(today).subtract(1, 'd').format('YYYY-MM-DD hh:mm:ss');
            var convertedStartDate = moment.utc(moment(startdate)).format()
            var mappeddate = convertedStartDate + ' TO ' + 'NOW'
            console.log(mappeddate)
            sessionStorage.setItem("resumeMappedDates", mappeddate)

            this.getFilteredResumes()

        } else if (value == 2) {
            this.setState({ dateValue: value })
            localStorage.setItem("resumeDateValue", JSON.stringify(value))
            localStorage.setItem("resumeFileterChecked", JSON.stringify(value))
            localStorage.setItem("resumeSortByDate", "resumeSortByDate")

            const today = new Date();
            var startdate = moment(today).subtract(7, 'd').format('YYYY-MM-DD hh:mm:ss');
            var convertedStartDate = moment.utc(moment(startdate)).format()
            var mappeddate = convertedStartDate + ' TO ' + 'NOW'
            console.log(mappeddate)

            sessionStorage.setItem("resumeMappedDates", mappeddate)

            this.getFilteredResumes()

        } else if (value == 3) {
            this.setState({ dateValue: value })
            localStorage.setItem("resumeDateValue", JSON.stringify(value))
            localStorage.setItem("resumeFileterChecked", JSON.stringify(value))
            localStorage.setItem("resumeSortByDate", "resumeSortByDate")

            const today = new Date();
            var startdate = moment(today).subtract(30, 'd').format('YYYY-MM-DD hh:mm:ss');
            var convertedStartDate = moment.utc(moment(startdate)).format()
            var mappeddate = convertedStartDate + ' TO ' + 'NOW'
            console.log(mappeddate)

            sessionStorage.setItem("resumeMappedDates", mappeddate)

            this.getFilteredResumes()
        } else if (value == 4) {
            this.setState({ dateValue: value })
            localStorage.setItem("resumeDateValue", JSON.stringify(value))
            localStorage.setItem("resumeFileterChecked", JSON.stringify(value))
            localStorage.setItem("resumeSortByDate", "resumeSortByDate")

            var mappeddate = '*' + ' TO ' + 'NOW'
            console.log(mappeddate)

            sessionStorage.setItem("resumeMappedDates", mappeddate)

            this.getFilteredResumes()
        } else if (value == "remove") {
            this.setState({ dateValue: "" })
            localStorage.setItem("resumeDateValue", JSON.stringify(value))
            localStorage.setItem("resumeFileterChecked", JSON.stringify(value))
            localStorage.removeItem("resumeSortByDate")

            var mappeddate = '*' + ' TO ' + 'NOW'
            console.log(mappeddate)

            sessionStorage.setItem("resumeMappedDates", mappeddate)

            this.getFilteredResumes()
        }


    }

    sortby = (sortValue) => {
        this.props.showLoader()
        if (sortValue === "recent") {
            localStorage.setItem("resumeSortBy", "recent")
            this.setState({ recent: !this.state.recent, relavance: false, sortValue: sortValue })
            this.getFilteredResumes(sortValue)
        } else if (sortValue === "relavance") {
            localStorage.setItem("resumeSortBy", "relevance")
            this.setState({ relavance: !this.state.relavance, recent: false, sortValue: sortValue })
            this.getFilteredResumes(sortValue)

        } else if (sortValue === "remove") {
            localStorage.removeItem("resumeSortBy")
            this.setState({ relavance: false, recent: false, sortValue: "recent" })
            this.getFilteredResumes("recent")

        }

    }

    reset = () => {
        // this.props.showLoader()

        sessionStorage.removeItem("resumefiltered-jobtypes")
        localStorage.removeItem("resumejobtypeFlages")
        sessionStorage.removeItem("resumefiltered-visatypes")
        localStorage.removeItem("resumevisatypeFlages")
        sessionStorage.removeItem("resumefiltered-experience")
        localStorage.removeItem("resumeExpFlages")
        sessionStorage.removeItem("resumefiltered-country")
        localStorage.removeItem("resumeQualificationFlages")
        sessionStorage.removeItem("resumefiltered-qualification")
        localStorage.removeItem("resumeCountryFlages")
        sessionStorage.removeItem("resumeMappedDates")
        sessionStorage.removeItem("currentsearchpage")
        localStorage.removeItem("resumeSortBy")
        localStorage.removeItem("resumeSortByDate")

        //  this.props.hideLoader()

    }

    render() {
        return (
            <div style={{ background: "#ffffff" }}>
                <ResumeChips className="col-12" data={this.state.Visaflages} data2={this.state.jobtypeflages} data3={[]} data4={[]} data5={this.state.Travelflages} data6={this.state.dateValue} sortValue={this.state.sortValue} jobtypeFunction={this.jobtypeFun} handleVisatype={this.visatypeFun} handleExpFun={this.handleExp} handleQualifications={this.handleQualifications} selectedOptionValue={this.sortByDate} sortby={this.sortby} handleworkmode={this.handleworkmode} jobtypes={jobtypes} lastupdated={posteddate} handleLoadingClick={this.handleLoadingClick} visaOptions={visatypes} exp={this.state.exp} recent={this.state.recent} relavance={this.state.relavance} qualifications={qualifications} />
                {/*  <button className="btn btn-outline-secondary" onClick={() => this.reset()} >
                    Reset
        </button>*/}
                <div id="resumeFilters" className='col-12 row align-items-center p-0 m-0' style={{ overflowY: "auto" }}>
                    <div className='accordion accordion-flush col-12 row' id="accordionFlushExample">
                        <div className="accordion-item  col-6">
                            <div className="card">
                                <div className="accordion-header" id="flush-headingOne">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button" data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseOne" aria-expanded="false"
                                        aria-controls="flush-collapseOne"
                                        style={{ color: "#000", backgroundColor: "#fff", paddingLeft: "7px" }}
                                    >
                                        Sort By
                                    </button>
                                </div>
                                <div
                                    id="flush-collapseOne"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingOne"
                                    data-bs-parent="#accordionFlushExample"
                                >
                                    <div className="accordion-body">
                                        <ul className="list-group">
                                            <li className="list-group-item">
                                                <input
                                                    className="form-check-input me-1"
                                                    type="checkbox"
                                                    value=""
                                                    aria-label="..."
                                                    onClick={(e) => {
                                                        this.sortby("recent");
                                                        //this.setJobtypeValue(i)
                                                        //console.log(e.target.checked)
                                                    }}
                                                    checked={localStorage.getItem("resumeSortBy") === "recent" || localStorage.getItem("resumeSortBy") === "relevance" ? this.state.recent : false}
                                                />&nbsp;
                                                Recent
                                            </li>
                                            <li className="list-group-item">
                                                <input
                                                    className="form-check-input me-1"
                                                    type="checkbox"
                                                    value=""
                                                    aria-label="..."
                                                    onClick={(e) => {
                                                        this.sortby("relavance");
                                                        //this.setJobtypeValue(i)
                                                        //console.log(e.target.checked)
                                                    }}
                                                    //checked={this.state.relavance}
                                                    checked={localStorage.getItem("resumeSortBy") === "recent" || localStorage.getItem("resumeSortBy") === "relevance" ? this.state.relavance : false}

                                                />&nbsp;
                                                Relavance
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item  col-6">
                            <div className="card">
                                <div className="accordion-header" id="flush-headingTwo">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button" data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseTwo"
                                        aria-expanded="false" aria-controls="flush-collapseTwo"
                                        style={{ color: "#000", backgroundColor: "#fff", paddingLeft: "7px" }}
                                    >
                                        Job Type
                                    </button>
                                </div>
                                <div
                                    id="flush-collapseTwo" class="accordion-collapse collapse"
                                    aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample"
                                >
                                    <div className="accordion-body">
                                        <ul className="list-group ">
                                            {jobtypes.map((jobtype) => (
                                                <li className="list-group-item">
                                                    <input
                                                        className="form-check-input me-1"
                                                        type="checkbox"
                                                        value=""
                                                        aria-label="..."
                                                        onClick={(e) => {
                                                            localStorage.setItem('jobs-jobtype', `${e.target.checked}`);
                                                            // this.jobtypeFun(jobtype.id);
                                                            //this.setJobtypeValue(i)
                                                            //console.log(e.target.checked)

                                                            this.setState({ jobtypeflages: parseInt(localStorage.getItem("resumeFilterResetValue")) === 1 ? [] : this.state.jobtypeflages })
                                                            setTimeout(() => {
                                                                this.jobtypeFun(jobtype.value);

                                                            }, 200);
                                                        }}
                                                        checked={(JSON.parse(localStorage.getItem("resumejobtypeFlages")) || []).includes(jobtype.value)}
                                                    />
                                                    &nbsp;
                                                    {jobtype.text}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item  col-6">
                            <div className="card">
                                <div className="accordion-header" id="flush-headingThree">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button" data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseThree"
                                        aria-expanded="false" aria-controls="flush-collapseThree"
                                        style={{ color: "#000", backgroundColor: "#fff", paddingLeft: "7px" }}
                                    >
                                        Posted Date
                                    </button>
                                </div>
                                <div
                                    id="flush-collapseThree" class="accordion-collapse collapse"
                                    aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample"
                                >
                                    <div className="accordion-body">
                                        <ul className="list-group">

                                            {posteddate.map(date =>
                                                <li className="list-group-item">
                                                    <input
                                                        className="form-check-input me-1"
                                                        type="checkbox"
                                                        value=""
                                                        aria-label="..."
                                                        onClick={() => { this.sortByDate(date.id) }}
                                                        checked={localStorage.getItem("resumeSortByDate") === "resumeSortByDate" ? parseInt(this.state.dateValue) === date.id : false}
                                                    />
                                                    &nbsp;{date.text}
                                                </li>)}


                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="accordion-item  col-6">
                            <div className="card">
                                <div className="accordion-header" id="flush-headingFive">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button" data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseFive"
                                        aria-expanded="false" aria-controls="flush-collapseFive"
                                        style={{ color: "#000", backgroundColor: "#fff", paddingLeft: "7px" }}
                                    >
                                        Experience
                                    </button>
                                </div>
                                <div
                                    id="flush-collapseFive" class="accordion-collapse collapse"
                                    aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample"
                                >
                                    <div className="accordion-body">
                                        <ul className="list-group">
                                            {this.state.exp.map(exp =>
                                                <li className="list-group-item">
                                                    <input
                                                        className="form-check-input me-1"
                                                        type="checkbox"
                                                        value=""
                                                        aria-label="..."
                                                        onClick={(e) => {
                                                            //this.setJobtypeValue(i)
                                                            //console.log(e.target.checked)
                                                            this.setState({ Expflages: parseInt(localStorage.getItem("resumeFilterResetValue")) === 1 ? [] : this.state.Expflages })
                                                            setTimeout(() => {
                                                                this.handleExp(exp.value);

                                                            }, 200);

                                                        }}
                                                        checked={(JSON.parse(localStorage.getItem("resumeExpFlages")) || []).includes(exp.value)}
                                                    />
                                                    &nbsp; {exp.text}
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="accordion-item  col-6">
                            <div className="card">
                                <div className="accordion-header" id="flush-headingSix">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button" data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseSix"
                                        aria-expanded="false" aria-controls="flush-collapseSix"
                                        style={{ color: "#000", backgroundColor: "#fff", paddingLeft: "0px" }}
                                    >
                                        &nbsp; Qualification
                                    </button>
                                </div>
                                <div
                                    id="flush-collapseSix" class="accordion-collapse collapse"
                                    aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample"
                                >
                                    <div className="accordion-body">
                                        <ul className="list-group">


                                            {qualifications.map(q =>
                                                <li className="list-group-item">
                                                    <input
                                                        className="form-check-input me-1"
                                                        type="checkbox"
                                                        value=""
                                                        aria-label="..."
                                                        onClick={(e) => {
                                                            //this.setJobtypeValue(i)
                                                            //console.log(e.target.checked)

                                                            this.setState({ QualificationFlages: parseInt(localStorage.getItem("resumeFilterResetValue")) === 1 ? [] : this.state.QualificationFlages })
                                                            setTimeout(() => {
                                                                this.handleQualifications(q.text);

                                                            }, 200);
                                                        }}
                                                        checked={(JSON.parse(localStorage.getItem("resumeQualificationFlages")) || []).includes(q.text)}
                                                    />
                                                    &nbsp; {q.text}
                                                </li>
                                            )}


                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <ul className="list-group">
                            <li className="list-group-item">
                                <input
                                    className="form-check-input me-1"
                                    type="checkbox"
                                    value=""
                                    aria-label="..."
                                    onClick={(e) => {
                                        //this.setJobtypeValue(i)
                                        //console.log(e.target.checked)
                                        setTimeout(() => {
                                            this.handleWillingToRelocateFilter();

                                        }, 200);
                                    }}
                                    checked={localStorage.getItem("willingtorelocate") === 'true'}

                                />
                                &nbsp; Willing to Relocate
                            </li>
                        </ul>

                    </div>

                    {/* social account */}
                    {/* <div className="card mt-4" style={{ padding: "10px" }}>
                        <ul className="list-group">
                            <li className="list-group-item">
                                <input
                                    className="form-check-input me-1"
                                    type="checkbox"
                                    value=""
                                    aria-label="..."
                                    onClick={(e) => {
                                        //this.setJobtypeValue(i)
                                        //console.log(e.target.checked)
                                        setTimeout(() => {
                                            this.handleLinkedInFilter();

                                        }, 200);
                                    }}
                                />
                                &nbsp; Social Profile
                            </li>
                        </ul>
                                </div>*/}
                    {/* <div className="accordion-item">
        <div className="card">
          <div className="accordion-header card-header head" id="headingSix">
            <button
              className="accordion-button fw-bold"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSix"
              aria-expanded="true"
              aria-controls="collapseSix"
              style={{ color: "#000", backgroundColor: "#fff" }}
            >
              Social Accounts
            </button>
          </div>
          <div
            id="collapseSix"
            className="accordion-collapse collapse show"
            aria-labelledby="headingSix"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <ul className="list-group">
                <li className="list-group-item">
                  <input
                    className="form-check-input me-1"
                    type="checkbox"
                    value=""
                    aria-label="..."
                  />
                  &nbsp; Yes
                </li>
                <li className="list-group-item">
                  <input
                    className="form-check-input me-1"
                    type="checkbox"
                    value=""
                    aria-label="..."
                  />
                  &nbsp; No
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div> */}

                    {/* third party resume */}
                    {/* <div className="card mt-4" style={{ padding: "10px" }}>
                        <ul className="list-group">
                            <li className="list-group-item">
                                <input
                                    className="form-check-input me-1"
                                    type="checkbox"
                                    value=""
                                    aria-label="..."
                                />
                                &nbsp; Exclude 3rd Party Resumes
                            </li>
                        </ul>
                    </div> */}

                    {/* countries */}
                    {/* <div className="card mt-4" style={{ padding: "10px" }}>
                        <label htmlFor="" className="ms-2 mb-2 fw-bold">  Profile From Country </label>
                        <select class="form-select" aria-label="Default select example"
                            onChange={(e) => {
                                this.handleCountryFilter(e.target.value);

                            }}>
                            <option selected>Select Country</option>
                            {this.state.countries.map((country) => (
                                <option value={country.id}>{country.name}</option>
                            ))}
                        </select>
                    </div> */}

                    {/* Rating */}
                    {/*   <div className="card mt-4" style={{ padding: "10px" }}>
                        <label htmlFor="" className="ms-2 mb-2 fw-bold">
                
                            {this.state.salaryTypeName === "Per Hour" ? "Rate" : "Salary"}
                        </label>
                        <div className="row">
                            <div className="col-md-4">
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="90"
                                    value={this.state.expectedFrom}
                                    onChange={(e) => { this.setState({ expectedFrom: e.target.value }); sessionStorage.setItem("resumeFiltered-salaryFrom", e.target.value) }}
                                    required
                                />
                            </div>
                            <div class=" col-md-8">
                                <div className="row">
                                    <div className="col-md-6">



                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="150,000"
                                            value={this.state.expectedTo}
                                            onChange={(e) => { this.setState({ expectedTo: e.target.value }); sessionStorage.setItem("resumeFiltered-salaryTo", e.target.value) }}
                                            required
                                        />
                                    </div>

                                    <div className="col-md-6">




                                        <select
                                            style={{ paddingRight: "0px" }}
                                            className="form-select "
                                            onChange={this.handleSalaryChange}
                                            aria-label="Default select example"

                                            required
                                        >
                                            <option selected value="Per Hour">
                                                Hour
                                            </option>
                                            <option selected value="Per Year">
                                                Year
                                            </option>

                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {/* travel 
                    <div className="card mt-4" style={{ padding: "10px" }}>
                        <label htmlFor="" className="ms-2 mb-2 fw-bold">
                            Travel
                        </label>
                        <select class="form-select" aria-label="Default select example">
                            <option selected>Select Travel</option>
                            <option value="1">not required</option>
                            <option value="2">25%</option>
                            <option value="2">50%</option>
                            <option value="2">75%</option>
                            <option value="2">100%</option>
                        </select>
                    </div>*/}
                    {/* security Clearence */}

                    <div className="accordion-item  col-12">
                        <div className="card">
                            <div className="accordion-header" id="flush-headingFour">
                                <button
                                    className="accordion-button collapsed"
                                    type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseFour"
                                    aria-expanded="false" aria-controls="flush-collapseFour"
                                    style={{
                                        color: "#000",
                                        backgroundColor: "#fff",
                                        boxShadow: "none",
                                        paddingLeft: "0px"
                                    }}
                                >
                                    &nbsp; Visa Type
                                </button>
                            </div>
                            <div
                                id="flush-collapseFour" class="accordion-collapse collapse"
                                aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample"
                            >
                                <div className="accordion-body">
                                    <div style={{ display: "flex" }}>
                                        {/* Left side */}
                                        <ul className="list-group" style={{ flex: 1, paddingRight: "10px", maxHeight: "auto" }}>
                                            {visatypes.slice(0, Math.ceil(visatypes.length / 2)).map(visatype => (
                                                <li className="list-group-item" key={visatype.id}>
                                                    <input
                                                        className="form-check-input me-1"
                                                        type="checkbox"
                                                        value={visatype.id}
                                                        aria-label="..."
                                                        onClick={(e) => {
                                                            localStorage.setItem('jobs-visatype', `${e.target.checked}`);
                                                            this.setState({ Visaflages: parseInt(localStorage.getItem("resumeFilterResetValue")) === 1 ? [] : this.state.Visaflages });
                                                            setTimeout(() => {
                                                                this.visatypeFun(visatype.solr_key);
                                                            }, 200);
                                                        }}
                                                        checked={(JSON.parse(localStorage.getItem("resumevisatypeFlages")) || []).includes(visatype.solr_key)}
                                                    />&nbsp;
                                                    {visatype.text}
                                                </li>
                                            ))}
                                        </ul>

                                        {/* Right side */}
                                        <ul className="list-group" style={{ flex: 1, paddingLeft: "10px", maxHeight: "150px" }}>
                                            {visatypes.slice(Math.ceil(visatypes.length / 2)).map(visatype => (
                                                <li className="list-group-item" key={visatype.id}>
                                                    <input
                                                        className="form-check-input me-1"
                                                        type="checkbox"
                                                        value={visatype.id}
                                                        aria-label="..."
                                                        onClick={(e) => {
                                                            localStorage.setItem('jobs-visatype', `${e.target.checked}`);
                                                            this.setState({ Visaflages: parseInt(localStorage.getItem("resumeFilterResetValue")) === 1 ? [] : this.state.Visaflages });
                                                            setTimeout(() => {
                                                                this.visatypeFun(visatype.solr_key);
                                                            }, 200);
                                                        }}
                                                        checked={(JSON.parse(localStorage.getItem("resumevisatypeFlages")) || []).includes(visatype.solr_key)}
                                                    />&nbsp;
                                                    {visatype.text}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                    {/* <div className="card mt-4" style={{ padding: "10px" }}>
                        <ul className="list-group">
                            <li className="list-group-item">
                                <input
                                    className="form-check-input me-1"
                                    type="checkbox"
                                    value=""
                                    aria-label="..."
                                />
                                &nbsp; Security Clearance
                            </li>
                        </ul>
                    </div> */}
                    {/* secrete clearence */}
                    {/* <div className="card mt-4" style={{ padding: "10px" }}>
                        <ul className="list-group">
                            <li className="list-group-item">
                                <input
                                    className="form-check-input me-1"
                                    type="checkbox"
                                    value=""
                                    aria-label="..."
                                />
                                &nbsp; Secrete Clearance
                            </li>
                        </ul>
                    </div> */}

                    {/*  <div className="accordion-item mb-4 mt-4">
                        <div className="card ">
                            <div className="accordion-header card-header head" id="headingEleven">
                                <button
                                    className="accordion-button fw-bold"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseEleven"
                                    aria-expanded="true"
                                    aria-controls="collapseEleven"
                                    style={{ color: "#000", backgroundColor: "#fff" }}
                                >
                                    Contact Method
                                </button>
                             </div> 
                            <div
                                id="collapseEleven"
                                className="accordion-collapse collapse show"
                                aria-labelledby="headingEleven"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="accordion-body">
                                    <ul className="list-group">
                                        <li className="list-group-item">
                                            <input
                                                className="form-check-input me-1"
                                                type="checkbox"
                                                // value=""
                                                aria-label="..."
                                                onChange={() => this.handleEmailFilter()}
                                                checked={this.state.emailChecked}
                                            />
                                            &nbsp; Email
                                        </li>
                                        <li className="list-group-item">
                                            <input
                                                className="form-check-input me-1"
                                                type="checkbox"
                                                value=""
                                                aria-label="..."
                                                onChange={() => this.handlePhoeFilter()}
                                                checked={this.state.phoneChecked}
                                            />
                                            &nbsp; Phone
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showLoader: () => dispatch(showLoader()),
        hideLoader: () => dispatch(hideLoader()),
    };
};
export default withRouter(connect(null, mapDispatchToProps)(ResumeFilters));
