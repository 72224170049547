import React, { useState, useEffect } from "react";
import "../../../src/Components/searchJobs/searchJobPage.css";
import { data } from "../searchJobs/searchJobData";
import icon from "../../Images/SearchJobs_Img/Icon-feather-bell.png";
import deloitte from "../../Images/SearchJobs_Img/Deloitte-Logo.png";
import accenture from "../../Images/SearchJobs_Img/accenture-logo.png";
import prev from "../../Images/previous.png";
import next from "../../Images/next.png";
import Footer from "../Jobseeker/Footer";
import adOne from "../../Images/ad-22.jpg";
import adTwo from "../../Images/ad-22.jpg";
import add_ from "../../Images/didyoufindjob.jpg";
import axios from "axios";
import Searchblock from "./mysqlResumeSearchBlock";
import Key from "../../clientVariables.json";
import moment from "moment";
import parse from "html-react-parser";
import Sendcandidatetomail from "./sendcandidatetomail";
import Navbar2 from "../Navbar2";
import Messagepopup from "./Messagepopup";
import Notepopup from "./Notepopup";
import imageOne from "../../Images/SearchJobs_Img/g-logo.png";
import imageTwo from "../../Images/SearchJobs_Img/image-2.png";
import imageThree from "../../Images/SearchJobs_Img/image-3.png";
import imageFour from "../../Images/SearchJobs_Img/image-4.png";
import imageFive from "../../Images/SearchJobs_Img/image-5.png";
import adOne2 from "../../Images/dashboard_ad.jpg";
import Filters from "../searchJobs/searchjob_filters";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import InfinitScroll from "react-infinite-scroll-component";
import { Badge as MuiBadge } from "@mui/material";

import Pagination from "./ResumesPagination";
import Navbar from "../Employer/Empnavbar";
import HomeNavbar from "../Navbar";
import Alertcandidate from "./Alertcandidate";
import { BiFoodTag, BiArrowBack } from "react-icons/bi";
import ResumesLogo from "../../../src/Images/resumefileicon.png";
import viewdbygroupIcon from "../../../src/Images/view_group.png";
import ShareResume from "../Employer/ShareResume";
import ShareResumeMultiple from "../Employer/ShareResumeMultiple";
import { toast } from "react-toastify";
import ToastMessage from "../../Components/ToastMessage";
import Rating from "@mui/material/Rating";
import { BsDownload } from "react-icons/bs";
import {
  AiFillStar,
  AiOutlineMail,
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlineDownload,
  AiOutlineShareAlt,
  AiFillEye,
} from "react-icons/ai";
import { TiGroup, TiGroupOutline } from "react-icons/ti";
import { MdOutlineStickyNote2 } from "react-icons/md";
import { GrNotes } from "react-icons/gr";
import { FiMessageSquare } from "react-icons/fi";
import EmpResumeFilters from "./ResumeFilters";

//for loader
import { showLoader } from "../Actions/loader.actions";
import { hideLoader } from "../Actions/loader.actions";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

//for alert popup
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Highlighter from "react-highlight-words";
import { TiEdit } from "react-icons/ti";
import { Tooltip } from "@mui/material";

const SearchJobsTwo = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);
  const [fileNotFound, setFileNotFOund] = React.useState(false);

  const [jobData, setJobData] = useState([]);
  const [resumes, setResumes] = useState([]);
  const [start, setStart] = useState(6);
  const [rowsPerPage, setRowsPerPage] = useState(
    sessionStorage.getItem("resumesPerPage") !== null
      ? parseInt(sessionStorage.getItem("resumesPerPage"))
      : 10
  );
  const [groupIds, setGroupIds] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [count, setCount] = useState(20);
  const [isJobtypeChecked, setIsJobtypeChecked] = useState(false);
  const [numFound, setNumFound] = useState(0);
  const [resumeChecked, setResumeChecked] = useState();
  const [selectedResumes, setSelectedResumes] = useState([]);
  const [totalViewsCount, setTotalViewsCount] = useState(0);
  const [allResumeViews, setAllResumeViews] = useState([]);
  const [groupByallResumeViews, setGroupByAllResumeViews] = useState([]);

  const [currentresid, setcurrentresid] = useState("");
  const [currentresfile, setcurrentresfile] = useState("");
  const [currentresuid, setcurrentresuid] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [isFilterChecked, setIsFilterChecked] = useState(false);
  const images = [imageOne, imageTwo, imageThree, imageFour, imageFive];
  const [show, setShow] = useState(false);
  const [showMultiple, setShowMultiple] = useState(false);
  const [tomail, settomail] = useState("");
  const [candfullname, setcandfullname] = useState("");
  const [jobs, setJobs] = useState([]);
  const [topJob, setTopJob] = useState({});
  const [resumeViewsLeft, setResumeViewsLEft] = useState(0);

  const [topJobs, setTopJobs] = useState([]);
  const [selectedResIds, setSelectedResids] = useState([]);

  const [messageshow, setmessageShow] = useState(false);
  const [noteShow, setNoteShow] = useState(false);
  const [mailshow, setmailShow] = useState(false);
  const [packageExpired, setPackageExpired] = useState(false);
  const [limitExceeded, setLimitExceeded] = useState(false);
  const [groupMembersIds, setGroupMemebersIds] = useState([]);
  const [skillDescriptionSearch, setSkillDescriptionSearch] = useState("");
  const [noteResumeId, setNoteResumeId] = useState("");
  // console.log(sessionStorage.getItem('skillstoHighlight'));
  // console.log(sessionStorage.getItem('skillstoHighlight').split(","));

  const handlemessageShow = () => setmessageShow(true);
  const handleNoteShow = (resumeId) => {
    setNoteResumeId(resumeId);
    if (sessionStorage.getItem("id") !== null) {
      setNoteShow(true);
    } else {
      history.push({
        pathname: "/EmpLogin",
      });
    }
  };
  const jobtype = sessionStorage.getItem("filtered-jobtypes");
  const history = useHistory();

  const handleCloseAlert = () => {
    setOpen(false);
  };

  const handleCloseResumeNotfound = () => {
    setFileNotFOund(false);
  };

  const checkEmployerLimit = (rid) => {
    //get user viewed resumes count and allowed resume views count
    axios
      .post(`${Key.domain}/users/check_employer_package_limit`, {
        user_id: props.currentUser.id,
        session_token: props.currentUser.session_token,
        action_to_check: "resume_views",
      })
      .then((resdata) => {
        if (
          resdata.status === 200 &&
          resdata.data.success === 1 &&
          resdata.data.data !== undefined &&
          resdata.data.data !== null &&
          resdata.data.data !== undefined &&
          resdata.data.data !== "" &&
          resdata.data.data[0] !== null &&
          resdata.data.data[0] !== undefined &&
          resdata.data.data[0] !== "" &&
          resdata.data.data[0].resumes_viewed !== null &&
          resdata.data.data[0].resumes_viewed !== undefined &&
          resdata.data.data[0].resumes_viewed !== "" &&
          resdata.data.data[0].resumeviews_allowed !== null &&
          resdata.data.data[0].resumeviews_allowed !== undefined &&
          resdata.data.data[0].resumeviews_allowed !== ""
        ) {
          if (resdata.data.data[0].package_expired) {
            setPackageExpired(true);
          } else {
            const leftResumeViews =
              parseInt(resdata.data.data[0].resumeviews_allowed) -
              parseInt(resdata.data.data[0].resumes_viewed);
            setResumeViewsLEft(leftResumeViews);

            if (rid) {
              let admin_id = sessionStorage.getItem("admin_id");
              let ca_id =
                admin_id !== "" &&
                admin_id !== 0 &&
                admin_id !== null &&
                admin_id !== undefined &&
                admin_id !== "0"
                  ? admin_id
                  : sessionStorage.getItem("id");
              axios
                .post(`${Key.domain}/resume/update_resume_viewedStatus`, {
                  user_id: sessionStorage.getItem("id"),
                  resumeid: rid,
                  ca_id: ca_id,
                  resume_type: "regular",
                  views: 1,
                  session_token: props.currentUser.session_token,
                })
                .then((response) => {
                  var resdata = response.data.data;
                });
            }
          }
        } else {
          throw new Error("No views yet");
        }
      })
      .catch((err) => {
        console.log(err); // TODO: Error handling
      });
  };
  useEffect(() => {
    //get all recruiter under same employer API
    if (props.currentUser !== null) {
      checkEmployerLimit();

      axios
        .post(`${Key.domain}/resume/get_all_recruiters_ByUserGroup`, {
          user_id: props.currentUser.id,
          ca_id: props.currentUser.ca_id,
          session_token: props.currentUser.session_token,
        })
        .then((response) => {
          setGroupMemebersIds(response.data.data);
          if (
            response.status === 200 &&
            response.data &&
            response.data.success === 1 &&
            response.data.data &&
            response.data.data.length &&
            response.data.data.length > 0
          ) {
            let recruiters = response.data.data.filter(
              (res) => res !== props.currentUser.id && res !== 0
            );

            setGroupIds(recruiters);
          }
        });
    }

    /*axios
      .get(`${Key.domain}/Home/get-top-companies-jobs`, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      })
      .then((response) => {
        var searchdata = response.data.data;
        setJobs(searchdata);

        setTopJob(searchdata[0]);
        const jobbs = searchdata.slice(1);
        setTopJobs(jobbs);
      });*/
    return () => {
      sessionStorage.removeItem("resResumeTitle");
    };
  }, []);

  {
    /*useEffect(() => {
    console.log(resumes)
    if (resumes.length > 0 && resumes.some(res => !res.viewed_by)) {
      setPageLoading(true)
      props.showLoader()
      var resumeIds = resumes.map(res => JSON.parse(res.id));
      const tempResumes = resumes
      if (props.currentUser !== null) {

        //get resume viewed user ids based on resume ids
        axios.post(`${Key.domain}/resume/get_resumes_viewed_by_ids`,
          {
            "user_id": props.currentUser.id,
            "resume_ids": resumeIds,
            "session_token": props.currentUser.session_token
          })
          .then(response => {
            console.log(response)
            if (response.status === 200 && response.data && response.data.success === 1 && response.data.data.length && response.data.data.length > 0) {


              //assigning viewed_by column to resumes array
              tempResumes.map((resume, resumeIdex) =>
                response.data.data.map((result, resultIndex) => {
                  if (JSON.parse(resume.id) === JSON.parse(result.id)) {
                    tempResumes[resumeIdex].viewed_by = result.viewed_by;
                  }
                })
              )

              //assigning group viewed_by column to resumes array
              tempResumes.map((resume, resumeIdex) =>
                groupIds.map((result, resultIndex) => {
                  console.log(resume, result)
                  if (resume.viewed_by) {
                    if (resume.viewed_by.includes(result))
                      tempResumes[resumeIdex].viewed_by_group = true;
                  }
                })
              )


              setResumes(tempResumes)
              console.log(tempResumes)
            }
            setPageLoading(false)
            props.hideLoader()
          })
          .catch(error => {
            console.log(error)
            setPageLoading(false)
            props.hideLoader()
          })

      } else {
        setPageLoading(false)

      }

    }
  }, [resumes])*/
  }

  useEffect(() => {
    if (
      props.location.state !== undefined &&
      props.location.state.response !== null
    ) {
      setResumes(props.location.state.response);
      setResumeChecked(false);

      // setIsJobtypeChecked(props.location.state.jobtypeChecked)
    }

    if (
      props.location.state &&
      props.location.state.filterChecked !== undefined
    ) {
      setResumes(props.location.state.response);
    }
    if (props.currentUser !== null) {
      if (props.currentUser.package_id === 1) {
        setTotalViewsCount(20);
      } else if (props.currentUser.package_id === 2) {
        setTotalViewsCount(100);
      } else if (props.currentUser.package_id === 3) {
        setTotalViewsCount(200);
      } else if (props.currentUser.package_id === 4) {
        setTotalViewsCount(300);
      } else if (props.currentUser.package_id === 5) {
        setTotalViewsCount(400);
      }

      fetch(`${Key.domain}/resume/get_all_resume_views`, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          user_id: sessionStorage.getItem("id"),
          session_token: props.currentUser.session_token,
        }),
      })
        .then((response) => response.json()) //converting data into json format
        .then((resdata) => {
          setAllResumeViews(resdata.data.map((data) => data.resume_id));
        });

      fetch(`${Key.domain}/resume/get_all_resume_views_byGroup`, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          user_id: sessionStorage.getItem("id"),
          session_token: props.currentUser.session_token,
          ca_id: props.currentUser.ca_id,
        }),
      })
        .then((response) => response.json()) //converting data into json format
        .then((resdata) => {
          //setGroupByAllResumeViews(resdata.data.map((data) => data.resume_id));
          // console.log(resdata.data)
          setGroupByAllResumeViews(resdata.data);
        });
    }
    props.hideLoader();
  }, [props.location]);

  useEffect(() => {
    setSkillDescriptionSearch(
      props.location.state && props.location.state.skills
    );

    var Url = window.location.pathname;
    var currentUrl = Url.split("/");
    //var currentId = currentUrl[2].split("_");
    const searchkeyword = currentUrl[2];
    var searchResumesUrl = "";
    var searchword = searchkeyword.replace("%20", " ");
    sessionStorage.setItem("inputResumeTitle", searchword);

    if (searchword !== "" && searchword !== undefined && searchword !== null) {
      let searchtitle = JSON.stringify(searchword);
      searchResumesUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select?q=application_title:${searchtitle}&fq=search_flag:(1)&rows=10&start=0&sort=created%20desc&wt=json`;
    } else {
      searchResumesUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select?q=*%3A*&fq=search_flag:(1)&rows=10&start=0&sort=created%20desc&wt=json`;
    }

    console.log(searchResumesUrl);
    localStorage.setItem("resumeQuery", searchResumesUrl);
    fetch(`${Key.domain}/solr/solr_resumes`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ url: searchResumesUrl }),
    }).then((response) => {
      response.json().then((resdata) => {
        console.log(resdata);

        sessionStorage.setItem("resumesCount", resdata.data.response.numFound);

        setResumes(resdata.data.response.docs);
      });
    });
  }, [props.location.state && props.location.state.skills]);

  const onSubmit = (resumes) => {
    setResumes(resumes);
  };

  const onFilterChecked = (isJobtypeChecked) => {
    setIsFilterChecked(isJobtypeChecked);
  };
  const updateViewStatusMultiple = (ids) => {
    if (ids && ids !== undefined && ids.length > 0) {
      ids.map((id) => updateViewStatus(id));
    }
  };

  const updateViewStatus = (rid) => {
    checkEmployerLimit(rid);
  };

  const jobTitleClicked = (resume_id) => {
    var id = parseInt(resume_id);
    if (props.currentUser !== null) {
      axios
        .post(`${Key.domain}/resume/get_resumes_list_viewed_by_currentUser`, {
          user_id: props.currentUser.id,
          session_token: props.currentUser.session_token,
        })
        .then((response) => {
          if (
            response.data.success === 1 &&
            response.data.data !== undefined &&
            response.data.data !== null &&
            response.data.data !== undefined &&
            response.data.data !== ""
          ) {
            if (
              response.data.data &&
              response.data.data.length &&
              response.data.data.length > 0
            ) {
              //get user viewed resumes count and allowed resume views count
              axios
                .post(`${Key.domain}/users/check_employer_package_limit`, {
                  user_id: props.currentUser.id,
                  session_token: props.currentUser.session_token,
                  action_to_check: "resume_views",
                })
                .then((resdata) => {
                  if (
                    resdata.status === 200 &&
                    resdata.data.success === 1 &&
                    resdata.data.data !== undefined &&
                    resdata.data.data !== null &&
                    resdata.data.data !== undefined &&
                    resdata.data.data !== ""
                  ) {
                    var resumes = response.data.data;
                    var sortedResumes = resumes.some(
                      (res) => parseInt(res.resume_id) === parseInt(id)
                    );
                    console.log(sortedResumes, id);

                    if (sortedResumes) {
                      checkLimit();
                    } else if (resdata.data.data[0].package_expired) {
                      setPackageExpired(true);
                      setOpen(true);
                    } else {
                      /*  if (!sortedResumes && response.data.data.length >= parseInt(resdata.data.data[0].resumeviews_allowed)) {
                        setOpen(true)
                      } */
                      if (resdata.data.limitExceeded) {
                        setLimitExceeded(true);
                        setOpen(true);
                      } else {
                        checkLimit();
                      }
                    }
                  } else {
                    throw new Error("No views yet");
                  }
                })
                .catch((err) => {
                  console.log(err); // TODO: Error handling
                  checkLimit();
                });
            } else {
              throw new Error("No views yet");
            }
          }
        })
        .catch((err) => {
          console.log(err);
          checkLimit();
        });

      function checkLimit() {
        if (id !== null && id !== undefined) {
          axios
            .post(
              `${Key.domain}/resume/check_view_resumes_limit_based_on_package_id`,
              {
                ca_id: sessionStorage.getItem("id"),
                session_token: props.currentUser.session_token,
              }
            )
            .then((response) => {
              var resdata = response.data.data;
              //alert(resdata.length)
              if (resdata.length < totalViewsCount || resdata.length === 0) {
                history.push({
                  pathname: "/resume/view/resume_" + id,
                  state: {
                    skills: props.location.state.skills,
                    //filterChecked: props.location.state.filterChecked === true ? true : false
                  },
                });
                updateViewStatus(id); // id is resumeid
              } else if (allResumeViews.includes(id)) {
                history.push({
                  pathname: "/resume/view/resume_" + id,
                  state: {
                    skills: props.location.state.skills,
                  },
                });
                updateViewStatus(id); // id is resumeid
              } else {
                alert("You exceeded your resumes view limit");
              }
            });
        }
      }
    } else {
      sessionStorage.setItem(
        "redirectToResumeView",
        "/resume/view/resume_" + resume_id
      );
      // sessionStorage.setItem("resume_id",resume_id)
      history.push({
        pathname: "/EmpLogin",
      });
    }
  };

  const multipleDownloadOrViewResume = () => {
    if (props.currentUser !== null) {
      if (resumeViewsLeft > 0) {
        if (resumeViewsLeft < selectedResumes.length) {
          let msg = "";
          if (resumeViewsLeft === 1) {
            msg = "You can download only " + resumeViewsLeft + " resume";
          } else {
            msg = "You can download only " + resumeViewsLeft + " resumes";
          }
          toast(<ToastMessage message={msg} />);
        } else {
          if (selectedResumes.length > 0) {
            for (let i = 0; i < selectedResumes.length; i++) {
              axios
                .post(`${Key.domain}/resume/get_js_resume_details`, {
                  user_id: sessionStorage.getItem("id"),
                  resumeid: selectedResumes[i].id,
                  session_token: props.currentUser.session_token,
                })
                .then((response) => {
                  let resdetails = response.data.data.resumedetails;
                  var filename = resdetails.filename;
                  var resumeid = resdetails.id;
                  var uploader_id = resdetails.uploader_id;

                  fetch(
                    `${Key.files_domain}/MyFiles/resume_attachments/${uploader_id}/${resumeid}/${filename}`,
                    {
                      method: "GET", // *GET, POST, PUT, DELETE, etc.
                      headers: {
                        "Content-Type": "application/json",
                        Authorization:
                          "Bearer " + window.localStorage["Access_Token"],
                      },
                    }
                  )
                    .then((resdata) => {
                      let msg = "resume downloaded";
                      toast(<ToastMessage message={msg} />);

                      return resdata.blob().then((b) => {
                        var a = document.createElement("a");
                        a.href = URL.createObjectURL(b);
                        const targetFilename =
                          b.type &&
                          b.type !== undefined &&
                          b.type.toLowerCase().includes("pdf")
                            ? filename.split(".").slice(0, -1).join(".") +
                              ".pdf"
                            : b.type.toLowerCase().includes("docx")
                            ? filename.split(".").slice(0, -1).join(".") +
                              ".docx"
                            : b.type.toLowerCase().includes("doc")
                            ? filename.split(".").slice(0, -1).join(".") +
                              ".doc"
                            : filename.split(".").slice(0, -1).join(".") +
                              ".pdf";
                        a.setAttribute(
                          "download",
                          targetFilename ? targetFilename : filename
                        );
                        a.click();
                        updateViewStatus(resumeid);
                      });
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                });
            }
          } else {
            let msg = "Select a resume to download";
            toast(<ToastMessage message={msg} />);
          }
        }
      } else {
        setOpen(true);
      }
    } else {
      history.push({
        pathname: "/EmpLogin",
      });
    }
  };

  const downloadOrViewResume = (resumeId, uploader_id) => {
    if (resumeViewsLeft > 0) {
      axios
        .post(`${Key.domain}/resume/get_js_resume_details`, {
          user_id: sessionStorage.getItem("id"),
          resumeid: resumeId,
          session_token: props.currentUser.session_token,
        })
        .then((response) => {
          let resdetails = response.data.data.resumedetails;
          var filename = resdetails.filename;
          let extension = filename.split(".").pop();

          fetch(
            `${Key.files_domain}/MyFiles/resume_attachments/${uploader_id}/${resumeId}/${filename}`,
            {
              method: "GET", // *GET, POST, PUT, DELETE, etc.
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + window.localStorage["Access_Token"],
              },
            }
          )
            .then((resdata) => {
              /*  if (extension === "pdf") {
                //Build a URL from the file
                const fileURL = resdata.url;

                //Open the URL on new Window
                const pdfWindow = window.open();
                pdfWindow.location.href = fileURL;
                let msg = " resume downloaded";
          toast(<ToastMessage message={msg} />);
              } else {
                let msg = " resume downloaded";
          toast(<ToastMessage message={msg} />);
                return resdata.blob().then((b) => {
                  var a = document.createElement("a");
                  a.href = URL.createObjectURL(b);
                  const targetFilename=b.type&&b.type!==undefined&&b.type.toLowerCase().includes("pdf")?filename.split('.').slice(0, -1).join('.') + ".pdf":b.type.toLowerCase().includes("docx")?filename.split('.').slice(0, -1).join('.') + ".docx":b.type.toLowerCase().includes("doc")?filename.split('.').slice(0, -1).join('.') + ".doc":filename.split('.').slice(0, -1).join('.') + ".pdf"
                  a.setAttribute("download", targetFilename);
                  a.click();
                  updateViewStatus(resumeId)

                });
              } */

              let msg = "resume downloaded";
              toast(<ToastMessage message={msg} />);

              return resdata.blob().then((b) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                const targetFilename =
                  b.type &&
                  b.type !== undefined &&
                  b.type.toLowerCase().includes("pdf")
                    ? filename.split(".").slice(0, -1).join(".") + ".pdf"
                    : b.type.toLowerCase().includes("docx")
                    ? filename.split(".").slice(0, -1).join(".") + ".docx"
                    : b.type.toLowerCase().includes("doc")
                    ? filename.split(".").slice(0, -1).join(".") + ".doc"
                    : filename.split(".").slice(0, -1).join(".") + ".pdf";
                a.setAttribute(
                  "download",
                  targetFilename ? targetFilename : filename
                );
                a.click();
                updateViewStatus(resumeId);
              });
            })
            .catch((e) => {
              console.log(e);
            });
        });
    } else {
      setOpen(true);
    }
  };

  const handleChange = (e, resumeId) => {
    const { name, value, checked } = e.target;

    if (name === "allCheck") {
      let resumeslist = resumes.map((list) => {
        setResumeChecked(checked);
        return { ...list, isChecked: checked };
      });
      setResumes(resumeslist);
      let objs = resumeslist.filter((x) => x.isChecked === true);
      let filteredobjs = objs.filter((x) => x.filename !== "");
      var ids = filteredobjs.map((obj) => obj.id);
      setSelectedResids(ids);

      setSelectedResumes(filteredobjs);
    } else if (name === String(resumeId)) {
      console.log("comes here");
      let newResumes = resumes.map((list) =>
        list.id === resumeId ? { ...list, isChecked: checked } : list
      );
      setResumes(newResumes);

      let obj = newResumes.filter((x) => x.isChecked === true);
      let filteredobjs = obj.filter((x) => x.filename !== "");
      var ids = filteredobjs.map((obj) => obj.id);
      setSelectedResids(ids);
      setSelectedResumes(filteredobjs);
      obj.length > 1 ? setResumeChecked(checked) : setResumeChecked(false);
    }
  };

  const handleRedirect = () => {
    // props.history.location.goBack();
    sessionStorage.removeItem("inputResumeTitle");
    sessionStorage.removeItem("resumeCityName");
    sessionStorage.removeItem("resumeTitle");
    sessionStorage.removeItem("cityname");

    history.push("/Empdashboard");
  };

  const onPageChanged = (data) => {
    setResumeChecked(false);
    const { currentPage, totalPages, pageLimit } = data;
    const resumesPerPage =
      sessionStorage.getItem("resumesPerPage") !== null
        ? parseInt(sessionStorage.getItem("resumesPerPage"))
        : 10;

    setCurrentPage(currentPage);

    let pageChange = currentPage + 1;

    if (start == null) {
      // if (sessionStorage.getItem("startValue") === null) {
      //   sessionStorage.setItem("startValue", 0);
      // }
      var increment =
        start + parseInt(sessionStorage.getItem("startValue"))
          ? parseInt(sessionStorage.getItem("startValue"))
          : 0;
      setStart(increment);
      sessionStorage.setItem("startValue", increment);
    } else if (currentPage < pageChange) {
      increment = pageChange * resumesPerPage - resumesPerPage - resumesPerPage;
      if (increment == -resumesPerPage) {
        //alert("iam executed")
        increment = increment + resumesPerPage;
        setStart(increment);
        sessionStorage.setItem("startValue", increment);
      }
      setStart(increment);
      sessionStorage.setItem("startValue", increment);
    }

    var jobtype = sessionStorage.getItem("resumefiltered-jobtypes");
    var visatype = sessionStorage.getItem("resumefiltered-visatypes");
    var experience = sessionStorage.getItem("resumefiltered-experience");
    var resumeTitle = sessionStorage.getItem("resumeTitle")
      ? sessionStorage.getItem("resumeTitle")
      : sessionStorage.getItem("resumeTitle");
    var cityname =
      sessionStorage.getItem("resumeCityName") &&
      sessionStorage.getItem("resumeCityName") !== null &&
      sessionStorage.getItem("resumeCityName") !== "null"
        ? sessionStorage.getItem("resumeCityName")
        : "";
    var inputResumeTitle =
      resumeTitle !== "" && resumeTitle !== undefined && resumeTitle !== null
        ? resumeTitle
        : "";
    var Mappeddates = sessionStorage.getItem("resumeMappedDates");
    var qualifications = sessionStorage.getItem("resumefiltered-qualification");
    var country = sessionStorage.getItem("resumefiltered-country");
    var email =
      props.location.state && props.location.state.emailChecked !== undefined
        ? props.location.state.emailChecked
        : false;
    var phone =
      props.location.state && props.location.state.phoneChecked !== undefined
        ? props.location.state.phoneChecked
        : false;
    var willingToRelocate =
      props.location.state &&
      props.location.state.willingToRelocate !== undefined
        ? props.location.state.willingToRelocate
        : false;
    var salaryType =
      '"' + sessionStorage.getItem("resumefiltered-salary") + '"';
    // console.log(sessionStorage.getItem("resResumeTitle") )
    // var inputResumeTitle = sessionStorage.getItem("resResumeTitle") !== null ? sessionStorage.getItem("resResumeTitle") : sessionStorage.getItem("resumeTitle") ? sessionStorage.getItem("resumeTitle") : "";
    var salaryTo = sessionStorage.getItem("resumeFiltered-salaryTo");
    var salaryFrom = sessionStorage.getItem("resumeFiltered-salaryFrom");
    console.log(skillDescriptionSearch);
    var skillNDescriptionSearch =
      props.location.state && props.location.state.skills !== undefined
        ? props.location.state.skills
        : "* TO *";
    var skillstoHighlight = sessionStorage.getItem("skillstoHighlight");
    // const str = inputResumeTitle

    // const res = str.includes("developer") || str.includes("programmer") || str.includes('architect') || str.includes("designer") || str.includes('consultant') || str.includes("administrator") || str.includes('coach') || str.includes("lead") || str.includes('manager') || str.includes("master") || str.includes('specalist') || str.includes('modeler') || str.includes('director') || str.includes('hacker');

    // if (res === true) {
    //   inputResumeTitle = '"' + str + '"'
    // }

    if (
      inputResumeTitle === null ||
      inputResumeTitle === undefined ||
      inputResumeTitle === ""
    ) {
      sessionStorage.removeItem("jobtitle");
      localStorage.removeItem("jobtitle");
      sessionStorage.removeItem("inputjobtitle");
    }

    const inputlocationcity = sessionStorage.getItem("resumeCityName");
    // alert(props.location.state.filterChecked)
    if (props.location.state !== undefined) {
      if (props.location.state.filterChecked !== true) {
        if (
          inputlocationcity !== "" &&
          inputResumeTitle !== "" &&
          inputlocationcity !== "null" &&
          inputResumeTitle !== "null" &&
          inputlocationcity !== null
        ) {
          var jobtype = props.location.state.homepageJobtypesChecked;

          const searchResumesUrl = `${Key.Solr_url}/${
            Key.solrResumes_collection
          }/select?q=${inputResumeTitle}&fq=${inputlocationcity}&rows=${resumesPerPage}&start=${sessionStorage.getItem(
            "startValue"
          )}&fq=jobtype:(${jobtype ? jobtype : "*:*"})&fq=${
            skillNDescriptionSearch !== undefined
              ? skillNDescriptionSearch
              : "* TO *"
          }&fq=search_flag:(1)&sort=created%20desc&wt=json`;

          localStorage.setItem("resumeQuery", searchResumesUrl);
          fetch(`${Key.domain}/solr/solr_resumes`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ url: searchResumesUrl }),
          }).then((response) => {
            response.json().then((resdata) => {
              sessionStorage.setItem("resumeTitle", inputResumeTitle);
              sessionStorage.setItem("resumeCityName", inputlocationcity);
              sessionStorage.setItem(
                "resumesCount",
                resdata.data.response.numFound
              );
              setResumes(resdata.data.response.docs);

              // history.push({
              //   pathname: "/ResumeSearch_Listview",
              //   state: {
              //     response: resdata.data.response.docs,
              //     jobtitle: true,
              //     location: true,

              //   },
              // });
            });
          });
        } else if (
          (inputlocationcity === "" ||
            inputlocationcity === null ||
            inputlocationcity === "null") &&
          inputResumeTitle !== ""
        ) {
          var jobtype = props.location.state.homepageJobtypesChecked;

          const searchResumesUrl = `${Key.Solr_url}/${
            Key.solrResumes_collection
          }/select?q=application_title:${inputResumeTitle}&rows=${resumesPerPage}&start=${sessionStorage.getItem(
            "startValue"
          )}&fq=jobtype:(${jobtype ? jobtype : "*:*"})&fq=${
            skillNDescriptionSearch !== undefined
              ? skillNDescriptionSearch
              : "* TO *"
          }&fq=search_flag:(1)&sort=created%20desc&wt=json`;
          localStorage.setItem("resumeQuery", searchResumesUrl);

          fetch(`${Key.domain}/solr/solr_resumes`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ url: searchResumesUrl }),
          }).then((response) => {
            response.json().then((resdata) => {
              sessionStorage.setItem("resumeTitle", inputResumeTitle);
              sessionStorage.setItem("resumeCityName", inputlocationcity);
              sessionStorage.setItem(
                "resumesCount",
                resdata.data.response.numFound
              );
              setResumes(resdata.data.response.docs);

              // history.push({
              //   pathname: "/ResumeSearch_Listview",
              //   state: {
              //     response: resdata.data.response.docs,
              //     jobtitle: true,
              //     location: false,
              //   },
              // });
            });
          });
        } else if (
          inputlocationcity !== "" &&
          inputlocationcity !== null &&
          inputlocationcity !== "null" &&
          inputlocationcity !== undefined &&
          (inputResumeTitle === "" || inputResumeTitle === null)
        ) {
          var jobtype = props.location.state.homepageJobtypesChecked;

          const searchResumesUrl = `${Key.Solr_url}/${
            Key.solrResumes_collection
          }/select?&fq=${inputlocationcity}&rows=${resumesPerPage}&start=${sessionStorage.getItem(
            "startValue"
          )}&fq=jobtype:(${jobtype ? jobtype : "*:*"})&fq=${
            skillNDescriptionSearch !== undefined
              ? skillNDescriptionSearch
              : "* TO *"
          }&fq=search_flag:(1)&sort=created%20desc&wt=json`;

          localStorage.setItem("resumeQuery", searchResumesUrl);
          fetch(`${Key.domain}/solr/solr_resumes`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ url: searchResumesUrl }),
          }).then((response) => {
            response.json().then((resdata) => {
              sessionStorage.setItem("resumeTitle", inputResumeTitle);
              sessionStorage.setItem("resumeCityName", inputlocationcity);
              sessionStorage.setItem(
                "resumesCount",
                resdata.data.response.numFound
              );
              setResumes(resdata.data.response.docs);

              // history.push({
              //   pathname: "/ResumeSearch_Listview",
              //   state: {
              //     response: resdata.data.response.docs,
              //     jobtitle: false,
              //     location: true,
              //   },
              // });
            });
          });
        } else {
          var jobtype = props.location.state.homepageJobtypesChecked;
          // alert(jobtype)
          const searchResumesUrl = `${Key.Solr_url}/${
            Key.solrResumes_collection
          }/select?q=*%3A*&rows=${resumesPerPage}&start=${sessionStorage.getItem(
            "startValue"
          )}&fq=jobtype:(${jobtype ? jobtype : "*:*"})&fq=${
            skillNDescriptionSearch !== undefined
              ? skillNDescriptionSearch
              : "* TO *"
          }&fq=search_flag:(1)&sort=created%20desc&wt=json`;
          console.log(searchResumesUrl);
          localStorage.setItem("resumeQuery", searchResumesUrl);
          fetch(`${Key.domain}/solr/solr_resumes`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ url: searchResumesUrl }),
          }).then((response) => {
            response.json().then((resdata) => {
              console.log(resdata);
              sessionStorage.setItem("resumeTitle", inputResumeTitle);
              sessionStorage.setItem("resumeCityName", inputlocationcity);
              sessionStorage.setItem(
                "resumesCount",
                resdata.data.response.numFound
              );

              // history.push({
              //   pathname: "/ResumeSearch_Listview",
              //   state: {
              //     response: resdata.data.response.docs,
              //     jobtitle: false,
              //     location: false,
              //   },
              // });

              setResumes(resdata.data.response.docs);
            });
          });
        }
      } else {
        var filterResumesUrl = `${Key.Solr_url}/${
          Key.solrResumes_collection
        }/select?q=${
          inputResumeTitle ? inputResumeTitle : "*%3A*"
        }&fq=${cityname}&fq=jobtype:(${
          jobtype ? jobtype : "*:*"
        })&fq=res_visatypeName:(${
          visatype ? visatype : "*:*"
        })&fq=total_experience:(${
          experience ? experience : "*:*"
        })&fq=qualificationTitle:(${
          qualifications ? qualifications : "*:*"
        })&fq=created:[${
          Mappeddates !== null ? Mappeddates : "* TO *"
        }]&fq=email_address:(${email ? "* TO *" : "*:*"})&fq=home_phone:(${
          phone ? "* TO *" : "*:*"
        })&fq=willing_to_relocate:(${willingToRelocate ? "1" : "*:*"})&fq=${
          skillNDescriptionSearch !== undefined
            ? skillNDescriptionSearch
            : "* TO *"
        }
        &fq=res_salarrytypeName:(${
          sessionStorage.getItem("resumefiltered-salary") !== null
            ? salaryType
            : "*:*"
        })&fq=salary:(${[
          salaryFrom !== null ? salaryFrom + " TO " + "*" : "*:*",
        ]})&rows=10&start=${sessionStorage.getItem(
          "startValue"
        )}&fq=search_flag:(1)&sort=lastmodified%20desc,id%20asc&wt=json`;

        localStorage.setItem("resumeQuery", filterResumesUrl);
        axios
          .post(
            `${Key.domain}/solr/solr_resumes`,
            { url: filterResumesUrl },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            }
          )
          .then((resdata) => {
            const retrivedData = resdata.data.data.response.docs;
            sessionStorage.setItem(
              "resumesCount",
              resdata.data.data.response.numFound
            );

            setResumes(retrivedData);
          });
      }
    }
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleShow = (resid, resfile, resuid) => {
    if (sessionStorage.getItem("id") !== null) {
      // console.log(packageExpired)
      if (resumeViewsLeft > 0) {
        console.log(resumeViewsLeft);
        axios
          .post(`${Key.domain}/resume/get_js_resume_details`, {
            user_id: sessionStorage.getItem("id"),
            resumeid: resid,
            session_token: props.currentUser.session_token,
          })
          .then((response) => {
            let resdetails = response.data.data.resumedetails;
            console.log(resdetails);
            if (
              resdetails.filename !== "" &&
              resdetails.filename !== null &&
              resdetails.filename !== undefined &&
              resdetails.filename !== "null"
            ) {
              setcurrentresid(resid);
              setcurrentresfile(resdetails.filename);
              setcurrentresuid(resuid);
              setShow(true);
            } else {
              setFileNotFOund(true);
            }
          })
          .catch((error) => {
            setFileNotFOund(true);
          });
      } else {
        setOpen(true);
      }
    } else {
      history.push({
        pathname: "/EmpLogin",
      });
    }
  };

  const handleShowMultiple = () => {
    if (sessionStorage.getItem("id") !== null) {
      if (resumeViewsLeft > 0) {
        if (selectedResumes.length > 0) {
          if (resumeViewsLeft < selectedResumes.length) {
            let msg = "";
            if (resumeViewsLeft === 1) {
              msg = "You can share only " + resumeViewsLeft + " resume";
            } else {
              msg = "You can share only " + resumeViewsLeft + " resumes";
            }

            toast(<ToastMessage message={msg} />);
          } else {
            axios
              .post(`${Key.domain}/resume/get_resumefiles_multiple`, {
                user_id: sessionStorage.getItem("id"),
                resumeids: selectedResIds,
                session_token: props.currentUser.session_token,
              })
              .then((response) => {
                let resdetails = response.data.data;

                setSelectedResumes(resdetails);
                // setcurrentresid(resid);
                // setcurrentresfile(resdetails.filename);
                // setcurrentresuid(resuid);
                setShowMultiple(true);
              });
          }
        } else {
          let msg = "Select a resume to share";
          toast(<ToastMessage message={msg} />);
        }
      } else {
        setOpen(true);
      }
    } else {
      history.push({
        pathname: "/EmpLogin",
      });
    }
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleCloseMultiple = () => {
    setShowMultiple(false);
  };
  const handlemessageClose = () => {
    setmessageShow(false);
  };

  const handleNoteClose = () => {
    setNoteShow(false);
  };

  const handlemailClose = () => {
    setmailShow(false);
  };

  const handlemailShow = (mail, name) => {
    if (sessionStorage.getItem("id") !== null) {
      settomail(mail);
      setmailShow(true);
      setcandfullname(name);
    } else {
      history.push({
        pathname: "/EmpLogin",
      });
    }
  };

  const changeRowsPerPage = (value) => {
    setRowsPerPage(value);
    sessionStorage.setItem("resumesPerPage", value);
  };

  return (
    <div>
      {props.currentUser !== null ? <Navbar /> : <HomeNavbar />}
      {sessionStorage.getItem("id") !== null ? (
        <Dialog open={show} onClose={handleClose} fullWidth>
          {/* <Modal.Header closeButton>
            <Modal.Title>Share </Modal.Title>
          </Modal.Header> */}

          <DialogTitle
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <DialogContentText variant="h6">Share</DialogContentText>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <ShareResume
              handlepopUpClose={handleClose}
              filename={currentresfile}
              res_id={currentresid}
              user_id={currentresuid}
              usermail={
                props.currentUser.username !== null
                  ? props.currentUser.username
                  : ""
              }
              updateViewStatus={updateViewStatus}
            />
          </DialogContent>
        </Dialog>
      ) : null}

      {/* for alert message*/}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        fullWidth
        onClose={handleCloseAlert}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {packageExpired ? "Package Expired!" : "Limit exceeded!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {packageExpired ? (
              <span>
                Your package is expired. Please{" "}
                <a
                  href="/Contactsales"
                  style={{
                    cursor: "pointer",
                    color: "blue",
                    textDecoration: "underline",
                  }}
                >
                  ContactSales
                </a>{" "}
                to upgrade your package!`
              </span>
            ) : (
              <span>
                You have exceeded your free limit to view, download or share
                resumes. Please{" "}
                <a
                  href="/Contactsales"
                  style={{
                    cursor: "pointer",
                    color: "blue",
                    textDecoration: "underline",
                  }}
                >
                  ContactSales
                </a>{" "}
                to upgrade your package!
              </span>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>

      {/* for resume file not found alert message*/}
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        open={fileNotFound}
        onClose={handleCloseResumeNotfound}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Attachment Not Found"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{"Resume Attachment not found"}</DialogContentText>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>

      {sessionStorage.getItem("id") !== null ? (
        <Dialog
          fullWidth
          open={mailshow}
          centered
          size="lg"
          onClose={handlemailClose}
        >
          {/* <Modal.Header closeButton>
              <Modal.Title style={{ fontSize: "18px" }}>
                Send Mail To Candidate
              </Modal.Title>
            </Modal.Header> */}

          <DialogTitle
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <DialogContentText variant="h6" style={{ fontSize: "18px" }}>
              {" "}
              Send Mail To Candidate
            </DialogContentText>
            <IconButton aria-label="close" onClick={handlemailClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Sendcandidatetomail
              handlemailClose={handlemailClose}
              tomail={tomail}
              candfullname={candfullname}
              fromempEmail={props.currentUser.username}
            />
          </DialogContent>
        </Dialog>
      ) : null}

      {sessionStorage.getItem("id") !== null ? (
        <Dialog fullWidth open={showMultiple} onClose={handleCloseMultiple}>
          {/* <Modal.Header closeButton>
            <Modal.Title>Share </Modal.Title>
          </Modal.Header> */}
          <DialogTitle
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <DialogContentText variant="h6">Share</DialogContentText>
            <IconButton aria-label="close" onClick={handleCloseMultiple}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <ShareResumeMultiple
              handleCloseMultiple={handleCloseMultiple}
              // filename={currentresfile}
              // res_id={currentresid}
              // user_id={currentresuid}
              selectedResumes={selectedResumes}
              usermail={
                props.currentUser.username !== null
                  ? props.currentUser.username
                  : ""
              }
              updateViewStatus={updateViewStatusMultiple}
            />
          </DialogContent>
        </Dialog>
      ) : null}

      <Searchblock
        onSubmit={onSubmit}
        fulltime={
          props.location.state && props.location.state.fulltime !== undefined
            ? props.location.state.fulltime
            : false
        }
        contract={
          props.location.state && props.location.state.contract !== undefined
            ? props.location.state.contract
            : false
        }
        jobtitle={sessionStorage.getItem("jobtitle")}
        cityName={sessionStorage.getItem("cityname")}
      />
      <br />
      <div id="searchJobPage" style={{ height: "40%" }}>
        <button class="feedback2" onClick={handleRedirect}>
          <BiArrowBack size={18} />
        </button>

        <div className="container-fluid">
          <div className="row">
            <div
              className="col-sm-12 col-md-12 col-lg-12"
              style={{
                marginTop:
                  (JSON.parse(localStorage.getItem("resumeFileterChecked")) !==
                    null &&
                    JSON.parse(localStorage.getItem("resumeFileterChecked"))
                      .length > 0) ||
                  JSON.parse(localStorage.getItem("resumeFileterChecked")) !==
                    null
                    ? "30px"
                    : "0",
              }}
            >
              <p
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  marginRight: "20px",
                }}
              >
                {/* {resumeChecked ? <AiOutlineDownload style={{ cursor: "pointer" }} onClick={() => multipleDownloadOrViewResume()} className="mx-2 mt-1" /> : ""}
                 */}
              </p>

              <div className="row">
                <div className="col">
                  <div>
                    {pageLoading
                      ? ""
                      : resumes.map((res, idx) => {
                          return (
                            <div class="card mb-3" key={res.id}>
                              {res.viewed_by ? (
                                res.viewed_by.includes(
                                  sessionStorage.getItem("id")
                                ) ? (
                                  // <Badge className="resumeViewedStatus">Viewed</Badge>
                                  <MuiBadge
                                    className="resumeViewedStatus"
                                    bg="secondry"
                                  >
                                    Viewed
                                  </MuiBadge>
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )}
                              {/*
                          {res.viewed_by_group ?
                            <Badge className="resumeViewedStatus">Viewed By Group</Badge>

                            : ""} */}
                              <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-1 logo">
                                  <div
                                    className="form-check check-box"
                                    // style={{
                                    //   display: "flex",
                                    //   justifyContent: "center",
                                    //   marginTop: "-25px",
                                    //   paddingBottom: "30px",
                                    //   paddingTop: "7px",
                                    // }}
                                  ></div>
                                  <img src={ResumesLogo} />
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-11">
                                  <div
                                    className="card-body"
                                    style={{ lineHeight: "30px" }}
                                  >
                                    <p
                                      className="resume-rating"
                                      // style={{
                                      //   display: "flex",
                                      //   justifyContent: "end",
                                      //   marginRight: "20px",
                                      //   marginTop: "0",
                                      //   marginBottom: "0",
                                      // }}
                                    >
                                      Rating :{" "}
                                      <span className="ms-2">
                                        {res.profile_rating
                                          ? res.profile_rating < 3.5
                                            ? "3.5"
                                            : res.profile_rating
                                          : "3.5"}{" "}
                                      </span>
                                      {/* <AiFillStar className="mx-2 mt-1" /> */}
                                      <div
                                        style={{
                                          marginTop: "2px",
                                        }}
                                      >
                                        <Rating
                                          name="size-small"
                                          size="small"
                                          color="#000"
                                          value={
                                            res.profile_rating
                                              ? res.profile_rating < 3.5
                                                ? "3.5"
                                                : res.profile_rating
                                              : "3.5"
                                          }
                                          precision={0.1}
                                          readOnly
                                        />
                                      </div>
                                    </p>

                                    <h6
                                      className="card-title"
                                      style={{
                                        cursor: "pointer",
                                        color: "#406882",
                                        fontWeight: "bold",
                                      }}
                                      onClick={() => jobTitleClicked(res.id)}
                                    >
                                      {res.full_name}
                                    </h6>
                                    <p
                                      className="card-text"
                                      id="job__description"
                                      style={{
                                        fontWeight: "600",
                                        marginBottom: "0",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontWeight: "600",
                                          marginBottom: "0",
                                        }}
                                      >
                                        <Highlighter
                                          highlightStyle={{
                                            backgroundColor: "#95ffbe",
                                          }}
                                          searchWords={
                                            sessionStorage.getItem(
                                              "skillstoHighlight"
                                            ) !== undefined &&
                                            sessionStorage.getItem(
                                              "skillstoHighlight"
                                            ) !== null &&
                                            sessionStorage.getItem(
                                              "skillstoHighlight"
                                            ) !== ""
                                              ? sessionStorage
                                                  .getItem("skillstoHighlight")
                                                  .split(",")
                                              : []
                                          }
                                          autoEscape={true}
                                          textToHighlight={
                                            res.application_title
                                          }
                                        />
                                      </span>
                                    </p>
                                    <p
                                      className="card-text"
                                      id="job__description"
                                      style={{ marginBottom: "0" }}
                                    >
                                      <span
                                        style={{
                                          marginBottom: "0",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Summary
                                      </span>{" "}
                                      :{" "}
                                      {/* res.summary &&
                                      res.summary !== "null" &&
                                      res.summary !== "undefined" &&
                                      res.summary !== ""
                                      ? parse(
                                        res.summary
                                          .slice(0, 750)
                                          .replace(/<[^>]+>/g, "")
                                      ) + "..."
                                      : "NOT AVAILABLE" */}
                                      <Highlighter
                                        highlightStyle={{
                                          backgroundColor: "#95ffbe",
                                        }}
                                        searchWords={
                                          sessionStorage.getItem(
                                            "skillstoHighlight"
                                          ) !== undefined &&
                                          sessionStorage.getItem(
                                            "skillstoHighlight"
                                          ) !== null &&
                                          sessionStorage.getItem(
                                            "skillstoHighlight"
                                          ) !== ""
                                            ? sessionStorage
                                                .getItem("skillstoHighlight")
                                                .split(",")
                                            : []
                                        }
                                        autoEscape={true}
                                        textToHighlight={
                                          res.summary &&
                                          res.summary !== "null" &&
                                          res.summary !== "undefined" &&
                                          res.summary !== ""
                                            ? parse(
                                                res.summary
                                                  .slice(0, 750)
                                                  .replace(/<[^>]+>/g, "")
                                              ) + "..."
                                            : "NOT AVAILABLE"
                                        }
                                      />
                                    </p>
                                    <p
                                      className="card-text mb-0 mt-0"
                                      style={{
                                        fontSize: "14px",
                                        marginBottom: "0",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontWeight: "600",
                                          marginBottom: "0",
                                        }}
                                      >
                                        Key Skills
                                      </span>{" "}
                                      :{" "}
                                      <span
                                        style={{
                                          marginLeft: "4px",
                                          letterSpacing: "0.2px",
                                        }}
                                      >
                                        {" "}
                                        <Highlighter
                                          highlightStyle={{
                                            backgroundColor: "#95ffbe",
                                          }}
                                          searchWords={
                                            sessionStorage.getItem(
                                              "skillstoHighlight"
                                            ) !== undefined &&
                                            sessionStorage.getItem(
                                              "skillstoHighlight"
                                            ) !== null &&
                                            sessionStorage.getItem(
                                              "skillstoHighlight"
                                            ) !== ""
                                              ? sessionStorage
                                                  .getItem("skillstoHighlight")
                                                  .split(",")
                                              : []
                                          }
                                          autoEscape={true}
                                          textToHighlight={
                                            res.skillName
                                              ? res.skillName
                                                  .join(",")
                                                  .split(",")
                                                  .join(", ")
                                                  .slice(0, 100)
                                              : "N/A"
                                          }
                                        />
                                      </span>
                                    </p>
                                    <p
                                      className="card-text mb-0 mt-0"
                                      style={{
                                        fontSize: "14px",
                                        marginBottom: "0",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontWeight: "600",
                                          marginBottom: "0",
                                        }}
                                      >
                                        Willing to Relocate
                                      </span>{" "}
                                      :{" "}
                                      <span
                                        style={{
                                          marginLeft: "4px",
                                          letterSpacing: "0.2px",
                                        }}
                                      >
                                        {" "}
                                        {res.willing_to_relocate == "0"
                                          ? "No"
                                          : res.willing_to_relocate == "1"
                                          ? "Yes"
                                          : "No"}
                                      </span>
                                    </p>
                                  </div>

                                  <div className="card-footer bottom">
                                    <small className="text-muted">
                                      Location&nbsp;:&nbsp;
                                      {res.res_cityName ? (
                                        <span>
                                          {res.res_cityName},&nbsp;{" "}
                                          {res.res_shortRegion}
                                        </span>
                                      ) : (
                                        "N/A"
                                      )}
                                    </small>
                                    <small className="text-muted">
                                      Job Type&nbsp;:&nbsp;
                                      {res.res_jobtypeName
                                        ? res.res_jobtypeName
                                            .join(",")
                                            .split(",")
                                            .join(", ")
                                        : "N/A"}
                                    </small>
                                    <small className="text-muted">
                                      Visa Type&nbsp;:&nbsp;
                                      {res.res_visatypeName
                                        ? res.res_visatypeName
                                        : res.visatype}
                                    </small>
                                    <small className="text-muted">
                                      Posted&nbsp;:&nbsp;
                                      {moment(res.created).fromNow()}
                                    </small>
                                  </div>
                                  <div
                                    className="mb-2"
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      fontSize: "20px",
                                    }}
                                  >
                                    <span style={{ display: "inline-flex" }}>
                                      <Tooltip title="Share">
                                        <AiOutlineShareAlt
                                          className="mx-2"
                                          onClick={() =>
                                            handleShow(
                                              res.id,
                                              res.filename,
                                              res.uid
                                            )
                                          }
                                        />
                                      </Tooltip>

                                      <Tooltip title="Send mail to candidate">
                                        {/* <AiOutlineMail className="mx-2" onClick={handlemailShow} /> */}
                                        <AiOutlineMail
                                          className="mx-2"
                                          onClick={() =>
                                            handlemailShow(
                                              res.email_address,
                                              res.full_name
                                            )
                                          }
                                        />
                                      </Tooltip>
                                      <Tooltip title="Message">
                                        <FiMessageSquare
                                          className="mx-2"
                                          onClick={handlemessageShow}
                                        />
                                      </Tooltip>
                                      <Tooltip title="Short Notes">
                                        <TiEdit
                                          className="mx-2"
                                          onClick={() => handleNoteShow(res.id)}
                                        />
                                      </Tooltip>
                                    </span>

                                    <span
                                      style={{
                                        marginRight: "20px",
                                        display: "inline-flex",
                                      }}
                                    >
                                      {/* viewed by Person i.e. login user */}

                                      {allResumeViews
                                        .join(",")
                                        .includes(res.id) ? (
                                        <Tooltip title="Viewed">
                                          <AiFillEye
                                            className="mx-2"
                                            style={{ color: "#406882" }}
                                          />
                                        </Tooltip>
                                      ) : (
                                        <Tooltip title="Not Viewed">
                                          <AiOutlineEye className="mx-2" />
                                        </Tooltip>
                                      )}

                                      {/* viewed by user's group i.e current user's group */}
                                      {/* groupMembersIds.length!==0 && groupMembersIds.join(",").includes(res.viewed_by) */}

                                      {groupByallResumeViews
                                        .map((item) => item.resume_id)
                                        .join(",")
                                        .includes(res.id) ? (
                                        <Tooltip
                                          title={groupByallResumeViews.map(
                                            (item) =>
                                              item.resume_id == res.id
                                                ? "Viewed By Group Member : " +
                                                  item.RecruiterName
                                                : ""
                                          )}
                                        >
                                          <TiGroup
                                            className="mx-2"
                                            style={{ color: "#406882" }}
                                          />
                                        </Tooltip>
                                      ) : (
                                        <Tooltip title="Group Members Not Viewed">
                                          <TiGroupOutline className="mx-2" />
                                        </Tooltip>
                                      )}

                                      <Tooltip title="Download">
                                        <AiOutlineDownload
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            downloadOrViewResume(
                                              res.id,
                                              res.uid
                                            )
                                          }
                                        />
                                      </Tooltip>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                    {/*{numFound !==0 || sessionStorage.getItem("resumesCount") !== "0" ?
                    <div className="row">
                      <div className="col-sm-12 col-md-8">
                        {isJobtypeChecked !== true ? (
                          <div style={{ color: "#406882" }}>
                            Page {currentPage} of{" "}
                            {sessionStorage.getItem("resumesCount")
                              ? numFound
                                ? numFound
                                : parseInt(
                                  sessionStorage.getItem("resumesCount")
                                )
                              : numFound}{" "}
                            Resumes
                          </div>
                        ) : (
                          <div style={{ color: "#406882" }}>
                            Page {currentPage} of{" "}
                            {sessionStorage.getItem("resumesCount")} Resumes
                          </div>
                        )}

                        <div
                          style={{
                            justifyContent: "space-between",
                            display: "flex",
                          }}
                        >


                          {isJobtypeChecked !== true ? (
                            <Pagination
                              totalRecords={
                                sessionStorage.getItem("resumesCount")
                                  ? numFound
                                    ? numFound
                                    : parseInt(
                                      sessionStorage.getItem("resumesCount")
                                    )
                                  : numFound
                              }
                              pageLimit={
                                sessionStorage.getItem("resumesPerPage") !== null
                                  ? sessionStorage.getItem("resumesPerPage")
                                  : 10
                              }
                              pageNeighbours={1}
                              onPageChanged={onPageChanged}
                            />
                          ) : (
                            <Pagination
                              totalRecords={

                                parseInt(
                                  sessionStorage.getItem("resumesCount")
                                )
                              }
                              pageLimit={
                                sessionStorage.getItem("resumesPerPage") !== null
                                  ? sessionStorage.getItem("resumesPerPage")
                                  : 10
                              }
                              pageNeighbours={1}
                              onPageChanged={onPageChanged}
                            />
                          )}




                        </div>
                      </div>
                      <div className="col-sm-12 col-md-4" style={{ paddingTop: "6px" }}>
                        <div
                          class="form-group row"
                          id="rows-perpage"
                        // style={{ marginLeft: "40px" }}
                        >
                          <label
                            for="staticEmail"
                            class="col-sm-8 col-form-label"
                            style={{ width: "140px" }}
                          >
                            Rows Per Page
                          </label>
                          <div class="col-sm-2">
                            <select
                              class="form-select form-select mb-0"
                              aria-label=".form-select-lg example"
                              style={{ width: "80px" }}
                              onChange={(e) => {
                                changeRowsPerPage(e.target.value);
                                onPageChanged(
                                  sessionStorage.getItem("currentsearchpage"),
                                  "",
                                  sessionStorage.getItem("resumesPerPage")
                                );
                              }}
                            >
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="30">30</option>
                            </select>
                          </div>
                        </div>
                        <div class="form-group row"></div>
                      </div>
                    </div> : <div className='container' style={{ paddingTop: "2%" }}>
                    <div class="alert alert-success" role="alert" style={{ display: "flex", placeContent: "center" }}>
        
        
                        <h5>No Resumes Found !</h5>
                    </div>
        
                            </div> } */}
                  </div>
                  {numFound !== 0 ||
                  sessionStorage.getItem("resumesCount") !== "0" ? (
                    <div className="row">
                      <div className="col-md-6 adds-images">
                        <img src={adOne2} className="img-fluid" />
                      </div>
                      <div className="col-md-6 adds-images">
                        <img src={adTwo} className="img-fluid" />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <br />
                  {numFound !== 0 ||
                  sessionStorage.getItem("resumesCount") !== "0" ? (
                    <Dialog
                      fullWidth
                      open={messageshow}
                      centered
                      onClose={handlemessageClose}
                      className="col-4"
                    >
                      {/* <Modal.Header closeButton>
                        <Modal.Title style={{ fontSize: "18px" }}>
                          Message
                        </Modal.Title>
                      </Modal.Header> */}

                      <DialogTitle
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <DialogContentText
                          variant="h6"
                          style={{ fontSize: "18px" }}
                        >
                          Message
                        </DialogContentText>
                        <IconButton
                          aria-label="close"
                          onClick={handlemessageClose}
                        >
                          <CloseIcon />
                        </IconButton>
                      </DialogTitle>
                      <DialogContent>
                        <Messagepopup handlemessageClose={handlemessageClose} />
                      </DialogContent>
                    </Dialog>
                  ) : (
                    ""
                  )}
                  {numFound !== 0 ||
                  sessionStorage.getItem("resumesCount") !== "0" ? (
                    <Dialog
                      open={noteShow}
                      fullWidth
                      centered
                      onClose={handleNoteClose}
                      size="sm"
                    >
                      {/* <Modal.Header closeButton>
                        <Modal.Title style={{ fontSize: "18px" }}>
                          Note
                        </Modal.Title>
                      </Modal.Header> */}

                      <DialogTitle
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <DialogContentText
                          variant="h6"
                          style={{ fontSize: "18px" }}
                        >
                          Note
                        </DialogContentText>
                        <IconButton
                          aria-label="close"
                          onClick={handleNoteClose}
                        >
                          <CloseIcon />
                        </IconButton>
                      </DialogTitle>

                      <DialogContent>
                        <Notepopup
                          handleNoteClose={handleNoteClose}
                          noteResumeId={noteResumeId}
                        />
                      </DialogContent>
                    </Dialog>
                  ) : (
                    ""
                  )}

                  {props.currentUser !== null ? (
                    numFound !== 0 ||
                    sessionStorage.getItem("resumesCount") !== "0" ? (
                      <Alertcandidate
                        joblocation={
                          props.location.state &&
                          props.location.state.inputjoblocation !== undefined
                            ? props.location.state.inputjoblocation
                            : ""
                        }
                        jobtitle={
                          props.location.state &&
                          props.location.state.inputResumeTitle !== undefined
                            ? props.location.state.inputResumeTitle
                            : ""
                        }
                      />
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  <div></div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

//Get props from state
function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.UserReducer.user,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchJobsTwo)
);

//!res.viewed_by_group
//?

//   <Tooltip
//     content="Group Members Not Viewed"
//     direction="left"
//   >
//     <img
//       src={viewdbygroupIcon}
//       className="mx-2"
//     />
//   </Tooltip>
// ) : (
//   <Tooltip
//     content="Group Member Viewed"
//     direction="left"
//   >
//     <AiFillEye
//       className="mx-2"
//       style={{ color: "#406882" }}
//     />
//   </Tooltip>
// )}*/
