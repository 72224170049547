import React, { useState, useRef, useEffect } from "react";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import Key from "../clientVariables.json";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";

const FeedbackFormNew = (props) => {
  const [value, setValue] = useState(0);
  const [buttonLoading, setButtonLoading] = useState(false);
  const formikRef = useRef();
  const [submit, setSubmit] = useState(false);

  useEffect(() => {
    setSubmit(false);
  }, []);
  const handleSubmit = (values) => {
    
    // e.preventDefault();
    if (values.query !== "") {
      setButtonLoading(true);
      setSubmit(true);
      axios
        .put(`${Key.domain}/commonAPIs/send_feedback`, {
          user_id: props.currentUser.id,
          session_token: props.currentUser.session_token,
          email: props.currentUser.username,
          name: props.currentUser.full_name,
          description: values.query,
          rating: value,
        })
        .then((res) => {
          
          if (res.status === 200 && res.data.data.insertId) {
            axios
              .post(`${Key.domain}/empdashboard/send_feedback_mail`, {
                user_id: props.currentUser.id,
                session_token: props.currentUser.session_token,
                description: values.query,
                name: props.currentUser.full_name,
                email: props.currentUser.username,
                rating: value,
              })
              .then((res) => {
                
                setButtonLoading(false);
                if (res && res.status) {
                  props.handleSidebar(false);
                  
                  values.query = "";
                  setValue();
                  setSubmit(false);
                } else {
                  // alert("Mail not sent!");
                  
                  values.query = "";
                  setValue();
                  setSubmit(false);
                }
              });
          } else {
            setButtonLoading(false);

            // alert("Error while sending feedback!");
          }
        });
    }
  };
  return (
    <div className="feedback_form_area_inner">
      {submit ? (
        <p style={{ height: "150px",fontWeight:"500",fontSize:"20px",marginTop:"40px" }}>Thanks for your valuable feedback</p>
      ) : (
        <div>
          <Typography component="legend" style={{ fontWeight: "500" }}>
            How would you rate Jobsnprofiles?
          </Typography>
          <Rating
            name="size-large"
            value={value}
            onChange={(event, newValue) => {
              setValue(Number(newValue));
            }}
            size="large"
          />

          <Formik
            innerRef={formikRef}
            initialValues={{
              query: "",
            }}
            validationSchema={Yup.object().shape({
              query: Yup.string().required("Feedback Required"),
            })}
            onSubmit={(fields) => {
              
              handleSubmit(fields);
            }}
          >
            {({ touched, errors, isSubmitting, values }) => (
              <div>
                <Form>
                  <div className="form-group">
                    <label htmlFor="title" className="mb-1">
                      Write your feedback
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <Field
                      type="text"
                      as="textarea"
                      name="query"
                      placeholder=" Mesaage"
                      className={
                        "form-control" +
                        (errors.query && touched.query ? " is-invalid" : "")
                      }
                    />

                    <ErrorMessage
                      component="div"
                      name="query"
                      className="invalid-feedback"
                    />
                  </div>
                  <div class="form-group2">
                    <label className="mb-1">
                      Do you agree to publicly share your feedback?
                    </label>
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label
                      class="ms-2 form-check-label"
                      for="flexRadioDefault1"
                    >
                      Yes
                    </label>
                    <input
                      class="ms-2 form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                    />
                    <label
                      class="ms-2 form-check-label"
                      for="flexRadioDefault2"
                    >
                      No
                    </label>
                  </div>
                  <button
                    type="submit"
                    className="mt-4 btn btn-primary btn-block "
                  >
                    Submit
                  </button>
                </Form>
              </div>
            )}
          </Formik>

          {/* <form onSubmit={handleSubmit}>
          <div class="form-group2">
            <label style={{ fontSize: "15px" }}>Write your feedback</label>
            <textarea
              id=""
              placeholder="Message"
              class="custom-inp-txt"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            ></textarea>
          </div>

          <div class="form-group2">
            <label style={{ fontSize: "15px" }}>your email</label>
            <input
              type="email"
              required
              id=""
              placeholder="Email"
              class="custom-inp"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div class="form-group2">
            <label>Do you agree to publicly share your feedback?</label>
            <input
              class="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault1"
            />
            <label class="ms-2 form-check-label" for="flexRadioDefault1">
              yes
            </label>
            <input
              class="ms-2 form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault2"
            />
            <label class="ms-2 form-check-label" for="flexRadioDefault2">
              no
            </label>
          </div>
          <div class="">
            {/* {buttonLoading ? ( */}
          {/* <>
              <button type="submit" id="a1" className="btn bnt-outline">
                Submit
              </button>
            </> */}
          {/* ) : (
              <>
                <Button
                  variant="outline-secondary"
                  id="a1"
                  onClick={() => sendMail()}
                >
                  Submit
                </Button>{" "}
              </>
            )} */}
          {/* </div>
        </form> */}
        </div>
      )}
    </div>
  );
};
function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.UserReducer.user,
  };
}
export default withRouter(connect(mapStateToProps, null)(FeedbackFormNew));
