// import React, { useState } from 'react';
// import {
//   Select,
//   MenuItem,
//   FormControl,
//   InputLabel,
//   Button,
//   Box,
// } from '@mui/material';

// const JobFilters = () => {
//   const [selectedFilters, setSelectedFilters] = useState({
//     jobType: '',
//     postedDate: '',
//     visaType: '',
//     workMode: '',
//     travel: '',
//   });

//   const handleFilterChange = (filterName, value) => {
//     setSelectedFilters((prevFilters) => ({
//       ...prevFilters,
//       [filterName]: value,
//     }));
//   };

//   const handleSubmit = () => {
//     // Implement logic to apply filters
//
//   };

//   const handleClearAll = () => {
//     // Implement logic to clear all filters
//     setSelectedFilters({
//       jobType: '',
//       postedDate: '',
//       visaType: '',
//       workMode: '',
//       travel: '',
//     });
//   };

//   return (
//     <div>
//       <div>
//       {/* Job Type Filter */}
//       <FormControl sx={{ m: 1, minWidth: 120 }}>
//         <InputLabel>Job Type</InputLabel>
//         <Select>
//           <MenuItem value="fullTime">Full Time</MenuItem>
//           <MenuItem value="partTime">Part Time</MenuItem>
//           {/* Add more job types */}
//         </Select>
//       </FormControl>

//       {/* Posted Date Filter */}
//       <FormControl sx={{ m: 1, minWidth: 120 }}>
//         <InputLabel>Posted Date</InputLabel>
//         <Select>
//           <MenuItem value="last24hours">Last 24 Hours</MenuItem>
//           <MenuItem value="lastWeek">Last Week</MenuItem>
//           {/* Add more options */}
//         </Select>
//       </FormControl>

//       {/* Visa Type Filter */}
//       <FormControl sx={{ m: 1, minWidth: 120 }}>
//         <InputLabel>Visa Type</InputLabel>
//         <Select>
//           <MenuItem value="workVisa">Work Visa</MenuItem>
//           <MenuItem value="studentVisa">Student Visa</MenuItem>
//           {/* Add more visa types */}
//         </Select>
//       </FormControl>

//       {/* Work Mode Filter */}
//       <FormControl sx={{ m: 1, minWidth: 120 }}>
//         <InputLabel>Work Mode</InputLabel>
//         <Select>
//           <MenuItem value="remote">Remote</MenuItem>
//           <MenuItem value="office">Office</MenuItem>
//           {/* Add more work modes */}
//         </Select>
//       </FormControl>

//       {/* Travel Filter */}
//       <FormControl sx={{ m: 1, minWidth: 120 }}>
//         <InputLabel>Travel</InputLabel>
//         <Select>
//           <MenuItem value="required">Required</MenuItem>
//           <MenuItem value="notRequired">Not Required</MenuItem>
//           {/* Add more travel options */}
//         </Select>
//       </FormControl>
//     </div>

//       {/* Submit and Clear Buttons */}
//       <Box sx={{ m: 1 }}>
//         <Button variant="contained" color="primary" onClick={handleSubmit}>
//           Submit
//         </Button>
//         <Button variant="outlined" color="secondary" onClick={handleClearAll}>
//           Clear All
//         </Button>
//       </Box>
//     </div>
//   );
// };

// export default JobFilters;

// import React, { useState } from 'react';
// import {
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Checkbox,
//   ListItemText,
//   OutlinedInput,
// } from '@mui/material';

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };

// const JobFilters = ({ label, options }) => {
//     const [selectedItems, setSelectedItems] = useState([]);

//   const handleChange = (event) => {
//     const {
//       target: { value },
//     } = event;
//     setSelectedItems(value);
//   };

//   return (
//     <FormControl sx={{ m: 1, width: 300 }}>
//       <InputLabel id={`multiple-checkbox-label-${label}`}>{label}</InputLabel>
//       <Select
//         labelId={`multiple-checkbox-label-${label}`}
//         id={`multiple-checkbox-${label}`}
//         multiple
//         value={selectedItems}
//         onChange={handleChange}
//         input={<OutlinedInput label={label} />}
//         renderValue={(selected) => selected.join(', ')}
//         MenuProps={MenuProps}
//       >
//         {options.map((option) => (
//           <MenuItem key={option} value={option}>
//             <Checkbox checked={selectedItems.indexOf(option) > -1} />
//             <ListItemText primary={option} />
//           </MenuItem>
//         ))}
//       </Select>
//     </FormControl>
//   )
// }

// export default JobFilters

// import React, { useState } from 'react';
// import {
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Checkbox,
//   ListItemText,
//   OutlinedInput,
// } from '@mui/material';

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };

// const JobFilters = ({ label, options, isMultiple, onFilterChange }) => {
//   const [selectedItems, setSelectedItems] = useState([]);

//   const handleChange = (event) => {
//     const {
//       target: { value },
//     } = event;

//     if (isMultiple) {
//       // For multiple selection, toggle options
//       const selectedIndex = selectedItems.indexOf(value);
//       const newSelected = [...selectedItems];

//       if (selectedIndex === -1) {
//         newSelected.push(value);
//       } else {
//         newSelected.splice(selectedIndex, 1);
//       }

//       setSelectedItems(newSelected);
//     } else {
//       // For single selection, update selection directly
//       setSelectedItems([value]);
//     }
//     onFilterChange(value);
//   };

//   return (
//     <FormControl sx={{ m: 1, width: 300 }}>
//       <InputLabel id={`multiple-checkbox-label-${label}`}>{label}</InputLabel>
//       <Select
//         labelId={`multiple-checkbox-label-${label}`}
//         id={`multiple-checkbox-${label}`}
//         multiple={isMultiple}
//         value={selectedItems}
//         onChange={handleChange}
//         input={<OutlinedInput label={label} />}
//         renderValue={(selected) => (isMultiple ? selected.join(', ') : selected)}
//         MenuProps={MenuProps}
//       >
//         {options.map((option) => (
//           <MenuItem key={option} value={option}>
//             <Checkbox checked={selectedItems.indexOf(option) > -1} />
//             <ListItemText primary={option} />
//           </MenuItem>
//         ))}
//       </Select>
//     </FormControl>
//   );
// };

// export default JobFilters;

// JobFilters.js
// import React, { useState } from 'react';
// import {
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Checkbox,
//   ListItemText,
//   OutlinedInput,
// } from '@mui/material';

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };

// const JobFilters = ({ label, options, isMultiple = true, onFilterChange }) => {
//   const [selectedItems, setSelectedItems] = useState([]);

//   const handleChange = (event) => {
//     const {
//       target: { value },
//     } = event;

//     setSelectedItems(isMultiple ? value : [value]);
//     onFilterChange(isMultiple ? value : [value]); // Notify parent of filter change
//   };

//   return (
//     <FormControl sx={{ m: 1, width: 300 }}>
//       <InputLabel id={`multiple-checkbox-label-${label}`}>{label}</InputLabel>
//       <Select
//         labelId={`multiple-checkbox-label-${label}`}
//         id={`multiple-checkbox-${label}`}
//         multiple={isMultiple}
//         value={selectedItems}
//         onChange={handleChange}
//         input={<OutlinedInput label={label} />}
//         renderValue={(selected) => (isMultiple ? selected.join(', ') : selected)}
//         MenuProps={MenuProps}
//       >
//         {options.map((option) => (
//           <MenuItem key={option} value={option}>
//             {isMultiple && <Checkbox checked={selectedItems.indexOf(option) > -1} />}
//             <ListItemText primary={option} />
//           </MenuItem>
//         ))}
//       </Select>
//     </FormControl>
//   );
// };

// export default JobFilters;

// import React, { useState } from 'react';
// import {
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Checkbox,
//   ListItemText,
//   OutlinedInput,
// } from '@mui/material';

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };

// const JobFilters = ({ label, options, isMultiple = true, onFilterChange }) => {
//   const [selectedItems, setSelectedItems] = useState([]);

//   const handleChange = (event) => {
//     const { value } = event.target;

//     setSelectedItems(isMultiple ? value : [value]);
//     onFilterChange(label, value); // Notify parent of filter change
//   };

//   return (
//     <FormControl sx={{ m: 1, width: 300 }}>
//       <InputLabel id={`multiple-checkbox-label-${label}`}>{label}</InputLabel>
//       <Select
//         labelId={`multiple-checkbox-label-${label}`}
//         id={`multiple-checkbox-${label}`}
//         multiple={isMultiple}
//         value={selectedItems}
//         onChange={handleChange}
//         input={<OutlinedInput label={label} />}
//         renderValue={(selected) =>
//           isMultiple
//             ? selected.map((value) => options.find((opt) => opt.value === value)?.text).join(', ')
//             : (options.find((opt) => opt.value === selected) || {}).text || ''
//         }
//         MenuProps={MenuProps}
//       >
//         {options.map((option) => (
//           <MenuItem key={option.value} value={option.value}>
//             {isMultiple && (
//               <Checkbox checked={selectedItems.indexOf(option.value) > -1} />
//             )}
//             <ListItemText primary={option.text} />
//           </MenuItem>
//         ))}
//       </Select>
//     </FormControl>
//   );
// };

// export default JobFilters;

import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  useMediaQuery,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useResponsiveStyles } from "../../Styles/responsiveStyles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const StyledSelect = styled(Select)({
  "&.MuiOutlinedInput-root": {
    fieldset: {
      borderColor: "transparent",
    },
    "&:hover fieldset": {
      borderColor: "transparent",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
    },
  },
});

const JobFilters = ({
  label,
  options,
  isMultiple = true,
  onFilterChange,
  clearInputs,
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const styles = useResponsiveStyles();

  const isMatchLGL = useMediaQuery("(min-width:1025px)");
  const isMobileSmall = useMediaQuery("(max-width: 479px)");
  const isMobileLarge = useMediaQuery(
    "(min-width: 480px) and (max-width: 767px)"
  );
  const isTabletSmall = useMediaQuery(
    "(min-width: 768px) and (max-width: 991px)"
  );
  const isTabletLarge = useMediaQuery(
    "(min-width: 992px) and (max-width: 1199px)"
  );
  const isDesktopXS = useMediaQuery(
    "(min-width: 992px) and (max-width: 1199px)"
  );
  const isDesktopSmall = useMediaQuery(
    "(min-width: 1200px) and (max-width: 1439px)"
  );
  const isDesktopMedium = useMediaQuery(
    "(min-width: 1440px) and (max-width: 1919px)"
  );
  const isDesktopLarge = useMediaQuery("(min-width: 1920px)");

  const handleChange = (event) => {
    const { value } = event.target;

    setSelectedItems(isMultiple ? value : [value]);
    onFilterChange(label, value);
  };

  useEffect(() => {
    if (clearInputs) {
      setSelectedItems([]);
    }
  }, [clearInputs]);

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: isDesktopLarge
          ? 220
          : isDesktopMedium
          ? 160
          : isDesktopSmall
          ? 140
          : 120,
      },
    },
  };

  return (
    <FormControl
      // size="small"
      sx={{
        m: 1,
        // width:isMatchLGL ? 180 : 120,
        width: isDesktopLarge
          ? 220
          : isDesktopMedium
          ? 160
          : isDesktopSmall
          ? 120
          : 100,
        height: "fit-content",
        minWidth: window.innerWidth <= 430 && "25vw" || window.innerWidth <= 768 && "15vw",
        position: "relative",
        margin: "4px",
        bgcolor: "#fff",
        borderRadius: "30px",
        border: "none",
      }}
    >
      <InputLabel
        id={`multiple-checkbox-label-${label}`}
        sx={{
          fontSize: window.innerWidth <= 768 ? styles.fontSize16 : styles.fontSize13,
          // :isMatchLGL ? 14 : 12,
          lineHeight: styles.fontSize14,
          padding: "0px",
          position: "absolute",
          top: "-7px",
          transition: "top 0.3s ease-in-out",
          "&.Mui-focused": {
            top: "-1px",
          },
          ...(selectedItems.length > 0 && {
            top: "-1px",
            // fontWeight:500,
            // color:'#2c387e',
            //   fontSize: 12,
          }),
        }}
      >
        {label}
      </InputLabel>
      <StyledSelect
        labelId={`multiple-checkbox-label-${label}`}
        id={`multiple-checkbox-${label}`}
        multiple={isMultiple}
        value={selectedItems}
        onChange={handleChange}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) =>
          selected
            .map((value) => {
              const selectedOption = options.find((opt) => opt.value === value);
              return selectedOption ? selectedOption.text : "";
            })
            .join(", ")
        }
        MenuProps={MenuProps}
        sx={{
          height: "30px",
          borderRadius: "30px",
          padding: "0px",
          // minWidth: "15vw",
          // width:"25vw"
          // paddingBottom:'0px',
          // paddingInline:'7px',
          fontSize: window.innerWidth <= 768 && styles.fontSize16,
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            sx={{ padding: styles.paddingFilterMenuItem }}
          >
            {isMultiple && (
              <Checkbox
                checked={selectedItems.indexOf(option.value) > -1}
                sx={{
                  "& .MuiSvgIcon-root": { fontSize: styles.fontSize14 },
                  height: styles.Avatar40,
                }}
              />
            )}
            <ListItemText>
              <Typography
                variant="body1"
                style={{
                  fontSize: isDesktopLarge
                    ? 16
                    : isDesktopMedium
                    ? 14
                    : isDesktopSmall
                    ? 13
                    : 11,
                }}
              >
                {option.text}
              </Typography>
            </ListItemText>
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};

export default JobFilters;
