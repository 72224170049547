import React from "react"

const SearchResumeContext = React.createContext({
    searchResumeTitle: "",
    updateSearchResumeTitle: () => { },
    selectedFromSuggestions: false,
    updateselectedFromSuggestions: () => { },
    jobsCount:0,
    updateJobsCount: () => { },

}

)
export default SearchResumeContext;