import React from "react";
import "../../Styles/footer2.css";

function footer() {
  return (
    <footer >
      <div className="footer-change">

     
    <div id="footer2">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <center>
              <p>© 2024 By JobsnProfiles. All Rights Reserved.</p>
            </center>
          </div>
        </div>
      </div>
    </div>
    </div>
    </footer>
  );
}

export default footer;
