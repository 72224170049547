import React from 'react';
import { makeStyles } from '@mui/styles';
import Chip from '@mui/material/Chip';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: 4,
        },
    },
}));

export default function Chips(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {localStorage.getItem("resumejobtypeFlages") !== null ? JSON.parse(localStorage.getItem("resumejobtypeFlages")).map(data =>
                <Chip
                    style={{ backgroundColor: "rgb(177, 205, 234)", margin: "0px 4px 4px 4px" }}
                    label={props.jobtypes.map(jobtype => {
                        if (jobtype.value === data) {
                            return (jobtype.name !== undefined ? jobtype.name : jobtype.text)
                        }
                    })}
                    onDelete={() => { props.jobtypeFunction(data, "calledfromchips") }}
                />
            ) : ""}

            {localStorage.getItem("resumevisatypeFlages") !== null ? JSON.parse(localStorage.getItem("resumevisatypeFlages")).map(data =>
                <Chip
                    style={{ backgroundColor: "rgb(177, 205, 234)" }}
                    label={props.visaOptions !== undefined ? props.visaOptions.map(visa => {
                        if (visa.solr_key === data) {
                            return (visa.solr_key !== undefined ? visa.solr_key : visa.solr_key)
                        }
                    }) : data}
                    onDelete={() => { props.handleVisatype(data, "calledfromchips") }}
                />
            ) : ""}

            {props.data3.map(data =>
                <Chip
                    style={{ backgroundColor: "rgb(177, 205, 234)" }}
                    label={"OPT Issued:" + data}
                    onDelete={() => { props.handleOptIssuedYear(data) }}
                />
            )}
            {props.data4.map(data =>
                <Chip
                    style={{ backgroundColor: "rgb(177, 205, 234)" }}
                    label={data}
                    onDelete={() => { props.handleGradYear(data) }}
                />
            )}
            {localStorage.getItem("resumeExpFlages") !== null ? JSON.parse(localStorage.getItem("resumeExpFlages")).map(data =>
                <Chip
                    style={{ backgroundColor: "rgb(177, 205, 234)" }}
                    label={props.exp.map(exp => {
                        if (exp.value === data) {
                            return (exp.text !== undefined ? exp.text : exp.text)
                        }
                    })}
                    onDelete={() => { props.handleExpFun(data, "calledfromchips") }}
                />
            ) : ""}

            {localStorage.getItem("resumeQualificationFlages") !== null ? JSON.parse(localStorage.getItem("resumeQualificationFlages")).map(data =>
                <Chip
                    style={{ backgroundColor: "rgb(177, 205, 234)" }}
                    label={props.qualifications.map(qualify => {
                        if (qualify.text === data) {
                            return (qualify.text !== undefined ? qualify.text : qualify.text)
                        }
                    })}
                    onDelete={() => { props.handleQualifications(data, "calledfromchips") }}
                />
            ) : ""}

            {localStorage.getItem("resumeSortByDate") === 'resumeSortByDate' ?
                <Chip
                    style={{ backgroundColor: "rgb(177, 205, 234)" }}
                    label={props.lastupdated.map(updated => {
                        if (updated.id === parseInt(props.data6)) {
                            return (updated.name !== undefined ? updated.name : updated.text)
                        }
                    })}
                    onDelete={() => { props.selectedOptionValue("remove", "calledfromchips"); localStorage.removeItem("resumeSortByDate") }}
                />
                : ''}

            {localStorage.getItem("resumeSortBy") === "recent" || localStorage.getItem("resumeSortBy") === "relevance" ?
                <Chip
                    style={{ backgroundColor: "rgb(177, 205, 234)" }}
                    label={localStorage.getItem("resumeSortBy")}
                    onDelete={() => { props.sortby("remove"); localStorage.removeItem("resumeSortBy") }}
                /> : ''}

            {localStorage.getItem("workModeFlages") !== null ? JSON.parse(localStorage.getItem("workModeFlages")).map(data =>
                <Chip
                    style={{ backgroundColor: "rgb(177, 205, 234)" }}
                    label={props.workmodetypes.map(work => {
                        if (work.id === data) {
                            return (work.name !== undefined ? work.name : work.name)
                        }
                    })}
                    onDelete={() => { props.handleworkmode(data, "calledfromchips") }}
                />
            ) : ""}
        </div>
    );
}
