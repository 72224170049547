import * as types from "../constant/actionTypes";
import DashboardService from "../services/dashboard.service";
import DashboardController from "../controllers/dashboard.controller";

const initial_state = {
    myFileResumeDetails: DashboardService.getFileDetails(),

};

export default (state = initial_state, action) => {
    switch (action.type) {
        case types.GET_MYFILE_RESUME_DETAILS:
            console.log(action.payload)
            return { ...state, myFileResumeDetails: action.payload }
        case types.UPDATE_FILEPROFILE_SUCCESS:
            console.log(action.payload)
            return { ...state, updateFileProfileSuccess: action.payload }
        default:
            return state;
    }
}
