import React, { useState, useEffect } from "react";
import Drawer from "react-modern-drawer";
import Logo from "../Images/jobsnProfiles.webp";
import axios from "axios";
import Key from "../clientVariables.json";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
//import styles 👇
import "react-modern-drawer/dist/index.css";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import profileimg from "../../src/Images/profiledef.png";
import { AiOutlineFileText } from "react-icons/ai";

import { FaNetworkWired } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { RiVisaFill } from "react-icons/ri";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import HorizontalRuleOutlinedIcon from "@mui/icons-material/HorizontalRuleOutlined";
import { Grid } from "@mui/material";

const loginListeners = [];

function Ampmenubar(props) {
  const [open, setOpen] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [jobfair, setJobFair] = useState(false);
  const [employer, setEmployer] = useState(false);
  const [jobseeker, setJobseeker] = useState(false);
  const [benchsales, setBenchsales] = useState(false);
  const [dashboardDetails, setDashboardDetails] = useState([]);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };
  const handleClickJobFair = () => {
    setJobFair(!jobfair);
  };

  const logoutFunction = () => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Key.domain}/users/logout`, {
          userid: sessionStorage.getItem("id"),
        })
        .then((response) => {
          ////////console.log.log(response)
          loginListeners.forEach((fn) => fn(null));
          sessionStorage.clear();
          localStorage.clear();
          window.location.href = "/";
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  useEffect(() => {
    // props.showLoader();
    // sessionStorage.removeItem("jobtitle");
    // sessionStorage.removeItem("inputjobtitle");

    axios
      .post(`${Key.domain}/dashboard/get_js_dashboard_details`, {
        user_id: props.currentUser.id || 195252,
        session_token: props.currentUser.session_token,
      })
      .then((response) => {
        // props.hideLoader()

        // console.log(response)
        if (
          response.status === 200 &&
          response.data !== undefined &&
          response.data !== null &&
          response.data.data !== undefined &&
          response.data.data !== null &&
          response.data.data.userDetails !== undefined &&
          response.data.data.userDetails !== null
        ) {
          var userdata = response.data.data.userDetails;
          // var primaryres = userdata.resumeid ? userdata.resumeid : 0;

          // sessionStorage.setItem("primaryresume", primaryres);
          // sessionStorage.setItem("job_role", userdata.job_title);

          setDashboardDetails(userdata);
        }
      });
  }, []);
  return (
    <Grid container spacing={0}>
      <Grid item xs={11}>
        <HiOutlineMenuAlt2
          onClick={toggleDrawer}
          size={25}
          className="mt-4 mb-2"
        />

        <Drawer
          open={isOpen}
          onClose={toggleDrawer}
          direction="left"
          className="menu-drawer"
        >
          <div style={{ paddingTop: "25px" }}>
            <img
              src={Logo}
              className="img-fluid"
              width={200}
              style={{ paddingLeft: "15px" }}
            />

            <div
              className=""
              style={{
                padding: "8px",
                backgroundColor: "coral",
                color: "#FFF",
                marginTop: "10px",
              }}
            >
              <div className="col-2" style={{ float: "right" }}>
                <img
                  src={
                    sessionStorage.getItem("profileimg") &&
                    sessionStorage.getItem("profileimg") !== "null"
                      ? `${Key.files_domain}/dashboard/profile_image/` +
                        sessionStorage.getItem("id") +
                        "/" +
                        sessionStorage.getItem("profileimg")
                      : profileimg
                  }
                  alt="Admin"
                  class="rounded-circle p-1 "
                  id="bg-primary"
                  style={{ width: "40px", height: "40px" }}
                />
              </div>

              <div className="col-8">
                <span style={{ fontSize: "14px" }}>
                  {dashboardDetails.full_name || "N/A"}
                </span>
                <p style={{ fontSize: "14px" }}>
                  {" "}
                  {dashboardDetails.job_title !== undefined &&
                  dashboardDetails.job_title !== null
                    ? dashboardDetails.job_title
                    : "Role : N/A"}
                </p>
              </div>
            </div>
            <List
              sx={{
                width: "100%",
                maxWidth: 360,

                bgcolor: "background.paper",
              }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              <hr style={{ height: "2px", marginTop: "-5px" }} />

              <Link to="/Jsdashboard" style={{ color: "#000" }}>
                <ListItemButton>
                  <ListItemIcon>
                    <HomeOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" />
                </ListItemButton>
              </Link>

              <Link to="/H1Visa" style={{ color: "#000" }}>
                <ListItemButton>
                  <ListItemIcon>
                    <RiVisaFill size={20} />
                  </ListItemIcon>
                  <ListItemText primary="Company H1 Visa" />
                </ListItemButton>
              </Link>

              <Link to="/Filemanager" style={{ color: "#000" }}>
                <ListItemButton>
                  <ListItemIcon>
                    <AiOutlineFileText size={20} />
                  </ListItemIcon>
                  <ListItemText primary="File Manager" />
                </ListItemButton>
              </Link>

              <Link to="/My_network" style={{ color: "#000" }}>
                <ListItemButton>
                  <ListItemIcon>
                    <FaNetworkWired size={20} />
                  </ListItemIcon>
                  <ListItemText primary="My Network" />
                </ListItemButton>
              </Link>

              <Link to="#" style={{ color: "#000" }}>
                <ListItemButton>
                  <ListItemIcon>
                    <FaNetworkWired size={20} />
                  </ListItemIcon>
                  <ListItemText primary="Career Advice Tips" />
                </ListItemButton>
              </Link>

              <ListItemButton onClick={handleClickJobFair}>
                <ListItemIcon>
                  <FiSettings size={20} />
                </ListItemIcon>
                <ListItemText primary="Settings" />
                {jobfair ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={jobfair} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link to="/accountSettings" style={{ color: "#000" }}>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <HorizontalRuleOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Account Settings" />
                    </ListItemButton>
                  </Link>

                  <Link
                    to="/Logout"
                    onClick={() => logoutFunction()}
                    style={{ color: "#000" }}
                  >
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <HorizontalRuleOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Logout" />
                    </ListItemButton>
                  </Link>
                </List>
              </Collapse>
            </List>
          </div>
        </Drawer>
      </Grid>
    </Grid>
  );
}
function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.UserReducer.user,
  };
}
export default connect(mapStateToProps, null)(Ampmenubar);
