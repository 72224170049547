import { useMediaQuery } from '@mui/material';

export const useResponsiveStyles = () => {
  const isDesktopLarge = useMediaQuery('(min-width: 1920px)');
  const isDesktopMedium = useMediaQuery('(min-width: 1440px) and (max-width: 1919px)');
  const isDesktopSmall = useMediaQuery('(min-width: 1200px) and (max-width: 1439px)');
  const isDesktopExtraSmall = useMediaQuery('(min-width: 992px) and (max-width: 1199px)');


  const getFontSize24 = () => {
    return isDesktopLarge ? "28px" : 
           isDesktopMedium ? "24px" : 
           isDesktopSmall ? "20px" : 
           isDesktopExtraSmall ? "16px" : "15px";
  };

  const getFontSize16 = () => {
    return isDesktopLarge ? "20px" : 
           isDesktopMedium ? "16px" : 
           isDesktopSmall ? "14px" : 
           isDesktopExtraSmall ? "12px" : "12px";
  };

  const getFontSize14 = () => {
    return isDesktopLarge ? "17px" : 
           isDesktopMedium ? "14px" : 
           isDesktopSmall ? "13px" : 
           isDesktopExtraSmall ? "12px" : "11px";
  };

  const getFontSize13 = () => {
    return isDesktopLarge ? "15px" : 
           isDesktopMedium ? "13px" : 
           isDesktopSmall ? "11px" : 
           isDesktopExtraSmall ? "10px" : "9px";
  };

  const getFontSize12 = () => {
    return isDesktopLarge ? "15px" : 
           isDesktopMedium ? "12px" : 
           isDesktopSmall ? "11px" : 
           isDesktopExtraSmall ? "10px" : "9px";
  };

  const getFontSize11 = () => {
    return isDesktopLarge ? "14px" : 
           isDesktopMedium ? "11px" : 
           isDesktopSmall ? "10px" : 
           isDesktopExtraSmall ? "9px" : "8px";
  };

  const getFontSize18 = () => {
    return isDesktopLarge ? "22px" : 
           isDesktopMedium ? "18px" : 
           isDesktopSmall ? "16px" : 
           isDesktopExtraSmall ? "16px" : "14px";
  };

  const getFontSize9 = () => {
    return isDesktopLarge ? "11px" : 
    isDesktopMedium ? "9px" : 
    isDesktopSmall ? "8px" : 
    isDesktopExtraSmall ? "7px" : "8px";
  }

  const getListAvatarSize40 = () => {
    return isDesktopLarge ? "50px" : 
    isDesktopMedium ? "40px" : 
    isDesktopSmall ? "36px" : 
    isDesktopExtraSmall ? "30px" : "30px";
  }

  const getPaddingListItem = () => {
    return isDesktopLarge ? "10px 20px" : 
    isDesktopMedium ? "8px 16px" : 
    isDesktopSmall ? "6px 12px" : 
    isDesktopExtraSmall ? "6px 12px" : "6px 12px";
  }

  const getPaddingFilterMenuItem = () => {
    return isDesktopLarge ? "10px 20px" : 
    isDesktopMedium ? "6px 12px" : 
    isDesktopSmall ? "5px 10px" : 
    isDesktopExtraSmall ? "4px 8px" : "3px 6px";
  }

  const getIconSize20 = () => {
    return isDesktopLarge ? "23px" : 
    isDesktopMedium ? "20px" : 
    isDesktopSmall ? "18px" : 
    isDesktopExtraSmall ? "16px" : "15px";
  }

  const getAvatarIcon24 = () => {
    return isDesktopLarge ? "37px" : 
    isDesktopMedium ? "30px" : 
    isDesktopSmall ? "27px" : 
    isDesktopExtraSmall ? "22px" : "20px";
  }

  const getListHoverTextWithIconWidth180 = () => {
    return isDesktopLarge ? "200px" : 
    isDesktopMedium ? "150px" : 
    isDesktopSmall ? "100px" : 
    isDesktopExtraSmall ? "80px" : "80px";
  }

  const getHoverResumeButtonWidth = () => {
    return isDesktopLarge ? "100px" : 
    isDesktopMedium ? "80px" : 
    isDesktopSmall ? "34px" : 
    isDesktopExtraSmall ? "34px" : "34px";
  }

  const getJobDetailsWidth400 = () => {
   return isDesktopLarge ? 500 : ( isDesktopMedium ? 400 : isDesktopSmall ? 280 : 200 )
  }

  const getResumeListRoleWidth = () => {
    return isDesktopLarge ? '150px' : ( isDesktopMedium ? "130px" : isDesktopSmall ? '100px'  : isDesktopExtraSmall ? '90px' : '90px' )
  }

  const getResumeListTimeWidth = () => {
    return isDesktopLarge ? '75px' : ( isDesktopMedium ? "65px" : isDesktopSmall ? '55px'  : isDesktopExtraSmall ? '50px' : '50px' )
  }

  const getListToolbarPadding = () => {
    return isDesktopLarge ? '0px 28px !important' : ( isDesktopMedium ? "0px 24px !important" : isDesktopSmall ? '0px 18px !important'  : isDesktopExtraSmall ? '0px 16px !important' : '0px 16px' )
  }

  const getListToolbarMinHeight = () => {
    return isDesktopLarge ? '52px !important' : ( isDesktopMedium ? "48px !important" : isDesktopSmall ? '40px !important'  : isDesktopExtraSmall ? '38px !important' : '37px' )
  }

  const getresumeSearchtitleWidth = () => {
    return isDesktopLarge ? '240px' : ( isDesktopMedium ? "200px" : isDesktopSmall ? '160px'  : isDesktopExtraSmall ? '140px' : '140px' )
  }

  const getResumeProfileAvatar100 = () => {
    return isDesktopLarge ? '120px' : ( isDesktopMedium ? "100px" : isDesktopSmall ? '80px'  : isDesktopExtraSmall ? '60px' : '70px' )
  }

  const getheightJFI = () => {
    return isDesktopLarge ? '600px' : ( isDesktopMedium ? "500px" : isDesktopSmall ? '360px'  : isDesktopExtraSmall ? '270px' : "300px" )
  }

  const getwidthJFI = () => {
    return isDesktopLarge ? '550px' : ( isDesktopMedium ? "450px" : isDesktopSmall ? '320px'  : isDesktopExtraSmall ? '230px' : '250px' )
  }

  return {
    fontSize16: getFontSize16(),
    fontSize12: getFontSize12(),
    fontSize24: getFontSize24(),
    fontSize14: getFontSize14(),
    fontSize11: getFontSize11(),
    fontSize18: getFontSize18(),
    fontSize9: getFontSize9(),
    fontSize13: getFontSize13(),
    Avatar40: getListAvatarSize40(),
    paddingListItem: getPaddingListItem(),
    icon20: getIconSize20(),
    avatarIcon24: getAvatarIcon24(),
    listHoverTextWithIconWidth180: getListHoverTextWithIconWidth180(),
    resumeHoverButton: getHoverResumeButtonWidth(),
    jobDetailsWidth400: getJobDetailsWidth400(),
    resumeListRoleWidth: getResumeListRoleWidth(),
    resumeListTimeWidth: getResumeListTimeWidth(),
    listToolbarPadding: getListToolbarPadding(),
    minHeightListToolbar: getListToolbarMinHeight(),
    widthListResumeSearchTitle: getresumeSearchtitleWidth(),
    resumeProfileAvatar100: getResumeProfileAvatar100(),
    paddingFilterMenuItem: getPaddingFilterMenuItem(),
    heightJFI: getheightJFI(),
    widthJFI: getwidthJFI(),
  };
};
