import * as types from "../constant/actionTypes";
import DashboardService from "../services/dashboard.service";
import DashboardController from "../controllers/dashboard.controller";

const initial_state = {
    companyjobsCount: 0,

};

export default (state = initial_state, action) => {
    // console.log(action)
    switch (action.type) {
        case types.GET_COMPANY_JOBS:
           // console.log(action.payload)
            return { ...state, companyjobsCount: action.payload }
       
        default:
            return state;
    }
}
