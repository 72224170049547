import React, { Component } from "react";
import Key from "../../clientVariables.json";
import Select from "react-select";
import axios from "axios";
import { TiTick } from "react-icons/ti";



export default class fr extends Component {
    state = {};
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            mail: '',
            subject: '',
            submit: false,
            email: [],
            skill:"",
            skillsList: [],
            skillIds: [],
            skillTitles: [],
            disabledAll: false
        }
    }

    componentDidMount = () => {
        console.log(this.props, localStorage.getItem("chat_user"))
        var element = document.getElementsByClassName('rcw-sender');
        element[0].classList.add('mystyle2');


        axios
            .get(`${Key.domain}/commonAPIs/get_all_skills`, {
                method: "GET", // *GET, POST, PUT, DELETE, etc.
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
                },
            })
            .then((resdata) => {
                console.log(resdata.data.data);
                this.setState({
                    skillsList: resdata.data.data.map((skill) => ({
                        value: skill.id,
                        label: skill.skills,
                    }))
                })
            });
    }

    start = (values) => {
        this.setState({ submit: true })

        fetch(`${Key.domain}/users/insert_chat_bot_data`, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            },
            body: JSON.stringify({
                name: values.fullname,
                email: values.email,
                subject: values.subject,
                chat_history: ''
            })
        })
            .then(response => response.json()) //converting data into json format
            .then(resdata => {
                console.log(resdata)
                if (resdata.success === 1 && resdata.data.affectedRows > 0) {
                    var chatUser = {
                        name: values.fullname,
                        email: values.mail,
                        subject: values.subject
                    }
                    localStorage.setItem("chat_user", JSON.stringify(chatUser))
                    var element = document.getElementsByClassName('rcw-sender');
                    console.log(element);
                    element[0].classList.remove('mystyle2');
                    element[0].classList.add('mystyle');
                    var message = "Welcome " + values.fullname + ", We are excited to offer you messaging, allowing you to respond to us in your own time. Click here to get started."
                    this.props.handlemsg(message);
                }
            })
    }
    handleChange = (e) => {
        console.log(e)
        this.setState({skill:e.target.value})
        // this.setState({ skillIds: (Array.isArray(e) ? e.map((x) => x.value) : []) })
        // this.setState({ skillTitles: (Array.isArray(e) ? e.map((x) => x.label) : []) })
    }
    handleSubmitSkills = () => {
        this.setState({ disableAll: true })
        this.props.handleSkillsFunction(this.state.skill)
        // this.props.handleSkillsFunction(this.state.skillIds, this.state.skillTitles)
    }
    render() {
        return (
            <div style={{ width: '100%', paddingBottom: "15%" }} className="row">
                {!this.state.disableAll && <>           
              <div className="col-md-11 chat-form">
                    {/* <label htmlFor="skills">Skills </label> */}


                    {/* <Select
                        isMulti
                        name="skills"
                        placeholder="Enter Skills"
                        options={this.state.skillsList}
                        onChange={(e) => this.handleChange(e)} // assign onChange function
                        value={this.state.skillsList.filter((obj) =>
                            this.state.skillIds.includes(obj.value)
                        )} // set selected values
                        disbaled={this.state.disabledAll}
                    /> */}

                    <input
                    className="form-control"
                    value={this.state.skill}
                    onChange={e=>this.handleChange(e)}
                    />

                </div>
                
                    {/* {this.state.skillIds.length > 0 &&
                        <div className={this.state.skillIds.length > 0 ? "col-md-1" : "col-md-1"}>
                            <TiTick style={{ fontSize: "35px", cursor: "pointer" }} onClick={() => this.handleSubmitSkills()} />
                        </div>} */}


                
                        <div className="col-md-1">
                            <TiTick style={{ fontSize: "35px", cursor: "pointer" }} onClick={() => this.handleSubmitSkills()} />
                        </div>

                </>

                }
            </div>
        )
    }

}