import React, { useState } from "react";
import Drawer from "react-modern-drawer";
import Logo from "../Images/jobsnProfiles.webp";
import { Link } from "react-router-dom";
//import styles 👇
import "react-modern-drawer/dist/index.css";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { AiOutlineUser, AiOutlineDatabase } from "react-icons/ai";
import { MdOutlineLocalPostOffice } from "react-icons/md";
import List from "@mui/material/List";
import { Grid } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import HorizontalRuleOutlinedIcon from "@mui/icons-material/HorizontalRuleOutlined";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

export default function Ampmenubar() {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Mobile devices
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md")); // Tablets
  const isDesktopSmall = useMediaQuery(theme.breakpoints.between("md", "lg")); // Small desktops
  const isDesktopLarge = useMediaQuery(theme.breakpoints.up("lg")); // Large desktops

  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [jobfair, setJobFair] = useState(false);
  const [employer, setEmployer] = useState(false);
  const [jobseeker, setJobseeker] = useState(false);
  const [benchsales, setBenchsales] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };
  const handleClick = () => {
    setOpen(!open);
  };
  const handleregviaClick = () => {
    sessionStorage.setItem("viaparser", true);
  };
  const handleClickJobFair = () => {
    setJobFair(!jobfair);
  };
  const handleClickEmployer = () => {
    setEmployer(!employer);
  };
  const handleClickJobseker = () => {
    setJobseeker(!jobseeker);
  };
  const handleClickBenchsales = () => {
    setBenchsales(!benchsales);
  };

  return (
    <Grid container spacing={0}>
      {" "}
      <Grid item xs={12} >
        <HiOutlineMenuAlt2
          onClick={toggleDrawer}
          size={ window.innerWidth <= 500 ?26 : 20}
          className="mt-1"

          style={{color:"#000",float:"left"}}
        />

        <Drawer
          open={isOpen}
          onClose={toggleDrawer}
          direction="left"
          className="menu-drawer"
          style={{ zIndex: "10000" ,  }}
        >
          <div style={{ paddingTop: "2px" }}>
            <List
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              <div className="col-12" style={{ backgroundColor: "aliceblue" }}>
                <img
                  src={Logo}
                  className="img-fluid"
                  width={"200px"}
                  height="66px"
                  alt="Jobsnprofiles Logo"
                  style={{ paddingLeft: "15px" }}
                />
                <div class="d-grid gap-2 mt-3 mb-2">
                  <Link to="/signup">
                    <button
                      class="btn btn-outline-primary"
                      type="button"
                      style={{
                        backgroundColor: "rgb(233, 90, 49)",
                        color: "#FFF",
                        borderRadius: "1px",
                        width: "100%",
                      }}
                      onClick={() => handleregviaClick()}
                    >
                      Register Via Resume
                    </button>
                  </Link>
                </div>
              </div>
              <Link to="/">
                <ListItemButton>
                  <ListItemIcon>
                    <HomeOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Home" style={{ color: "#000" }} />
                </ListItemButton>
              </Link>
              <ListItemButton onClick={handleClickJobFair}>
                <ListItemIcon>
                  <WorkOutlineOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Job Fair"  style={{color:"#000"}} />
                {jobfair ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={jobfair} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link to="/Previousjobfair">
                    <ListItemButton sx={{ pl: 2 }}>
                      <ListItemIcon>
                        <HorizontalRuleOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Previous Job Fair" />
                    </ListItemButton>
                  </Link>
                  <Link to="/Upcomingjobfair">
                    <ListItemButton sx={{ pl: 2 }}>
                      <ListItemIcon>
                        <HorizontalRuleOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Upcoming Job Fairs" />
                    </ListItemButton>
                  </Link>
                  <Link to="/VirtualJobFairs2023">
                    <ListItemButton
                      sx={{ pl: 2, fontSize: isMobile ? "15px" : "" }}
                    >
                      <ListItemIcon>
                        <HorizontalRuleOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Virtual Job Fair 2023" />
                    </ListItemButton>
                  </Link>

                  <Link to="/VirtualJobFair2024">
                    <ListItemButton
                      sx={{ pl: 2, fontSize: isMobile ? "15px" : "" }}
                    >
                      <ListItemIcon>
                        <HorizontalRuleOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Virtual Job Fair 2024" />
                    </ListItemButton>
                  </Link>
                </List>
              </Collapse>
              <Link target="_blank" to="/Layoffs">
                <ListItemButton>
                  <ListItemIcon>
                    <AiOutlineDatabase size={25} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Layoff Data"
                    style={{ color: "#000" }}
                  />
                </ListItemButton>
              </Link>

              <Link target="_blank" to="/ResumeBuilder">
                <ListItemButton>
                  <ListItemIcon>
                    <AiOutlineDatabase size={25} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Resume Builder"
                    style={{ color: "#000" }}
                  />
                </ListItemButton>
              </Link>
              <ListItemButton onClick={handleClick}>
                <ListItemIcon>
                  <MdOutlineLocalPostOffice size={25} />
                </ListItemIcon>
                <ListItemText primary="Post FreeJob" style={{color:"#000"}} />
                {open ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link to="/PostFreeJob">
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <HorizontalRuleOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="New Customer" />
                    </ListItemButton>
                  </Link>
                  <Link to="/EmpLogin">
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <HorizontalRuleOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Existing Customer" />
                    </ListItemButton>
                  </Link>
                </List>
              </Collapse>

              <ListItemButton onClick={handleClickEmployer}>
                <ListItemIcon>
                  <AiOutlineUser size={25} />
                </ListItemIcon>
                <ListItemText primary="Employer" style={{color:"#000"}} />
                {employer ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={employer} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link to="/Empsignup">
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <HorizontalRuleOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Sign Up" />
                    </ListItemButton>
                  </Link>
                  <Link to="/EmpLogin">
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <HorizontalRuleOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Sign In" />
                    </ListItemButton>
                  </Link>
                </List>
              </Collapse>
              <ListItemButton onClick={handleClickJobseker}>
                <ListItemIcon>
                  <AiOutlineUser size={25} />
                </ListItemIcon>
                <ListItemText primary="Job Seeker"  style={{color:"#000"}}/>
                {jobseeker ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={jobseeker} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link to="/Signup">
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <HorizontalRuleOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Sign Up" />
                    </ListItemButton>
                  </Link>
                  <Link to="/Login">
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <HorizontalRuleOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Sign In" />
                    </ListItemButton>
                  </Link>
                </List>
              </Collapse>
              <ListItemButton onClick={handleClickBenchsales}>
                <ListItemIcon>
                  <AiOutlineUser size={25} />
                </ListItemIcon>
                <ListItemText primary="BenchSales" style={{color:"#000"}} />
                {benchsales ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={benchsales} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link to="/bs_signup">
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <HorizontalRuleOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Sign Up" />
                    </ListItemButton>
                  </Link>
                  <Link to="https://bs.jobsnprofiles.com" target="_blank">
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <HorizontalRuleOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Login" />
                    </ListItemButton>
                  </Link>
                </List>
              </Collapse>
            </List>
          </div>
        </Drawer>
      </Grid>
    </Grid>
  );
}
