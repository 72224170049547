import React, { useState, useEffect, useRef } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { IoIosAdd } from "react-icons/io";
import { AiFillDelete } from "react-icons/ai";
import axios from "axios"
import Key from './clientVariables.json'
import { Card } from "@mui/material";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { FiChevronRight } from 'react-icons/fi';
import ToastMessage from './Components/ToastMessage';



function Educationtab(props) {
  const formikRef = useRef();

  const [addEducation, setAddEducation] = useState([{ id: 0 }]);
  const [addCertificate, setAddCertificate] = useState([{ id: 0 }]);
  const [eduCount, setEduCount] = useState(0);
  const [degrees, setDegrees] = useState([]);
  const [certificateCount, setCertificateCount] = useState(0);
  const [insertedResumeid, setInsertedResumeId] = useState(null)
  const [insertededucationid, setInsertedEducationId] = useState(null)
  const [insertedCertid, setInsertedCertId] = useState(null)
  const [currentUserdetails, setCurrentUserDetails] = useState(null)
  const [isRegisterViaResume, setIsregisterViaResume] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false)

  useEffect(() => {
    setIsregisterViaResume(props.isRegisterViaResume)
  }, [props.isRegisterViaResume])

  useEffect(() => {
    setCurrentUserDetails(props.currentUserdetails)
  }, [props.currentUserdetails])

  useEffect(() => {
    setInsertedResumeId(props.insertedResumeId)
  }, [props.insertedResumeId])

  const handleAddEducation = (count) => {
    setAddEducation([...addEducation, { id: count + 1 }]);
    setEduCount(count + 1);
  };
  const handleRemoveEducation = (id) => {
    setAddEducation(addEducation.filter((edu) => edu.id !== id));
  };
  const handleAddCertificate = (count) => {
    setAddCertificate([...addCertificate, { id: count + 1 }]);
    setCertificateCount(count + 1);
  };
  const handleRemoveCerificate = (id) => {
    setAddCertificate(addCertificate.filter(certificate => certificate.id !== id))
  }

  useEffect(() => {
    axios.get(`${Key.domain}/commonAPIs/get_all_educations_list`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      },
    })
      .then((resdata) => {
        console.log(resdata.data.data)
        setDegrees(resdata.data.data)
      })
  }, [])

  useEffect(() => {
    if (addEducation !== null && addEducation.length > 0) {
      // console.log(addEducation)

      if (formikRef.current) {
        addEducation.map((edu, index) => {
          formikRef.current.setFieldValue(
            "degree" + index,
            edu.degree !== undefined ? edu.degree : ''
          )
        })
      }
    }
  }, [addEducation])
  const initialValues = {
    university: "",
    yearofpassing: ""
  }
  addEducation.map((edu, index) => {
    initialValues["degree" + index] = ''
    initialValues["yearofpassing" + index] = ""
  })
  addCertificate.map((edu, index) => {
    // initialValues["certYear"+ index]=""
  })
  const myschema = {}
  addEducation.map((edu, index) => {
    myschema["degree" + index] = Yup.string().required("Degree is Required")
    myschema["yearofpassing" + index] = Yup.string().required("Year of passing Required ").matches(/^[0-9\b]+$/, "Only Numbers are allowed ").max(4, "year of passing should be 4 digits only")
  })
  addCertificate.map((cer, index) => {
    myschema["certYear" + index] = Yup.string().matches(/^[0-9\b]+$/, "Only Numbers allowed ").min(4, " 4 digits only").max(4, "must be 4 digits only")
  })

  const handleInputChange = (e, index) => {
    // console.log(e.target.value, e.target.name, index)
    var Education = [...addEducation]
    var Certificate = [...addCertificate]
    var name = e.target.name
    if (name === "degree" + index) {
      Education[index]["degree"] = e.target.value
      const selectedDegree = degrees.filter(degree => degree.title === e.target.value)
      if (selectedDegree && selectedDegree.length > 0) {
        Education[index]["educationId"] = selectedDegree[0].id
      }
    }
    if (name === "university" + index) {
      Education[index]["university"] = e.target.value
    }
    if (name === "yearofpassing" + index) {
      Education[index]["yearofpassing"] = e.target.value
    }
    if (name === "certName" + index) {
      Certificate[index]["certName"] = e.target.value
    }
    if (name === "certYear" + index) {
      Certificate[index]["certYear"] = e.target.value
    }
    setAddCertificate(Certificate)
    setAddEducation(Education)
  }

  const verifyEducationDetails = () => {
    setButtonLoading(true)
    axios.post(`${Key.domain}/parser/add_js_educations_via_parser`, {
      "user_id": currentUserdetails ? currentUserdetails.id : props.currentUser.id,
      "session_token": currentUserdetails ? currentUserdetails.session_token : props.currentUser.session_token,
      "education_id": insertededucationid || null,
      "resumeid": insertedResumeid || null,
      "educations_data": addEducation
    }).then(response => {
      console.log(response)
      if (response.status === 200 && response.data && response.data.success === 1 && response.data.data.insertId && response.data.data.insertId > 0) {
        setInsertedEducationId(response.data.data.insertId)
       if(addCertificate&&addCertificate.length&&addCertificate.length>0&&addCertificate[0].certName) {
        axios.post(`${Key.domain}/parser/add_js_certificates_via_parser`, {
          "user_id": currentUserdetails ? currentUserdetails.id : props.currentUser.id,
          "session_token": currentUserdetails ? currentUserdetails.session_token : props.currentUser.session_token,
          "certificate_id": insertedCertid || null,
          "resumeid": insertedResumeid || null,
          "certificates_data": addCertificate
        }).then(response => {
          setButtonLoading(false)
          console.log(response)
          if (response.status === 200 && response.data && response.data.success === 1 && response.data.data.insertId && response.data.data.insertId > 0) {
            setInsertedCertId(response.data.data.insertId)
            // alert("Verified successfully!")
            props.setEducationData(addEducation)
            props.setCertificateData(addCertificate)
            props.setIsotherTabDisabled(false)
            props.movetoNextTab(4)
          }
          else {
            setButtonLoading(false)

            // alert("Error while inserting certificate details")
            let msg = "Error while inserting certificate details"
            toast(
              <ToastMessage message={msg} />
            )
          }
        })
      }else{
        setButtonLoading(false)
        props.movetoNextTab(4)

      }


      } else {
        setButtonLoading(false)
        // alert("Error while inserting education details")
        let msg = "Error while inserting education details"
        toast(
          <ToastMessage message={msg} />
        )
      }
    })
  }
  return (
    <Formik
      innerRef={formikRef}

      initialValues={initialValues}

      validationSchema={Yup.object().shape(myschema)}
      onSubmit={(fields) => {
        // console.log(addCertificate, addEducation)
        // console.log("SUCCESS!! :-)\n\n" + JSON.stringify(fields, null, 4));
        verifyEducationDetails()

      }}
      render={({ errors, status, touched, values }) => (
        <Card className="educationcard">
          <Form id="addresume">
            <div className="row">
              <div
                className="addicon"
                style={{ float: "right" }}

              >
                <IoIosAdd onClick={() => handleAddEducation(eduCount)} />
              </div>
              <h5 className="sectiontitle">Education</h5>
              {addEducation.map((edu, index) => {
                return (
                  <div key={edu.id}>
                    <div className="row mt-2" id="educationblock">
                      <span
                        className="delete-icon"

                      >
                        <AiFillDelete style={{ fill: "#406882" }} onClick={() => handleRemoveEducation(edu.id)} />
                      </span>
                      <div className="col-md-4">
                        <div className="form-group1">
                          <label htmlFor={"degree" + index}>
                            Degree <span style={{ color: "red" }}>*</span>
                          </label>

                          <Field name={"degree" + index} as="select" className={
                            "form-select form-control" +
                            (errors["degree" + index] && touched["degree" + index] ? " is-invalid" : "")
                          }
                            onChange={(e) => handleInputChange(e, index)}
                          >
                            <option selected>Select Degree</option>
                            {degrees.map(degree =>
                              <option value={degree.title}>{degree.title}</option>
                            )}



                          </Field>
                          <ErrorMessage
                            name={"degree" + index}
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group1">
                          <label htmlFor={"university" + index}>
                            University
                          </label>
                          <Field
                            name={"university" + index}
                            placeholder="University"
                            type="text"
                            className={
                              "form-control" +
                              (errors.university && touched.university
                                ? " is-invalid"
                                : "")
                            }
                            onChange={(e) => handleInputChange(e, index)}

                          />
                          <ErrorMessage
                            name="university"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group1">
                          <label htmlFor={"yearofpassing" + index}>
                            Year Of Passing<span style={{ color: "red" }}>*</span>

                          </label>
                          <Field
                            name={"yearofpassing" + index}
                            type="text"
                            placeholder="Year Of Passing"
                            className={
                              "form-control" +
                              // (errors["certYear"+index] && touched["certYear"+index]
                              (errors["yearofpassing" + index] && touched["yearofpassing" + index]
                                ? " is-invalid"
                                : "")
                            }
                            onChange={(e) => {
                              handleInputChange(e, index)
                              formikRef.current.setFieldValue(
                                "yearofpassing" + index,
                                e.target.value
                              )
                            }}
                            value={values["yearofpassing" + index]}
                          />
                          <ErrorMessage
                            name={"yearofpassing" + index}
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

              <div className="certificationstab">
                <div className="row">
                  <div
                    className="addicon"
                    onClick={() => handleAddCertificate(certificateCount)}
                  >
                    <IoIosAdd />
                  </div>
                  <h5 className="sectiontitle">Certifications</h5>
                  {addCertificate.map((certificate, index) => {
                    return (
                      <div className="row mt-2" key={certificate.id}>
                        <span
                          className="delete-icon"

                        >
                          <AiFillDelete style={{ fill: "#406882" }} onClick={() => handleRemoveCerificate(certificate.id)} />
                        </span>
                        <div className="col-md-6">
                          <div className="form-group1">
                            <label htmlFor={"certName" + index}>
                              Name of Certification
                            </label>
                            <Field
                              name={"certName" + index}
                              type="text"
                              placeholder="Name of Certification"
                              className={
                                "form-control" +
                                (errors.certName && touched.certName
                                  ? " is-invalid"
                                  : "")
                              }
                              onChange={(e) => {
                                handleInputChange(e, index)

                              }}

                            />
                            <ErrorMessage
                              name="certName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group1">
                            <label htmlFor={"certYear" + index}>Year</label>
                            <Field
                              name={"certYear" + index}
                              type="text"

                              placeholder="Certification year"
                              className={
                                "form-control" +
                                (errors["certYear" + index] && touched["certYear" + index]
                                  ? " is-invalid"
                                  : "")
                              }
                              onChange={(e) => {
                                handleInputChange(e, index);
                                formikRef.current.setFieldValue(
                                  "certYear" + index,
                                  e.target.value
                                )
                              }
                              }
                              value={values["certYear" + index]}
                            />
                            <ErrorMessage
                              name={"certYear" + index}
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="form-group1">
                {!buttonLoading ?
                  <button
                    type="submit"
                    className="btn btn-primary mr-2"
                    id="update_profile"
                  >
                    Submit
                  </button>
                  :
                  <button
                    type="submit"
                    className="btn btn-primary mr-2"
                    id="update_profile"
                    disabled
                  >
                    Submitting....
                  </button>
                }
              </div>
            </div>
          </Form>
        </Card>
      )}
    />
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.UserReducer.user,
    isLoading: state.UserReducer.isLoginLoading,
    iserror: state.UserReducer.error,
  };
}
export default withRouter(connect(mapStateToProps, null)(Educationtab))
