import {
  BusinessCenterRounded,
  EmailRounded,
  FmdGoodOutlined,
  KeyboardDoubleArrowUpRounded,
  MoreVertRounded,
  PhoneRounded,
} from "@mui/icons-material";
import MetaTags from "react-meta-tags";
import Alertcandidate from "../../../src/Components/searchResumes/Alertcandidate";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import {
  Avatar,
  Backdrop,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  IconButton,
  InputBase,
  List,
  ListItemButton,
  Modal,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  tableCellClasses,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Navbar from "../Navbar";
import moment from "moment";
import Key from "../../clientVariables.json";
import { useLocation, useHistory } from "react-router-dom";
import { hideLoader, showLoader } from "../Actions/loader.actions";
import axios from "axios";
import { FaListCheck, FaUserGraduate } from "react-icons/fa6";
import { MdFlight, MdOutlineWorkHistory } from "react-icons/md";
import parse from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import { styled, alpha } from "@mui/material/styles";
import { TiSocialLinkedin } from "react-icons/ti";
import JobFilters from "../Jobseeker/JobFilters";
import { solrJobsFilterData } from "../Jobseeker/js_solr_filters_data";
import { pageChangeActions } from "../Actions/pageStartActions";
import JobRowsSelect from "../Jobseeker/JobRowsSelect";
import PaginationComponent from "../Jobseeker/PaginationComponent";
import { lowercasedUsStatesArray } from "../Jobseeker/UsStates";
import {
  clearURLjobSearchUrlActions,
  setURLjobSearchUrlActions,
} from "../Actions/jobSearchUrlActions";
import { buildSolrQuery } from "../../utils/solrQueryBuilder";
import { toast } from "react-toastify";
import { useResponsiveStyles } from "../../Styles/responsiveStyles";
import SearchComponent from "../Jobseeker/SearchComponent";
import SearchIcon from "@mui/icons-material/Search";

const Bullet = styled("span")({
  display: "inline-block",
  width: "3px",
  height: "3px",
  borderRadius: "50%",
  backgroundColor: "currentColor",
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "rgba(64, 104, 130, 0.1)",
  "&:hover": {
    backgroundColor: "rgba(64, 104, 130, 0.1)",
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  flexGrow: 1,
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input::placeholder": {
    color: alpha(theme.palette.text.primary, 0.5),
  },
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const SearchJobs_list = () => {
  const location = useLocation();
  const styles = useResponsiveStyles();
  // const {
  //   response: jobsData,
  //   jobtitle: jobTitleFromHome,
  //   location: jobLocation,
  //   // url: url_
  // } = location.state;
  const currentUser = useSelector((state) => state.UserReducer.user);
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState({});
  const [selectedJobSkills, setSelectedJobSkills] = useState({});
  const [similarJobs, setSimilarJobs] = useState([]);
  const [selectedItem, setSelectedItem] = useState(0);
  const [filters, setFilters] = useState({});
  const [selectedJobCompanyDetails, setSelectedJobCompanyDetails] = useState(
    {}
  );
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [selectedJobRecruiterDetails, setSelectedJobRecruiterDetails] =
    useState({});
  const [showToolbar, setShowToolbar] = useState(false);
  const dispatch = useDispatch();
  const gridRef = useRef(null);
  const history = useHistory();
  const [clearInputs, setClearInputs] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageStartNum, setPageStartNum] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalJobsCount, setTotalJobsCount] = useState();
  const redirectionSearchJobsUrl = useSelector(
    (state) => state.jobSearchUrlReducer
  );
  const pageStartValue = useSelector(
    (state) => state.pageStartValReducer.pageStartValue
  );
  const [redirecturl, setRedirectUrl] = useState(
    redirectionSearchJobsUrl?.redirectTo
  );

  const [jobtitleInputValue, setJobtitleInputValue] = useState(
    redirectionSearchJobsUrl?.searchText || ""
  );
  const [statenameInputValue, setStateNameInputValue] = useState();
  const [cityNameInputValue, setCityNameInputValue] = useState(
    redirectionSearchJobsUrl?.location || ""
  );
  const [jobtitles, setJobtitles] = useState([]);
  const [citySuggestions, setCitySuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [titleSuggestions, setTitleSuggestions] = useState([]);
  const [showTitleSuggestions, setShowTitleSuggestions] = useState(false);
  const [errorLocation, setErrorLocation] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const suggestionListRef = useRef(null);
  const skillMatchRef = useRef(null);
  const [openSearchModal, setOpenSearchModal] = useState(false);

  const isMatchLGS = useMediaQuery("(min-width:801px)");
  const isMatchLGL = useMediaQuery("(min-width:1025px)");
  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Mobile devices
  // const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md")); // Tablets
  // const isDesktopSmall = useMediaQuery(theme.breakpoints.between("md", "lg")); // Small desktops
  // const isDesktopLarge = useMediaQuery(theme.breakpoints.up("lg")); // Large desktops
  const isMobileSmall = useMediaQuery("(max-width: 479px)");
  const isMobileLarge = useMediaQuery(
    "(min-width: 480px) and (max-width: 767px)"
  );
  const isTabletSmall = useMediaQuery(
    "(min-width: 768px) and (max-width: 991px)"
  );
  const isTabletLarge = useMediaQuery(
    "(min-width: 992px) and (max-width: 1199px)"
  );
  const isDesktopXS = useMediaQuery(
    "(min-width: 992px) and (max-width: 1199px)"
  );
  const isDesktopSmall = useMediaQuery(
    "(min-width: 1200px) and (max-width: 1439px)"
  );
  const isDesktopMedium = useMediaQuery(
    "(min-width: 1440px) and (max-width: 1919px)"
  );
  const isDesktopLarge = useMediaQuery("(min-width: 1920px)");

  const handleOpenSearchModal = () => {
    setOpenSearchModal(true);
  };

  const handleCloseSearchModal = () => {
    setOpenSearchModal(false);
  };

  let isTabletOrMobile = window.innerWidth;

  useEffect(() => {
    // setJobtitleInputValue(sessionStorage.getItem("inputjobtitle_home"));
    // setCityNameInputValue(sessionStorage.getItem("cityname_home"));
    // sessionStorage.removeItem("inputjobtitle_home");
    // sessionStorage.removeItem("cityname_home");
    if (gridRef.current) {
      gridRef.current.addEventListener("scroll", handleToolbarScroll);
    }

    return () => {
      if (gridRef.current) {
        gridRef.current.removeEventListener("scroll", handleToolbarScroll);
      }
    };
  }, []);

  useEffect(() => {
    // const fetch = async () => {
    //   try {
    //     console.log("filters", filters);

    //     if (Object.keys(filters).length !== 0) {
    //       await fetchData(filters);
    //     } else if (
    //       pageStartNum ||
    //       currentPage ||
    //       rowsPerPage ||
    //       redirectionSearchJobsUrl?.shouldRedirect
    //     ) {
    //       await fetchData(filters);
    //     }

    //     // setRedirectUrl(redirectionSearchJobsUrl?.redirectTo);
    //     // if (redirectionSearchJobsUrl?.redirectTo !== redirecturl) {
    //     //   handleClearFilters();
    //     // }
    //     console.log("start numf", pageStartNum);
    //   } catch (error) {
    //     console.error(error);
    //   }
    // };
    // fetch();

    fetchData(filters);
  }, [
    filters,
    pageStartNum,
    currentPage,
    rowsPerPage,
    redirectionSearchJobsUrl,
  ]);

  useEffect(() => {
    handleClearFilters();
  }, [redirectionSearchJobsUrl]);

  useEffect(() => {
    if (Object.keys(filters).length !== 0) {
      setCurrentPage(1);
      setPageStartNum(0);
    }
  }, [filters]);

  useEffect(() => {
    // setCurrentPage(1);
    // setPageStartNum(0);
    dispatch(pageChangeActions(0));
  }, [totalPages]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (gridRef.current) {
        gridRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const handleToolbarScroll = () => {
    const yOffset = gridRef.current.scrollTop;
    const triggerScroll = 200;

    if (yOffset > triggerScroll) {
      setShowToolbar(true);
    } else {
      setShowToolbar(false);
    }
  };

  const handleScroll = () => {
    const scrollTop = gridRef.current.scrollTop;
    setShowScrollButton(scrollTop > 100);
  };
  const scrollToTop = () => {
    if (gridRef.current) {
      gridRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const scrollToSkillMatch = () => {
    if (skillMatchRef.current) {
      gridRef.current.scrollTo({
        top: skillMatchRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (clearInputs) {
      setFilters({});
      setJobs([]);
      setClearInputs((prevClearInputs) => !prevClearInputs);
      //  setSelectedLocation(null);
    }
  }, [clearInputs]);

  const handleFilterChange = (filterCategory, selectedValues) => {
    setFilters({ ...filters, [filterCategory]: selectedValues });
  };
  const handleClearFilters = () => {
    setFilters({});
    setJobs([]);
    setClearInputs((prevClearInputs) => !prevClearInputs);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    // let startNum = rowsPerPage * newPage - rowsPerPage + 1;
    let startNum = rowsPerPage * newPage - rowsPerPage;
    setPageStartNum(startNum);
    dispatch(pageChangeActions(startNum));
  };
  const indexOfLastItem = currentPage * rowsPerPage;
  const indexOfFirstItem = indexOfLastItem - rowsPerPage;
  // const currentJobs = jobs.slice(indexOfFirstItem, indexOfLastItem);

  const handleRowsChange = (selectedRows) => {
    setRowsPerPage(selectedRows);
    setCurrentPage(1);
  };

  const onTextChanged = (value) => {
    setJobtitleInputValue(value);
  };

  const onCityChanged = (value) => {
    // console.log("onCityChange", value);
    setCityNameInputValue(value);
    var cityAndState = value.split(",");
    var cityName = cityAndState[0];
    var StateName = cityAndState[1];
  };

  const getCityNames = (value) => {
    setShowSuggestions(true);
    axios
      .post(`${Key.domain}/Jobs/get_joblocations`, {
        statename: value,
      })
      .then((response) => {
        // console.log(response.data.data);
        if (response.data.data?.length === 0) {
          //setErrorLocation(true);
          setShowSuggestions(false);
        } else {
          setErrorLocation(false);
          setCitySuggestions(
            response.data.data.map((res) =>
              res.cityName.concat(" , ", res.stateName)
            )
          );
        }
      });
  };

  const getJobtitles = (value) => {
    setShowTitleSuggestions(true);
    axios
      .post(`${Key.domain}/Jobs/get_jobtitles`, {
        jobtitle: value,
      })
      .then((response) => {
        // console.log(response.data.data);
        setTitleSuggestions(response.data.data.map((res) => res.title));
      });
  };

  const retry = async (fn, retries = 3, delay = 1000) => {
    for (let i = 0; i < retries; i++) {
      try {
        return await fn();
      } catch (error) {
        if (i < retries - 1) {
          await new Promise((resolve) => setTimeout(resolve, delay));
        } else {
          throw error;
        }
      }
    }
  };

  const fetchData = async (filterValues) => {
    dispatch(showLoader());

    try {
      let searchUrl = null;
      const searchText = redirectionSearchJobsUrl?.searchText;
      const skills =
        searchText && typeof searchText === "string"
          ? searchText
              .trim()
              .split(/[ ,]+/)
              .filter((skill) => skill.trim().length > 0)
          : null;
      const role = redirectionSearchJobsUrl?.searchText || null;

      searchUrl = await buildSolrQuery({
        role: role || null,
        company: null,
        skills: skills,
        id: null,
        location: null,
        filterValues: filterValues,
        pageStartNum: pageStartNum,
        rowsPerPage: rowsPerPage,
        redirectionSearchJobsUrl: redirectionSearchJobsUrl,
        primaryResumeDetails: null,
        searchType: redirectionSearchJobsUrl?.searchType,
      });

      if (searchUrl) {
        const response = await axios.post(`${Key.domain}/solr/solr_jobs`, {
          url: searchUrl,
        });

        if (response.data.data) {
          setJobs(response.data.data.response?.docs);

          try {
            await retry(() =>
              getJobDetails(response.data.data.response?.docs[0]?.id)
            );
          } catch (error) {
            toast.error("Failed to get job details.", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }

          try {
            await retry(() =>
              getSimilarJobs(response.data.data.response?.docs[0])
            );
          } catch (error) {
            toast.error("Failed to get similar jobs.", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }

          setTotalJobsCount(response.data.data.response?.numFound);
          const totalPages = Math.ceil(
            response.data.data.response?.numFound / rowsPerPage
          );
          setTotalPages(totalPages);
        } else if (response.data.data.response?.numFound === 0) {
          toast.warning("Data Not Found.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      } else {
        toast.error("Something went wrong.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Something went wrong.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } finally {
      dispatch(hideLoader());
    }
  };
  // const fetchData = async (
  //   filterValues
  //   // , pageStartNum, rowsPerPage, redirectionSearchJobsUrl, primaryResumeDetails, cityNameInputValue, jobtitleInputValue
  // ) => {
  //   // console.log("filterValues", filterValues, redirectionSearchJobsUrl);
  //   dispatch(showLoader());

  //   try {
  //     let searchUrl = await null;

  //     const searchText = await redirectionSearchJobsUrl?.searchText;
  //     const skills =
  //       (await searchText) && typeof searchText === "string"
  //         ? searchText
  //             .trim()
  //             .split(/[ ,]+/)
  //             .filter((skill) => skill.trim().length > 0)
  //         : null;

  //     const role = redirectionSearchJobsUrl?.searchText || null;

  //     searchUrl = await buildSolrQuery({
  //       role: role ? role : null,
  //       company: null,
  //       skills: skills,
  //       id: null,
  //       location: null,
  //       filterValues: filterValues,
  //       pageStartNum: pageStartNum,
  //       rowsPerPage: rowsPerPage,
  //       redirectionSearchJobsUrl: redirectionSearchJobsUrl,
  //       primaryResumeDetails: null,
  //       searchType: redirectionSearchJobsUrl?.searchType,
  //     });

  //     // console.log("searchUrl", searchUrl);

  //     if (searchUrl) {
  //       const response = await axios.post(`${Key.domain}/solr/solr_jobs`, {
  //         url: searchUrl,
  //       });

  //       if (response.data.data) {
  //         // console.log(
  //         //   "response.data.data.response?.docs",
  //         //   response.data.data.response?.docs
  //         // );
  //         setJobs(response.data.data.response?.docs);
  //         getJobDetails(response.data.data.response?.docs[0]?.id);
  //         getSimilarJobs(response.data.data.response?.docs[0]);
  //         setTotalJobsCount(response.data.data.response?.numFound);
  //         let totalPages = Math.ceil(
  //           response.data.data.response?.numFound / rowsPerPage
  //         );
  //         setTotalPages(totalPages);
  //       } else if (response.data.data.response?.numFound === 0) {
  //         toast.warning("Data Not Found.", {
  //           position: toast.POSITION.TOP_RIGHT,
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //         });
  //       } else {
  //         toast.error("Something went wrong.", {
  //           position: toast.POSITION.TOP_RIGHT,
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //         });
  //       }
  //     } else {
  //       toast.error("Something went wrong.", {
  //         position: toast.POSITION.TOP_RIGHT,
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //       });
  //     }
  //     dispatch(hideLoader());
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     dispatch(hideLoader());
  //     toast.error("Something went wrong.", {
  //       position: toast.POSITION.TOP_RIGHT,
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //     });
  //   }

  //   dispatch(hideLoader());
  // };

  const jobCardClickHandler = async (job, index) => {
    const jobData = jobs.filter((data) => job.id === data.id);
    await getJobDetails(job.id);
    await getSimilarJobs(job);
    // console.log("job data", jobData);
    // console.log("job id", job.id);
    // setSelectedJob(jobData[0]);
  };

  const getJobDetails = async (jobId) => {
    // console.log("i am called");
    dispatch(showLoader());
    setSelectedJob({});
    setSelectedJobCompanyDetails({});
    setSelectedJobRecruiterDetails({});
    try {
      const response = await axios.post(`${Key.domain}/Jobs/get_job_details`, {
        id: jobId,
      });
      if (response.status === 200) {
        // console.log(response.data.data[0]?.jobDetails);
        // console.log(response.data.data[0]?.jobDetails?.companyDetails[0]);

        setSelectedJob(response.data.data[0]?.jobDetails);
        let skills = response.data.data[0]?.jobDetails?.ai_skills?.split(",");
        setSelectedJobSkills(skills);
        if (response.data.data[0].jobDetails?.companyDetails[0]) {
          // console.log("i am called companyDetails");
          setSelectedJobCompanyDetails(
            response.data.data[0].jobDetails.companyDetails[0]
          );

          // console.log(
          //   "after",
          //   response.data.data[0].jobDetails.companyDetails[0]
          // );
          if (
            response.data.data[0].jobDetails.companyDetails[0]?.recruiterdetails
          ) {
            // console.log("i am called recruiterdetails");
            setSelectedJobRecruiterDetails(
              response.data.data[0].jobDetails.companyDetails[0]
                .recruiterdetails
            );
          }
        }
        //checkApplySaveJob(props.jobDetails.id);
        // checkApplySave(jobId);

        dispatch(hideLoader());
      } else {
        // console.log("response.status === 200 false get_job_details");
        dispatch(hideLoader());
      }
    } catch (error) {
      console.error(error);
      dispatch(hideLoader());
    }
    dispatch(hideLoader());

    // setSelectedJob()
  };

  const getSimilarJobs = async (selectedJob) => {
    // console.log("getSimilarJobs", selectedJob);
    let role =
      selectedJob?.title !== null &&
      selectedJob?.title !== undefined &&
      selectedJob.title !== ""
        ? selectedJob.title
        : "*:*";
    // console.log("role", role);
    const searchUrl = `${Key.Solr_url}/${Key.solrJobs_collection}/select?q=${
      role ? '"' + role + '"' : "*:*"
    }OR companyName:(${role ? '"' + role + '"' : "*:*"}) OR id:(${
      role ? '"' + role + '"' : "*:*"
    }) &rows=10&start=0&fq=status:(1)&fq=industry_type:"IT"&sort=modified%20desc&wt=json`;
    try {
      const response = await axios.post(`${Key.domain}/solr/solr_jobs`, {
        url: searchUrl,
      });
      if (response.status === 200) {
        // console.log(response.data.data.response.docs);
        setSimilarJobs(response?.data.data.response?.docs);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const redirectToCompanyView = (compId) => {
    // console.log("redirectToCompanyView", compId);
    history.push("/company/view/comp_" + compId);
  };

  const redirectToJobView = (job) => {
    // console.log("redirectToJobViewcalled");
    sessionStorage.setItem("job_Name", job?.title);
    history.push({
      pathname: "/job/view/Job_" + job?.id,
      state: {
        Jobs: "yes",
      },
    });
  };

  const redirectToLogin = (jobid, jobTitle) => {
    //window.alert("called")
    sessionStorage.setItem("job_Name", jobTitle);
    // console.log("called");
    const fullUrl = window.location.href;
    const urlObject = new URL(fullUrl);
    let baseUrl;

    if (urlObject.hostname === "localhost") {
      baseUrl = `${urlObject.protocol}//${urlObject.host}`;
    } else {
      baseUrl = urlObject.origin;
    }

    // console.log(baseUrl);

    const url = baseUrl + "/job/view/Job_" + jobid;
    sessionStorage.setItem("jobDetailApplyUrl", url);

    history.push({
      pathname: "/Login",
      state: {
        searchJobs: "yes",
        //showJobsOnBack: props.jobs,
      },
    });
  };

  const replacements = {
    "#": "%23",
    "++": "%2B%2B",
    "&": "%26",
    " / ": "/",
  };

  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  function replaceSpecialCharacters(inputString, replacementMap) {
    let modifiedString = inputString;

    for (const [specialChar, replacement] of Object.entries(replacementMap)) {
      const regex = new RegExp(escapeRegExp(specialChar), "g");
      modifiedString = modifiedString.replace(regex, replacement);
    }

    return modifiedString;
  }

  const findJobsHandler = async (title, loca) => {
    await handleClearFilters();

    // await dispatch(clearURLjobSearchUrlActions());

    // console.log("jobtitleInputValue", title, loca);

    // if(jobtitleInputValue && jobtitleInputValue !== "" && cityNameInputValue && cityNameInputValue !== ''){
    //   dispatch(setURLjobSearchUrlActions("", jobtitleInputValue, cityNameInputValue, null));
    // }else if(jobtitleInputValue && jobtitleInputValue !== ""){
    //   dispatch(setURLjobSearchUrlActions("", jobtitleInputValue, null, 'title'));
    // }else if (cityNameInputValue && cityNameInputValue !== "") {
    //   dispatch(setURLjobSearchUrlActions("", null, cityNameInputValue, 'location'));
    // }

    // await setRedux(title, loca);

    try {
      await fetchData(filters);
    } catch (error) {
      console.error("Error fetching solr data:", error);
      toast.error("Failed to fetch data", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const scrollToIndex = (index) => {
    if (suggestionListRef.current) {
      const suggestionItemHeight = 30; // Adjust this value as needed
      suggestionListRef.current.scrollTop = index * suggestionItemHeight;
    }
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "rgba(64, 104, 130, 0.9)",
      //  theme.palette.common.black,
      color: theme.palette.common.white,
      fontSize: styles.fontSize14,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: styles.fontSize14,
    },
  }));

  const getLocationString = (city, state, shortState, isRemote) => {
    const cityName = city && city !== "N/A" && city !== "" ? city : null;
    const stateName = state && state !== "N/A" && state !== "" ? state : null;
    const shortRegion =
      shortState && shortState !== "N/A" && shortState !== ""
        ? shortState
        : null;
    const remote = isRemote && isRemote !== undefined ? isRemote : null;

    if (cityName && (stateName || shortRegion)) {
      return `${cityName}, ${stateName || shortRegion}`;
    } else if (cityName) {
      return cityName;
    } else if (stateName) {
      return stateName;
    } else if (shortRegion) {
      return shortRegion;
    } else if (
      remote &&
      (isRemote === "1" || (Array.isArray(isRemote) && isRemote[0] === "1"))
    ) {
      return "(Remote)";
    } else {
      return "(Remote)";
    }
  };

  // function for validating Correct phone Number
  function isValidPhoneNumber(phoneString) {
    // Regular expression for a 10-digit phone number with optional parentheses and hyphens
    // const phoneRegex = /^\(?([0-9]{3})\)?[-.]?([0-9]{3})[-.]?([0-9]{4})$/;
    const phoneRegex = /^\(?([0-9]{3})\)?[ -.]?([0-9]{3})[ -.]?([0-9]{4})$/;

    // console.log("value :", phoneString);
    // console.log(phoneRegex.test(phoneString));

    // Checking if the string matches the phone number format Or not
    return phoneRegex.test(phoneString);
  }

  const [jobSelected, setJobSelected] = useState(false);
  const [showJobDetails, setShowJobDetails] = useState(true);
  const [showRecruiterDetails, setShowRecruiterDetails] = useState(false);

  useEffect(() => {
    // Handle initial view and desktop/mobile changes
    // setShowJobDetails(true);
    if (window.innerWidth <= 768) {
      setShowJobDetails(false); // Show details initially on desktop, hide when mobile
      setShowRecruiterDetails(false);
    } else {
      setShowRecruiterDetails(true);
      setShowJobDetails(true); // Hide details on mobile or when job is selected
    }

    // console.log("location is : ", location);
  }, []);
  return (
    <>
      <Navbar />
      <Grid container spacing={1} sx={{ bgcolor: "#f2f6fd" }}>
        <MetaTags>
          <title> Find Your Perfect Job Match Now | JobsnProfiles </title>
          <meta
            name="description"
            content=" 200,000+ US Jobs: IT, Remote & More! Find Your Perfect Match Now | JobsnProfiles
 "
          />
        </MetaTags>
        <Grid item xs={12}>
          <Paper
            elevation={1}
            sx={{ display: "flex", p: "10px", bgcolor: "#fff" }}
          >
            <Box sx={{}}>
              {redirectionSearchJobsUrl?.shouldRedirect && (
                <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
                  <Typography
                    paragraph
                    sx={{
                      margin: 0,
                      fontSize: styles.fontSize14,
                      width: "90px",
                    }}
                  >
                    Jobs By :
                  </Typography>
                  {redirectionSearchJobsUrl && (
                    <Grid container spacing={1}>
                      {redirectionSearchJobsUrl?.searchText &&
                        redirectionSearchJobsUrl?.searchText
                          ?.split(",")
                          .map((title) => (
                            <Grid item>
                              <Chip
                                label={title}
                                size="small"
                                sx={{ fontSize: styles.fontSize12 }}
                              />
                            </Grid>
                          ))}
                      {redirectionSearchJobsUrl?.location &&
                        redirectionSearchJobsUrl?.location
                          ?.split("L_O_C")
                          .map((title) => (
                            <Grid item>
                              <Chip
                                label={title}
                                size="small"
                                sx={{ fontSize: styles.fontSize12 }}
                              />
                            </Grid>
                          ))}
                      {redirectionSearchJobsUrl?.companyName &&
                        redirectionSearchJobsUrl?.companyName
                          .split("OR")
                          .map((title) => (
                            <Grid item>
                              <Chip
                                label={title}
                                size="small"
                                sx={{ fontSize: styles.fontSize12 }}
                              />
                            </Grid>
                          ))}
                    </Grid>
                  )}
                </Box>
              )}
            </Box>

            {redirectionSearchJobsUrl.shouldRedirect ? (
              <>
                <Box sx={{ flexGrow: 2 }} />
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      fontSize: styles.fontSize12,
                      bgcolor: "#e97d44",
                      textTransform: "capitalize",
                      width: "120px",
                    }}
                    onClick={handleOpenSearchModal}
                  >
                    Modify Search
                  </Button>
                </Box>
              </>
            ) : (
              <>
                {" "}
                <Box sx={{ flexGrow: 0.7 }} />
                <Search onClick={handleOpenSearchModal}>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search…"
                    inputProps={{ "aria-label": "search" }}
                  />
                </Search>
                <Box sx={{ flexGrow: 0.7 }} />
              </>
            )}
          </Paper>
        </Grid>

        {!(jobSelected && isTabletOrMobile <= 768) ? (
          <>
            <Grid item xs={12}>
              <Paper
                elevation={2}
                sx={{
                  display: "flex",
                  padding: "8px",
                  bgcolor: "rgba(64, 104, 130, 0.1)",
                  alignItems: "center",
                  padding: "1.2vw 1vw",
                  overflowX: "auto",
                  overflowY: "hidden",
                  width:
                    (isTabletOrMobile <= 430 && "100vw") ||
                    (isTabletOrMobile <= 768 && "90vw") ||
                    "auto",
                }}
              >
                <Typography
                  paragraph
                  sx={{
                    margin: 0,
                    fontSize:
                      isTabletOrMobile <= 768
                        ? styles.fontSize18
                        : styles.fontSize14,
                    minWidth:
                      (isTabletOrMobile <= 430 && "15vw") ||
                      (isTabletOrMobile <= 768 && "8vw"),
                  }}
                >
                  Filter By :{" "}
                </Typography>
                <JobFilters
                  label="Sort By"
                  options={solrJobsFilterData.sortBy.map((option) => ({
                    value: option.value,
                    text: option.text,
                  }))}
                  isMultiple={false}
                  onFilterChange={handleFilterChange}
                  clearInputs={clearInputs}
                />
                <JobFilters
                  label="Job Type"
                  options={solrJobsFilterData.jobTypes.map((option) => ({
                    value: option.value,
                    text: option.text,
                  }))}
                  isMultiple={true}
                  onFilterChange={handleFilterChange}
                  clearInputs={clearInputs}
                />
                <JobFilters
                  label="Posted Date"
                  options={solrJobsFilterData.postedDate.map((option) => ({
                    value: option.value,
                    text: option.text,
                  }))}
                  isMultiple={false}
                  onFilterChange={handleFilterChange}
                  clearInputs={clearInputs}
                />
                <JobFilters
                  label="Visa Type"
                  options={solrJobsFilterData.visaType.map((option) => ({
                    value: option.value,
                    text: option.text,
                  }))}
                  isMultiple={true}
                  onFilterChange={handleFilterChange}
                  clearInputs={clearInputs}
                />
                <JobFilters
                  label="Work Mode"
                  options={solrJobsFilterData.workMode.map((option) => ({
                    value: option.value,
                    text: option.text,
                  }))}
                  isMultiple={false}
                  onFilterChange={handleFilterChange}
                  clearInputs={clearInputs}
                />
                <JobFilters
                  label="Travel"
                  options={solrJobsFilterData.travel.map((option) => ({
                    value: option.value,
                    text: option.text,
                  }))}
                  isMultiple={true}
                  onFilterChange={handleFilterChange}
                  clearInputs={clearInputs}
                />
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    textTransform: "capitalize",
                    borderRadius: "30px",
                    padding: "0px 20px opx 20px",
                    border: "1px solid #e97d44",
                    color: "#e97d44",
                    fontSize:
                      isTabletOrMobile <= 768
                        ? styles.fontSize14
                        : styles.fontSize12,
                    minWidth:
                      (isTabletOrMobile <= 430 && "25vw") ||
                      (isTabletOrMobile <= 768 && "15vw"),
                  }}
                  onClick={handleClearFilters}
                >
                  Clear Filters
                </Button>
              </Paper>
            </Grid>
          </>
        ) : (
          <>
            <>
              <div
                className="tab-mobile-header"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  width: "100vw",
                  padding: "2.5vw",
                  backgroundColor: "#fff",
                  borderRadius: "1.2vw",
                  margin:
                    isTabletOrMobile <= 768 && jobSelected
                      ? "1.2vw 0 0 1.2vw"
                      : "unset",
                  position: "sticky",
                  top: "0px",
                  zIndex: "1000",
                }}
              >
                <KeyboardBackspaceIcon
                  onClick={() => {
                    setJobSelected(false);
                    setShowJobDetails(false);
                    setShowRecruiterDetails(false);
                  }}
                />
                <button
                  style={{
                    fontSize: styles.fontSize18,
                    cursor: "pointer",
                    width: "40%",
                    border: showJobDetails ? "none" : "1px solid #406882",
                    backgroundColor: showJobDetails ? "#406882" : "white",
                    color: showJobDetails ? "white" : "#406882",
                    padding: "1.4vw 0.8vw",
                    borderRadius: "0.6vw",
                    transition: "all 0.3s ease-in-out",
                  }}
                  onClick={() => {
                    setShowJobDetails(true);
                    setShowRecruiterDetails(false);
                  }}
                >
                  Job Detail
                </button>
                <button
                  style={{
                    fontSize: styles.fontSize18,
                    cursor: "pointer",
                    width: "40%",
                    border: showRecruiterDetails ? "none" : "1px solid #406882",
                    color: showRecruiterDetails ? "white" : "#406882",
                    backgroundColor: showRecruiterDetails ? "#406882" : "white",
                    padding: "1.4vw 0.8vw",
                    borderRadius: "0.6vw",
                    transition: "all 0.3s ease-in-out",
                  }}
                  onClick={() => {
                    setShowJobDetails(false);
                    setShowRecruiterDetails(true);
                  }}
                >
                  Recruiter Detail
                </button>
              </div>
            </>
          </>
        )}

        <Grid item xs={isMobileSmall || isTabletSmall ? 12 : 3}>
          <Grid container spacing={1}>
            {!(jobSelected && isTabletOrMobile <= 768) ? (
              <>
                <Grid item xs={12}>
                  <Paper
                    elevation={2}
                    sx={{
                      height: "600px",
                      overflow: "scroll",
                      borderRadius: "8px",
                    }}
                  >
                    <List>
                      {jobs?.length > 0 ? (
                        jobs.map(
                          (job, index) => (
                            // <ListItem sx={{borderBottom:'1px solid #ddd'}}>
                            <ListItemButton
                              // onClick={() => setSelectedItem(index)}
                              key={index}
                              selected={selectedItem === index}
                              disableRipple
                              sx={{
                                borderBottom: "1px solid #ddd",
                                padding: styles.paddingListItem,
                              }}
                              onClick={() => {
                                jobCardClickHandler(job, index);
                                setSelectedItem(index);
                                setJobSelected(true);
                                setShowJobDetails(true);
                              }}
                            >
                              <Grid container spacing={0}>
                                <Grid item xs={isMatchLGL ? 2 : 2.3}>
                                  <Avatar
                                    src={
                                      job?.companyid
                                        ? `${Key.domain}/company/company_logo/${job?.companyid}/getcompanynamebyquery`
                                        : ""
                                    }
                                    sx={{
                                      width: styles.Avatar40,
                                      height: styles.Avatar40,
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={isMatchLGL ? 10 : 9.7}>
                                  <Stack
                                    direction="column"
                                    sx={{ width: "100%" }}
                                  >
                                    <Typography
                                      paragraph
                                      sx={{
                                        margin: 0,
                                        color: "rgba(0,0,0,0.7)",
                                        fontWeight: 600,
                                        fontSize: styles.fontSize12,
                                        width: isDesktopLarge
                                          ? "260px"
                                          : isDesktopMedium
                                          ? "200px"
                                          : isDesktopSmall
                                          ? "180px"
                                          : "150px",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {job?.title}
                                    </Typography>
                                    <Typography
                                      paragraph
                                      sx={{
                                        margin: 0,
                                        color: "rgba(0, 0, 0, 0.56)",
                                        fontWeight: 400,
                                        fontSize: styles.fontSize12,
                                        textTransform: "capitalize",
                                        maxWidth: isDesktopLarge
                                          ? "200px"
                                          : "150px",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {job?.companyName}
                                    </Typography>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                      }}
                                    >
                                      <Typography
                                        paragraph
                                        sx={{
                                          margin: 0,
                                          color: "rgba(0,0,0,0.5)",
                                          fontSize: styles.fontSize12,
                                          maxWidth: isDesktopLarge
                                            ? "170px"
                                            : "100px",
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {getLocationString(
                                          job?.cityName,
                                          job?.stateName,
                                          job?.shortRegion,
                                          job?.is_remote
                                        )}
                                      </Typography>
                                      <Typography
                                        paragraph
                                        sx={{
                                          margin: 0,
                                          color: "rgba(0,0,0,0.5)",
                                          fontWeight: 500,
                                          fontSize: styles.fontSize12,
                                        }}
                                      >
                                        {job.modified
                                          ? moment(job.modified).fromNow()
                                          : moment(job.created).fromNow()}
                                      </Typography>
                                    </Box>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </ListItemButton>
                          )
                          // </ListItem>
                        )
                      ) : (
                        <>No Data Found...</>
                      )}
                    </List>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    direction="column"
                    component={Card}
                    sx={{ p: 1 }}
                    gap={2}
                  >
                    <PaginationComponent
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        paragraph
                        sx={{
                          margin: 0,
                          fontSize: styles.fontSize12,
                        }}
                      >
                        Page <strong>{currentPage}</strong> of{" "}
                        <strong>{totalJobsCount}</strong> Jobs
                      </Typography>
                      <JobRowsSelect
                        rowsPerPage={rowsPerPage}
                        onRowsChange={handleRowsChange}
                      />
                    </Box>
                  </Stack>
                </Grid>
              </>
            ) : (
              <></>
            )}

            {/* {!jobSelected && (
              <>
                <Grid item xs={12}>
                  <Alertcandidate
                    jobType="5"
                    joblocation={sessionStorage.getItem("cityname")}
                    // jobtitle={jobtitle}
                    jobtitle={sessionStorage.getItem("inputjobtitle")}
                  />
                </Grid>
              </>
            )} */}
          </Grid>
        </Grid>

        {/* Job Details ----------------------------------------------------------------------------------------------------------------------- */}

        {showJobDetails ? (
          <>
            {" "}
            <Grid item xs={isMobileSmall || isTabletSmall ? 12 : 5}>
              <Box
                sx={{
                  maxHeight: "700px",
                  overflowY: "scroll",
                  position: "relative",
                }}
                ref={gridRef}
              >
                {showToolbar && Object.keys(selectedJob)?.length !== 0 && (
                  <Toolbar
                    sx={{
                      position: "sticky",
                      top: 0,
                      zIndex: 3,
                      backgroundColor: "#fff",
                      borderTopLeftRadius: "8px",
                      borderTopRightRadius: "8px",
                      borderBottom: "1px solid #ddd",
                    }}
                  >
                    <Card sx={{ boxShadow: "none", width: "100%" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Stack direction="column">
                          <Typography
                            // onClick={() => redirectToJobView(selectedJob)}
                            component="body"
                            paragraph
                            sx={{
                              textTransform: "capitalize",
                              fontWeight: 600,
                              cursor: "pointer",
                              margin: 0,
                              fontSize: styles.fontSize16,
                              "&:hover": {
                                textDecoration: "underline",
                                textDecorationColor: "#2196f3",
                              },
                            }}
                          >
                            {selectedJob?.title}
                          </Typography>
                          <Breadcrumbs
                            separator={<Bullet />}
                            aria-label="breadcrumb"
                          >
                            {selectedJob?.companyname && (
                              <Typography
                                paragraph
                                sx={{
                                  margin: 0,
                                  fontWeight: 400,
                                  textTransform: "capitalize",
                                  fontSize: styles.fontSize12,
                                  cursor: "pointer",
                                  "&:hover": {
                                    textDecoration: "underline",
                                  },
                                }}
                                onClick={() =>
                                  redirectToCompanyView(selectedJob?.companyid)
                                }
                              >
                                {selectedJob?.companyname}
                              </Typography>
                            )}
                            <Typography
                              paragraph
                              sx={{
                                margin: 0,
                                fontWeight: 400,
                                textTransform: "capitalize",
                                fontSize: styles.fontSize12,
                              }}
                            >
                              {getLocationString(
                                selectedJob?.cityname,
                                selectedJob?.statename,
                                selectedJob?.shortRegion,
                                selectedJob?.is_remote
                              )}
                            </Typography>
                            {/* <Typography
                        paragraph
                        sx={{
                          margin: 0,
                          color: "#757575",
                          fontWeight: 500,
                     gridRefApply to     fontSize: "14px",
                        }}
                      >
                        {selectedJob.created !== undefined &&
                        selectedJob.created !== null
                          ? moment(selectedJob.created).fromNow() !==
                            "Invalid date"
                            ? moment(selectedJob.created).fromNow()
                            : moment("2022-03-31T06:26:04.138Z").fromNow()
                          : moment("2022-03-31T06:26:04.138Z").fromNow()}
                      </Typography> */}
                          </Breadcrumbs>
                        </Stack>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "end",
                          }}
                        >
                          <Stack direction="row" gap={1}>
                            <Button
                              variant="contained"
                              disableElevation
                              size="small"
                              sx={{
                                paddingLeft: "10px",
                                paddingRight: "10px",
                                bgcolor: "#53778e",
                                textTransform: "capitalize",
                                "&:hover": {
                                  backgroundColor: "#406882 !important",
                                  color: "white !important", // Replace with the hover color you want
                                },
                              }}
                              onClick={() => history.push("/Login")}
                              // onClick={() => handleOpenJobApplyProcess()}
                              // disabled={apply}
                            >
                              Apply
                            </Button>
                            <Button
                              // onClick={() => history.push("/Login")}
                              onClick={() =>
                                redirectToLogin(
                                  selectedJob.id,
                                  selectedJob.title
                                )
                              }
                              variant="outlined"
                              size="small"
                              // disabled={save}
                              sx={{
                                paddingLeft: "10px",
                                paddingRight: "10px",
                                textTransform: "capitalize",
                                borderColor: "#53778e",
                                color: "#53778e",
                              }}
                            >
                              Save
                            </Button>
                          </Stack>
                        </Box>
                      </Box>
                    </Card>
                  </Toolbar>
                )}
                {Object.keys(selectedJob)?.length !== 0 ? (
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Card
                        sx={{
                          borderRadius: "8px",
                          boxShadow: "none",
                          padding: "10px",
                        }}
                      >
                        <Stack direction="column" gap={2}>
                          <Stack direction="column">
                            <Typography
                              component="body"
                              variant={isMatchLGL ? "h5" : "h6"}
                              sx={{
                                textTransform: "capitalize",
                                fontWeight: 500,
                                fontSize: styles.fontSize24,
                                cursor: "pointer",
                                "&:hover": {
                                  textDecoration: "underline",
                                  textDecorationColor: "#2196f3",
                                },
                              }}
                              // onClick={() => redirectToJobView(selectedJob)}
                            >
                              {selectedJob?.title}&nbsp;#{selectedJob?.id}
                            </Typography>
                            <Breadcrumbs
                              separator={<Bullet />}
                              aria-label="breadcrumb"
                            >
                              {selectedJob?.companyname && (
                                <Typography
                                  paragraph
                                  sx={{
                                    margin: 0,
                                    fontWeight: 400,
                                    textTransform: "capitalize",
                                    fontSize: styles.fontSize14,
                                    cursor: "pointer",
                                    "&:hover": {
                                      textDecoration: "underline",
                                    },
                                  }}
                                  onClick={() =>
                                    redirectToCompanyView(
                                      selectedJob?.companyid
                                    )
                                  }
                                >
                                  {selectedJob?.companyname}
                                </Typography>
                              )}
                              <Typography
                                paragraph
                                sx={{
                                  margin: 0,
                                  fontWeight: 400,
                                  textTransform: "capitalize",
                                  fontSize: styles.fontSize14,
                                }}
                              >
                                {getLocationString(
                                  selectedJob?.cityname,
                                  selectedJob?.statename,
                                  selectedJob?.shortRegion,
                                  selectedJob?.is_remote
                                )}
                              </Typography>
                              <Typography
                                paragraph
                                sx={{
                                  margin: 0,
                                  color: "#757575",
                                  fontWeight: 500,
                                  fontSize: styles.fontSize14,
                                }}
                              >
                                {selectedJob.modified !== undefined &&
                                selectedJob.modified !== null
                                  ? moment(selectedJob.modified).fromNow()
                                  : moment(selectedJob.created).fromNow()}
                              </Typography>
                            </Breadcrumbs>
                          </Stack>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box sx={{ flexGrow: 1 }}>
                              <Stack direction="column" gap={1}>
                                <Tooltip
                                  title={
                                    <Typography>
                                      {/* Your complete text here */}
                                      <BusinessCenterRounded
                                        sx={{
                                          height: styles.icon20,
                                          width: styles.icon20,
                                          color: "#757575",
                                          marginRight: "5px",
                                        }}
                                      />
                                      {selectedJob.jobtypetitle !== undefined &&
                                      selectedJob.jobtypetitle !== null
                                        ? selectedJob.jobtypetitle
                                        : "N/A"}
                                      &nbsp;&nbsp;
                                      {selectedJob.noofjobs !== undefined &&
                                      selectedJob.noofjobs !== null &&
                                      parseInt(selectedJob.noofjobs) !== 0
                                        ? `(Positions : ${selectedJob.noofjobs})`
                                        : "(Positions : N/A)"}
                                    </Typography>
                                  }
                                  followCursor
                                  // arrow
                                  // placement="top"
                                  enterTouchDelay={500}
                                >
                                  <Typography
                                    paragraph
                                    sx={{
                                      margin: 0,
                                      display: "flex",
                                      alignItems: "end",
                                      gap: "2px",
                                      fontSize: styles.fontSize14,
                                      minWidth: "80px",
                                      maxWidth: styles.jobDetailsWidth400,
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {/* Your abbreviated content here */}
                                    <BusinessCenterRounded
                                      sx={{
                                        height: styles.icon20,
                                        width: styles.icon20,
                                        color: "#757575",
                                        marginRight: "5px",
                                      }}
                                    />
                                    {selectedJob.jobtypetitle !== undefined &&
                                    selectedJob.jobtypetitle !== null
                                      ? selectedJob.jobtypetitle
                                      : "N/A"}
                                    &nbsp;&nbsp;
                                    {selectedJob.noofjobs !== undefined &&
                                    selectedJob.noofjobs !== null &&
                                    parseInt(selectedJob.noofjobs) !== 0
                                      ? `(Positions : ${selectedJob.noofjobs})`
                                      : "(Positions : N/A)"}
                                  </Typography>
                                </Tooltip>

                                <Tooltip
                                  title={
                                    selectedJob.visatypeName !== undefined &&
                                    selectedJob.visatypeName !== null
                                      ? selectedJob.visatypeName
                                      : "H1"
                                  }
                                  followCursor
                                  // arrow
                                  // placement="top"
                                  enterTouchDelay={500}
                                >
                                  <Typography
                                    paragraph
                                    sx={{
                                      margin: 0,
                                      fontSize: styles.fontSize14,
                                      display: "flex",
                                      alignItems: "end",
                                      gap: "2px",
                                      maxWidth: styles.jobDetailsWidth400,
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    <MdFlight
                                      style={{
                                        height: styles.icon20,
                                        width: styles.icon20,
                                        color: "#757575",
                                        marginRight: "5px",
                                      }}
                                    />
                                    {selectedJob.visatypeName !== undefined &&
                                    selectedJob.visatypeName !== null
                                      ? selectedJob.visatypeName
                                      : "H1"}
                                  </Typography>
                                </Tooltip>

                                {selectedJob?.source === "JNP" && (
                                  <>
                                    {" "}
                                    <Typography
                                      paragraph
                                      sx={{
                                        margin: 0,
                                        fontSize: styles.fontSize14,
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "2px",
                                      }}
                                    >
                                      <FaUserGraduate
                                        style={{
                                          height: styles.icon20,
                                          width: styles.icon20,
                                          color: "#757575",
                                          marginRight: "5px",
                                        }}
                                      />
                                      {selectedJob.qualifications !==
                                        undefined &&
                                      selectedJob.qualifications !== null &&
                                      selectedJob.qualifications !== ""
                                        ? selectedJob.qualifications
                                        : "Bachelor's Of Degree"}
                                    </Typography>
                                    <Typography
                                      paragraph
                                      sx={{
                                        margin: 0,
                                        fontSize: styles.fontSize14,
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "2px",
                                      }}
                                    >
                                      <MdOutlineWorkHistory
                                        style={{
                                          height: styles.icon20,
                                          width: styles.icon20,
                                          color: "#757575",
                                          marginRight: "5px",
                                        }}
                                      />
                                      {selectedJob?.experience === "0"
                                        ? "0 - 3 Years"
                                        : selectedJob?.experience}
                                    </Typography>
                                  </>
                                )}

                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "2px",
                                  }}
                                >
                                  <FaListCheck
                                    style={{
                                      height: styles.icon20,
                                      width: styles.icon20,
                                      color: "#757575",
                                      marginRight: "5px",
                                    }}
                                  />
                                  <Tooltip
                                    title={selectedJob?.ai_skills}
                                    followCursor
                                    // arrow
                                    // placement="top"
                                    enterTouchDelay={500}
                                  >
                                    <Typography
                                      paragraph
                                      sx={{
                                        margin: 0,
                                        fontSize: styles.fontSize14,
                                        cursor: "pointer",
                                        maxWidth: styles.jobDetailsWidth400,
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        "&:hover": {
                                          textDecoration: "underline",
                                        },
                                      }}
                                      onClick={scrollToSkillMatch}
                                    >
                                      Skills : {selectedJob?.ai_skills}
                                    </Typography>
                                  </Tooltip>
                                </Box>
                              </Stack>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "end",
                                alignItems: "end",
                              }}
                            >
                              <Stack direction="row" gap={1}>
                                {parseInt(selectedJob.jobapplylink) === 1 ? (
                                  <Button
                                    variant="contained"
                                    disableElevation
                                    sx={{
                                      paddingLeft: "20px",
                                      paddingRight: "20px",
                                      fontSize: styles.fontSize14,
                                      "&:hover": {
                                        backgroundColor: "#406882 !important",
                                        color: "white !important", // Replace with the hover color you want
                                      },
                                      bgcolor: "#53778e",
                                      textTransform: "capitalize",
                                    }}
                                    onClick={() =>
                                      redirectToLogin(
                                        selectedJob.id,
                                        selectedJob.title
                                      )
                                    }
                                    // onClick={() => history.push("/Login")}
                                    // target="_blank"
                                    // href={selectedJob.joblink}
                                  >
                                    Apply
                                  </Button>
                                ) : (
                                  <Button
                                    variant="contained"
                                    disableElevation
                                    sx={{
                                      paddingLeft: "20px",
                                      paddingRight: "20px",
                                      bgcolor: "#53778e",
                                      color: " white",
                                      textTransform: "capitalize",
                                      fontSize: styles.fontSize14,
                                    }}
                                    onClick={() =>
                                      redirectToLogin(
                                        selectedJob.id,
                                        selectedJob.title
                                      )
                                    }
                                    //onClick={() => history.push("/Login")}
                                    // onClick={() => handleOpenJobApplyProcess()}
                                  >
                                    Apply
                                  </Button>
                                )}

                                <Button
                                  // onClick={() => history.push("/Login")}
                                  // disabled={save}
                                  variant="outlined"
                                  sx={{
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                    textTransform: "capitalize",
                                    borderColor: "#53778e",
                                    color: "#53778e",
                                    fontSize: styles.fontSize14,
                                  }}
                                  onClick={() =>
                                    redirectToLogin(
                                      selectedJob.id,
                                      selectedJob.title
                                    )
                                  }
                                >
                                  Save
                                </Button>
                              </Stack>
                            </Box>
                          </Box>
                        </Stack>
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <Card sx={{ boxShadow: "none", padding: "10px" }}>
                        <Typography
                          paragraph
                          sx={{ fontWeight: 500, fontSize: styles.fontSize16 }}
                        >
                          About the job
                        </Typography>
                        <Typography
                          paragraph
                          style={{ fontSize: "16px !important" }}
                        >
                          {selectedJob.description !== null &&
                          selectedJob.description !== undefined &&
                          selectedJob.description
                            ? !selectedJob.description.includes("ahref") &&
                              !selectedJob.description.includes("divclass") &&
                              !selectedJob.description.includes("aclass") &&
                              !selectedJob.description.includes("pclass") &&
                              !selectedJob.description.includes("liclass")
                              ? parse(selectedJob.description)
                              : selectedJob.description.replace(/<[^>]+>/g, "")
                            : ""}
                        </Typography>
                      </Card>
                    </Grid>
                    <Divider />
                    <Grid xs={12} sx={{ bgcolor: "#fff" }}>
                      <div ref={skillMatchRef} style={{ padding: "5px" }}>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <TableContainer
                            component={Paper}
                            sx={{ maxHeight: 400, maxWidth: "400px" }}
                          >
                            <Table
                              aria-label="customized table"
                              stickyHeader
                              size="small"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    colSpan={2}
                                    sx={{ textAlign: "center" }}
                                  >
                                    Skills
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {selectedJobSkills &&
                                selectedJobSkills.length > 0 ? (
                                  selectedJobSkills.map((row, index) => {
                                    if (index % 2 === 0) {
                                      return (
                                        <StyledTableRow key={index}>
                                          <TableCell
                                            sx={{ fontSize: styles.fontSize14 }}
                                          >
                                            {row}
                                          </TableCell>
                                          {selectedJobSkills[index + 1] && (
                                            <TableCell
                                              sx={{
                                                fontSize: styles.fontSize14,
                                              }}
                                            >
                                              {selectedJobSkills[index + 1]}
                                            </TableCell>
                                          )}
                                        </StyledTableRow>
                                      );
                                    }
                                    return null;
                                  })
                                ) : (
                                  <Typography
                                    paragraph
                                    sx={{
                                      margin: 0,
                                      paddingTop: 2,
                                      textAlign: "center",
                                    }}
                                  >
                                    Skills Not found
                                  </Typography>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      </div>
                    </Grid>
                  </Grid>
                ) : (
                  <Card>No Data Found...</Card>
                )}
                {showScrollButton && (
                  <Box sx={{ ml: 2 }} component={Paper} elevation={3}>
                    <IconButton
                      color="primary"
                      onClick={scrollToTop}
                      sx={{
                        position: "fixed",
                        bottom: "16px",
                        // right: "16px",

                        backgroundImage:
                          "linear-gradient(360deg, #406882 0%, #518aa7 50%, #6d9ec6 100%)",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "#406882",
                        },
                      }}
                    >
                      <KeyboardDoubleArrowUpRounded />
                    </IconButton>
                  </Box>
                )}
              </Box>
            </Grid>
          </>
        ) : (
          <></>
        )}

        {/* Recruiter Details and Similar Jobs------------------------------------------------------------------------------------------------------ */}
        {showRecruiterDetails && (
          <Grid
            item
            xs={isMobileSmall || isTabletSmall ? 12 : 4}
            sx={{ height: window.innerHeight, overflow: "scroll" }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {Object.keys(selectedJobRecruiterDetails)?.length !== 0 ? (
                  <Card
                    sx={{
                      boxShadow: "none",
                      borderRadius: "8px",
                      padding: "10px",
                    }}
                  >
                    <CardHeader
                      title="Recruiter Details"
                      sx={{
                        pb: 0,
                        "& .MuiCardHeader-title": {
                          fontSize: styles.fontSize18,
                          fontWeight: 500,
                        },
                      }}
                      /* action={
                  <MoreVertRounded
                    sx={{ color: "#757575", height: "20px", width: "20px" }}
                  />
                } */
                    />
                    <Divider />
                    <CardContent sx={{ p: 0 }}>
                      <Box
                        sx={{
                          position: "relative",
                          padding: "4px",
                          width: "100%",
                        }}
                      >
                        <Box sx={{ display: "flex" }}>
                          <Box
                            sx={{
                              borderLeft: "10px solid rgba(64, 104, 130, 0.8)",
                              borderTop: "4px solid rgba(64, 104, 130, 0.8)",
                              borderBottom: "4px solid rgba(64, 104, 130, 0.8)",
                              borderRadius: "50%",
                            }}
                          >
                            <Tooltip
                              title={`Recruiter id: ${selectedJobRecruiterDetails.id}`}
                              arrow
                              placement="top"
                            >
                              <Box
                                sx={{
                                  borderLeft: "6px solid #fff",
                                  borderTop: "3px solid #fff",
                                  borderBottom: "3px solid #fff",
                                  borderRadius: "50%",
                                }}
                              >
                                <Avatar
                                  // src="https://img.freepik.com/free-photo/horizontal-portrait-smiling-happy-young-pleasant-looking-female-wears-denim-shirt-stylish-glasses-with-straight-blonde-hair-expresses-positiveness-poses_176420-13176.jpg?w=996&t=st=1702271653~exp=1702272253~hmac=6df403e4dd80c55861bc496ced5b75e6bdc1f83019152ab036ed6467fcd8174a"
                                  src={
                                    selectedJobRecruiterDetails.image
                                      ? `${Key.files_domain}/dashboard/profile_image/` +
                                        selectedJobRecruiterDetails.id +
                                        "/" +
                                        selectedJobRecruiterDetails.image
                                      : ""
                                  }
                                  sx={{ height: "100px", width: "100px" }}
                                />
                              </Box>
                            </Tooltip>
                          </Box>
                        </Box>
                        {(isValidPhoneNumber(selectedJob?.contactphone) ||
                          isValidPhoneNumber(
                            selectedJobCompanyDetails?.contactphone
                          )) && (
                          <Box
                            sx={{
                              position: "absolute",
                              left: "115px",
                              top: "15px",
                              ".phone-text": {
                                margin: 0,
                                fontSize: "11px",
                                color: "rgba(0,0,0,0.7)",
                                fontWeight: 500,
                                cursor: "pointer",
                                textTransform: "lowercase",
                                filter: "blur(2px)",
                              },
                              "&:hover": {
                                ".phone-text": {
                                  color: "#406882",
                                  textDecoration: "underline",
                                },
                              },
                            }}
                          >
                            {isMatchLGL &&
                            isValidPhoneNumber(selectedJob?.contactphone) ? (
                              <Typography
                                variant="button"
                                paragraph
                                className="phone-text"
                                //  onClick={handlemessageShow}
                              >
                                <PhoneRounded
                                  sx={{
                                    height: "12px",
                                    width: "12px",
                                    mr: "3px",
                                  }}
                                />
                                {/* {selectedJob?.contactphone} */}XXXXXXXXXX
                              </Typography>
                            ) : isMatchLGL &&
                              isValidPhoneNumber(
                                selectedJobCompanyDetails?.contactphone
                              ) ? (
                              <Typography
                                variant="button"
                                paragraph
                                className="phone-text"
                                //  onClick={handlemessageShow}
                              >
                                <PhoneRounded
                                  sx={{
                                    height: "12px",
                                    width: "12px",
                                    mr: "3px",
                                  }}
                                />
                                {/* {selectedJobCompanyDetails?.contactphone} */}
                                XXXXXXXXXX
                              </Typography>
                            ) : (
                              ""
                            )}
                          </Box>
                        )}
                        {selectedJob.contactemail !== "" &&
                          selectedJob.contactemail !== null &&
                          selectedJob.contactemail &&
                          selectedJobCompanyDetails?.contactemail !== null &&
                          selectedJobCompanyDetails?.contactemail && (
                            <Box
                              sx={{
                                position: "absolute",
                                right: "5px",
                                top: "15px",
                                ".email-text": {
                                  margin: 0,
                                  fontSize: "11px",
                                  color: "rgba(0,0,0,0.7)",
                                  fontWeight: 500,
                                  cursor: "pointer",
                                  textTransform: "lowercase",
                                  filter: "blur(2px)",
                                },
                                "&:hover": {
                                  ".email-text": {
                                    color: "#406882",
                                    textDecoration: "underline",
                                  },
                                },
                              }}
                            >
                              <Typography
                                variant="button"
                                paragraph
                                className="email-text"
                                // onClick={()=>handlemailShow(selectedJob,selectedJobCompanyDetails)}
                              >
                                <EmailRounded
                                  sx={{
                                    height: "12px",
                                    width: "12px",
                                    mr: "3px",
                                  }}
                                />
                                {/*  {selectedJob.contactemail} */}
                                {/* {selectedJob.contactemail !== "" &&
                                selectedJob.contactemail !== null
                                  ? selectedJob.contactemail
                                  : selectedJobCompanyDetails?.contactemail !==
                                    null
                                  ? selectedJobCompanyDetails?.contactemail
                                  : ""} */}
                                XXXXXXXXXXXX.com
                              </Typography>
                            </Box>
                          )}

                        <Paper
                          elevation={1}
                          sx={{
                            position: "absolute",
                            left: "105px",
                            right: 0,
                            top: "38px",
                            padding: "10px",
                            borderRadius: "6px",
                            width: "auto",
                            // bgcolor:'#406882',
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <h6
                                  style={{
                                    margin: 0,
                                    maxWidth: "250px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    fontWeight: 800,
                                    color: "rgba(0,0,0,0.7)",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {selectedJob.contactname !== "" &&
                                  selectedJob.contactname !== null
                                    ? selectedJob.contactname
                                    : selectedJobCompanyDetails?.contactname !==
                                      null
                                    ? selectedJobCompanyDetails?.contactname
                                    : ""}
                                </h6>
                              </Box>
                              <p
                                style={{
                                  margin: 0,
                                  fontSize: "12px",
                                  fontWeight: 500,
                                  color: "#406882",
                                  maxWidth: "320px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {selectedJobRecruiterDetails.role}
                              </p>
                            </Box>
                            <Box
                              sx={{
                                border: "1px solid #ddd",
                                borderRadius: "6px",
                                "&:hover": {
                                  bgcolor: "#406882",
                                  ".inicon": {
                                    color: "#fff",
                                    cursor: "pointer",
                                  },
                                },
                                ".inicon": {
                                  color: "#406882",
                                },
                              }}
                            >
                              <TiSocialLinkedin
                                className="inicon"
                                style={{ height: "22px", width: "26px" }}
                              />
                            </Box>
                          </Box>
                        </Paper>
                      </Box>
                    </CardContent>
                  </Card>
                ) : (
                  <Card>Recruiter Details Not Found...</Card>
                )}
              </Grid>
              <Grid item xs={12}>
                <Card sx={{ boxShadow: "none", borderRadius: "8px" }}>
                  <CardHeader
                    title={
                      <p
                        style={{ margin: 0, fontWeight: 500, fontSize: "16px" }}
                      >
                        Similar Jobs
                      </p>
                    }
                    action={
                      <MoreVertRounded
                        sx={{ color: "#757575", height: "20px", width: "20px" }}
                      />
                    }
                    sx={{ borderBottom: "1px solid #ddd" }}
                  />
                  <CardContent>
                    <List>
                      {similarJobs?.length > 0 ? (
                        similarJobs.map(
                          (job, index) => (
                            // <ListItem sx={{borderBottom:'1px solid #ddd'}}>
                            <ListItemButton
                              disableRipple
                              sx={{
                                borderBottom: "1px solid #ddd",
                                padding: styles.paddingListItem,
                              }}
                              //onClick={() => jobCardClickHandler(job, index)}
                            >
                              <Grid container spacing={0}>
                                <Grid item xs={2}>
                                  <Avatar
                                    src={`${Key.domain}/company/company_logo/${job?.companyid}/getcompanynamebyquery`}
                                    sx={{
                                      width: styles.Avatar40,
                                      height: styles.Avatar40,
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={10}>
                                  <Stack
                                    direction="column"
                                    sx={{ width: "100%" }}
                                  >
                                    <Typography
                                      paragraph
                                      sx={{
                                        margin: 0,
                                        color: "rgba(0,0,0,0.7)",
                                        fontWeight: 600,
                                        fontSize: styles.fontSize12,
                                      }}
                                      onClick={() => redirectToJobView(job)}
                                    >
                                      {job?.title}
                                    </Typography>
                                    <Typography
                                      paragraph
                                      sx={{
                                        margin: 0,
                                        color: "rgba(0, 0, 0, 0.56)",
                                        fontWeight: 400,
                                        fontSize: styles.fontSize12,
                                      }}
                                      onClick={() =>
                                        redirectToCompanyView(job?.companyid)
                                      }
                                    >
                                      {job?.companyName}
                                    </Typography>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                      }}
                                    >
                                      <Typography
                                        paragraph
                                        sx={{
                                          margin: 0,
                                          color: "rgba(0,0,0,0.5)",
                                          fontSize: styles.fontSize12,
                                        }}
                                      >
                                        {`${
                                          job?.cityName &&
                                          job?.cityName !== "N/A" &&
                                          job?.cityName !== null &&
                                          job?.stateName &&
                                          job?.stateName !== "N/A" &&
                                          job?.stateName !== null &&
                                          job?.shortRegion &&
                                          job?.shortRegion !== "N/A" &&
                                          job?.shortRegion !== null
                                            ? job?.cityName +
                                              ", " +
                                              // job?.stateName +
                                              // ", " +
                                              job?.shortRegion
                                            : "(Remote)"
                                        }`}
                                        {/* {`${
                              job?.cityName &&
                              job?.cityName !== "N/A" &&
                              job?.cityName !== null
                                ? job?.cityName + ", "
                                : ""
                            } ${
                              job?.stateName &&
                              job?.stateName !== "N/A" &&
                              job?.stateName !== null
                                ? job?.stateName + ", "
                                : ""
                            } ${
                              job?.shortRegion &&
                              job?.shortRegion !== "N/A" &&
                              job?.shortRegion !== null
                                ? job?.shortRegion
                                : "(Remote)"
                            }`} */}
                                      </Typography>
                                      <Typography
                                        paragraph
                                        sx={{
                                          margin: 0,
                                          color: "rgba(0,0,0,0.5)",
                                          fontWeight: 500,
                                          fontSize: styles.fontSize12,
                                        }}
                                      >
                                        {job.modified !== undefined &&
                                        job.modified !== null
                                          ? moment(job.modified).fromNow()
                                          : moment(job.created).fromNow()}
                                      </Typography>
                                    </Box>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </ListItemButton>
                          )
                          // </ListItem>
                        )
                      ) : (
                        <></>
                      )}
                    </List>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid item xs={12}></Grid>
      </Grid>
      <BlurredModal open={openSearchModal} onClose={handleCloseSearchModal}>
        <Box sx={styleModal}>
          <SearchComponent closeSearch={setOpenSearchModal} />
        </Box>
      </BlurredModal>
    </>
  );
};

export default SearchJobs_list;

const BlurredBackdrop = styled(Backdrop)(({ theme }) => ({
  backdropFilter: "blur(8px)",
  backgroundColor: "rgba(255, 255, 255, 0.5)",
}));

function BlurredModal({ open, onClose, children }) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      BackdropComponent={BlurredBackdrop}
      BackdropProps={{
        sx: { backdropFilter: "blur(2px)" },
      }}
    >
      {children}
    </Modal>
  );
}

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 0,
  borderRadius: "6px",
  maxHeight: "70vh",
  overflow: "scroll",
};
