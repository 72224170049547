const initialState = {
    pageStartValue: 1
};

const pageStartValReducer = (state = initialState, action) => {
    switch (action.type) {
        case "PAGE_START_VALUE":
            return {
                pageStartValue: action.payload.pageStartValue,
            };
        default:
            return state;
    }
};

export default pageStartValReducer;
