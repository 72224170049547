import React, { useState, useEffect, useContext } from 'react'
import "../../Styles/Jsdashboard.css";
import { Container, Grid } from "@mui/material";
import Key from '../../clientVariables.json'
import { useHistory } from "react-router";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from 'axios';
import { showLoader } from "../../Components/Actions/loader.actions";
import { hideLoader } from "../../Components/Actions/loader.actions";
import SearchContext from "../../../src/resumeBuilder/SearchContext"

import { postOptions } from '../../utils/httpConfig';
import request from "../../utils/request"
import { ENDPOINT } from '../../utils/endpoint';
import states from './stateslist'
import { BiSearchAlt } from 'react-icons/bi'

function Jssearchblock(props) {

    const history = useHistory();
    const searchResContext = useContext(SearchContext)

    const [resumes, setResumes] = useState([])

    const [resumeTitle, setResumeTitle] = useState("")
    const [cityname, setCityName] = useState("")
    const [fulltime, setFullTime] = useState(false)
    const [contract, setContract] = useState(false)
    const [citySuggestions, setCitySuggestions] = useState([])
    const [titleSuggestions, setTitleSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false)
    const [showTitleSuggestions, setShowTitleSuggestions] = useState(false)
    const [locationSuggestions, setLocationSuggestions] = useState("")
    const [Skills, setSkills] = useState("")



    const postRecentSearches = async () => {
        const result = await request(`${ENDPOINT['PostRecentSearches']}`,
            postOptions({
                user_id: sessionStorage.getItem("id"),
                session_token: props.currentUser.session_token,
                search_title: resumeTitle,
                search_location: cityname || locationSuggestions,
                skills: Skills
            }))

        console.log(result)
    }




    useEffect(() => {
        setFullTime(props.fulltime !== undefined ? props.fulltime : false)
        setContract(props.contract !== undefined ? props.contract : false)
        if (window.location.pathname !== '/' && window.location.pathname !== '/Jsdashboard' && props.onSubmit !== undefined) {
            props.onSubmit(resumes)


        }
        if (sessionStorage.getItem("fromSearchResumesFree") !== "" && sessionStorage.getItem("fromSearchResumesFree") !== null && sessionStorage.getItem("fromSearchResumesFree") !== undefined) {
            submit();
        }
        console.log(sessionStorage.getItem("resResumeTitle"))
        setResumeTitle(sessionStorage.getItem("inputResumeTitle"))
        setCityName(sessionStorage.getItem("resumeCityName") ? sessionStorage.getItem("resumeCityName").replace(/"/g, '') : "")
        console.log(props.jobtitle, sessionStorage.getItem("jobtitle"))
        setSkills(props.location.state && props.location.state.skills !== undefined ? props.location.state.skills : '')
    }, [resumes])

    const clearItems = () => {

        sessionStorage.removeItem("resumefiltered-jobtypes")
        localStorage.removeItem("resumejobtypeFlages")
        localStorage.setItem("resumeFilterResetValue", 1)

        sessionStorage.removeItem("resumefiltered-visatypes")
        localStorage.removeItem("resumevisatypeFlages")

        sessionStorage.removeItem("resumefiltered-experience")
        localStorage.removeItem("resumeExpFlages")

        sessionStorage.removeItem("resumefiltered-country")

        localStorage.removeItem("resumeQualificationFlages")
        sessionStorage.removeItem("resumefiltered-qualification")
        localStorage.removeItem("resumeCountryFlages")

        sessionStorage.removeItem("resumeMappedDates")

        sessionStorage.removeItem("currentsearchpage")
        localStorage.removeItem("resumeSortBy")
        localStorage.removeItem("resumeSortByDate")

        sessionStorage.removeItem("resumefiltered-salary")
        sessionStorage.removeItem("resumeFiltered-salaryTo")
        sessionStorage.removeItem("resumeFiltered-salaryFrom")
        localStorage.removeItem("resumeFileterChecked")
    }





    const showAlternateResults = async (jobtype, skillstoHighlight) => {
        let title = sessionStorage.getItem("resumeTitle").replace(/['"]/g, '')
        const searchResumesUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select?q=application_title:(${title})&fq=res_stateName:(${sessionStorage.getItem("resumeCityName") !== undefined && sessionStorage.getItem("resumeCityName") !== "" ? sessionStorage.getItem("resumeCityName") : "*:*"})&fq=search_flag:(1)&rows=${10}&start=${0}&wt=json`;
        const result = await request(`${ENDPOINT['SolrResumes']}`,
            postOptions({
                url: searchResumesUrl,
            }))
        props.hideLoader()

        history.push({
            pathname: "/ResumeSearch_Listview",
            state: {
                response: result.data.response.docs,
                jobtitle: true, location: false,
                fulltime: fulltime,
                contract: contract,
                homepageJobtypesChecked: jobtype,
                skills: Skills,
                skillstoHighlight: skillstoHighlight,
                // searchSkills: (skillsToDisplay !== null ) ? skillsToDisplay.toLowerCase().includes(' and') ? skillsToDisplay.toLowerCase().split(" and") :skillsToDisplay.toLowerCase().includes(' or') ? skillsToDisplay.toLowerCase().split(" or") : skillsToDisplay : ""
            }
        });

        sessionStorage.setItem("resumesCount", result.data.response.numFound);

    }

    const submit = async (e) => {
        if (e !== undefined) {
            e.preventDefault()
        }
        clearItems()
        props.showLoader()

        var skillstoHighlight = [];

        if ((resumeTitle !== null) && (resumeTitle.includes("AND") || resumeTitle.includes("OR") || resumeTitle.includes(","))) {

            if (resumeTitle.includes("AND")) {

                var application_title = resumeTitle.split('AND', 1)
                var str = resumeTitle;
                var skills = str.substring(str.indexOf('AND') + 3);
                console.log(skills)
                sessionStorage.setItem("skillNDescriptionSearch", skills)

                const filtereAND = resumeTitle.replaceAll('AND', ',');
                const filtered = filtereAND.replaceAll(' ', ',');
                skillstoHighlight = filtered.split(",");
                sessionStorage.setItem("skillstoHighlight", skillstoHighlight)

            } else if (resumeTitle.includes("OR")) {


                application_title = resumeTitle.split(' OR ', 1)
                str = resumeTitle;
                var skills = str.substring(str.indexOf(' OR ') + 4);

                const titleTwoContainskeyword = skills.toLowerCase().includes("developer") || skills.toLowerCase().includes("programmer") || skills.toLowerCase().includes('architect') || skills.toLowerCase().includes("designer") || skills.toLowerCase().includes('consultant') || skills.toLowerCase().includes("administrator") || skills.toLowerCase().includes('coach') || skills.toLowerCase().includes("lead") || skills.toLowerCase().includes('manager') || skills.toLowerCase().includes("master") || skills.toLowerCase().includes('specalist') || skills.toLowerCase().includes('modeler') || skills.toLowerCase().includes('director') || skills.toLowerCase().includes('hacker') || skills.toLowerCase().includes('engineer') || skills.toLowerCase().includes('analyst') || skills.toLowerCase().includes('dba') || skills.toLowerCase().includes('tester')

                var roleBasedTwoTitles = ''

                if (titleTwoContainskeyword === true) {

                    var roleBasedVariabe = resumeTitle.replace(' OR ', '" OR "') // Eg: Java developer" OR "Python developer
                    roleBasedTwoTitles = '"' + roleBasedVariabe + '"' // Eg: "Java developer" OR "Python developer"
                    console.log(resumeTitle)
                    console.log(roleBasedTwoTitles)
                    skills = ""

                } else {
                    sessionStorage.setItem("skillNDescriptionSearch", skills)
                    const filtereAND = resumeTitle.replaceAll(' OR ', ',');
                    const filtered = filtereAND.replaceAll(' ', ',');
                    skillstoHighlight = filtered.split(",");
                    sessionStorage.setItem("skillstoHighlight", skillstoHighlight)
                }
            } else if (resumeTitle.includes(",")) {

                application_title = resumeTitle.split(',', 1)
                str = resumeTitle;
                var skills = str.substring(str.indexOf(',') + 1);
                sessionStorage.setItem("skillNDescriptionSearch", skills)

                const filtereAND = resumeTitle.replaceAll(',', ',');
                const filtered = filtereAND.replaceAll(' ', ',');
                skillstoHighlight = filtered.split(",");
                sessionStorage.setItem("skillstoHighlight", skillstoHighlight)

            }
            if (roleBasedTwoTitles === undefined || roleBasedTwoTitles === '') {

                console.log(roleBasedTwoTitles)

                var resume_title = application_title[0]
                console.log(application_title[0])
                console.log(skills)
                console.log(skillstoHighlight)


                var inputResumeTitle = resume_title


                const string = inputResumeTitle
                const res = string.toLowerCase().includes("developer") || string.toLowerCase().includes("programmer") || string.toLowerCase().includes('architect') || string.toLowerCase().includes("designer") || string.toLowerCase().includes('consultant') || string.toLowerCase().includes("administrator") || string.toLowerCase().includes('coach') || string.toLowerCase().includes("lead") || string.toLowerCase().includes('manager') || string.toLowerCase().includes("master") || string.toLowerCase().includes('specalist') || string.toLowerCase().includes('modeler') || string.toLowerCase().includes('director') || string.toLowerCase().includes('hacker') || string.toLowerCase().includes('engineer') || string.toLowerCase().includes('analyst') || string.toLowerCase().includes('dba') || string.toLowerCase().includes('tester') || string.toLowerCase().includes('scientist');

                if (res === true) {
                    inputResumeTitle = '"' + string.replace(/"/g, "") + '"'
                }
            } else {

                inputResumeTitle = roleBasedTwoTitles

            }

        } else {
            inputResumeTitle = sessionStorage.getItem("inputResumeTitle") !== '' && sessionStorage.getItem("inputResumeTitle") !== null && sessionStorage.getItem("inputResumeTitle") !== undefined ? sessionStorage.getItem("inputResumeTitle") : '';
            inputResumeTitle = inputResumeTitle !== '' ? '"' + inputResumeTitle + '"' : '';
            if (typeof (parseInt(resumeTitle)) === 'number') {
                //sessionStorage.removeItem("skillNDescriptionSearch")
            }
            if (Skills !== undefined && Skills !== null && Skills !== "") {
                sessionStorage.setItem("skillNDescriptionSearch", Skills)
            }
            const filtereAND = resumeTitle !== null && resumeTitle !== "" && resumeTitle !== undefined ? resumeTitle.replaceAll(' ', ',') : "";
            const filtered = filtereAND.replaceAll(' ', ',');
            const skills = Skills !== "" && Skills !== undefined && Skills !== null ? Skills.replaceAll(' AND ', ',') : "";
            console.log(skills)
            const splittedSkills = skills !== "" && skills !== undefined && skills !== null ? skills.split(',') : ""
            console.log(splittedSkills)
            skillstoHighlight = filtered.split(",");
            const mergeSkills = [...skillstoHighlight, ...splittedSkills]
            skillstoHighlight = mergeSkills

            const string = inputResumeTitle !== null ? inputResumeTitle : ""
            const res = string.toLowerCase().includes("developer") || string.toLowerCase().includes("programmer") || string.toLowerCase().includes('architect') || string.toLowerCase().includes("designer") || string.toLowerCase().includes('consultant') || string.toLowerCase().includes("administrator") || string.toLowerCase().includes('coach') || string.toLowerCase().includes("lead") || string.toLowerCase().includes('manager') || string.toLowerCase().includes("master") || string.toLowerCase().includes('specalist') || string.toLowerCase().includes('modeler') || string.toLowerCase().includes('director') || string.toLowerCase().includes('hacker') || string.toLowerCase().includes('engineer') || string.toLowerCase().includes('analyst') || string.toLowerCase().includes('dba') || string.toLowerCase().includes('tester') || string.toLowerCase().includes('scientist');

            if (res === true) {
                inputResumeTitle = '"' + string.replace(/"/g, "") + '"'
                if (inputResumeTitle.toLowerCase().includes("dot net developer")) {
                    inputResumeTitle = '"' + ".net developer" + '"'
                } else if (inputResumeTitle.toLowerCase().includes("service now developer") || inputResumeTitle.toLowerCase().includes("servicenow developer")) {
                    inputResumeTitle = '(' + '"' + "Service Now developer" + '"' + ' OR ' + '"' + "ServiceNow developer" + '"' + ')'
                } else if (inputResumeTitle.toLowerCase().includes("react js developer") || inputResumeTitle.toLowerCase().includes("react.js developer") || inputResumeTitle.toLowerCase().includes("react js")) {
                    inputResumeTitle = '(' + '"' + "React js developer" + '"' + ' OR ' + '"' + "React.js developer" + '"' + ')'
                } else if (inputResumeTitle.includes("#")) {
                    inputResumeTitle = inputResumeTitle.replace("#", '%23')
                } else if (inputResumeTitle.includes("++")) {
                    inputResumeTitle = inputResumeTitle.replace("++", '%2B%2B')
                } else if (inputResumeTitle.includes("&")) {
                    inputResumeTitle = inputResumeTitle.replace("&", '%26')
                } else if (inputResumeTitle.includes("/")) {
                    inputResumeTitle = inputResumeTitle.replace(" / ", '/')
                }
            } else {

                if (inputResumeTitle !== null && typeof (parseInt(inputResumeTitle)) === 'number') {
                    inputResumeTitle = inputResumeTitle
                    console.log(inputResumeTitle)
                } else if (inputResumeTitle && inputResumeTitle.includes("#")) {
                    inputResumeTitle = inputResumeTitle.replace("#", '%23')
                } else if (inputResumeTitle && inputResumeTitle.includes("++")) {
                    inputResumeTitle = inputResumeTitle.replace("++", '%2B%2B')
                } else if (inputResumeTitle && inputResumeTitle.includes("&")) {
                    inputResumeTitle = inputResumeTitle.replace("&", '%26')
                } else if (inputResumeTitle && inputResumeTitle.includes("/")) {
                    inputResumeTitle = inputResumeTitle.replace(" / ", '/')
                }
                else {
                    inputResumeTitle = "(*%3A*)"
                    console.log(inputResumeTitle)

                }
            }
            sessionStorage.setItem("skillstoHighlight", skillstoHighlight)
        }


        if (inputResumeTitle === null || inputResumeTitle === undefined || inputResumeTitle === '') {
            sessionStorage.removeItem("jobtitle")
            localStorage.removeItem("jobtitle")
            sessionStorage.removeItem("resumeTitle")
            sessionStorage.removeItem("inputjobtitle");

        }

        const inputlocationcity = sessionStorage.getItem("resumeCityName")

        if (inputlocationcity.includes(',')) {
            const location = inputlocationcity !== "" ? inputlocationcity.split(/[,]+/) : "";

            if (location[1].replace(' ', '').length === 2) {
                var cityName = location[0]
                var stateName = ""
                var shortRegion = location[1]
            } else {
                cityName = location[0]
                stateName = location[1]
            }

        } else if (inputlocationcity.length === 2) {
            shortRegion = inputlocationcity
        } else {
            cityName = inputlocationcity

        }

        var fqSkills = sessionStorage.getItem("skillNDescriptionSearch") !== undefined && sessionStorage.getItem("skillNDescriptionSearch") !== null && sessionStorage.getItem("skillNDescriptionSearch") !== "" ? sessionStorage.getItem("skillNDescriptionSearch") : "* TO *"
        if (fulltime === true && contract === false) {
            var jobtype = "1"
        } else if (contract === true && fulltime === false) {
            jobtype = "10"
        } else if (fulltime === true && contract === true) {
            jobtype = "1 OR 10"
        }
        if (locationSuggestions === "") {
            var stateslist = states.states
            var stateBoolean = stateslist.map(state => state.toLowerCase()).includes(cityname.toLowerCase())
            if (stateBoolean === true) {
                var searchResumesUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select?q=application_title:(${inputResumeTitle ? inputResumeTitle : "*%3A*"}) OR id:(${inputResumeTitle ? inputResumeTitle : "*%3A*"})&fq=res_stateName:(${stateName !== undefined && stateName !== '' && stateName !== null ? stateName : "*:*"})&fq=res_cityName:(${cityName !== undefined && cityName !== '' && cityName !== null ? cityName : "*:*"})&fq=res_shortRegion:(${shortRegion !== undefined && shortRegion !== '' && shortRegion !== null ? shortRegion : "*:*"})&fq=jobtype:(${jobtype ? jobtype : "*:*"})&fq=(${fqSkills})&fq=search_flag:(1)&rows=10&start=0&sort=lastmodified%20desc&wt=json`

            } else {
                searchResumesUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select?q=application_title:(${inputResumeTitle ? inputResumeTitle : "*%3A*"}) OR id:(${inputResumeTitle ? inputResumeTitle : "*%3A*"})&fq=res_stateName:(${stateName !== undefined && stateName !== '' && stateName !== null ? stateName : "*:*"})&fq=res_cityName:(${cityName !== undefined && cityName !== '' && cityName !== null ? cityName : "*:*"})&fq=res_shortRegion:(${shortRegion !== undefined && shortRegion !== '' && shortRegion !== null ? shortRegion : "*:*"})&fq=jobtype:(${jobtype ? jobtype : "*:*"})&fq=(${fqSkills})&fq=search_flag:(1)&rows=10&start=0&sort=lastmodified%20desc&wt=json`
            }
        }
        else {
            searchResumesUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select?q=application_title:(${inputResumeTitle ? inputResumeTitle : "*%3A*"}) OR id:(${inputResumeTitle ? inputResumeTitle : "*%3A*"})&fq=res_stateName:(${stateName !== undefined && stateName !== '' && stateName !== null ? stateName : "*:*"})&fq=res_cityName:(${cityName !== undefined && cityName !== '' && cityName !== null ? cityName : "*:*"})&fq=res_shortRegion:(${shortRegion !== undefined && shortRegion !== '' && shortRegion !== null ? shortRegion : "*:*"})&fq=jobtype:(${jobtype ? jobtype : "*:*"})&fq=(${fqSkills})&fq=search_flag:(1)&rows=10&start=0&sort=lastmodified%20desc&wt=json`
        }


        const result = await request(`${ENDPOINT['SolrResumes']}`,
            postOptions({
                url: searchResumesUrl,
            }))

        sessionStorage.setItem("resumeTitle", inputResumeTitle)
        sessionStorage.setItem("resumeCityName", cityname)
        localStorage.setItem("resumeQuery", searchResumesUrl)


        if (result.data.response.docs.length === 0) {
            showAlternateResults(jobtype, skills)
        } else {
            props.hideLoader()

            history.push({
                pathname: "/ResumeSearch_Listview",
                state: {
                    response: result.data.response.docs,
                    jobtitle: true, location: true,
                    skills: skills,
                   // searchSkills: (skillsToDisplay !== null ) ? skillsToDisplay.toLowerCase().includes(' and') ? skillsToDisplay.toLowerCase().split(" and") :skillsToDisplay.toLowerCase().includes(' or') ? skillsToDisplay.toLowerCase().split(" or") : skillsToDisplay : ""
                }
            });
            sessionStorage.setItem("resumesCount", result.data.response.numFound)
        }




    }




    const getJobtitles = (value) => {
        setShowTitleSuggestions(true)
        axios.post(`${Key.domain}/Jobs/get_jobtitles`, {
            jobtitle: value,
        })
            .then(response => {
                console.log(response.data.data)
                setTitleSuggestions(response.data.data.map(res => res.title))
            })
    }

    const getCityNames = (value) => {
        setShowSuggestions(true)
        axios.post(`${Key.domain}/Jobs/get_joblocations`, {
            statename: value,
        })
            .then(response => {
                console.log(response.data.data)
                setCitySuggestions(response.data.data.map(res => res.cityName.concat(" , ", res.stateName)))
            })
    }

    const pushAdvancedResume = () => {
        sessionStorage.removeItem("advancedSearch-skills")
        localStorage.removeItem("skillFlages")

        sessionStorage.removeItem("advanceresumefiltered-jobtypes")
        sessionStorage.removeItem("advanceresumefiltered-visatypes")
        sessionStorage.removeItem("advanceresumefiltered-qualifications")
        sessionStorage.removeItem("advanceresumefiltered-exptypes")
        sessionStorage.removeItem("inputResumeTitle")
        sessionStorage.removeItem("resumeTitle");
        sessionStorage.removeItem("resumeCityName");
        sessionStorage.removeItem("technology_jsondata");
        sessionStorage.removeItem("AdvanceResumeIds")
        sessionStorage.removeItem("currenttab")
        sessionStorage.removeItem("Selectedjsondata")

        const searchResumesUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select?q=*%3A*&fq=search_flag:(1)&rows=10&start=0&sort=lastmodified%20desc&wt=json`
        localStorage.setItem("resumeQuery", searchResumesUrl)

        fetch(`${Key.domain}/solr/solr_resumes`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ url: searchResumesUrl }),
        })
            .then((response) => {
                response.json().then((resdata) => {

                    sessionStorage.setItem("resumesCount", resdata.data.response.numFound)
                    sessionStorage.setItem("PaginationCount", resdata.data.response.numFound)
                })

            })


        history.push({
            pathname: "/AdvancedResumeSearch",
            state: {
                filterChecked: false,
                response: [],
                jobtitle: false, location: true,

            }
        });
    }

    return (
        <div>
            <div className="Search__bg">
                <Container>
                    <div className="jsmain_body">
                        <Grid container spacing={2}>
                            <Grid item sm={8} md={8} lg={8} xs={8}>


                                <h2 className="js_title">2,000,000 + RESUMES</h2>

                            </Grid>
                            <Grid item sm={4} md={4} lg={4} xs={4}>
                                {/* <span>Advanced Search</span> */}
                            </Grid>
                            <div class="Searchblocks find-resumes">
                                <form autocomplete="on" onSubmit={(e) => { submit(e); sessionStorage.setItem("resumeviewBackButton", "1") }}>
                                    <Grid container spacing={2}>
                                        <Grid item sm={12} md={12} lg={3} >

                                            <input type="text" class="form-control"
                                                onBlur={(e) => {
                                                    setTimeout(() => {
                                                        setShowTitleSuggestions(false)
                                                    }, [300])
                                                }}
                                                value={sessionStorage.getItem("inputResumeTitle") ? sessionStorage.getItem("inputResumeTitle").replace(/"/g, "") :
                                                    resumeTitle !== "null" && resumeTitle !== "(*%3A*)" && resumeTitle !== null ? resumeTitle.replace(/"/g, "") : ""}
                                                placeholder="Search Resumes,&nbsp;Resume title" onChange={e => {
                                                    setResumeTitle(e.target.value); getJobtitles(e.target.value);
                                                    sessionStorage.setItem("inputResumeTitle", e.target.value)
                                                }} />
                                            {showTitleSuggestions === true ?
                                                <ul className="sm-12 col-md-4 col-lg-3 suggestionList">
                                                    {titleSuggestions.map((suggestion) =>
                                                        <li onClick={() => { setResumeTitle('"' + suggestion + '"'); console.log(resumeTitle); setShowTitleSuggestions(false); sessionStorage.setItem("inputResumeTitle", '"' + suggestion + '"'); searchResContext.updateselectedFromSuggestions(true) }}>
                                                            {suggestion}
                                                        </li>
                                                    )}
                                                </ul> : ""}
                                        </Grid>

                                        <Grid item sm={12} md={12} lg={3} >

                                            <input type="text" class="form-control"
                                                onBlur={(e) => {
                                                    setTimeout(() => {
                                                        setShowTitleSuggestions(false)
                                                    }, [300])
                                                }}
                                                value={sessionStorage.getItem("skillNDescriptionSearch") ? sessionStorage.getItem("skillNDescriptionSearch") : Skills}
                                                placeholder="Skills, Boolean Eg: AND, OR"
                                                onChange={e => {
                                                    setSkills(e.target.value);
                                                    sessionStorage.setItem("skillNDescriptionSearch", e.target.value)
                                                    //  sessionStorage.setItem("inputResumeTitle", e.target.value)
                                                }}
                                            />

                                        </Grid>

                                        <Grid item sm={12} md={12} lg={3} >
                                            <div class="form-group">
                                                <input type="text" class="form-control" value={locationSuggestions !== "" ? locationSuggestions : cityname} id="exampleInputPassword1"
                                                    placeholder="City, State or Statename"
                                                    onBlur={(e) => {
                                                        setTimeout(() => {
                                                            setShowSuggestions(false)
                                                        }, [300])
                                                    }}
                                                    onChange={e => {
                                                        setCityName(e.target.value); getCityNames(e.target.value); setLocationSuggestions("");
                                                        sessionStorage.setItem("resumeCityName", e.target.value)
                                                    }} />
                                            </div>
                                            {showSuggestions === true ?
                                                <ul className="sm-12 col-md-4 mt-0 col-lg-3 suggestionList">
                                                    {citySuggestions.map((suggestion) =>
                                                        <li onClick={() => { setLocationSuggestions(suggestion); setShowSuggestions(false); sessionStorage.setItem("resumeCityName", suggestion) }}>
                                                            {suggestion}
                                                        </li>
                                                    )}
                                                </ul> : ""}
                                        </Grid>
                                        {/*<Col sm={12} md={2} style={{ marginTop: "5px" }} >
                                            {cityName !== "" ?
                                                <select class="form-select form-control" >
                                                    <option >Select Radius</option>
                                                    <option>
                                                        10 Miles
                                                    </option>
                                                    <option>
                                                        20 Miles
                                                    </option>
                                                    <option>
                                                        30 Miles
                                                    </option>
                                                    <option>
                                                        40 Miles
                                                    </option>
                                                    <option>
                                                        50 Miles
                                                    </option>
                                                </select>
                                                :
                                                <select class="form-select form-control" disabled>
                                                    <option >Select Radius</option>
                                                    <option>
                                                        10 Miles
                                                    </option>
                                                    <option>
                                                        20 Miles
                                                    </option>
                                                    <option>
                                                        30 Miles
                                                    </option>
                                                    <option>
                                                        40 Miles
                                                    </option>
                                                    <option>
                                                        50 Miles
                                                    </option>
                                                </select>



                                            }
                                        </Col>*/}

                                        <Grid item sm={12} md={2} lg={1} >

                                            <button type="submit" class="btn btn-button " id="_button" >
                                                Find Resumes
                                            </button>

                                        </Grid>

                                        {/* Check boxes*/}
                                        <div className="filters_">
                                            {/* <FormControlLabel className="fi"
                                                control={
                                                    <Checkbox
                                                        size="small"
                                                        name="fulltime"
                                                        value="fulltime"
                                                        style={mystyle}
                                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 15 } }}
                                                        checked={fulltime ? true : false}
                                                        onChange={() => setFullTime(!fulltime)}
                                                    />
                                                }
                                                label={<span style={{ fontSize: '12px', marginRight: '0px' }}>Full Time</span>}
                                            />
                                            <FormControlLabel className="fi"
                                                control={
                                                    <Checkbox
                                                        size="small"
                                                        name="parttime"
                                                        value="parttime"
                                                        style={mystyle}
                                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 15 } }}
                                                        checked={contract ? true : false}
                                                        onChange={() => setContract(!contract)}
                                                    />
                                                }
                                                label={<span style={{ fontSize: '12px', marginRight: '0px' }}>Contract</span>}
                                            />
                                          <FormControlLabel className="fi"
                                                control={
                                                    <Checkbox
                                                        size="small"
                                                        name="remote"
                                                        value="remote"
                                                        style={mystyle}
                                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 15 } }}
                                                    />
                                                }
                                                label={<span style={{ fontSize: '12px', marginRight: '0px' }}>Remote</span>}
                                            /> */}
                                            {/* <div
                                                className="jsfeatures">

                                                <p className="AdvancsearchTitle" onClick={() => pushAdvancedResume()}> <BiSearchAlt  />Advanced Search</p>



                                            </div>*/}
                                        </div>
                                    </Grid>
                                </form>
                            </div>
                        </Grid>
                    </div>
                </Container>

            </div >


        </div >
    )
}

function mapStateToProps(state, ownProps) {
    return {
        currentUser: state.UserReducer.user,
    };
}

const mapDispatchToProps = dispatch => {

    return {

        showLoader: () => dispatch(showLoader()),
        hideLoader: () => dispatch(hideLoader()),

    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Jssearchblock));

