import React, { useState, useEffect } from "react";
import Logo from "../Images/jobsnProfiles.png";
import "../Styles/Jsnavbar.css";
import { BiChevronDown } from "react-icons/bi";
import UserActions from "../controllers/user.controller";
import { withRouter,  Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
//import notification from '../Images/Icon material-settings.png'
import { MdOutlineNotificationsNone } from "react-icons/md";
import profileimg from "../Images/profiledef.png";
import axios from "axios";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import Key from "../clientVariables.json";
import NotificationImg from "../../src/Images/notification.png";
import { useTheme , useMediaQuery } from "@mui/material";
import { showLoader } from "../Components/Actions/loader.actions";
import { hideLoader } from "../Components/Actions/loader.actions";
import { BsList } from "react-icons/bs";
import Ampnavbar2 from "../Components/Ampnavbar2";

const Navbar = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Mobile devices
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md")); // Tablets
  const isDesktopSmall = useMediaQuery(theme.breakpoints.between("md", "lg")); // Small desktops
  const isDesktopLarge = useMediaQuery(theme.breakpoints.up("lg")); // Large
  const [scrollPosition, setScrollPosition] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    getNotifications();
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const dashboardClicked = () => {
    sessionStorage.removeItem("jobtitle");
    sessionStorage.removeItem("cityname");
    sessionStorage.removeItem("resumeCityName")
    sessionStorage.removeItem("skillNDescriptionSearch");
    sessionStorage.removeItem("filtered-jobtypes");
    sessionStorage.removeItem("filtered-visatypes");
    sessionStorage.removeItem("jobMappedDates");
    localStorage.removeItem("workModeFlages");
    sessionStorage.removeItem("filtered-workmode");
    localStorage.removeItem("jobtypeFlages");
    localStorage.removeItem("visatypeFlages");
    localStorage.removeItem("traveltypeFlages");
    // sessionStorage.removeItem("currentsearchpage")
    sessionStorage.removeItem("appliedJobsCount");
    sessionStorage.removeItem("appliedjobsPerPage");
    sessionStorage.removeItem("inputResumeTitle");
  };

  const mynetworkClicked = () => {
    sessionStorage.removeItem("myNetworkTabIndex");
  };

  const getNotifications = () => {
    axios
      .post(`${Key.domain}/Notifications/get_notifications`, {
        user_id: sessionStorage.getItem("id"),
      })
      .then((response) => {
        
        setNotifications(response.data.data.notifications);
        setNotificationsCount(response.data.data.notificationsCount);
        props.hideLoader();
      });
  };

  const updateNotificationReadStatus = (notificationid, job_id) => {
    props.showLoader();
    

    axios
      .post(`${Key.domain}/Notifications/update_notification_readstatus`, {
        user_id: sessionStorage.getItem("id"),
        notificationId: notificationid,
      })
      .then((response) => {
        
        getNotifications();
        // history.push({
        //   pathname: "/job/view/Job_" + job_id,
        //   state:{
        //     notification:"navabar"}
        // });
      });

    setTimeout(() => {
      props.history.push({
        pathname: "/job/view/Job_" + job_id,
      });
    }, 1000);
    
    
    
    
    
  };

  const viewAllNotifications = () => {
    props.history.push("/Notificationslist");
  };

  const pushtohome = () => {
    props.history.push("/home");
  };

  return (
    <>
      {(isDesktopLarge || isDesktopSmall) && (
        <nav
          class={
            scrollPosition > 80
              ? "navbar effect fixed-top navbar-expand-lg "
              : "navbar fixed-top navbar-expand-lg "
          }
        >
          <div class="container-fluid">
            <a
              class={
                scrollPosition > 80 ? "navbar-brand effect" : "navbar-brand"
              }
              style={{ cursor: "pointer" }}
              onClick={() => pushtohome()}
            >
              <img
                src={Logo}
                style={{
                  cursor: "pointer",
                  width: `${scrollPosition > 80 ? "100%" : ""}`,
                }}
              />
            </a>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon">
                <BsList color="#FFF" />
              </span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav">
                <li className="nav-item">
                  <Tooltip title="Dashboard">
                    <NavLink
                      // class="nav-link   mx-2"
                      exact
                      to="/Jsdashboard"
                      activeClassName="active-link-auth"
                      onClick={dashboardClicked}
                    >
                      {/* {scrollPosition > 80 ? (
                        <MdSpaceDashboard size="20" />
                      ) : ( */}
                        Dashboard
                      {/* )} */}
                    </NavLink>
                  </Tooltip>
                </li>

                <li class="nav-item">
                  <Tooltip title="Company H1 Visa">
                    <NavLink
                      exact
                      activeClassName="active-link mx-2"
                      to="/H1Visa"
                    >
                      {/* {scrollPosition > 80 ? (
                        <GrVisa size="20" />
                      ) : ( */}
                        Company H1 Visa
                      {/* )} */}
                    </NavLink>
                  </Tooltip>
                </li>

                <li className="nav-item">
                  <Tooltip title="File Manager">
                    <NavLink
                      // class="nav-link   mx-2"
                      to="/Filemanager"
                      activeClassName="active-link-auth"
                    >
                      {/* {scrollPosition > 80 ? (
                        <GoFileSubmodule size="20" />
                      ) : ( */}
                        File Manager
                      {/* )} */}
                    </NavLink>
                  </Tooltip>
                </li>
                <li className="nav-item">
                  <Tooltip title="My Network">
                    <NavLink
                      // class="nav-link   mx-2"
                      activeClassName="active-link-auth"
                      to="/My_network"
                      onClick={mynetworkClicked}
                    >
                      {/* {scrollPosition > 80 ? (
                        <IoIosPeople size="20" />
                      ) : ( */}
                        My Network
                      {/* )} */}
                    </NavLink>
                  </Tooltip>
                </li>
                <li className="nav-item">
                  <a class="nav-link   mx-2">
                    {notifications.length >= 0 && (
                      <div
                        class="dropdown notification-bell"
                        // style={{ float: "right" }}
                      >
                        <MdOutlineNotificationsNone
                          size={25}
                          style={{ cursor: "pointer" }}
                        />

                        <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                          {notificationsCount}
                          <span class="visually-hidden">unread messages</span>
                        </span>
                        <div class="dropdown-content">
                          <div
                            style={{
                              marginBottom: "12px",
                              padding: "4px",
                              borderBottom: "1px solid #ddd",
                            }}
                          >
                            <span>Notifications({notificationsCount})</span>
                            <span
                              style={{
                                float: "right",
                                fontSize: "12px",
                                cursor: "pointer",
                              }}
                              onClick={() => viewAllNotifications()}
                            >
                              View All
                            </span>
                          </div>

                          {notifications.map((notify) => (
                            <div
                              class="notification-list notification-list"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                notify.notification_subject !==
                                "Your recruiter deleted a Job"
                                  ? updateNotificationReadStatus(
                                      notify.id,
                                      notify.job_id
                                    )
                                  : ""
                              }
                            >
                              <div class="notification-list_img">
                                <img src={NotificationImg} alt="user" />
                              </div>

                              {notify.notification_read.includes(
                                sessionStorage.getItem("id")
                              ) ? (
                                <div class="notification-list_detail">
                                  <p>{notify.notification_subject}</p>
                                  <p>
                                    <small>
                                      {moment(notify.created).fromNow()}
                                    </small>
                                  </p>
                                </div>
                              ) : (
                                <div class="notification-list_detail">
                                  <p style={{ fontWeight: "700" }}>
                                    {notify.notification_subject}
                                  </p>
                                  <p style={{ marginTop: "-8px" }}>
                                    <small>
                                      {moment(notify.created).fromNow()}
                                      <span
                                        class="dot1"
                                        style={{
                                          float: "right",
                                          height: "10px",
                                          width: "10px",
                                          backgroundColor: "green",
                                          borderRadius: "50%",
                                        }}
                                      ></span>
                                    </small>
                                  </p>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </a>
                </li>

                <li class="nav-item dropdown" style={{ marginTop: "-8px" }}>
                  <a
                    class="nav-link dropdown-toggle mx-2"
                    href="#"
                    id="navbarDropdownMenuLink"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src={
                        sessionStorage.getItem("profileimg") &&
                        sessionStorage.getItem("profileimg") !== "null"
                          ? `${Key.files_domain}/dashboard/profile_image/` +
                            sessionStorage.getItem("id") +
                            "/" +
                            sessionStorage.getItem("profileimg")
                          : profileimg
                      }
                      id="profile_right"
                      class="rounded-circle"
                    />
                    <span>
                      <BiChevronDown />
                    </span>
                  </a>
                  <ul
                    class="dropdown-menu dropdown-menu-end "
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <li>
                      <Link
                        class="dropdown-item"
                        to="/accountSettings"
                        id="_dropdown"
                      >
                        Account Settings
                      </Link>
                      <li class="nav-item">
                        <Link
                          class="dropdown-item"
                          onClick={props.logout}
                          id="_dropdown"
                        >
                          Logout
                        </Link>
                      </li>
                    </li>
                  </ul>
                </li>

                {/* <li className="nav-item">
                <button className="btn get">OPT Portal</button>
              </li> */}
              </ul>
            </div>
          </div>
        </nav>
      )}
      {isTablet && <Ampnavbar2 />}

      {isMobile && <Ampnavbar2 />}
    </>
  );
};

const mapDispatchToProps = function (dispatch) {
  return {
    logout: () => {
      UserActions.logout()(dispatch);
    },
    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),
  };
};

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.UserReducer.user,
    isLoading: state.UserReducer.isLoginLoading,
    iserror: state.UserReducer.error,
  };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar));
