import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import ToastMessage from '../../Components/ToastMessage';

const LoginSchema = Yup.object().shape({

	textarea: Yup.string()

		.required("Message is Required"),
});




class Message extends React.Component {
	render() {
		return (
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<Formik
							initialValues={{ email: "", password: "", textarea: "" }}
							validationSchema={LoginSchema}
							onSubmit={(values) => {
								console.log(values);
								// alert("Message sent!");
								let msg = "Message sent!"
								toast(
									<ToastMessage message={msg} />
								)
								this.props.handlemessageClose()
							}}
						>
							{({ touched, errors, isSubmitting, values }) =>

								<div>
									<Form>


										<div className="form-group">
											<label>Message</label><span style={{ color: "red" }}>*</span>
											<Field
												as="textarea"
												name="textarea"
												placeholder="Enter message"
												className={`mt-2 form-control
						${touched.textarea && errors.textarea
														? "is-invalid"
														: ""
													}`}

											/>
											<ErrorMessage
												component="div"
												name="textarea"
												className="invalid-feedback"
											/>
										</div>

										<button
											type="submit"
											className="btn btn-primary btn-block mt-4"
									style={{float:'right', padding:'5px 20px 5px 20px'}}

										>
											Send
										</button>
									</Form>
								</div>

							}
						</Formik>

					</div>
				</div>
			</div>
		);
	}
}

export default Message;
