// // export const SET_PRIMARY_RESUME = 'SET_PRIMARY_RESUME';

// // export const setPrimaryResumeDetails = (resumeDetails) => ({
// //     type: SET_PRIMARY_RESUME,
// //     payload: resumeDetails,
// //   });

//   // export const updatePrimaryResume = (updatedResume) => ({
//   //   type: 'UPDATE_RESUME',
//   //   payload: updatedResume,
//   // });
  
//   // export const getPrimaryResume = () => ({
//   //   type: 'GET_RESUME',
//   // });

//   // resumeActions.js

// import axios from "axios";
// import Key from "../../clientVariables.json";

// // Action types
// export const UPDATE_RESUME = 'UPDATE_RESUME';
// export const GET_RESUME_SUCCESS = 'GET_RESUME_SUCCESS';
// export const GET_RESUME_FAILURE = 'GET_RESUME_FAILURE';

// // Action creators
// export const updatePrimaryResume = (updatedResume) => ({
//   type: UPDATE_RESUME,
//   payload: updatedResume,
// });

// export const getPrimaryResumeSuccess = (resumeDetails) => ({
//   type: GET_RESUME_SUCCESS,
//   payload: resumeDetails,
// });

// export const getPrimaryResumeFailure = (error) => ({
//   type: GET_RESUME_FAILURE,
//   payload: error,
// });

// // Async action for getting resume
// export const getPrimaryResume = () => {
//   return async (dispatch) => {
//     try {
//       const response = await axios.post(`${Key.domain}/dashboard/get_js_dashboard_details`, {
//         user_id: sessionStorage.getItem('id'),
//         session_token: sessionStorage.getItem('userToken'),
//       });

//       const response1 = await axios.post(`${Key.domain}/resume/get_js_basic_resume_details`, {
//         user_id: sessionStorage.getItem('id'),
//         session_token: sessionStorage.getItem('userToken'),
//         resumeid: response.data.data.userDetails.resumeid,
//       });

//       dispatch(getPrimaryResumeSuccess(response1.data.data.resumedetails));
//     } catch (error) {
//       dispatch(getPrimaryResumeFailure(error.message));
//     }
//   };
// };
// primaryResumeActions.js

import axios from "axios";
import Key from "../../clientVariables.json";


export const UPDATE_RESUME_REQUEST = 'UPDATE_RESUME_REQUEST';
export const UPDATE_RESUME_SUCCESS = 'UPDATE_RESUME_SUCCESS';
export const UPDATE_RESUME_FAILURE = 'UPDATE_RESUME_FAILURE';
export const GET_RESUME_SUCCESS = 'GET_RESUME_SUCCESS';
export const GET_RESUME_FAILURE = 'GET_RESUME_FAILURE';


export const updateResumeRequest = () => ({
  type: UPDATE_RESUME_REQUEST,
});

export const updateResumeSuccess = (updatedResume) => ({
  type: UPDATE_RESUME_SUCCESS,
  payload: updatedResume,
});

export const updateResumeFailure = (error) => ({
  type: UPDATE_RESUME_FAILURE,
  payload: error,
});

export const getPrimaryResumeSuccess = (resumeDetails) => ({
  type: GET_RESUME_SUCCESS,
  payload: resumeDetails,
});

export const getPrimaryResumeFailure = (error) => ({
  type: GET_RESUME_FAILURE,
  payload: error,
});


export const updatePrimaryResume = (updatedResumeId) => {
  return async (dispatch) => {
    try {
      dispatch(updateResumeRequest());

      const user = JSON.parse(sessionStorage.getItem('user'))
      const response = await axios.post(`${Key.domain}/resume/set_primary_resume`, {
        session_token: user.session_token,
        resumeid: updatedResumeId,
        primary: 1,
        user_id: sessionStorage.getItem("id"),
      });
      const response1 = await axios.post(
        `${Key.domain}/resume/get_js_resumes_list`, {
          session_token:  user.session_token,
          infinite_scroll_index: 1,
          user_id: sessionStorage.getItem("id"),
          sortby: 1,
        });
        
        const resumeIdsNotPrimary = response1.data.data.filter((resume, index) => resume.isPrimary === 0).map((resume) => resume.resume_id);

      const response2 =await axios
      .post(`${Key.domain}/solr/solr_update_searchflag_primary_resume`, {
        primaryresumeid: updatedResumeId,
        otherresumeids: resumeIdsNotPrimary,
      });

      const response4 = await axios.post(`${Key.domain}/resume/get_js_basic_resume_details`, {
        user_id: sessionStorage.getItem('id'),
        session_token: user.session_token,
        resumeid: updatedResumeId,
      });
      
      dispatch(updateResumeSuccess(response4.data.data.resumedetails));

      
      dispatch(getPrimaryResume());
    } catch (error) {
      dispatch(updateResumeFailure(error.message));
    }
  };
};


export const getPrimaryResume = () => {
  return async (dispatch) => {
    try {
      const user = JSON.parse(sessionStorage.getItem('user'))

      const response = await axios.post(`${Key.domain}/dashboard/get_js_dashboard_details`, {
        user_id: sessionStorage.getItem('id'),
        session_token: user.session_token,
      });

      const response1 = await axios.post(`${Key.domain}/resume/get_js_basic_resume_details`, {
        user_id: sessionStorage.getItem('id'),
        session_token: user.session_token,
        resumeid: response.data.data.userDetails.resumeid,
      });

      dispatch(getPrimaryResumeSuccess(response1.data.data.resumedetails));
    } catch (error) {
      dispatch(getPrimaryResumeFailure(error.message));
    }
  };
};
