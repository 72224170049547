// USER
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const IS_LOGINLOADING = "IS_LOGINLOADING"
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";



//Resume

export const GET_MYFILE_RESUME_DETAILS = "GET_MYFILE_RESUME_DETAILS";
export const UPDATE_FILEPROFILE_SUCCESS = "UPDATE_FILEPROFILE_SUCCESS";
export const MYFILE_LOADING = "MYFILE_LOADING";

//Company

export const GET_COMPANY_JOBS = "GET_COMPANY_JOBS";
